import { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Card, CardActionArea, CardContent, CardMedia,
  Avatar,
  Typography, Link,
  SxProps,
} from "@mui/material"
import { $ipfs } from "../../../components/utils";
import { makeStyles } from "@material-ui/styles";
import { UiContext } from "../../../context/app";
import { useAppContext } from "../../../components/hooks";
import { FavoriteIconButton } from "../../../components/favorite-button";

interface SquareCardProps {
  target?: string,
  type?: 'collection' | 'nft' | 'profile',
  image?: string,
  animation?: string,
  logo?: any,
  title?: string,
  favorites?: string,
  href?: string,
  randomColor?: string,
  mediaSx?: SxProps,
}
const DEFAULT_NFT_IMG_URL = '/assets/images/bb/img_default/default_NFT.png';
const DEFAULT_PROFILE_IMG_URL = '/assets/images/bb/img_default/default_profiles.png';
export const SquareCardView = ({
  target, type,
  image, animation, logo, title,
  favorites, href,
  randomColor, mediaSx,
}: SquareCardProps) => {
  const { mobile } = useContext(UiContext);
  const { wallet } = useAppContext();
  const { account } = wallet;

  const cardRef = useRef<any>();
  const [cardMediaWidth, setCardMediaWidth] = useState();

  const isProfile = type === 'profile'
  const classes = useStyle({ width: cardMediaWidth, mobile, randomColor, profile: isProfile });
  const imageUrl = $ipfs(image) ||
    (isProfile ? DEFAULT_PROFILE_IMG_URL : DEFAULT_NFT_IMG_URL);
  const videoPlayer = (event: any, state?: string) => {
    let playPromise = event.target.play();
    if (playPromise !== undefined && state === 'play')
      playPromise.then(() => { }).catch((error: any) => { })
    if (playPromise && state === 'pause')
      playPromise.then(() => { event.target.pause() }).catch((error: any) => { })
  }

  useEffect(() => {
    setCardMediaWidth(cardRef?.current?.clientWidth)
    window.addEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth))
    return () => window.removeEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth));
  }, [])
  return (
    <Card ref={cardRef} className={classes.card}>
      <CardActionArea component={Link} href={href} className={classes.cardActionArea}>
        <Box sx={{ position: 'relative' }}>

          <CardMedia
            component='video'
            className={classes.cardMedia}
            sx={{ ...mediaSx }}
            src={$ipfs(animation)}
            poster={imageUrl}
            onError={(e: any) => {
              e.currentTarget.onerror = null;
              e.target.poster = isProfile ? DEFAULT_PROFILE_IMG_URL : DEFAULT_NFT_IMG_URL;
            }}
            onClick={(event: any) => videoPlayer(event, 'play')}
            onMouseEnter={(event: any) => videoPlayer(event, 'play')}
            onMouseLeave={(event: any) => videoPlayer(event, 'pause')}
            loop />
          <Box className={classes.mobileShadow} />
        </Box>
        <CardContent className={classes.cardContent}>
          <Box className={classes.titleBox}>
            <Avatar src={logo} className={classes.logo} />
            <Typography
              variant={
                mobile ? 'subtitle2'
                  : (isProfile ? 'subtitle1' : 'h6')
              }
              className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Typography variant={mobile ? 'subtitle2' : 'h6'} className={classes.favorite}>
            <FavoriteIconButton account={account || undefined} target={target || ''} type={type} />
            {favorites}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card >
  )
};

const useStyle = makeStyles({
  card: {
    background: 'transparent',
    overflow: 'visible',
  },
  cardActionArea: {
    overflow: 'visible'
  },
  cardMedia: {
    borderRadius: '12px',
    objectFit: 'cover',
    height: ({ width }: any) => width,
    boxShadow: ({ mobile, randomColor }: any) => randomColor ? ((mobile ? '4px -4px ' : '7px -7px ') + randomColor) : '',
  },
  mobileShadow: ({ mobile }: any) =>
    mobile && ({
      position: 'absolute', bottom: 0,
      width: '100%',
      height: '100%',
      boxShadow: 'inset 0 -50px 50px -50px #000',
      pointerEvents: 'none',
    }),
  cardContent: {
    position: 'relative',
    display: 'flex',
    background: 'transparent',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: ({ mobile, profile }) => !mobile && profile && 0,
    paddingRight: ({ mobile, profile }) => !mobile && profile && 0,
  },
  titleBox: {
    position: ({ mobile }: any) => mobile ? 'relative' : 'static',
    top: '-15px',
    left: '-12px',
    display: 'flex',
    alignItems: 'center',
    gap: ({ mobile, profile }: any) => profile ? (mobile ? '10px' : '12px') : (mobile ? '7px' : '31px'),
  },
  logo: {
    position: ({ mobile }: any) => mobile ? 'relative' : 'static',
    top: '-10px',
    border: '2px solid #000'
  },
  title: {
  },
  favorite: {
    position: ({ mobile }: any) => mobile ? 'absolute' : 'static',
    top: '-31px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: ({ mobile }: any) => mobile ? '4.6px' : '18px'
  }

})