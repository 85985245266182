import { useContext } from "react";
import { Box, Container, Avatar, Typography, Link, AppBar, Button, Toolbar } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { CommunitiyButtonGroup } from "../../../components/buttons-communities";
import { NamedUser } from "../../../components/named-user";
import { UiContext } from "../../../context/app";
import { INftCollection } from "../../../models";
import { useAppContext } from "../../../components/hooks";


interface CollectionInfoProps {
  collection: INftCollection,
}
const CollectionToolbar = ({ href }: { href: string }) => {
  const { strings } = useContext(UiContext);
  return (
    <AppBar position='fixed' color={'inherit'} sx={{ top: '48px', zIndex: 2 }}>
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' href={href}>{strings('ADD_ITEM')}</Button>
      </Toolbar>
    </AppBar>
  )
}

export default ({ collection }: CollectionInfoProps) => {
  const { mobile } = useContext(UiContext);
  const { wallet } = useAppContext();

  const FLEX_DIRECTION = mobile ? 'column' : 'row';
  const ALIGN_ITEMS = mobile ? 'center' : 'flex-end';
  const AVATAR_MR = mobile ? 0 : '33px';
  const COMMUNITY_BOX_SX_MOBILE = { display: 'flex', justifyContent: 'center', mt: '-35px' }
  const COMMUNITY_BOX_SX_DEFAULT = { position: 'relative', right: '-70%', top: '-35px' };
  const COMMUNITY_BOX_SX = mobile ? COMMUNITY_BOX_SX_MOBILE : COMMUNITY_BOX_SX_DEFAULT;
  const COMMUNITY_VARIANT = mobile ? 'contain' : 'outlined';
  const isMineCol = collection?.owner === wallet.account;
  const colInfoMT = isMineCol ? '49px' : 0;
  const toolbar = isMineCol && <CollectionToolbar href={`nft/${collection?.address}/mint`} />

  return (
    <>
      {toolbar}
      <Box sx={{ mt: colInfoMT, mb: 4, position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          flex: '0 0 250px',
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${collection?.bannerImage})`,
          borderBottom: '1px solid black',
        }} />

        <Container maxWidth='md' sx={{ alignItems: 'center' }}>
          <Box sx={{
            position: 'relative',
            left: 0,
            top: '-66px'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: FLEX_DIRECTION,
              alignItems: ALIGN_ITEMS
            }}>
              <Avatar
                component={Link}
                sx={{
                  height: 132, width: 132,
                  border: '4px solid white',
                  mr: AVATAR_MR
                }}
                src={collection?.meta?.image}
                href={`/profile/${collection?.owner}`} />
              <Link variant='h4' sx={{ fontWeight: 'bold' }} href={`/profile/${collection?.owner}`} underline='none'>
                <NamedUser account={collection?.owner} />
              </Link>
              {
                !mobile &&
                <Box sx={{
                  mt: 'auto',
                  mb: '6px',
                  width: '7px',
                  height: '24px',
                  ml: '21.5px',
                  mr: '23.5px',
                  backgroundImage: 'linear-gradient(to bottom, #c8c8c8, #808080)'
                }} />
              }
              <Typography variant='h6'>
                {collection?.meta?.name}
              </Typography>
            </Box>
          </Box>
          <Box sx={COMMUNITY_BOX_SX}>
            <CommunitiyButtonGroup
              color='secondary'
              variant={COMMUNITY_VARIANT}
              sx={{ color: 'secondary.main' }}
              communities={collection?.communities} />
          </Box>
          <Container>
            <ReactMarkdown>
              {
                collection?.meta?.description ?
                  collection.meta.description.replace(/\\n/g, "\n") : ''
              }
            </ReactMarkdown>
          </Container>
        </Container>
      </Box>
    </>
  )
}

