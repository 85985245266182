import { useEffect, useState } from "react";
import { useAppContext } from "../../components/hooks";
import { INftToken, ISellConfigs } from "../../models";
import { listListing, purchase } from "../../services/market";
import { createView } from "../../views"

const { default: NftListView } = createView('nft-listing-view');

export const NftListingCard = ({ nft }: { nft: INftToken }) => {
  const context = useAppContext()
  const [listings, setListings] = useState<ISellConfigs[]>([]);

  const { chain, contract, tokenId } = nft;
  const options = { chain, contract, tokenId };

  useEffect(() => {
    listListing(context, options)
      .then((rs) => setListings(rs.items))
      .catch((error) => console.error(error))
  }, [nft])

  return (
    <NftListView nft={nft} listings={listings} />
  )
}