import { where } from "firebase/firestore";
import { ExploreOptions, listOptions } from ".";
import { 
  IContext, 
  ActivityType, 
  IBidConfigs, 
  INftCollection, 
  INftToken, 
  INftTokenActivities, 
  INftTokenPricingHistory, 
  IOfferConfigs, 
  IPaged, 
  ISellConfigs, 
  Summaries, 
  IUser
} from "../../../models";
import { documents, document } from "../../db-utils";
import { readMarket, readNftWithRoality, readResaller, readWeb3 } from "../crypto-readonly";
import { collectionId, nftId } from "../func-pure";

export const listOffers =
async (context: IContext, options: listOptions & { from?: string }):
  Promise<IPaged<IOfferConfigs>> => {
  const { chain, contract, tokenId, from } = options;
  const queryArgs: any[] = [];

  if(!chain || !contract || !tokenId) 
    throw new Error('Invalid Listing Options.');

  const opt = {
    page: 0,
    perPage: 50,
    target: nftId(chain, contract, tokenId),
    from
  }

  if (opt.target)
    queryArgs.push(where('target', '==', opt.target));

  if (opt.from)
    queryArgs.push(where('from', '==', opt.from));

  const items = await documents('offers', { queryArgs });

  return {
    page: opt.page,
    perPage: opt.perPage,
    pages: 99999, // @deprecated
    total: 99999, // @deprecated
    hasNext: false, // @todo handle hasNext
    items
  };
}


export const listBiddings =
async (context: IContext, options: listOptions & { bidder?: string }):
  Promise<IPaged<IBidConfigs>> => {
  let { chain, contract, tokenId, bidder } = options;
  if(!chain || !contract || !tokenId) 
    throw new Error('Invalid Listing Options.');
  const target = nftId(chain, contract, tokenId);

  const queryArgs: any[] = [];
  const opt = {
    page: 0,
    perPage: 50,
    ...options
  }

  if (opt.bidder)
    queryArgs.push(where('bidder', '==', opt.bidder));

  const items = (await documents(`sells/${target}/bids`, { queryArgs })) as IBidConfigs[];

  return {
    page: opt.page,
    perPage: opt.perPage,
    pages: 99999, // @deprecated
    total: 99999, // @deprecated
    hasNext: false, // @todo handle hasNext
    items
  };
}


export const listListing =
async (context: IContext, options: listOptions & { listingId: string, seller?: string }):
  Promise<IPaged<ISellConfigs>> => {
  const { seller : address} = options;

  const queryArgs: any[] = [];
  queryArgs.push(where('bookTxInfo.seller', '==', address));

  const sells = await documents('sells', { queryArgs });

  for (const sell of sells) {
    const data = await document('nfts', sell.id) as INftToken;

    if (!data)
      throw new Error('Token not exist.');

    if (data.chain && data.contract) {
      const colId = collectionId(data.chain, data.contract);
      const colData = await document('collections', colId) as INftCollection;

      if (!!colData?.meta?.name) {
        if (!data.summaries) data.summaries = {};
        data.summaries[Summaries.COLLECTION_NAME] = colData.meta.name;
      }
    }
    sell.nft = data;
  }

  return {
    page: 0,
    perPage: sells.length,
    pages: 1,
    total: sells.length,
    hasNext: false,
    items: sells
  };
}



export const listActivities =
async (context: IContext, options: listOptions & { types?: ActivityType[] }):
  Promise<IPaged<INftTokenActivities>> => {
  const { chain, contract, tokenId } = options;
  let { types } = options;

  if (!types || types.length === 0)
    types = [ActivityType.Transfer, ActivityType.Booked, ActivityType.Deal];

  if (!chain || !contract || !tokenId) throw new Error("Token data incorrect.");

  const web3 = readWeb3(chain);
  const market = readMarket(chain);
  const resaller = readResaller(chain);
  const nftBase = readNftWithRoality(chain, contract);

  let evts: any[] = [];
  let tokenIndex = await market.methods.indexToken(contract, tokenId).call();;

  if (types.indexOf(ActivityType.Transfer) !== -1) {
    let transEvts = await nftBase.getPastEvents('Transfer', {
      filter: { tokenId: tokenId },
      fromBlock: 'earliest'
    });
    evts = [...evts, ...transEvts];
  }

  if (types.indexOf(ActivityType.Deal) !== -1) {
    let dealEvts = await market.getPastEvents('Deal', {
      filter: { tokenIndex, nftContract: contract },
      fromBlock: 'earliest'
    });
    evts = [...evts, ...dealEvts]
  }

  if (types.indexOf(ActivityType.Booked) !== -1) {
    let bookEvts = await resaller.getPastEvents('Booked', {
      filter: { tokenIndex, nftContract: contract },
      fromBlock: 'earliest'
    });
    evts = [...evts, ...bookEvts];
  }

  evts.sort((cur, next) => cur.blockNumber - next.blockNumber);

  let activities = [];

  for (const evt of evts) {
    let { blockNumber, returnValues, event } = evt;
    let { timestamp } = await web3.eth.getBlock(blockNumber);

    let activity: INftTokenActivities = {
      // todo: check posibility if timestamp been represented as string
      timestamp: timestamp as number,
      id: evts.indexOf(evt).toString(),
      type: (<any>ActivityType)[event]
    };

    activity.from = returnValues.seller;
    activity.to = returnValues.buyer;

    if (returnValues.priceOptions?.length >= 1)
      activity.price = returnValues.priceOptions[0];

    if (returnValues.price)
      activity.price = returnValues.price;

    if (activity.type === ActivityType.Transfer) {
      activity.from = returnValues.from;
      activity.to = returnValues.to;

      if (returnValues.from === '0x0000000000000000000000000000000000000000')
        activity.type = ActivityType.Minted;
    }

    activities.push(activity);
  }

  return {
    page: 0,
    perPage: 99999,
    pages: 0,
    total: 10,
    hasNext: false, // @todo handle hasNext
    items: activities,
  };
}

export const listPricings =
async (context: IContext, options: listOptions):
  Promise<INftTokenPricingHistory[]> => {
    const { chain, contract, tokenId } = options;
    const { market, web3 } = context;

    if(!chain || !contract || !tokenId) 
      throw new Error('Invalid Listing Options.');
  
    let indexToken = await market.methods.indexToken(contract, tokenId).call();;

    let evts = await market.getPastEvents('Deal', {
      filter: { tokenIndex: indexToken, nftContract: contract },
      fromBlock: 'earliest'
    });

    const pricings: INftTokenPricingHistory[] = [];

    for await (const evt of evts) {
      const { blockNumber, returnValues } = evt;
      const { timestamp } = await web3.eth.getBlock(blockNumber);

      pricings.push({
        timestamp: timestamp,
        price: returnValues.price
      });
    }

    return pricings.sort((
      cur: INftTokenPricingHistory,
      next: INftTokenPricingHistory
    ) => cur.timestamp - next.timestamp);
  }



  export const exploreNfts = 
    (context: IContext, options: ExploreOptions):
      Promise<IPaged<INftToken>> => 
        Promise.reject(new Error('NotImplement'));

  export const exploreCollections = 
    (context: IContext, options: ExploreOptions):
      Promise<IPaged<INftCollection>> =>
        Promise.reject(new Error('NotImplement'));

  export const exploreProfiles = 
    (context: IContext, options: ExploreOptions):
      Promise<IPaged<IUser>> => 
        Promise.reject(new Error('NotImplement'));