import { Typography } from "@mui/material"
import { StaticMessage } from "../../components/static-message"

export const TabFavorited = () => 
  <StaticMessage>
    <Typography variant="h4" align="center">
      COMING SOON
    </Typography>
    <Typography variant="body1" align="center">
      Favorited item feature is on the way, please stay tuned for updates.
    </Typography>
  </StaticMessage>