import { useContext } from "react";
import { Box, Alert, Link, Tooltip, Typography, Stack, Card, CardActions, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { NamedUser } from "../../../components/named-user";
import { $price } from "../../../components/utils";
import { UiContext } from "../../../context/app"
import { INftToken, ISellConfigs, SellMethods } from "../../../models";
import { convertEth } from "../../../services/market";
import { DataGridHeader } from "../styled-components";
import { ListingBuyButton } from "../button-buy";
import { useAppContext } from "../../../components/hooks";
import { CancelBookButton } from "../button-cancel-book";
import { ListingBidButton } from "../button-bid";

const MomentTooltip = ({ time }: any) =>
  <Tooltip title={moment.unix(time).format('lll')} arrow placement='top'>
    <Typography>{moment.unix(time).fromNow()}</Typography>
  </Tooltip>

export default ({ listings, nft }: { listings: ISellConfigs[], nft: INftToken }) => {
  const { strings } = useContext(UiContext);
  const { wallet } = useAppContext();
  const ListAction = ({ listing }: any) =>
    listing &&
    (
      listing.seller === wallet.account ?
        <CancelBookButton
          nft={nft}
          buttonProps={{ variant: 'outlined' }}
          target={listing.id}>
          {strings('CANCEL')}
        </CancelBookButton>
        :
        listing.method === SellMethods.SELL_TO_HIGHEST_BIDDER ?
          <ListingBidButton
            nft={nft}
            listing={listing}
            buttonProps={{ variant: 'outlined' }}>
            {strings('PLACE_BID')}
          </ListingBidButton>
          : <ListingBuyButton
            nft={nft}
            listing={listing}
            buttonProps={{ variant: 'outlined' }}>
            {strings('BUY')}
          </ListingBuyButton>)

  const listingColumns: GridColDef[] = [
    {
      field: 'unitPrice',
      headerName: strings('DATAGRID_UNIT_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderHeader: DataGridHeader,
      valueGetter: (params) => $price(params.row.price),
      renderCell: (params) =>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEthereum} />
          <Typography>{params.value} ETH</Typography>
        </Stack>,
    },
    {
      field: 'USDUnitPrice',
      headerName: strings('DATAGRID_USD_UNIT_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderHeader: DataGridHeader,
      valueGetter: (params) => convertEth(params.row.price),
      renderCell: (params) => <Typography>{`$ ${params.value}`}</Typography>
    },
    {
      field: 'quantity',
      headerName: strings('DATAGRID_QUANTITY'),
      editable: false,
      sortable: false,
      minWidth: 150,
      renderHeader: DataGridHeader,
      valueGetter: (params) => params.row.quantity,
    },
    {
      field: 'expiration',
      headerName: strings('DATAGRID_EXPIRATION'),
      editable: false,
      sortable: false,
      minWidth: 150,
      renderHeader: DataGridHeader,
      renderCell: (params) => <MomentTooltip time={params.row.duration?.end} />,
    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      editable: false,
      sortable: false,
      renderHeader: DataGridHeader,
      valueGetter: (params) => params.row.seller,
      renderCell: (params) =>
        <Link href={`/profile/${params.value}`}>
          <NamedUser account={params.value} />
        </Link>
    },
    {
      field: ' ',
      editable: false,
      sortable: false,
      minWidth: 120,
      renderCell: (params) => {
        return <ListAction listing={params.row} />
      }
    }
  ]

  return (
    <Box sx={{ flex: 1, flexWrap: 'nowrap', overflowX: 'scroll' }}>
      <DataGrid
        columns={listingColumns}
        rows={listings}
        disableColumnMenu
        hideFooter
        autoHeight
        selectionModel={'none'}
        sx={{ borderWidth: 0 }}
        components={{
          NoRowsOverlay: () =>
            <Alert severity="info">
              {strings('NO_OFFERS')}
            </Alert>,
        }}
      />
    </Box>
  )
}