import { Box, Button, ButtonGroup, Link, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { MoreVert, Web } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Communities } from '../../models';
import { useState } from 'react';
import { strings } from '../../strings';


const icons = {
  WEBSITE: <Web />,
  DISCORD: <FontAwesomeIcon style={{ color: "inherit" }} icon={faDiscord} size='lg' />,
  TWITTER: <FontAwesomeIcon style={{ color: "inherit" }} icon={faTwitter} size='lg' />,
  INSTAGRAM: <FontAwesomeIcon color="inherit" icon={faInstagram} size='lg' />,
}

export const CommunitiyButtonGroup = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isOpen = Boolean(anchorEl);
  const communities = [
    'WEBSITE', 'DISCORD', 'TWITTER', 'INSTAGRAM'
  ]
  if (!props.communities || Object.keys(props.communities).length === 0) return <></>;

  return (
    // @todo hide 
    // < Button
    //   variant='outlined'
    //   sx={{ ...props.sx, maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px' }
    //   }
    //   onClick={(event: any) => setAnchorEl(event.currentTarget)}
    //   onMouseLeave={() => setAnchorEl(null)}
    // >
    //   <MoreVert />
    //   <Popper
    //     open={isOpen}
    //     anchorEl={anchorEl}
    //     placement='bottom-start'
    //     onMouseLeave={() => setAnchorEl(null)}
    //   >
    //     <Paper sx={{ py: 0 }}>
    //       <MenuList>
    //         {Object.keys(props.communities).map((key: string) => (
    //           props.communities &&
    //           props.communities[key] &&
    //           <MenuItem
    //             key={key}
    //             onClick={() => {
    //               setAnchorEl(null)
    //             }}
    //             sx={{ color: 'inherit', textTransform: 'none' }}
    //           >
    //             <Link href={props.communities[key]} underline='none' sx={{ display: 'flex', flexDirection: 'row' }}>
    //               <ListItemIcon>{icons[key as Communities]}</ListItemIcon>
    //               <ListItemText>{strings(key)}</ListItemText>
    //             </Link>
    //           </MenuItem>
    //         ))}
    //       </MenuList >
    //     </Paper>
    //   </Popper>
    // </Button >
    <ButtonGroup variant={props.variant || 'outlined'} sx={{ ...props.sx }} {...props} >
      {communities.map(item =>
        !!props.communities &&
        !!props.communities[item] &&
        <Button
          key={item}
          size='large'
          href={props.communities[item]}>
          {icons[item as Communities]}
        </Button>
      )}
    </ButtonGroup >
  )
}