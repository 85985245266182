import { createTheme } from "@mui/material";
import type { } from '@mui/x-data-grid/themeAugmentation';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#7f7f7d',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    divider: '#d1d1d1',
  },
  typography: {
    h4: { fontSize: '32px', fontWeight: 'bold' }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
        }
      }
    }
  }
});
