import { Box, Typography } from "@mui/material";

export const DataGridHeader = (params: any) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{
      width: '5px',
      height: '11px',
      background: 'linear-gradient(to bottom, #c8c8c8, #808080)',
      mr: 1,
    }} />
    <Typography sx={{ textOverflow: 'ellipsis', fontSize: '13px' }}>
      {params.colDef.headerName}
    </Typography>
  </Box>