import { makeStyles } from "@material-ui/styles";
import { Box, Avatar, Badge, Button, Typography, } from "@mui/material"
import { useContext } from "react";
import { NamedUser } from "../../../components/named-user";
import { $ipfs } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftCollection } from "../../../models";

const CARD_WIDTH = '142px';
const CARD_WIDTH_MOBILE = '90px';

export const CircleCardView = ({ collection, index }: { collection?: INftCollection, index?: number }) => {
  const { mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });
  const href = `/collection/${collection?.chain}/${collection?.address}`;

  return (
    <Box className={classes.cardBox} >
      <Button className={classes.cardButton} href={href}>
        <Box>
          <Typography variant='subtitle1'>{index}</Typography>
          <Badge
            className={classes.avatarBadge}
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={<Box className={classes.avatarBadgeContent} />}
            invisible={true}>
            <Avatar className={classes.avatar} src={$ipfs(collection?.meta?.image)} />
          </Badge>
        </Box>
        <Box sx={{ textAlign: 'center', paddingTop: mobile ? '10px' : '12px' }}>
          <Typography sx={{ fontSize: mobile ? '16px' : '20px' }} className={classes.collectionInfo}>{collection?.meta?.name}</Typography>
          <Typography sx={{ fontSize: mobile ? '14px' : '16px' }} className={classes.collectionInfo}>
            <NamedUser account={collection?.owner} />
          </Typography>
        </Box>
      </Button>
    </Box>
  )
};

const useStyle = makeStyles({
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'fit-content',
    gap: '16px',
  },
  cardButton: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    textTransform: 'none'
  },
  avatarBadge: {
    marginTop: '-10px'
  },
  avatarBadgeContent: {
    height: '27px',
    width: '27px',
    background: '#51ff58',
    borderRadius: '50%',
    border: '4px solid #000'
  },
  avatar: ({ mobile }: any) => ({
    minHeight: mobile ? CARD_WIDTH_MOBILE : CARD_WIDTH,
    minWidth: mobile ? CARD_WIDTH_MOBILE : CARD_WIDTH,
  }),
  collectionInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: ({ mobile }: any) => mobile ? CARD_WIDTH_MOBILE : CARD_WIDTH,
  }
})