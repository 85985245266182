export enum OfferStatus {
  OFFERING = "OFFERING",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
}

export interface IOfferOptions {
  target: string; // hash(contract+tokenId)
  duration: { begin: number, end:number },
  price: string; // BN,
  currency?: string;
  quantity?: number;
}

export interface IOfferConfigs extends IOfferOptions {
  id?: string;
  from: string; // address,
  timestamp: number; // TBD
  status: OfferStatus;
  version?: number;
}