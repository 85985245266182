import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { INftToken, IContext } from "../../models";
import { authByWallet } from "./service-auth";

export const batchUpload = async (context: IContext, tokens:INftToken[]): Promise<void> => {
  await authByWallet(context);
  const db = getFirestore();
  const batch = writeBatch(db);
  tokens.forEach(token => {
    if(!token.id) return;
    batch.set(doc(db, 'nfts', token.id), token);
  });
  await batch.commit();
}