import { Chains } from "../base-types";

export enum Sorting {
  PRICE_LOW_TO_HIGH = "PRICE_LOW_TO_HIGH",
  PRICE_HIGH_TO_LOW = "PRICE_HIGH_TO_LOW",
  RECENTLY_LISTED = "RECENTLY_LISTED",
  RECENTLY_CREATED = "RECENTLY_CREATED",
  RECENTLY_SOLD = "RECENTLY_SOLD",
  RECENTLY_RECEIVED = "RECENTLY_RECEIVED",
  ENDING_SOON = "ENDING_SOON",
  HIGHEST_LAST_SALE = "HIGHEST_LAST_SALE",
  MOST_VIEWED = "MOST_VIEWED",
  MOST_FAVORITED = "MOST_FAVORITED",
  OLDEST = "OLDEST",
  VOLUME_TRADED_HIGH_TO_LOW = "VOLUME_TRADED_HIGH_TO_LOW",
  TRENDING_AUCTIONS = "TRENDING_AUCTIONS",
}

export enum Status {
  UNSPECIFIED = "UNSPECIFIED",
  BUY_NOW = "BUY_NOW",
  ON_AUCTION = "ON_AUCTION",
  NEW = "NEW",
  HAS_OFFER = "HAS_OFFER",
}

export interface IPagingOptions {
  page?: number;
  perPage?: number;
}

export interface IPaged<T> extends IPagingOptions {
  pages: number;
  total: number;
  hasNext: boolean;
  items: T[];
}

export interface IListOptions extends IPagingOptions {
  collectionId?: string; // deprecated
  id?: string;
  owner?: string;
  search?: string;
  chain?: Chains | number;
  creator?: string;
  tokenId?: string;
  contract?: string;
  tags?: string[];
  sorting?: Sorting;
  range?: string[];
  status?: Status;
  state?: string;
}