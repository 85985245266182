import { useContext } from "react";
import {
  Box, Typography, Select, SelectProps, InputAdornment, Tooltip, Accordion, AccordionDetails, AccordionSummary
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { makeStyles } from '@material-ui/styles';
import { BBIcon } from "../../assets/icons/bb";
import { UiContext } from "../../context/app";

export const DataGridHeader = (params: any) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{
      width: '5px',
      height: '11px',
      background: 'linear-gradient(to bottom, #c8c8c8, #808080)',
      mr: 1,
    }} />
    <Typography sx={{ textOverflow: 'ellipsis', fontSize: '13px' }}>
      {params.colDef.headerName}
    </Typography>
  </Box>

export const SelectBB = (props: SelectProps) => {
  let isOpen = false;
  return (
    <Select
      {...props}
      onOpen={() => isOpen = true}
      onClose={() => isOpen = false}
      IconComponent={() => <img src='/assets/images/bb/filter-more-green.svg'
        style={{ position: 'absolute', right: '8px', transform: isOpen ? 'rotate(180deg)' : 'none', pointerEvents: 'none' }} />
      }>
      {props.children}
    </Select >
  )
};

export const StyledTooltip = (props: any) => {
  const { children, title, tooltipProps } = props;
  return (
    <Tooltip
      {...props}
      placement='top'
      enterTouchDelay={0}
      PopperProps={{ sx: { "& .MuiTooltip-tooltip": { maxWidth: '80vw' } } }}
      title={title || ''}>
      {children}
    </Tooltip>
  )
}

export const BlendTitleTypography = (props: any) => {
  const { title } = props;
  return (
    <Box sx={{ position: 'relative', padding: 0, margin: 0 }}>
      <Typography
        {...props}
        sx={{
          position: 'absolute',
          top: '1px',
          left: '-1px',
          mixBlendMode: 'screen',
          color: '#ff0000',
          whiteSpace: 'nowrap',
          fontSize: props.fontSize,
          fontWeight: 'bold'
        }}>{title}</Typography>
      <Typography
        {...props}
        sx={{
          mixBlendMode: 'screen',
          color: '#00ff00',
          whiteSpace: 'nowrap',
          fontSize: props.fontSize,
          fontWeight: 'bold'
        }}>{title}</Typography>
      <Typography
        {...props}
        sx={{
          position: 'absolute',
          top: '-1px',
          left: '1px',
          mixBlendMode: 'screen',
          color: '#0000ff',
          whiteSpace: 'nowrap',
          fontSize: props.fontSize,
          fontWeight: 'bold'
        }}>{title}</Typography>
    </Box>
  )
}

interface AdvanceOptionProps {
  title: string,
  children?: any,
  accordionProps?: any,
  expandIcon?: object,
}

export const AdvanceOption = ({ title, children, accordionProps, expandIcon }: AdvanceOptionProps) => {
  const { mobile } = useContext(UiContext);
  const classes = mobile ? drawerStyle() : deskStyles();
  return (
    <Accordion {...accordionProps} className={classes.accordion} >
      <AccordionSummary expandIcon={expandIcon || <ExpandMore />} className={classes.summary}>
        <Typography variant='subtitle2'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
const deskStyles = makeStyles({
  accordion: {
    background: '#252525',
    boxShadow: 'none',
    '.MuiPaper-root.MuiAccordion-root::before': {
      backgroundColor: '#f7f7f7',
    },
    '&:last-child': {
      boxShadow: 'none',
    },
    '&.Mui-expanded': {
      backgroundColor: '#2D2D2D',
    }
  },
  summary: {
    background: 'transparent',
    border: '0px',
    padding: '8px 26px 8px 26px',
    '&.Mui-expanded': {
      background: 'transparent',
    }
  },
  details: {
    background: 'transparent',
    padding: '16px 26px 30px 26px'
  }
});

const drawerStyle = makeStyles({
  accordion: {
    background: 'transparent',
    borderTop: '1px solid #5c5c5c',
    borderBottom: '1px solid #5c5c5c',
    '&:before': {
      display: 'none',
    },
    '&:first-child': {
      boxShadow: 'none',
      borderRadius: 0,
    },
    '&:last-child': {
      boxShadow: 'none',
      borderBottom: '1px solid #5c5c5c',
      borderRadius: 0,
    },
    '&:not(last-child)': {
      borderBottom: 0,
    },
    '&.Mui-expanded': {
      backgroundColor: '#2D2D2D',
    }
  },
  summary: {
    background: 'transparent',
    padding: '8px 26px 8px 26px',
    '&.Mui-expanded': {
      background: 'transparent',
    },
    '&:last-child': {
      boxShadow: 'none',
    },
  },
  details: {
    background: 'transparent',
    padding: '16px 26px 30px 26px',
  }
})