const appName = process.env.REACT_APP_NAME?.toLowerCase() || 'soyl';
const spacings: { [key: string]: any } = {
  'bb': {
    'DETAIL_CONTAINER_MT': '60px',
    'DETAIL_OWNCONTAINER_MT': '60px',
    'DETAIL_COL_SPACING': '38px',
    'DETAIL_ROW_SPACING': '11px',
  },
  'soyl': {
    'DETAIL_CONTAINER_MT': '0px',
    'DETAIL_OWNCONTAINER_MT': '75px',
    'DETAIL_COL_SPACING': '40px',
    'DETAIL_ROW_SPACING': '16px',
  },
  'sw': {
    'DETAIL_CONTAINER_MT': '0px',
    'DETAIL_OWNCONTAINER_MT': '75px',
    'DETAIL_COL_SPACING': '40px',
    'DETAIL_ROW_SPACING': '16px',
  },
};


export const createSpacings = () => spacings[appName];

