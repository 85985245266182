import { ExploreOptions, listOptions } from ".";
import { ActivityType, BookStates, IBidConfigs, IContext, INftCollection, INftToken, INftTokenActivities, INftTokenPricingHistory, IOfferConfigs, IPaged, ISellConfigs, IUser, Sorting, Summaries } from "../../../models";
import { document, documentsMatched } from "../../db-utils";
import { contractAt, createReadOnly } from "../crypto";
import { asBiddingConfigs, asOfferConfigs, asSellConfigs } from "../../../models/trading";
import { orderBy, where } from "firebase/firestore";
import { fillNftInfo } from "../service-info";
import { nftId } from "../func-pure";
import { _getNftCollectionSummary } from "../service-live-info";
import NftTrade from '../../../contracts/NftTrade.json';
import moment from "moment";
import { readWeb3, readMarket, readResaller } from "../crypto-readonly";

const zeroAddress = '0x0000000000000000000000000000000000000000';

const OLD_CONTRACTS = [
  '0x8bd54bcB46E44150784dc47cE0Ac81Dc756bC460',
  '0x8Dbf6686106d1204AfcD3f57c6e7b31323C672c4',
  '0xf5002317F40285dCeDb97644a8a16251592A2B3a',
];

export async function listOffers(
    context: IContext, 
    options: listOptions & { from?: string }
  ):Promise<IPaged<IOfferConfigs>> {
    // /listing/${target}/offering
    // Structure in Firebase: OfferConfig
    // Structure for output: IOfferConfigs
    const { chain, contract: nftContract, tokenId, from: buyer} = options;

    const offers:IOfferConfigs[] = await documentsMatched(
      `/offering`, 
      { chain, nftContract, tokenId, buyer }, 
      { parse: (data) => asOfferConfigs(data)}
    );

    return {
      page: 0,
      pages: 1,
      perPage: 9999,
      total: offers.length,
      hasNext: false,
      items: offers
    };
  }

// @deprecated
export async function listBiddings(
    context: IContext, 
    options: listOptions & { bidder?: string }
  ):Promise<IPaged<IBidConfigs>> {
    // /listing/${target}/bidding
    const { chain, contract: nftContract, tokenId, bidder: buyer } = options;

    const biddings = await documentsMatched(
      `/bidding`,
      { chain, nftContract, tokenId, buyer},
      { parse: data => asBiddingConfigs(data) }
    );
      
    return {
      page: 0,
      pages: 1,
      perPage: 9999,
      total: biddings.length,
      hasNext: false,
      items: biddings
    };
  }

export async function listListing(
    context: IContext, 
    options: listOptions & { seller?: string }
  ):Promise<IPaged<ISellConfigs>> {
    const { chain, contract: nftContract, tokenId, seller } = options;

    const listing:ISellConfigs[] = await documentsMatched(
      `/listing`, 
      { chain, nftContract, tokenId, seller, bookState: BookStates.BOOKED }, 
      { parse: (data) => asSellConfigs(data)}
    );
      
    return {
      page: 0,
      pages: 1,
      perPage: 9999,
      total: listing.length,
      hasNext: false,
      items: listing
    };
  }

export async function listActivities(
    context: IContext, 
    options: listOptions & { types?: ActivityType[] }
  ):Promise<IPaged<INftTokenActivities>>{
    const { chain, contract: nftContract, tokenId } = options;
    let { types } = options;

    if(!chain)
      throw new Error("Invalid Listing Options");
    if (!types || types.length === 0)
      types = [ActivityType.Transfer, ActivityType.Booked, ActivityType.Deal];
  
    let events = [];
    let listings: ISellConfigs[]  = [];
    let oldEvts: INftTokenActivities[] = [];

    try{
      const tradeReader = contractAt({chain, artifacts: NftTrade});
      
      if( nftContract && OLD_CONTRACTS.includes(nftContract) ){
        oldEvts = await _listOldActivities(options)
      }
      if( types.includes(ActivityType.Deal) )
        events = await tradeReader.getPastEvents('Dealed', {
          filter: {nftContract, tokenId},
          fromBlock: 'earliest',
          toBlock: 'latest'
        });
    } catch (err) {
      console.error(err);
    }

    if( types.includes(ActivityType.Booked) )
      listings= await documentsMatched(
        `/listing`, 
        { chain, nftContract, tokenId, bookState: BookStates.BOOKED }, 
        { parse: (data) => asSellConfigs(data)}
      ); 

    const activitiesListing: INftTokenActivities[] = listings.map((e:any) => (
       { 
        id: e.id,
        type: ActivityType.Booked,
        from: e.seller,
        price: e.price,
        timestamp: e.timestamp
      }
    ))
    const activitiesOnTrade:INftTokenActivities[] = events.map((e:any) => {
      const { event, blockNumber } = e;
      const { 
        seller: from,
        buyer: to,
        dealedPrice: price,
        dealedTime: timestamp
      } = e.returnValues;
      return {
        id: `${event}:${tokenId}:${blockNumber}`,
        type: ActivityType.Deal,
        from, to, price,
        timestamp
      };
    });

    const activitiesOnNftContract:INftTokenActivities[] = await (async () => {
      if(!nftContract || !types.includes(ActivityType.Transfer)) return [];

      const nftc = await createReadOnly(chain, nftContract);
      const transferEvts = await nftc.transferEvents({tokenId, withStamp: true});
      return transferEvts.map(e => {
        let type = (<any>ActivityType)[e.event];
        if(e.from === zeroAddress)
          type = ActivityType.Minted; 

        return({
        id: `${e.event}:${e.tokenId}:${e.blockNumber}`,
        type,
        from: e.from,
        to: e.to,
        timestamp: e.timestamp,
      })});
    })();

    const activities = [
      ...activitiesListing,
      ...activitiesOnTrade,
      ...oldEvts, 
      ...activitiesOnNftContract
    ].sort((a,b) => a.timestamp - b.timestamp);

    return {
      page: 0,
      pages: 1,
      perPage: 9999,
      total: activities.length,
      hasNext: false,
      items: activities
    };
}

export async function listPricings(
    context: IContext, 
    options: listOptions
  ):Promise<INftTokenPricingHistory[]> {
    const { chain, contract, tokenId } = options;

    if(!chain || !contract || !tokenId)
      throw new Error("Invalid Listing Options");

    const tradeReader = contractAt({chain, artifacts: NftTrade});
    const events = await tradeReader.getPastEvents('Dealed', {
      filter: {nftContract: contract, tokenId},
      fromBlock: 'earliest',
      toBlock: 'latest'
    });
    const pricing:INftTokenPricingHistory[] = events.map((e:any) => {
      const { event, blockNumber} = e;
      const { dealedPrice: price, dealedTime: timestamp } = e.returnValues;
      return {
        id: `${event}:${tokenId}:${blockNumber}`,
        price,
        timestamp
      };
    });

    return pricing;
}

async function _listOldActivities(
  options: listOptions & { types?: ActivityType[] })
  : Promise<INftTokenActivities[]> {
  const { chain, contract: nftContract, tokenId } = options;
  let { types } = options;

  if (!types || types.length === 0)
    types = [ActivityType.Booked, ActivityType.Deal];

  if (!chain || !nftContract || !tokenId) throw new Error("Token data incorrect.");

  const web3 = readWeb3(chain);
  const market = readMarket(chain);
  const resaller = readResaller(chain);

  let evts: any[] = [];
  let activities: INftTokenActivities[] = [];
  let tokenIndex = await market.methods.indexToken(nftContract, tokenId).call();;

  if (types.includes(ActivityType.Deal)) {
    let dealEvts = await market.getPastEvents('Deal', {
      filter: { tokenIndex, nftContract },
      fromBlock: 'earliest'
    });
    evts = [...evts, ...dealEvts]
  }

  if (types.includes(ActivityType.Booked)) {
    let bookEvts = await resaller.getPastEvents('Booked', {
      filter: { tokenIndex, nftContract },
      fromBlock: 'earliest'
    });
    evts = [...evts, ...bookEvts];
  }

  evts.sort((cur, next) => cur.blockNumber - next.blockNumber);


  for (const evt of evts) {
    let { blockNumber, returnValues, event } = evt;
    let { timestamp } = await web3.eth.getBlock(blockNumber);

    let activity: INftTokenActivities = {
      timestamp: timestamp as number,
      id: evts.indexOf(evt).toString(),
      type: (<any>ActivityType)[event]
    };

    activity.from = returnValues.seller;
    activity.to = returnValues.buyer;

    if (returnValues.priceOptions?.length >= 1)
      activity.price = returnValues.priceOptions[0];

    if (returnValues.price)
      activity.price = returnValues.price;

    activities.push(activity);
  }
  return (activities);
}

const _baseOn = (sorting:Sorting) => {
  let searchBase = 'nfts'; // listing
  let queryArgs = [];

  switch(sorting){
    case Sorting.PRICE_HIGH_TO_LOW:
      searchBase = 'listing';
      queryArgs.push(where('bookState', '==', BookStates.BOOKED));
      queryArgs.push(orderBy('price', 'desc'));
      break;
    case Sorting.PRICE_LOW_TO_HIGH:
      searchBase = 'listing';
      queryArgs.push(where('bookState', '==', BookStates.BOOKED));
      queryArgs.push(orderBy('price', 'asc'));
      break;
    case Sorting.ENDING_SOON:
      searchBase = 'listing';
      queryArgs.push(where('bookState', '==', BookStates.BOOKED));
      // queryArgs.push(where('beginTime', '<', moment().unix()));
      queryArgs.push(where('expireTime', '>', moment().unix()));
      queryArgs.push(orderBy('expireTime', 'asc'));
      break;
    case Sorting.HIGHEST_LAST_SALE:
      searchBase = 'nfts';
      queryArgs.push(orderBy('summaries.TOPEST_DEALED_PRICE', 'desc'));
      break;
    case Sorting.MOST_FAVORITED:
      searchBase = 'nfts';
      queryArgs.push(orderBy('summaries.FAVORITED', 'desc'));
      break;
    case Sorting.MOST_VIEWED:
      searchBase = 'nfts';
      queryArgs.push(orderBy('summaries.VIEWED', 'desc'));
      break;
    case Sorting.OLDEST:
      searchBase = 'nfts';
      queryArgs.push(orderBy('timestamp', 'asc'));
      break;
    case Sorting.RECENTLY_CREATED:
      searchBase = 'nfts';
      queryArgs.push(orderBy('timestamp', 'desc'));
      break;
    case Sorting.RECENTLY_LISTED:
      searchBase = 'listing';
      queryArgs.push(where('bookState', '==', BookStates.BOOKED));
      queryArgs.push(orderBy('createAt', 'desc'));
      break;
    case Sorting.RECENTLY_SOLD:
      searchBase = 'nfts';
      queryArgs.push(orderBy('summaries.LATEST_DEALED_TIME', 'desc'));
      break;
  }
  return [searchBase, queryArgs];
}

export const exploreNfts = async (context:IContext, options:ExploreOptions):
  Promise<IPaged<INftToken>> => {
    // Spec: There're two different source
    // NFT = /nfts/:id(=hash(chain,contract,tokenId))/<INftToken>
    // Sale = /listing/:uuid/<SaleConfig>
    const queryArgs:any[] = [];
    const { page = 0, perPage = 50, highlights, matches, ranges, sorting = Sorting.RECENTLY_CREATED } = options;
    const [collectionPath, args] = _baseOn(sorting);

    // todo: add highlights support
    if(!!highlights)
      queryArgs.push(where('id', 'in', highlights));
    
    // Note: Special handle to options.matches.contract
    // if (listing) convert attribute "contract" to "nftContract"
    const mapping:{[key:string]:string} = {
      "listing.contract": "nftContract"
    };
    const attrMap = (path:string, attr:string):string => mapping[`${path}.${attr}`] || attr;
    
    if(!!matches) 
      for(let attr in matches) 
        queryArgs.push(where(attrMap(collectionPath as string, attr), '==', matches[attr]));
    
    if(!!ranges)
      for(let i = 0; i < ranges.length; i++) {
        let range = ranges[i];
        if(range.exclude) {
          queryArgs.push(where(range.field, '<', range.lowerbound));
          queryArgs.push(where(range.field, '>', range.upperBound));
        } else {
          queryArgs.push(where(range.field, '>=', range.lowerbound));
          queryArgs.push(where(range.field, '<=', range.upperBound));
        }
      }

    queryArgs.push(...args as any[]);

    const results = await documentsMatched(
      collectionPath as string, {}, { page, perPage, queryArgs });

    if(collectionPath === 'nfts') {
      const items = await Promise.all(results.map(r => fillNftInfo(context, r)));
      return {
        pages: page, 
        total: items.length + perPage * page , 
        hasNext: !(items.length < perPage), 
        items,
      }
    }

    if(collectionPath === 'listing') {
      const items = await Promise.all(results.map(async r => {
        const nftInfo = await document('nfts', nftId(r.chain, r.nftContract, r.tokenId));
        const summaries = await _getNftCollectionSummary(nftInfo);
        const saleOptions = asSellConfigs(r);
        nftInfo.summaries = {
          ...nftInfo.summaries,
          ...summaries
        }
        nftInfo.saleOptions = saleOptions;
        return nftInfo;
      }));

      return {
        pages: 1, 
        total: 9999, 
        hasNext: false, 
        items,
      }
    }

    return {
      pages: 1,
      total: 0,
      hasNext: false,
      items: []
    };
  }


export const exploreCollections = 
  async (context: IContext, options: ExploreOptions):
  Promise<IPaged<INftCollection>> => {
    const queryArgs:any[] = [];
    const { page = 0, perPage = 50, highlights, matches, ranges, sorting = Sorting.VOLUME_TRADED_HIGH_TO_LOW } = options;
    const collectionPath = 'collections';
    
    if(!!highlights)
      queryArgs.push(where('id', 'in', highlights));

    if(!!ranges)
      for(let i = 0; i < ranges.length; i++) {
        let range = ranges[i];
        if(range.exclude) {
          queryArgs.push(where(range.field, '<', range.lowerbound));
          queryArgs.push(where(range.field, '>', range.upperBound));
        } else {
          queryArgs.push(where(range.field, '>=', range.lowerbound));
          queryArgs.push(where(range.field, '<=', range.upperBound));
        }
      }
    
    switch(sorting) {
      case Sorting.HIGHEST_LAST_SALE:
        queryArgs.push(orderBy(`summaries.${Summaries.LATEST_DEALED_PRICE}`, 'desc'));
        break;
      case Sorting.MOST_FAVORITED:
        queryArgs.push(orderBy(`summaries.${Summaries.FAVORITED}`, 'desc'));
        break;
      case Sorting.MOST_VIEWED:
        queryArgs.push(orderBy(`summaries.${Summaries.VISITED}`, 'desc'));
        break;
      case Sorting.OLDEST:
        queryArgs.push(orderBy(`timestamp`, 'asc'));
        break;
      case Sorting.RECENTLY_CREATED:
        queryArgs.push(orderBy(`timestamp`, 'desc'));
        break;
      case Sorting.RECENTLY_SOLD:
        queryArgs.push(orderBy(`summaries.${Summaries.LATEST_DEALED_TIME}`, 'desc'));
        break;
      case Sorting.VOLUME_TRADED_HIGH_TO_LOW:
        queryArgs.push(orderBy(`summaries.${Summaries.TOTAL_DEALED_AMOUNT}`, 'desc'));
        break;
      default:
        throw new Error('Given sorting not supported.');
    }

    const results:INftCollection[] = await documentsMatched(
      collectionPath as string, matches, { page, perPage, queryArgs });

    return {
      page,
      perPage,
      pages: 99,
      total: 9999,
      hasNext: results.length >= perPage,
      items: results
    }
  }

  
export const exploreProfiles = 
  async (context: IContext, options: ExploreOptions):
  Promise<IPaged<IUser>> => {
    const queryArgs:any[] = [];
    const { page = 0, perPage = 50, highlights, matches, ranges, sorting } = options;
    const collectionPath = 'users';
    
    if(!!highlights)
      queryArgs.push(where('address', 'in', highlights));

    if(!!ranges)
      for(let i = 0; i < ranges.length; i++) {
        let range = ranges[i];
        if(range.exclude) {
          queryArgs.push(where(range.field, '<', range.lowerbound));
          queryArgs.push(where(range.field, '>', range.upperBound));
        } else {
          queryArgs.push(where(range.field, '>=', range.lowerbound));
          queryArgs.push(where(range.field, '<=', range.upperBound));
        }
      }
    
    switch(sorting) {
      case Sorting.MOST_FAVORITED:
        queryArgs.push(orderBy(`summaries.${Summaries.FAVORITED}`, 'desc'));
        break;
      case Sorting.MOST_VIEWED:
        queryArgs.push(orderBy(`summaries.${Summaries.VISITED}`, 'desc'));
        break;
      // default:
      //   throw new Error('Given sorting not supported.');
    }

    const results:IUser[] = await documentsMatched(
      collectionPath as string, matches, { page, perPage, queryArgs });

    return {
      page,
      perPage,
      pages: 99,
      total: 9999,
      hasNext: results.length >= perPage,
      items: results
    }
  }