import { Avatar, Box, Button, Card, CardActions, CardActionArea, CardContent, CardHeader, CardMedia, Container, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../components/hooks";
import { PageBase } from "../../components/page-base";
import { StaticMessage } from "../../components/static-message";
import { $addr, $ipfs, $summary, useStrings } from "../../components/utils";
import { Chains, IListOptions, INftCollection, Summaries } from "../../models";
import { listCollections } from "../../services/market";
import { strings } from "../../strings";


const CollectionCard = ({ item, onClick }: any) => {
  return (
    <Card onClick={onClick}>
      <CardMedia
        sx={{ objectFit: 'cover', height: 200, width: '100%' }}
        component="img"
        image={$ipfs(item.bannerImage)}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {item.meta && item.meta.image && <Avatar alt="logo" src={item.meta.image} />}
        <Typography variant="h5">{item?.meta?.name || $addr(item?.address)}</Typography>
        <Typography>
          {strings("MY_COLLECTIONS_BY")}
          <Link underline="none" href="/profile">{strings("MY_COLLECTIONS_YOU")}</Link>
        </Typography>
        <Typography>{strings("MY_COLLECTIONS_DESCRIPTION").replace("[name]", item.meta.name)}</Typography>
        <Typography>{strings("MY_COLLECTIONS_TOTAL_SUPPLY").replace("[total]", $summary(item, Summaries.TOTAL_SUPPLY, 0))}</Typography>
      </CardContent>
    </Card>
  )
}

export const PageMyCollections = () => {
  const context = useAppContext();
  const { wallet } = context
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [collections, setCollections] = useState<INftCollection[]>([])

  const reload = async () => {
    if (!wallet.isConnected() && !wallet.account) return;

    try {
      setError(null);
      setLoading(true);
      const res = await listCollections(context, {
        owner: wallet.account || undefined,
        chain: wallet.chainId,
      });
      setCollections(res.items);
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => { reload() }, [wallet])

  return (
    <PageBase loading={loading} error={error} onReload={reload}>

      <Container>
        <Grid container my={5}>
          <Grid item xs={12} md={4}>

            <Card>
              <CardHeader
                title={strings("MY_COLLECTIONS_TITLE")}
                subheader="Create and manage collections of unique NFTs to share and sell."
              />
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  href="/collection/create">
                  {strings("MY_COLLECTIONS_CREATE_BUTTON")}
                </Button>
              </CardActions>
            </Card>

          </Grid>

          {
            collections.length === 0 &&
            <Grid item xs={12} md={8}>
              <StaticMessage sx={{ m: 0 }}>
                <Typography variant="h4" align="center">
                  NO COLLECTION FOUND
                </Typography>
                <Typography variant="body1" align="center">
                  You haven't create any collection yet.
                </Typography>
              </StaticMessage>
            </Grid>
          }
        </Grid>
      </Container>

      <Container>
        <Grid container spacing={2} sx={{ my: 1 }}>
          {collections.map((item) =>
            <Grid key={item.id} item xs={12} md={4}>
              <CollectionCard
                item={item}
                onClick={() => navigate(`/collection/${item.chain}/${item.address}`)} />
            </Grid>)
          }
        </Grid>
      </Container>
    </PageBase>
  )
}