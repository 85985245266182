import { TypeDefines } from "../../../../models/typed";
import { contractAt, ContractAtProps } from "./contractat";

type SignProps = ContractAtProps & {
  contract?:any,
  signer:string,
  type:'Sale'|'Offer'|'MintData',
  message:any,
  data?:string,
}

export const signTypedData = (options:SignProps):Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const { web3, chain, address, signer:from, type:primaryType, message:msg, data } = options;
    
    if(!web3)
      return reject(new Error('Must provide web3'));
    
    if(!(primaryType in TypeDefines))
      return reject(new Error(`Type ${primaryType} not supported.`));

    const contract = !!options.contract ? 
      options.contract : 
      contractAt(options);
      
    const domain = {
      name: await contract.methods.name().call(),
      version: await contract.methods.version().call(),
      chainId: chain,
      verifyingContract: contract.options.address,
    };
    
    const types:{[key:string]:any} = {};
    types.EIP712Domain = TypeDefines.EIP712Domain;
    types[primaryType] = TypeDefines[primaryType];

    const message:any = {...msg};
    if(!!data) message.data = data;

    const typedData = {
      domain,
      message,
      primaryType,
      types
    };

    web3.currentProvider.sendAsync({
      method: 'eth_signTypedData_v4',
      params: [from, JSON.stringify(typedData)],
      from
    }, (err:any, result:any) => {

      if(err) 
        return reject(err);
        
      if(result.error)
        return reject(result.error);
      
      resolve(result.result);
    });

  });
}