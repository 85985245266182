import { useContext, useEffect } from 'react';
import {
  Box, Fab,
  LinearProgress, CircularProgress,
  Backdrop,
  useTheme,
} from "@mui/material";
import { PageError } from '../page-error';
import { ScrollTop } from "../../utils/scroll-top";
import { MobileMenu } from "./mobile-menu";
import { Paths, UiContext } from "../../context/app";
import { PageNavCard } from './page-nav-card';
import { FooterCard } from './buttom-bar-card';
import { PageBaseBackgroundCard } from './background-card';
import { INftToken } from '../../models';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../hooks';
import { trackPageView } from '../../services/market/service-tracking';
import { KeyboardArrowUp } from '@mui/icons-material';

export const Row = (props: any) =>
  <Box {...props} sx={{
    display: 'flex',
    flexDirection: 'row',
    ...props.sx,
  }}>
    {props.children}
  </Box>;

export const Column = (props: any) =>
  <Box {...props} sx={{
    display: 'flex',
    flexDirection: 'column',
    ...props.sx,
  }}>
    {props.children}
  </Box>;


type PageBaseProps = {
  navProps?: any;
  loading?: boolean;
  coverLoading?: boolean;
  error?: any;
  onReload?: () => void;
  children?: any,
  sx?: any
  chainId?: string,
  contract?: string,
  nft?: INftToken,
  availableAmount?: number,
  onSaleTokens?: number,
  expectMaxSupply?: number,
}

export const PageBase = (props: PageBaseProps) => {
  const {
    sx,
    loading, coverLoading,
    error, onReload, children,
    chainId, contract, nft,
    availableAmount, onSaleTokens, expectMaxSupply
  } = props;
  const context = useAppContext();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname + location.search;
    trackPageView(context, path);
  }, []);

  let content = children;

  if (error)
    content = <PageError error={error} onReload={onReload} />

  return (
    <Column sx={{ minHeight: '100vh', ...sx }}>

      <MobileMenu />

      <Box id="back-to-top-anchor" />

      <PageNavCard
        chainId={chainId}
        contract={contract}
        nft={nft}
        availableAmount={availableAmount}
        onSaleTokens={onSaleTokens}
        expectMaxSupply={expectMaxSupply} />

      {
        coverLoading ?
          <Backdrop open={!!loading} sx={{ zIndex: 1300, flex: 1 }}>
            <CircularProgress size={80} />
          </Backdrop>
          :
          loading && <LinearProgress color='secondary'
            sx={{
              position: 'fixed',
              top: 0,
              zIndex: 1300,
              height: '8px',
              width: '100%',
            }} />
      }
      <PageBaseBackgroundCard>
        {content}
      </PageBaseBackgroundCard>

      <Box sx={{ flex: 1 }}>&nbsp;</Box>

      <FooterCard />


      <ScrollTop>
        <Fab sx={{ background: 'transparent', ':hover': { background: 'transparent' } }}>
          <KeyboardArrowUp />
          {/* <img src='/assets/images/bb/btn-top.svg' /> */}
        </Fab>
      </ScrollTop>

    </Column >
  );
};