import { useCallback, useContext, useState } from "react";
import { AppBar, Box, Toolbar, Link, IconButton, InputAdornment, OutlinedInput, Drawer, Button, useTheme, Typography } from "@mui/material"
import { Clear, DensityMedium, Search } from "@mui/icons-material";
import { makeStyles } from "@material-ui/styles";
import { IUser } from "../../../../models";
import { useAppContext } from "../../../../components/hooks";
import { ListDrawer, UserButton } from ".";
import { BBIcon } from "../../../../assets/icons/bb";
import { UiContext } from "../../../../context/app";


const SearchDrawer = ({ open, close }: any) => {
  const { strings } = useContext(UiContext);
  const classes = useStyles();
  const theme = useTheme();

  const [search, setSearch] = useState('');

  const inputRef = useCallback((node) => {
    if (open && node) {
      const timeout = setTimeout(() => { node.focus() }, 100);
      return () => { clearTimeout(timeout) };
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor="left"
      sx={{ zIndex: theme.zIndex.appBar - 1 }}
      onClose={() => close(false)}
      PaperProps={{
        className: classes.drawerPaper
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <BBIcon icon='ic_search' size='32px' />
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 'bold'
          }}>
          {strings('SEARCH_TITLE')}
        </Typography>
      </Box>
      <Box className={classes.searchBox}>
        <OutlinedInput
          value={search}
          inputRef={inputRef}
          className={classes.searchInput}
          endAdornment={
            <InputAdornment position='end'>
              {
                search &&
                <IconButton onClick={() => setSearch('')}>
                  <Clear sx={{ color: '#fff' }} />
                </IconButton>
              }
            </InputAdornment>
          }
          onChange={e => { setSearch(e.target.value) }}
        />
        <Button
          disabled={search == ''}
          variant='outlined'
          sx={{ borderRadius: '8px', marginTop: '20px', height: '45px' }}
          onClick={() =>
            window.location.replace(`/collection/?search=${search}`)} >
          {strings('SEARCH')}
        </Button>
      </Box>
    </Drawer >
  )
}

export default ({ user }: { user: IUser | null }) => {
  const { wallet } = useAppContext();
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpenSearchDrawer, setIsOpenSearchDrawer] = useState<boolean>(false);
  return (
    <>
      <SearchDrawer open={isOpenSearchDrawer} close={setIsOpenSearchDrawer} />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar} >
          <Box className={classes.box}>
            <IconButton onClick={() => setOpenDrawer(true)}>
              <DensityMedium />
            </IconButton>
            <IconButton onClick={() => setIsOpenSearchDrawer(!isOpenSearchDrawer)}>
              <BBIcon icon='ic_search' size='22px' />
            </IconButton>
          </Box>
          <Link className={classes.logoLink} href='/'>
            <img src='/assets/images/bb/logo_bigamefi.svg' className={classes.logo} />
          </Link>
          <Box className={classes.box}>
            <UserButton wallet={wallet} user={user || undefined} />
          </Box>
        </Toolbar>
        <ListDrawer user={user || undefined} open={openDrawer} close={setOpenDrawer} />
      </AppBar>
    </>

  )
};

const useStyles = makeStyles({
  drawerPaper: {
    width: '100%',
    padding: '85px 20px 0 20px',
    background: 'rgba(0, 0, 0, 0.95)',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: '43px',
  },
  searchInput: {
    maxHeight: '50px',
    backgroundColor: '#5C5C5C',
    borderRadius: '8px',
    '& fieldset': {
      border: 0,
      borderRadius: '8px',
    }
  },
  appBar: {
    position: 'sticky',
    background: 'transparent',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    borderEndStartRadius: 10,
    borderEndEndRadius: 10,
  },
  toolbar: {
    position: 'relative',
    height: '55px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderEndStartRadius: 10,
    borderEndEndRadius: 10,
    opacity: 0.95,
    background: 'linear-gradient(to bottom, #383838 0%, #000 100%)',
  },
  box: {
    display: 'flex',
    alignItems: 'center'
  },
  logoLink: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    width: '36vw',
    maxHeight: '40px'
  },
  iconPerson: {
    width: '24px',
    height: '24px'
  },
})