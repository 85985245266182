import { Box } from "@mui/material";
import { Paragraph } from ".";

export const EnPolicy = () => <Box py={'26.5px'}>
  <Paragraph>
    At SOYL, accessible from SOYL, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by SOYL and how we use it.<br/>
    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
    This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in SOYL. This policy is not applicable to any information collected offline or via channels other than this website.
  </Paragraph>
  <Paragraph title={'Consent'}>
    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
  </Paragraph>
  <Paragraph title={'Information we collect'}>
    The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
    If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.<br/>
    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
  </Paragraph>
  <Paragraph title={'How we use your information'}>
    We use the information we collect in various ways, including to:<br />
    ■ Provide, operate, and maintain our website<br />
    ■ Improve, personalize, and expand our website<br />
    ■ Understand and analyze how you use our website<br />
    ■ Develop new products, services, features, and functionality<br />
    ■ Communicate with you, either directly or through one of our partners, including for customer service, to provide you with
    updates and other information relating to the website, and for marketing and promotional purposes<br />
    ■ Send you emails<br />
    ■ Find and prevent fraud<br />
  </Paragraph>
  <Paragraph title={'Log Files '}>
    SOYL follows a standard procedure of using log files. These files log visitors when they visit websites. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
  </Paragraph>
  <Paragraph title={'Cookies Cookie'}>
    Like any other website, SOYL uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.<br/>
    For more general information on cookies, please read the Cookies article on Generate Privacy Policy website.
  </Paragraph>
  <Paragraph title={'Advertising Partners Privacy Policies '}>
    You may consult this list to find the Privacy Policy for each of the advertising partners of SOYL.
    Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on SOYL, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.<br/>
    Note that SOYL has no access to or control over these cookies that are used by third-party advertisers.
  </Paragraph>
  <Paragraph title={'Third Party Privacy Policies '}>
    SOYL’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.<br/>
    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
  </Paragraph>
  <Paragraph title={'CCPA Privacy Rights CCPA '}>
    Under the CCPA, consumers have the right to, among other things, require that:
    Businesses that collect personal data about consumers to disclose the types and specific portions of personal data that the business collects about consumers.<br/>
    A business to delete any personal data collected by the business about the consumer.<br/>
    The business that sells the consumer’s personal data, not the business that sells the consumer’s personal data.
    If you make a request, we have one month to respond to you. If you wish to exercise any of these rights, please contact us.
  </Paragraph>
  <Paragraph title={'GDPR Data Protection Rights GDPR '}>
    We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:<br/>
    The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.<br/>
    The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.<br/>
    The right to erasure – You have the right to request that we erase your personal data, under certain conditions.<br/>
    The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.<br/>
    The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.<br/>
    The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.<br/>
    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
  </Paragraph>
  <Paragraph title={'Children’s Information '}>
    Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
    SOYL does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
  </Paragraph>
</Box>