import { useContext } from "react";
import { Box, Typography, Link, Stack, Tooltip } from "@mui/material";
import millify from "millify";
import ReactMarkdown from "react-markdown";
import { Visibility, Favorite, People } from "@mui/icons-material";
import { NamedUser } from "../../../components/named-user";
import { $addr, $summary } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Chains, INftToken, Summaries } from "../../../models";

export default ({ nft }: { nft: INftToken }) => {
  const { strings, mobile } = useContext(UiContext);

  const tokenName = nft.meta?.name && `(${nft.meta?.name})`;
  const collectionOwner = $summary(nft, Summaries.COLLECTION_OWNER);
  const collectionName = $summary(nft, Summaries.COLLECTION_NAME, $addr(nft.contract));
  const favorites = millify($summary(nft, Summaries.FAVORITED, 0), { precision: 2 });
  const views = undefined; // millify($summary(nft,Summaries.VISITED,0), { precision: 2 });

  const owners =
    nft.owners ?
      nft.owners?.map((owner) => (
        <Typography >
          <Link href={`/profile/${owner}`} underline='hover' sx={{ color: '#737373' }}>
            <NamedUser account={owner} />
          </Link>
        </Typography>
      ))
      : '';
  return (
    <>
      <Box sx={{ flexGrow: 1, py: 1 }}>
        <Link
          variant="h4"
          underline='none'
          href={`/profile/${collectionOwner}`}>
          <NamedUser account={collectionOwner} />
        </Link>

        <Typography variant="h4">
          <Link
            underline='none'
            href={`/collection/${nft.chain}/${nft.contract}`}>
            {collectionName}
          </Link>
          &ensp;
          {tokenName}
        </Typography>

        <Stack direction='row' spacing={2} sx={{ color: '#737373' }}>
          {
            (nft.owners && nft.owners?.length > 1) ?
              <Tooltip
                placement='right-start'
                enterTouchDelay={0}
                arrow
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": { maxWidth: '80vw', background: 'white', border: '1px solid #d0d0d0' },
                    '& .MuiTooltip-arrow': { '&:before': { border: '1px solid #d0d0d0', background: 'white' } }
                  }
                }}
                title={owners}>
                <Box sx={{ display: 'flex', justifyContent: 'center', align: 'center' }}>
                  <People />&nbsp;{strings('OWNERS').replace('[NUMBER]', `${nft.owners?.length}`)}
                </Box>
              </Tooltip>
              :
              collectionOwner && nft.owner === collectionOwner ?
                <Box>
                  {strings('CREATED_BY')}
                  &nbsp;
                  <Link href={`/profile/${collectionOwner}`}>
                    <NamedUser account={collectionOwner} />
                  </Link>
                </Box>
                :
                <Typography>
                  {strings('OWNED_BY')}
                  &nbsp;
                  <Link href={`/profile/${nft.owner}`}>
                    <NamedUser account={nft.owner} />
                  </Link>
                </Typography>
          }
          {views && <><Visibility />&nbsp;{`${views} views`}</>}
          {favorites && <><Favorite />&nbsp;{`${favorites} favorites`}</>}
        </Stack>
      </Box>

      <Box sx={{ py: 1 }}>
        {nft.meta?.description &&
          <Box sx={{ py: 2 }} maxWidth={mobile ? 'auto' : '435px'}>
            <ReactMarkdown>{nft.meta?.description.replace(/\\n/g, "\n")}</ReactMarkdown>
          </Box>
        }
      </Box>
    </>
  )
}