import { useContext } from "react";
import { Box, Typography, Link, Stack, useTheme, Tooltip } from "@mui/material";
import millify from "millify";
import ReactMarkdown from "react-markdown";
import { Visibility, Favorite, People } from "@mui/icons-material";
import { NamedUser } from "../../../components/named-user";
import { $addr, $summary } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftToken, Summaries } from "../../../models";
import { makeStyles } from "@material-ui/styles";

export default ({ nft }: { nft: INftToken }) => {
  const { strings, mobile } = useContext(UiContext);
  const theme = useTheme();
  const classes = useStyle({ theme })
  const tokenName = nft.meta?.name || '';
  const collectionOwner = $summary(nft, Summaries.COLLECTION_OWNER);
  const collectionName = $summary(nft, Summaries.COLLECTION_NAME, $addr(nft.contract));
  const favorites = millify($summary(nft, Summaries.FAVORITED, 0), { precision: 2 });
  const views = undefined; // millify($summary(nft,Summaries.VISITED,0), { precision: 2 });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Link
          className={classes.whiteLink}
          sx={{ fontSize: mobile ? '16px' : '20px' }}
          href={`/profile/${collectionOwner}`}>
          <NamedUser account={collectionOwner} />
        </Link>

        <Typography sx={{ fontSize: mobile ? '26px' : '32px', fontWeight: 'bold' }}>
          {tokenName}
          &ensp;
          <Link
            className={classes.whiteLink}
            href={`/collection/${nft.chain}/${nft.contract}`}>
            *{collectionName}
          </Link>

        </Typography>

        <Stack direction='row' spacing={2} marginTop={'9px'} sx={{ justifyContent: mobile ? 'space-between' : ''}}>
          {
            (nft.owners && nft.owners?.length > 1) ?
              <Box><People />&nbsp;{strings('OWNERS').replace('[NUMBER]', `${nft.owners?.length}`)}</Box>
              :
              collectionOwner &&
              <Box>
                {strings('CREATED_BY')}
                &nbsp;
                <Link href={`/profile/${collectionOwner}`}>
                  @<NamedUser account={collectionOwner} />
                </Link>
              </Box>
          }
          {views && <Box className={classes.nftSummary}><Visibility />&nbsp;{strings('VIEWS').replace('[NUMBER]', `${views}`)}</Box>}
          {favorites && <Box className={classes.nftSummary}><Favorite />&nbsp;{strings('FAVORITE').replace('[NUMBER]', `${favorites}`)}</Box>}
        </Stack>
      </Box>

      <Box sx={{ py: 1 }}>
        {nft.meta?.description &&
          <Box sx={{ py: 2 }} maxWidth={mobile ? 'auto' : '435px'}>
            <ReactMarkdown className={classes.description}>{nft.meta?.description.replace(/\\n/g, "\n")}</ReactMarkdown>
          </Box>
        }
      </Box>
    </>
  )
};

const useStyle = makeStyles({
  whiteLink: {
    color: (props: any) => props.theme.palette.primary.main,
  },
  description: {
    fontSize: ({ mobile }: any) => mobile ? '14px' : '16px',
    color: '#c7c7c7'
  },
  nftSummary: {
    display: 'flex',
    justifyContent: 'center'
  },

})