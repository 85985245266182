import { Container, Typography } from "@mui/material";
import { PageBase } from "../../components/page-base";

export const PageHome = () =>
  <PageBase>
    <Container>
      <Typography variant="h1" align="center">
        Slave World
      </Typography>
    </Container>
  </PageBase>