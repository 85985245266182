import { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material";
import { UiContext, UserContext } from "../../context/app"
import { Chains, INftCollection, INftToken, UserPower } from "../../models";
import { collectionId, collectionInfo, userInfo } from "../../services/market";
import { createView } from "../../views"
import { useAppContext } from "../hooks";
import { saleButtonType } from "../utils";

interface PageNavCard {
  chainId?: string,
  contract?: string,
  nft?: INftToken
  availableAmount?: number,
  onSaleTokens?: number,
  expectMaxSupply?: number
}

const { default: PageNavView } = createView('page-base-nav-view');
const { default: PageNavMobile } = createView('page-base-mobile-nav-view');
const { CancelBookButton } = createView('CancelBookButton');

export const PageNavCard = ({ chainId, contract, nft, availableAmount, onSaleTokens, expectMaxSupply }: PageNavCard) => {
  const context = useAppContext();
  const { wallet } = context;
  const { mobile, strings } = useContext(UiContext);
  const { user, setUser } = useContext(UserContext);
  const [collection, setCollection] = useState<INftCollection | null>(null);

  const SaleButtons = () => (
    <>
      {
        collection &&
        saleButtonType(wallet.account, UserPower.COLLECTION_ADD_ITEM, { collection }) &&
        <Button variant='outlined' href={`/nft/${collection?.address}/mint`}>
          {strings('ADD_ITEM')}
        </Button>
      }
      {/* { @todo 待ＢＢ討論是否create按鈕必要性
          !nft &&
          <Button variant='outlined' href='/my-collections'>{strings('CREATE')}</Button>
        } */}
      {
        nft &&
        saleButtonType(wallet.account, UserPower.NFT_CANCEL_SELL, { nft, expectMaxSupply, onSaleTokens }) &&
        <CancelBookButton
          buttonProps={{ variant: 'outlined' }}
          nft={nft}>
          {strings('CANCEL_SELLING')}
        </CancelBookButton>
      }
      {
        nft &&
        saleButtonType(wallet.account, UserPower.NFT_CAN_SELL, { nft, expectMaxSupply, availableAmount }) &&
        <Button variant='outlined' href={`/nft/${nft?.chain}/${nft?.contract}/${nft?.tokenId}/sell`}>
          {strings('SELL')}
        </Button>
      }
    </>
  );

  useEffect(() => {
    if (!chainId || !contract) return;
    collectionInfo(context, collectionId(chainId as unknown as Chains, contract))
      .then(setCollection)
      .catch(console.error);
  }, [chainId, contract]);
  useEffect(() => {
    if (wallet.status !== 'connected') return;
    userInfo(context)
      .then(setUser)
      .catch(console.error);
  }, [wallet.isConnected()]);


  return (
    mobile ?
      <PageNavMobile user={user} rightsideButtons={<SaleButtons />} />
      :
      <PageNavView user={user} rightsideButtons={<SaleButtons />} />
  )
}