import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box, Typography,
  Button, TextField,
  MenuItem, InputAdornment, Icon,
  Chip, Stack, Menu, Container,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import WebIcon from '@mui/icons-material/Web';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { PageBase } from '../../components/page-base';
import { Chains, Communities, INftCollection } from '../../models';
import { collectionDeploy, uploadImage } from '../../services/market';
import { useAppContext } from '../../components/hooks';
import { ImageDropzone } from '../../components/media-drop-zone';
import { strings } from '../../strings';

const COMMUNITIES_ICON: { [key: string]: any } = {
  "WEBSITE": <WebIcon />,
  "DISCORD": <Icon>discord</Icon>,
  "TWITTER": <TwitterIcon />,
  "INSTAGRAM": <InstagramIcon />
}

// TODO: should change the used in enum CATEGORIES typescript project
const CATEGORIES: { [key: string]: string } = {
  "ART": "Art",
  "COLLECTIBLES": "Collectibles"
}

const isEmpty = (text?: string) =>
  text === null || text === undefined || text.length === 0;

const getValueInMapping = (key: string, mapping?: { [key: string]: any }) => {
  if (!mapping) return;

  return mapping[key];
}

const errorOfSellerFee = (fee?: string) => {
  if (!fee) return null;

  return parseFloat(fee) > 1000 ? strings("ERROR_SELLER_FEE") : null;
}

const Row = ({ children, sx }: any) => (<Box sx={{ m: 3, ...sx }}>{children}</Box>)

export const PageNftCollectionCreate = () => {
  const context = useAppContext();
  const { wallet } = context;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categorier, setCategorier] = useState<any>(null);
  const [collection, setCollection] = useState<INftCollection>({
    chain: wallet.chainId as Chains,
    tags: wallet.chainId ? [wallet.chainId?.toString()] : [],
  });
  const [logo, setLogo] = useState<File>();
  const [featureImg, setFeatureImg] = useState<File>();
  const [bannerImg, setBanner] = useState<File>();
  const [sellerFee, setSellerFee] = useState("");

  const dropzoneStyle = { height: 180, p: 0.5, border: "3px dashed #CCCCCC", color: '#CCCCCC' };
  const requiredStyle = { '&::after': { color: 'red', content: '" *"' } };

  const disableSubmission = Boolean(!wallet.isConnected() ||
    !logo ||
    isEmpty(collection.meta?.name) ||
    errorOfSellerFee(sellerFee) ||
    (sellerFee && isEmpty(collection.meta?.fee_recipient)));

  const createCollection = async () => {
    try {
      setLoading(true);
      setError(null);
      let logoImg = logo ? await uploadImage(context, logo) : "";
      let featureImage = featureImg ? await uploadImage(context, featureImg) : "";
      let bannerImage = bannerImg ? await uploadImage(context, bannerImg) : "";
      let fee = sellerFee ? parseFloat(sellerFee) : undefined;

      let newCollection: INftCollection = {
        ...collection,
        featureImage: featureImage,
        bannerImage: bannerImage,
        meta: {
          ...collection.meta,
          image: logoImg
        }
      };

      if(!!fee && !!newCollection.meta)
        newCollection.meta.seller_fee_basis_points = fee;

      await collectionDeploy(context, newCollection);
      navigate(-1);
    }
    catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setCollection({ chain: wallet.chainId as Chains, tags: wallet.chainId ? [wallet.chainId?.toString()] : [] })
  }, [wallet]);

  return (
    <PageBase coverLoading loading={loading} error={error} onReload={() => setError(null)}>
      <Container>
        <Row>
          <Typography
            variant='caption'
            sx={{ fontWeight: 'bold', '&::before': { color: 'red', content: '"* "' } }}
            paragraph>
            {strings("REQUIRED_TIPS")}
          </Typography>

          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("LOGO_TITLE")}</Typography>

          <ImageDropzone
            onDrop={(files: any[]) => setLogo(files[0])}
            src={logo}
            sx={{ ...dropzoneStyle, aspectRatio: "1", borderRadius: '50%', }}
            imgStyle={{ aspectRatio: "1", borderRadius: '50%' }}
            alt="logo"
          />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("FEATURED_TITLE")}</Typography>
          <ImageDropzone
            sx={{ ...dropzoneStyle, aspectRatio: "3/2", borderRadius: 3, }}
            imgStyle={{ aspectRatio: "3/2", borderRadius: 3, }}
            src={featureImg}
            alt="feature"
            onDrop={(files: any[]) => setFeatureImg(files[0])}
            onClear={() => setFeatureImg(undefined)}
          />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("BANNER_TITLE")}</Typography>
          <ImageDropzone
            sx={{ ...dropzoneStyle, aspectRatio: "7/2", borderRadius: 3, }}
            imgStyle={{ aspectRatio: "7/2", borderRadius: 3, }}
            src={bannerImg}
            alt="banner"
            onDrop={(files: any[]) => setBanner(files[0])}
            onClear={() => setBanner(undefined)}
          />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("NAME_TITLE")}</Typography>
          <TextField
            value={collection.meta?.name || ""}
            required
            fullWidth
            onChange={(e) => setCollection({ ...collection, meta: { ...collection.meta, name: e.target.value } })} />
        </Row>
        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("URL_TITLE")}</Typography>
          <TextField
            value={collection.meta?.external_link || ""}
            fullWidth
            onChange={(e) => setCollection({ ...collection, meta: { ...collection.meta, external_link: e.target.value } })} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("DESC_TITLE")}</Typography>
          <TextField
            value={collection.meta?.description || ""}
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setCollection({ ...collection, meta: { ...collection.meta, description: e.target.value } })} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("CATEGORY_TITLE")}</Typography>
          {
            collection.category &&
            <Stack direction="row" spacing={1}>
              <Chip label={collection.category} onDelete={() => setCollection({ ...collection, category: "" })} />
            </Stack>
          }

          <Button
            variant="outlined"
            disabled={collection.category !== undefined && collection.category.length > 0}
            onClick={(e) => setCategorier(e.currentTarget)}>{strings("ADD_CATEGORY")}</Button>

          <Menu
            anchorEl={categorier}
            open={Boolean(categorier)}
            onClose={() => setCategorier(null)}
          >
            {
              Object.keys(CATEGORIES)
                .map(key => <MenuItem key={key} value={key} onClick={(e) => {
                  setCategorier(null);
                  setCollection({ ...collection, category: key })
                }}>{CATEGORIES[key]}</MenuItem>)
            }
          </Menu>
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("LINKS_TITLE")}</Typography>
          {
            Object.values(Communities).map(key =>
              <TextField
                key={key}
                type="url"
                value={getValueInMapping(key, collection.communities) || ""}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{COMMUNITIES_ICON[key]}</InputAdornment>,
                }}
                onChange={(e) => setCollection({ ...collection, communities: { ...collection.communities, [key]: e.target.value } })} />
            )
          }
        </Row>

        {/* todo: seller fee should editable in contract */}
        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("ROYALTIES_TITLE")}</Typography>
          <Typography variant="caption">{strings("ROYALTIES_FEE")}</Typography>
          <TextField
            value={parseFloat(sellerFee) ? parseFloat(sellerFee) * 0.01 : sellerFee}
            fullWidth
            type="number"
            error={!!errorOfSellerFee(sellerFee)}
            helperText={errorOfSellerFee(sellerFee)}
            onChange={(e) => {
              const fee = parseFloat(e.target.value) ? (parseFloat(e.target.value) * 100).toString() : e.target.value;
              setSellerFee(fee)
            }} />
        </Row>

        {
          sellerFee && parseFloat(sellerFee) > 0 && <Row>
            <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("ROYALTIES_ADDRESS")}</Typography>
            <TextField
              value={collection.meta?.fee_recipient || ""}
              fullWidth
              required
              error={!collection.meta?.fee_recipient || collection.meta?.fee_recipient.length === 0}
              onChange={(e) => setCollection({ ...collection, meta: { ...collection.meta, fee_recipient: e.target.value } })} />
          </Row>
        }

        <LoadingButton
          sx={{ m: 3 }}
          disabled={disableSubmission}
          variant="contained"
          loading={loading}
          onClick={createCollection}>{strings("CREATE")}</LoadingButton>
      </Container>
    </PageBase>
  )
}