import { createView } from "../../views";
import { IListOptions } from "../../models";

const { default: AdvanceOptions } = createView('advance-options-view');

interface AdvanceOptionsCardProps {
  listOptions: IListOptions,
  onApplyFilter: (rs: any) => void,
  showFilterDrawer?: boolean,
  onClose?: () => void,
}

export const AdvanceOptionsCard = ({ listOptions, onApplyFilter, showFilterDrawer, onClose }: AdvanceOptionsCardProps) => {
  return (
    <AdvanceOptions
      listOptions={listOptions}
      onApplyFilter={onApplyFilter}
      showFilterDrawer={showFilterDrawer}
      onClose={onClose} />
  )
}