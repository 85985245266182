import { ISellConfigs } from "./options/sell-options";
import { Chains, IOwnerable } from "./base-types";

export enum ActivityType {
  Deal = "DEAL",
  Transfer = "TRANSFER",
  TransferSingle = "TRANSFER",
  TransferBatch = "TRANSFER",
  Minted = "MINTED",
  Booked = "BOOKED"
}

export interface INftTokenMeta {
  name?: string;
  image?: string;
  animation_url?: string;
  description?: string;
  external_url?: string;
  attributes?: INftTokenMetaAttribute[];
}

export interface INftTokenIndexing {
  tags?: string[],
  category?: string,
  state?: string, // TBD: 發行階段，包括已發行、即將發行、測試階段等
  timestamp?: number,
}

// checkout: https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
export interface INftTokenMetaAttribute {
  // TBD  
}

// 交易紀錄
export interface INftTokenPricingHistory {
  price: string; // BN
  timestamp: number; // unix timestamp (seconds)
}

// 活動紀錄，欄位待確認
export interface INftTokenActivities {
  id?: string;
  type: ActivityType; // TBD
  price?: string; //BN
  from?: string; // 0xAB
  to?: string; // 0xAB
  timestamp: number // unix timestamp (seconds)
}

export interface INftTokenOnSale {
  saleOptions?: ISellConfigs;
  allSaleOptions?: ISellConfigs[];
}

export interface INftMintData {
  maxSupply?: number;
  mintSig?: string;
}

export interface INftToken extends IOwnerable, INftTokenIndexing, INftTokenOnSale, INftMintData {
  id?: string;
  owner?: string;
  owners?: string[];
  chain?: Chains | number;
  contract?: string; // 0xAB
  tokenId?: string;
  maxSupply?: number,
  meta?: INftTokenMeta;
  pricing?: INftTokenPricingHistory[];
  activities?: INftTokenActivities[];
  summaries?: { [key: string]: number | string }; //floorPrice, favorite, views, salingEndTime,
}