export const CURRENCY_DEFAULT = "0x0000000000000000000000000000000000000000";
export enum SellMethods {
  NOT_FOR_SELL = "NOT_FOR_SELL",
  FIXED_PRICE = "FIXED_PRICE",
  SELL_TO_HIGHEST_BIDDER = "SELL_TO_HIGHEST_BIDDER",
  SELL_WITH_DECLINING_PRICE = "SELL_WITH_DECLINING_PRICE",
}
export enum TradingMethods {
  NOT_FOR_SELL = 0,
  FIXED_PRICE = 1,
  SELL_TO_HIGHEST_BIDDER = 2,
  SELL_WITH_DECLINING_PRICE = 3,
  ACCEPT_OFFER = 4,
}

export enum SellStates {
  NONE,
  COMMING,
  SELLING,
  ENDING
}

export enum UserPower{
  NFT_CANCEL_SELL,
  NFT_CAN_SELL,
  COLLECTION_ADD_ITEM
}

export enum BookStates {
  NONE = "NONE",
  BOOKING = "BOOKING",
  BOOKED = "BOOKED",
  DEALED = "DEALED",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
}

export enum OfferStates {
  NONE = "NONE",
  OFFERING = "OFFERING",
  DEALED = "DEALED",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
}

export interface ISellOptionsBase {
  target: string;         // nftId := hash(chain/contract/tokenId)
  method: SellMethods;
  duration: { begin: number, end: number };
  currency?: string; // = CURRENCY_DEFAULT = 0x0000000000000000000000000000000000000000
  quantity?: number;
}

export interface IFixedPriceSellOptions extends ISellOptionsBase {
  // method: SellMethods.FIXED_PRICE;
  price?: string; 		      // BN, method == FIXED_PRICE
}

export interface IHighestBidderSellOptions extends ISellOptionsBase {
  // method: SellMethods.SELL_TO_HIGHEST_BIDDER;
  startingPrice?: string; // BN, method == SELL_WITH_DECLINING_PRICE
  reservePrice?: string; 	// BN, method == SELL_TO_HIGHEST_BIDDER
}

export interface IDecliningPriceSellOptions extends ISellOptionsBase {
  // method: SellMethods.SELL_WITH_DECLINING_PRICE;
  startingPrice?: string; // BN, method == SELL_WITH_DECLINING_PRICE
  endingPrice?: string; 	// BN, method == SELL_WITH_DECLINING_PRICE
}

export interface ISellOptions
  extends
  IFixedPriceSellOptions,
  IHighestBidderSellOptions,
  IDecliningPriceSellOptions {
}

export interface ISellConfigs extends ISellOptions {
  id?: string;
  price?: string;
  timestamp: number;
  seller?: string;

  bookId?: string; // v1
  bookTxInfo?: any; // v1
  dealTxInfo?: any; // v1
  bookState?: BookStates; // v1


  version?: number; // v2
  // signedData?: Sale; // v2
  // signature?: string; // v2
}