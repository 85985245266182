import { useContext, Fragment } from "react";
import { Toolbar, Stack, Divider, Typography, Box, Link } from "@mui/material";
import { CommunityIconButton } from "../../../../components/page-base/community-button";
import { UiContext } from "../../../../context/app"
import { Paths } from "../../../../context/app";

export default () => {
  const { mobile, strings } = useContext(UiContext);
  const { infoPaths, communityPaths } = Paths;
  return (
    <Toolbar sx={{
      justifyContent: mobile ? 'center' : 'space-between',
      backgroundColor: 'white',
      flexDirection: mobile ? 'column' : 'row',
      pt: mobile ? '19px' : '',
      pb: mobile ? '26px' : '',
      letterSpacing: '0.6px'
    }}>
      {!mobile && <Stack direction="row" spacing={2} >
        {infoPaths.map(p => <Fragment key={p.title}>
          <Link sx={{
            letterSpacing: '0.6px',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '12px'
          }} href={p.path} >
            {strings(p.title)}
          </Link>
          <Divider flexItem sx={{ borderColor: '#cbcbcb' }} orientation='vertical' />
        </Fragment>
        )}
      </Stack>}
      {mobile && <Typography sx={{ fontWeight: 'bold', letterSpacing: '0.6px' }} mb={1}>
        {strings("FOLLOW_US")}
      </Typography>}
      <Box display='flex' >
        {!mobile && < Typography sx={{ fontWeight: 'bold', mr: '20px', fontSize: '12px', alignSelf: 'center', letterSpacing: '0.6px', }} >{strings("FOLLOW_US")}</Typography>}
        <Stack direction="row" spacing={1} >
          {communityPaths.map(p => <CommunityIconButton icon={p.icon} href={p.path} key={p.title} />)}
        </Stack>
      </Box>
    </Toolbar >
  )
}