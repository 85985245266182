import { makeStyles } from "@material-ui/styles";
import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Avatar, Typography, Link,
  IconButton,
} from "@mui/material"
import { useContext, useState } from "react";
import { $ipfs } from "../../../components/utils";
import { UiContext } from "../../../context/app";


interface WaterfallCard {
  image?: string,
  logo?: string,
  title?: string,
  href?: string,
  randomColor?: string,
}

const DEFAULT_NFT_IMG_URL = '/assets/images/bb/img_default/default_NFT.png';

export const WaterfallCard = ({ image, logo, title, href, randomColor }: WaterfallCard) => {
  const { mobile } = useContext(UiContext);
  const [showBar, setShowBar] = useState(false);
  const classes = useStyle();
  const imageUrl = $ipfs(image) || DEFAULT_NFT_IMG_URL;
  return (
    <Link
      href={href || '/#'}
      className={classes.wrapper}
      onMouseEnter={() => setShowBar(true)}
      onMouseLeave={() => setShowBar(false)}>
      <CardMedia
        component='img'
        image={$ipfs(imageUrl)}
        onError={(e: any) => {
          e.currentTarget.onerror = null;
          e.target.image = DEFAULT_NFT_IMG_URL;
        }}
        style={{
          minHeight: !imageUrl ? '300px' : '',
          minWidth: !imageUrl ? '100%' : '',
          width: '100%',
          borderRadius: '12px',
          boxShadow: randomColor ? ((mobile ? '4px -4px ' : '7px -7px ') + randomColor) : '',
        }} />
      {
        showBar &&
        <Box className={classes.bar}>
          <Avatar src={logo} sx={{ opacity: logo ? 1 : 0 }} />
          <Typography>{title}</Typography>
          <IconButton
            href={href || '/#'}
            className={classes.action}>
            <ArrowForward />
          </IconButton>
        </Box>
      }
    </Link>
  )
};

const useStyle = makeStyles({
  wrapper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    color: 'white'
  },
  bar: {
    display: 'flex',
    position: 'absolute',
    bottom: '10px', left: '20px', right: '20px',
    width: '90%', height: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 9px 6px 6px',
    borderRadius: '100px',
    background: 'rgba(0,0,0,0.8)'
  },
  action: {
    color: '#74FBEF',
    border: '3px solid #74FBEF',
  }

})