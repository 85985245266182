import moment from "moment";
import { ADDRESS_ZERO } from "./base-types";
import { INftToken } from "./nft-token";
import { IBidOptions } from "./options/bid-options";
import { IOfferOptions } from "./options/offer-options";
import { CURRENCY_DEFAULT, ISellOptions, SellMethods, TradingMethods } from "./options/sell-options";
import { DURATION_AFTER_AUCTION_END, Offer, Sale, SaleConfig } from "./trading";

export const TypeDefines = {
  EIP712Domain: [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' }
  ],
  Sale: [
    { name: 'currency', type: 'address' },
    { name: 'nftContract', type: 'address' },
    { name: 'tokenId', type: 'uint256' },
    { name: 'quantity', type: 'uint256' },
    { name: 'price', type: 'uint256' },
    { name: 'acceptMinPrice', type: 'uint256' },
    { name: 'method', type: 'uint8' },
    { name: 'seller', type: 'address' },
    { name: 'buyer', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'beginTime', type: 'uint256' },
    { name: 'expireTime', type: 'uint256' },
    { name: 'maxFee', type: 'uint256' },
    { name: 'data', type: 'bytes' },
  ],
  Offer: [
    { name: 'currency', type: 'address' },
    { name: 'nftContract', type: 'address' },
    { name: 'tokenId', type: 'uint256' },
    { name: 'quantity', type: 'uint256' },
    { name: 'price', type: 'uint256' },
    { name: 'method', type: 'uint8' },
    { name: 'seller', type: 'address' },
    { name: 'buyer', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'beginTime', type: 'uint256' },
    { name: 'expireTime', type: 'uint256' },
    { name: 'data', type: 'bytes' },
  ],
  MintData: [
    { name: 'id', type: 'uint256' },
    { name: 'maxSupply', type: 'uint256' },
  ]
}

export const asSale = (seller:string, nft:INftToken, options:ISellOptions, maxFee:string):Sale => {
  let price:string = '0';
  let acceptMinPrice:string = '0';
  let method = TradingMethods.NOT_FOR_SELL;
  let buyer = ADDRESS_ZERO; // note: not support limit buyer for now
  let beginTime = options.duration.begin;
  let expireTime = options.duration.end;

  if(!nft.chain ||
    !nft.contract ||
    !nft.tokenId ||
    !nft.owner
    ) throw new Error('Missing Info.');

  switch(options.method){
    case SellMethods.FIXED_PRICE:
      if(!options.price) throw new Error('Missing Info.');

      method = TradingMethods.FIXED_PRICE;
      price = options.price;
      acceptMinPrice = options.price;
      expireTime = options.duration.end;
      break;
    case SellMethods.SELL_TO_HIGHEST_BIDDER:
      if(!options.startingPrice) 
        throw new Error('Missing Info.');

      if(!options.reservePrice)
        options.reservePrice = options.startingPrice;

      method = TradingMethods.SELL_TO_HIGHEST_BIDDER;
      price = options.startingPrice;
      acceptMinPrice = options.reservePrice;
      // NOTE: Auction must end before expire
      expireTime = options.duration.end + DURATION_AFTER_AUCTION_END;
      break;
    case SellMethods.SELL_WITH_DECLINING_PRICE:
      if(!options.startingPrice || !options.endingPrice) 
        throw new Error('Missing Info.');

      method = TradingMethods.SELL_WITH_DECLINING_PRICE;
      price = options.startingPrice;
      acceptMinPrice = options.endingPrice;
      expireTime = options.duration.end;
      break;
    default:
      throw new Error('Method not supported');
  }

  return ({
    currency: options.currency || CURRENCY_DEFAULT,
    nftContract: nft.contract as string,
    tokenId: nft.tokenId as string,
    quantity: options.quantity || 1,
    price,
    acceptMinPrice,
    method,
    seller,
    buyer, 
    nonce: moment().valueOf(),
    beginTime,
    expireTime,
    maxFee,
  });
}

export const trimSale = (data:Sale & any):Sale => ({
  currency: data.currency,
  nftContract: data.nftContract,
  tokenId: data.tokenId,
  quantity: data.quantity,
  price: data.price,
  acceptMinPrice: data.acceptMinPrice,
  method: data.method,
  seller: data.seller,
  buyer: data.buyer,
  nonce: data.nonce,
  beginTime: data.beginTime,
  expireTime: data.expireTime,
  maxFee: data.maxFee
});

export const asBidOffer = (buyer:string, saleConfig:SaleConfig, options:IBidOptions):Offer =>
  ({
    currency: saleConfig.currency,
    nftContract: saleConfig.nftContract,
    tokenId: saleConfig.tokenId,
    quantity: saleConfig.quantity,
    price: options.price,
    method: saleConfig.method,
    seller: ADDRESS_ZERO, // saleConfig.seller, note: not support limit seller for now
    buyer,
    nonce: moment().valueOf(),
    beginTime: saleConfig.beginTime,
    expireTime: saleConfig.expireTime,
  });

export const asOffer = (buyer:string, nft:INftToken, options:IOfferOptions):Offer => 
  ({
    currency: options.currency || CURRENCY_DEFAULT,
    nftContract: nft.contract as string,
    tokenId: nft.tokenId as string,
    quantity: options.quantity || 1,
    price: options.price,
    method: TradingMethods.ACCEPT_OFFER,
    seller: ADDRESS_ZERO, // spec: anyone who has this nft can accept this offer
    buyer,
    nonce: moment().valueOf(),
    beginTime: options.duration.begin,
    expireTime: options.duration.end,
  });


export const trimOffer = (data:Offer & any):Offer => ({
  currency: data.currency,
  nftContract: data.nftContract,
  tokenId: data.tokenId,
  quantity: data.quantity,
  price: data.price,
  method: data.method,
  seller: data.seller,
  buyer: data.buyer,
  nonce: data.nonce,
  beginTime: data.beginTime,
  expireTime: data.expireTime
});