import { useEffect, useState } from 'react';
import { useWeb3, useContract, useAppContext } from '../../components/hooks';
import { $price, useStrings } from "../../components/utils";
import {
  Gavel,
} from "@mui/icons-material";
import {
  Box,
  Alert, Divider, Typography,
  FormControlLabel, Checkbox, InputBase,
  Link,
  Button, ButtonProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import NftMarket from '../../contracts/NftMarket.json';
import MainCoin from '../../contracts/MainCoin.json';
import { INftToken, ICoin } from '../../models';
import { MarketDialog, MarketDialogActions, MarketDialogContent } from '../../components/dialog-market';
import { convertEth, listCoins, makeBid } from '../../services/market';
import Web3 from 'web3';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';


const STRINGS = {
  "BID.TITLE": "Place a bid",
  "BID.SUBMIT_BUTTON": "Place Bid",
  "PRICE": "Price",
  "BALANCE": "Balance: [balance] ETH",
  "REMINDER": "By checking this box, I agree to ",
  "REMINDER_SERVICE": "Terms Of Service",
  "SYMBOL": "ETH",
  "DOLLAR_EXCHANGE": "$ [dollars]",
  "INVALID_PRICE": "Invalid Price"
}

const dollarsExchange = (amount: string) => {
  if (!amount) return "0.00";
  return amount;
}

const PriceInput = ({ currency, amount, onChangePrice }: any) => {
  const strings = useStrings(STRINGS);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 2, border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: 3 }}>
      <Typography>{currency.name}</Typography>

      <Divider orientation="vertical" flexItem />

      <InputBase
        sx={{ flexGrow: 1, m: 1 }}
        value={amount}
        onChange={(e) => onChangePrice(e.target.value)}
      />

      <Divider orientation="vertical" flexItem />

      <Typography sx={{ minWidth: 60, textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '100%' }}>
        {amount > 0 ? strings("DOLLAR_EXCHANGE").replace("[dollars]", convertEth(Web3.utils.toWei(amount as string))) : ''}
      </Typography>
    </Box>
  )
}

interface BidButtonProps {
  nft?: INftToken,
  buttonProps?: ButtonProps,
  children?: React.ReactNode
}

export const BidButton = (props: BidButtonProps) => {
  const strings = useStrings(STRINGS);

  const navigate = useNavigate();
  const context = useAppContext();
  const { wallet, web3 } = context;

  const [isBiding, setIsBiding] = useState(false);
  const [amount, setAmount] = useState("");
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [currency, setCurrency] = useState<ICoin>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const currentPrice = props.nft ?
    (
      props.nft?.saleOptions?.reservePrice ?
        $price(props.nft?.saleOptions?.reservePrice)
        : $price(props.nft?.saleOptions?.startingPrice as string)
    )
    : ""
  const currentBalance = wallet.isConnected() ? $price(wallet.balance, 4) : $price('0', 4);

  const placeBid = async () => {
    try {
      setLoading(true);
      setError(null);
      // note: firebase collection bids 相同bidder目前不會覆蓋之前的bid
      const res = !!props.nft && await makeBid(context, {
        target: props.nft.id as string,
        nftDocId: props.nft.id || 'NOT_SUPPORTED',
        bookDocId: props.nft?.saleOptions?.id || 'NOT_SUPPORTED',
        price: web3.utils.toWei(amount).toString(),
        currency: props.nft.saleOptions?.currency
      })
      setIsBiding(false)
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false)
    }
  }

  const reloadCurrency = async () => {
    if (!wallet.chainId) return;
    try {
      const coins = await listCoins(wallet.chainId);
      const currency = coins.find(coin => coin.address === props.nft?.saleOptions?.currency);
      if (currency)
        setCurrency(currency);
    } catch (error: any) {
      setError(error);
    }
  }

  useEffect(() => { setAmount(currentPrice) }, [])

  useEffect(() => {
    if (!isBiding) return;
    setAmount("");
    setAcceptedPolicy(false);
  }, [isBiding]);

  useEffect(() => {
    reloadCurrency();
  }, [wallet]);

  useEffect(() => {
    if (isBiding) {
      const account = new Cookies().get('account');
      if (!account)
        navigate('/connect-wallet');

      if (wallet && wallet.isConnected() && wallet.account !== account)
        navigate('/connect-wallet');
    }
  }, [wallet, isBiding])
  return (
    <Box>
      <Button
        {...props.buttonProps}
        startIcon={props.buttonProps?.startIcon || <Gavel />}
        variant={props.buttonProps?.variant || 'contained'}
        onClick={() => setIsBiding(true)}
      >
        {props.children}
      </Button>

      <MarketDialog
        fullWidth
        open={isBiding}
        onClose={() => setIsBiding(false)}
        title={strings("BID.TITLE")}>
        <MarketDialogContent>
          <Typography>{strings("PRICE")}</Typography>
          <PriceInput
            strings={strings}
            amount={amount}
            currency={currency}
            onChangePrice={(price: string) => {
              if (parseFloat(price) <= parseFloat(currentPrice))
                setError({ message: strings("INVALID_PRICE") })
              else
                setError(null)
              setAmount(price)
            }} />
          <Typography>
            {
              strings("BALANCE").replace('[balance]', currentBalance)
            }
          </Typography>
          <FormControlLabel
            label={
              <Typography>
                {strings("REMINDER")}
                <Link
                  underline="none"
                  href="/terms"
                  target="_blank">
                  {strings("REMINDER_SERVICE")}
                </Link>
              </Typography>
            }
            control={
              <Checkbox
                checked={acceptedPolicy}
                onChange={(e) => setAcceptedPolicy(e.target.checked)} />
            }
          />
          {
            error &&
            <Alert severity='error'>{error.message}</Alert>
          }
        </MarketDialogContent>
        <MarketDialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            onClick={placeBid}
            disabled={
              !wallet.isConnected() ||
              !amount ||
              !acceptedPolicy ||
              parseFloat(amount) < parseFloat(currentPrice)
            }>
            {strings("BID.SUBMIT_BUTTON")}
          </LoadingButton>
        </MarketDialogActions>
      </MarketDialog>
    </Box>
  )
}