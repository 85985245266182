import { useContext, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import {
  AppBar, Box,
  IconButton,
  Toolbar, Link,
  Button, Stack,
  useTheme,
  Popper,
  Paper, MenuItem, MenuList, Avatar,
} from "@mui/material";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import { SoylIcon } from "../../../../campaign-20220316/icons";
import { useAppContext } from "../../../../components/hooks";
import { UiContext, UserContext, Paths, UiLangs } from "../../../../context/app";
import { userInfo } from "../../../../services/market";


export const $addr = (address: string = '') =>
  address ? `${address.substring(0, 2)}${address.substring(address.length - 4, address.length)}` : '';

type DropMenuProps = {
  open: boolean;
  anchorEl: any;
  children: any;
  onClose?: () => void;
}

const DropMenu = ({ open, anchorEl, onClose, children }: DropMenuProps) =>
  <Popper
    open={open}
    anchorEl={anchorEl}
    style={{ zIndex: 1300 }}
    onMouseLeave={() => !!onClose && onClose()}
  >
    <Paper >
      <MenuList>
        {children}
      </MenuList>
    </Paper>
  </Popper>;

export default () => {
  const context = useAppContext()
  const { wallet } = context;
  const {
    lang, setLang, mobile, strings,
    showDrawer, showUserMenu, showLangMenu,
    setDrawer, setUserMenu, setLangMenu,
  } = useContext(UiContext);
  const { user, setUser } = useContext(UserContext);
  const { home, mainPaths, userPaths } = Paths;
  const anchorRefs = useRef<{ profileEl?: any, langEl?: any }>({});
  const theme = useTheme();
  const colorTextIcon = mobile ? 'white' : theme.palette.primary.main;

  useEffect(() => {
    if (wallet.status !== 'connected') return;
    userInfo(context)
      .then(setUser)
      .catch(console.error);
  }, [wallet.isConnected()]);

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar
        disableGutters
        sx={{
          height: '60px',
          pr: mobile ? '10px' : '20px',
          background: mobile ? 'linear-gradient(to right, #3a3a3a, #737373 19%, #737373 80%, #3a3a3a)' : theme.palette.primary.contrastText,
          clipPath: mobile ?
            'polygon(0% 0%, 0% calc(100% - 8px), 38px calc(100% - 8px), 38px 100%, calc(100% - 9px) 100%, calc(100% - 9px) calc(100% - 8px), 100% calc(100% - 8px), 100% 0%)' :
            'polygon(0% 0%, 0% calc(100% - 8px), 10px calc(100% - 8px), 10px 100%, 217px 100%, 217px calc(100% - 8px), calc(100% - 20px) calc(100% - 8px), calc(100% - 20px) calc(100% - 16px), 100% calc(100% - 16px), 100% 0%)',
        }}>

        <Link href={home}>
          <img
            src="/assets/images/soyl-logo-w.svg"
            style={{
              zIndex: 99,
              position: 'absolute',
              top: '9px',
              left: mobile ? 0 : '10px',
              maxHeight: '43px',
              maxWidth: '90px'
            }} />
        </Link>

        {!mobile &&
          <Box sx={{
            position: 'absolute',
            top: '0px', left: '10px',
            width: '230px', height: '80px',
            backgroundColor: '#737373'
          }} />
        }

        {!mobile &&
          <Stack direction="row" spacing={4} ml={32}>
            {mainPaths.map(p =>
              <Button key={p.path} sx={{ color: colorTextIcon }} href={p.path}>
                {strings(p.title)}
              </Button>)}
          </Stack>
        }

        {!mobile &&
          <IconButton
            sx={{ ml: 'auto', mr: '5px', p: '3px' }}
            href='/profile'
            ref={ref => anchorRefs.current.profileEl = ref}
            onClick={() => setUserMenu(false)}
            onMouseEnter={() => setUserMenu(true)}
          >
            {wallet.isConnected() && user != null && user.image ?
              <Avatar alt='You' src={user.image} sx={{ width: 32, height: 32, }} />
              :
              <Avatar alt='You' src={'/assets/images/avatar_empty_black.svg'} sx={{ width: 32, height: 32, }} />

            }
            <DropMenu
              open={showUserMenu}
              onClose={() => setUserMenu(false)}
              anchorEl={anchorRefs.current.profileEl}>
              <MenuItem
                component={Link}
                href={'/'}>
                {strings('POPPER_HOME')}
              </MenuItem>
              {
                userPaths.map((item: { title: string, path: string, disabled?: boolean }) =>
                  <MenuItem
                    component={Link}
                    disabled={item.disabled || false}
                    key={item.title}
                    href={item.path}>
                    {strings(item.title)}
                  </MenuItem>
                )}
              <MenuItem
                onClick={() => {
                  new Cookies().remove('account', { path: '/' });
                  wallet.reset();
                }}>
                {strings('POPPER_LOGOUT')}
              </MenuItem>
            </DropMenu>
          </IconButton>
        }

        {!mobile &&
          <Button
            sx={{ color: colorTextIcon, minWidth: 0 }}
            ref={ref => anchorRefs.current.langEl = ref}
            onClick={() => setLangMenu(true)}
            onMouseEnter={() => setLangMenu(true)}
          >
            {lang === UiLangs.CH ? strings("LANG_CH") : strings("LANG_EN")}
            <DropMenu
              open={showLangMenu}
              onClose={() => setLangMenu(false)}
              anchorEl={anchorRefs.current.langEl}>
              <MenuItem onClick={() => setLang(UiLangs.EN)}>
                {strings('LANG_EN')}
              </MenuItem>
              <MenuItem onClick={() => setLang(UiLangs.CH)}>
                {strings('LANG_CH')}
              </MenuItem>
            </DropMenu>
          </Button>
        }

        {
          mobile && <>
            <IconButton
              sx={{ ml: 'auto', mr: '7px', p: '3px', color: 'white', fontSize: '30px' }}
              href='/profile'
            >
              <AccountBalanceWalletOutlined fontSize={'inherit'} />
            </IconButton>
          </>
        }

        {
          mobile &&
          <IconButton
            edge="start"
            onClick={() => setDrawer(!showDrawer)}>
            <SoylIcon color={colorTextIcon} icon="menu" size="34" />
          </IconButton>
        }
      </Toolbar>

    </AppBar >
  );
}
