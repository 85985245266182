import { Box, Container, Divider, Typography } from "@mui/material"
import { useContext } from "react";
import { PageBase } from "../../../components/page-base"
import { UiContext } from "../../../context/app";
import { ZhTerms } from "./zh-TW";
import { EnTerms } from "./en-US";

export const PageTermsOfService = () => {
  const { lang } = useContext(UiContext);
  return (
    <PageBase sx={{ backgroundImage: 'linear-gradient(to bottom, #d9d9d9, #fff)' }}>
      <Container maxWidth='md' sx={{ pt: '154px' }}>
        <Box display='flex' sx={{ alignItems: 'center', mb: '25.5px' }}>
          <Box sx={{
            width: '24px',
            height: '7px',
            mr: '24px',
            backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)'
          }} />
          <Typography variant='h3' fontSize={'42px'}>{'Terms and Conditions of Use for SOYL'}</Typography>
        </Box>
        <Divider sx={{ borderColor: '#000' }} />
        {lang === 'zh-TW' ? <ZhTerms /> : <EnTerms />}
      </Container >
    </PageBase >
  )
}