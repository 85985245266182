import { useContext } from "react";
import { Box, Stack, Typography, Link, Alert, Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import BN from 'bn.js'
import { $summary, $addr } from "../../../components/utils";
import { IBidConfigs, Summaries, SellMethods, INftToken } from "../../../models";
import { convertEth } from "../../../services/market";
import { UiContext } from "../../../context/app";
import { makeStyles } from "@material-ui/styles";
import { BBIcon } from "../../../assets/icons/bb";

const ZERO = new BN('0')
const HUNDRED = new BN('100');

export default ({ nft, offers }: { nft: INftToken, offers: IBidConfigs[] }) => {
  const { strings, mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });

  const floorPrice = $summary(nft, Summaries.FLOOR_PRICE);

  const offersColumns: GridColDef[] = [
    {
      field: 'price',
      headerName: strings('DATAGRID_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <BBIcon icon='ic_ethereum' size={16} />
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{params.value}</Typography>
          <Typography className={classes.text}>ETH</Typography>
        </Stack>,
    },
    {
      field: 'USDPrice',
      headerName: strings('DATAGRID_USDPRICE'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => `${convertEth(params.row.price)}`,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{`$${params.value}`}</Typography>
        </Box>
    },
    {
      field: 'floorDifference',
      headerName: strings('DATAGRID_FLOOR_DIFFERENCE'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!floorPrice) return '';
        const fp = new BN(floorPrice);
        const p = new BN(params.row.price);
        const diff = p.sub(fp);
        if (diff.gt(ZERO))
          return `${diff.div(fp).mul(HUNDRED).toString()} %`;
        if (diff.lt(ZERO))
          return `${diff.div(fp).mul(HUNDRED).abs().toString()} % below`;
        if (diff.eq(ZERO))
          return '0 %';
        return '';
      },
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{params.value}</Typography>
        </Box>
    },
    {
      field: 'expiration',
      headerName: strings('DATAGRID_EXPIRATION'),
      headerAlign: 'center',
      flex: 1,
      editable: false,
      sortable: false,
      valueGetter: (params) => {
        if (nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER)
          return moment.unix(nft.saleOptions?.duration.end).fromNow()
        else
          return moment.unix(params.row.duration?.end).fromNow()
      },
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{params.value}</Typography>
        </Box>
    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      headerAlign: 'center',
      flex: 1,
      editable: false,
      sortable: false,
      valueGetter: (params) => {
        if (nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER)
          return (params.row.bidder)
        else
          return (params.row.from)
      },
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Link variant='body1' href={`/profile/${params.value}`}>{$addr(params.value)}</Link >
        </Box>
    }
  ];

  return (
    <Card className={classes.offerCard}>
      <DataGrid
        columns={offersColumns}
        rows={offers}
        components={{
          NoRowsOverlay: () =>
            <Alert severity='info' >
              {strings('NO_OFFERS')}
            </Alert>
        }}
      />
    </Card >
  )
};

const useStyle = makeStyles({
  offerCard: {
    flexGrow: 1,
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    padding: ({ mobile }: any) => mobile ? 0 : '50px 30px 50px 30px',
    background: '#131313'
  },
  eleBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '15px',
    color: '#c7c7c7',
    textAlign: 'center',
  }
})

