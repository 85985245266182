
import { useContext, useEffect, useState } from "react";
import { article } from "../../campaign-20220316/articles";
import { UiContext } from "../../context/app";
import { createView } from "../../views";

const EMPTY_DATA = {
  creator: '',
  collection: '',
  intro: '',
  spotlightIntro: '',
  marketplaceIntro: '',
  commonMarketTitle: '',
  commonMarketIntro: '',
  profile: {},
  statement: {},
  viewpoints: [{
    author: '',
    avatar: '',
    intro: '',
    title: '',
    desc: '',
    date: '',
    content: {},
    innerHTML: '',
  }],
  marketplace: {
    creator: '',
    collection: '',
    content: '',
  },
  spotlight: {
    creator: '',
    quotetext: '',
    textassets: [],
    imageassets: {
      xs: [],
      md: [],
      xsalt: [],
      mdalt: [],
    },
  }
};

const { default: AdvView } = createView('adv-view');

export const AdvCard = (props: any) => {
  const { strings, lang, mobile } = useContext(UiContext);
  const [data, setData] = useState(EMPTY_DATA)

  useEffect(() => {
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang, setData]);

  return (
    <AdvView data={data}/>
  )
}


