import { useCallback, useEffect, useState } from "react"
import { Favorite, FavoriteBorder } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { likeCollection, likeNft, likeProfile } from "../../services/market/service-usability"
import { useAppContext } from "../hooks"

interface FavoriteIconButtonProps {
  account?: string,
  target: string,
  type?: 'collection' | 'nft' | 'profile'
}

export const FavoriteIconButton = ({ account, target, type = 'nft' }: FavoriteIconButtonProps) => {
  const context = useAppContext();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const favoriteType = `favorite-${type}s` as 'favorite-nfts' | 'favorite-collections' | 'favorite-profiles';

  const getFavorite = (() => {
    switch (favoriteType) {
      case 'favorite-nfts':
        return likeNft;
      case 'favorite-collections':
        return likeCollection;
      case 'favorite-profiles':
        return likeProfile;
    }
  })();

  const clickLike = async () => {
    try {
      setLoading(true);
      const rs = await getFavorite(context, target, !isFavorite);
      setIsFavorite(rs)
    } finally {
      setLoading(false);
    }

  }
  const reload = useCallback(async () => {
    try {
      setIsFavorite(await getFavorite(context, target))
    } catch (err: any) {
      console.error(err)
    }
  }, [target, account, type])

  useEffect(() => {
    reload()
  }, [target, account, type])

  return (
    <LoadingButton
      sx={{ minWidth: 'fit-content', maxWidth: 'fit-content', borderRadius: '50%', p: 0, m: 0 }}
      disableElevation
      loading={loading}
      onMouseDown={e => e.stopPropagation()}
      onClick={e => {
        e.preventDefault();
        clickLike();
      }}>
      {isFavorite ? <Favorite /> : <FavoriteBorder />}
    </LoadingButton >
  )
}