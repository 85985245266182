import Web3 from 'web3';

const INFURA_MAPPING:{[key:string]:string} = {
  '1': 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '3': 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '4': 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '97': 'https://data-seed-prebsc-1-s1.binance.org:8545',
  '137': 'wss://rpc-mainnet.matic.network',
  '1337': 'http://192.168.50.189:7545',
  '80001': 'wss://matic-testnet-archive-ws.bwarelabs.com',
}

export type ContractAtProps = {
  web3?:any;
  chain?:number;
  address?:string;
  abi?:any;
  artifacts?:any;
}

export const web3ReadOnly = (chain:number) => {
  if(!(chain in INFURA_MAPPING))
    throw new Error("Unsupported network for web3 readonly.");
  return new Web3(INFURA_MAPPING[chain]);
}


export const contractAt = (props:ContractAtProps):any => {
  let { web3, chain, address, artifacts, abi } = props;

  if(!chain) chain = 1;

  if(!web3 && chain) 
    web3 = web3ReadOnly(chain);
  
  if(!address && artifacts && chain && (chain in artifacts.networks))
    address = artifacts.networks[chain].address;

  if(!abi && artifacts)
    abi = artifacts.abi;

  if(!web3 || !address || !abi) 
    throw new Error("Unable to load contract.");

  return new web3.eth.Contract(abi, address);
}