import {
  Box, Container,
  Typography,
  Divider
} from "@mui/material";
import { useContext } from "react";
import { PageBase } from "../../../components/page-base";
import { UiContext } from "../../../context/app";
import { EnPolicy } from "./en-US";
import { ZhPolicy } from "./zh-TW";


export const Paragraph = (props: any) =>
  <Typography paragraph >
    {props.title && <Typography fontSize={'22px'} fontWeight={'bold'} >{props.title}</Typography>}
    <Typography fontSize={'15px'} sx={{ color: '#3a3a3a' }} >{props.children}</Typography>
  </Typography>

export const PagePrivacyPolicy = () => {
  const { lang } = useContext(UiContext);
  return (
    <PageBase sx={{ backgroundImage: 'linear-gradient(to bottom, #d9d9d9, #fff)' }}>
      <Container maxWidth='md' sx={{ pt: '154px' }}>
        <Box display='flex' sx={{ alignItems: 'center', mb: '25.5px' }}>
          <Box sx={{
            width: '24px',
            height: '7px',
            mr: '24px',
            backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)'
          }} />
          <Typography variant='h3' fontSize={'42px'}>{'Privacy Policy'}</Typography>
        </Box>
        <Divider sx={{ borderColor: '#000' }} />
        {lang == 'zh-TW' ? <ZhPolicy /> : <EnPolicy />}
      </Container >
    </PageBase >
  )
}