import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlaylistAddCheck } from "@mui/icons-material";
import { Alert, Box, Button, Card, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react"
import { TABS } from ".";
import { useAppContext } from "../../components/hooks";
import { $ipfs, $price } from "../../components/utils";
import { UiContext } from "../../context/app";
import { INftToken, SellMethods, Summaries } from "../../models";
import { cancelBook, convertEth, listListing } from "../../services/market";


export const TabListings = (props: any) => {
  const context = useAppContext();
  const { wallet } = context;
  const { strings } = useContext(UiContext);

  const [listings, setListings] = useState<INftToken[]>([])
  const { setLoading, setError, selectedTab, address } = props;

  const reload = useCallback(async () => {
    if (selectedTab === TABS.LISTINGS)
      try {
        setLoading(true);
        setError(null)

        if (address) {
          const rs = await listListing(context, address);
          setListings(rs.items);
        }
        else {
          const rs = await listListing(context, wallet.account as string);
          setListings(rs.items);
        }

      } catch (error: any) {
        setError(error)
      } finally {
        setLoading(false);
      }
  }, [selectedTab, wallet.account, wallet.isConnected()])

  const cancelBooking = async (target: string) => {
    try {
      setError(null);
      setLoading(true);
      // @todo: should pass id of saleOptions(v1) or saleConfig(v2)
      await cancelBook(context, target);

      reload()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => { reload() }, [selectedTab, wallet.account, wallet.isConnected(), reload])

  const listingColumn: GridColDef[] = [
    {
      field: 'item',
      headerName: 'Item',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row.nft.meta.name,
      renderCell: (params) =>
        <Button sx={{ textTransform: 'none' }} href={`/nft/${params.row.nft.chain}/${params.row.nft.contract}/${params.row.nft.tokenId}`}>
          <Stack direction='row' spacing={1}>
            <img src={$ipfs(params.row.nft.meta.image)} height={'52px'} />
            <Typography alignSelf='center'>{params.value}</Typography>
          </Stack>
        </Button>
    },
    {
      field: 'price',
      headerName: 'Price',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row.price,
      renderCell: (params) => <Box >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEthereum} />
          <Typography variant='body1' sx={{ textAlign: 'center' }}>&nbsp;{$price(params.value)}</Typography>
        </Box>
        <Typography variant='body2' >${convertEth(params.value)}</Typography>
      </Box>
    },
    {
      field: 'floorDifference',
      headerName: 'Floor Differenece Price',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        const floorPrice = params.row.nft.summaries && params.row.nft.summaries[Summaries.FLOOR_PRICE] && parseFloat($price(params.row.nft.summaries[Summaries.FLOOR_PRICE]));
        const difference = floorPrice ? ((parseFloat($price(params.row.saleOptions.price)) - floorPrice) / floorPrice) : 0;
        if (difference < 0)
          return (Math.abs(Math.round(difference * 10000) / 100)).toFixed(1) + '%' + ' below';
        else
          return (Math.round(difference * 10000) / 100).toFixed(1) + '%';
      },
      renderCell: (params) => <Typography>{params.value}</Typography>
    },
    {
      field: 'expiration',
      headerName: 'Expiration Date',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        if (params.row.method === SellMethods.SELL_TO_HIGHEST_BIDDER)
          return moment.unix(params.row.duration.end).format('LLL');
        else
          return moment.unix(params.row.duration.end).format('LLL');
      },
      renderCell: (params) => <Typography>{params.value}</Typography>
    },
    {
      field: 'actions',
      headerName: ' ',
      editable: false,
      sortable: false,
      flex: 1,
      align: 'right',
      valueGetter: (params) => params.row.target,
      renderCell: (params) => {
        if (wallet.isConnected() && wallet.account === params.row.nft.owner) {
          return (
            <Box>
              <Button variant='outlined' onClick={() => cancelBooking(params.value)}>{strings('CANCEL')}</Button>
            </Box>
          )
        }
        else return <></>;
      }
    }
  ]

  return (
    <Card sx={{ m: 2, flex: 1 }}>
      <Box display={'flex'} m={2}>
        <PlaylistAddCheck fontSize='large' />
        <Typography variant='h6'>{strings('ACTIVE_LISTINGS')}</Typography>
      </Box>
      {
        listings.length > 0 ?
          <DataGrid
            sx={{ borderWidth: 0 }}
            rows={listings}
            columns={listingColumn}
            disableColumnMenu
            hideFooter
            autoHeight
            rowHeight={100}
            selectionModel={'none'}
          />
          :
          <Alert severity="info">Empty</Alert>
      }
    </Card >
  )
}