import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Typography, Link } from "@mui/material";
import { useContext } from "react";
import { $addr, $summary } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Chains, INftToken, Summaries } from "../../../models";
import { StyledTooltip } from "../styled-components";

export default ({ nft }: { nft: INftToken }) => {
  const { strings, mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });

  const tokenChain = !!nft.chain && strings(Chains[nft.chain]);

  return (
    <List disablePadding className={classes.detailCard}>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('CONTRACT_ADDRESS')}</Typography>
        <StyledTooltip title={nft.contract || ''}>
          <Link
            variant='body1'
            component='button'
            href={`/collection/${nft.chain}/${nft.contract}`} >
            {$addr(nft.contract)}
          </Link>
        </StyledTooltip>
      </ListItem>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('TOKEN_ID')}</Typography>
        <StyledTooltip title={nft.tokenId || ''}>
          <Typography className={classes.content}>{nft.tokenId}</Typography>
        </StyledTooltip>
      </ListItem>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('TOKEN_STANDARD')}</Typography>
        <Typography className={classes.content}>{$summary(nft, Summaries.COLLECTION_PROTOCOL, '')}</Typography>
      </ListItem >
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('BLOCKCHAIN')}</Typography>
        <Typography className={classes.content}>{tokenChain}</Typography>
      </ListItem>
    </List >
  )
};

const useStyle = makeStyles({
  detailCard: {
    flexGrow: 1,
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    background: '#131313',
    padding: ({ mobile }: any) => mobile ? '20px' : '51px'
  },
  content: {
    maxWidth: '50vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'right',
    color: '#c7c7c7',
  }
})