import { GridProps } from "@mui/material";
import { IListOptions } from "../../models";
import { createView } from "../../views";

const { default: SearchToolbar } = createView('search-toolbar-view');

type ListOptionToolbarProps = {
  onChange?: (changes: IListOptions) => void
  onOpenFilter?: () => void;
  gridProps?: GridProps,
  listOptions?: IListOptions,
}

export const SearchToolbarCard = ({ listOptions, onChange, onOpenFilter, gridProps }: ListOptionToolbarProps) => {
  return (
    <SearchToolbar
      search={listOptions?.search}
      sorting={listOptions?.sorting}
      onChange={onChange}
      onOpenFilter={onOpenFilter} />
  )
}


