import { Fragment, useContext } from "react";
import {
  Box,
  Divider,
  Drawer,
  List, ListItem, ListItemText, Avatar,
} from "@mui/material";
import { strings } from "../../strings";
import { Paths, UiContext, UserContext } from "../../context/app";
import { useAppContext } from "../hooks";
import Cookies from "universal-cookie";

const StyledListItemText = (props: any) => <ListItemText {...props}
  primaryTypographyProps={{
    sx: {
      fontWeight: 'bold',
      fontSize: '13px',
      textTransform: 'uppercase',
    }
  }} />

export const MobileMenu = () => {
  const { wallet } = useAppContext();
  const { showDrawer, setDrawer } = useContext(UiContext);
  const { user } = useContext(UserContext);
  const { home, mainPaths, infoPaths, userPaths } = Paths;

  return (
    <Drawer
      anchor="right"
      open={showDrawer}
      onClose={() => setDrawer(false)}
      PaperProps={{
        sx: {
          width: '56%',
          backgroundColor: '#e8e8e8',
          p: '75px 20px 200px 20px'
        }
      }}>
      <List>
        <ListItem
          button
          component="a"
          href={'/'}
          sx={{ py: '4px', px: '5px' }}>
          <StyledListItemText primary={strings('POPPER_HOME')} />
        </ListItem>
        <Divider sx={{ borderColor: '#000' }} />
        {
          mainPaths.map(item => <Fragment key={item.title}>
            <ListItem
              button
              component="a"
              disabled={item.disabled}
              key={item.title}
              href={item.path}
              sx={{ py: '4px', px: '5px' }}>
              <StyledListItemText primary={strings(item.title)} />
            </ListItem>
            <Divider sx={{ borderColor: '#000' }} />
          </Fragment>
          )
        }
        {
          infoPaths.map(item => <Fragment key={item.title}>
            <ListItem
              button
              component="a"
              disabled={item.disabled}
              key={item.title}
              href={item.path}
              sx={{ py: '4px', px: '5px' }}>
              <StyledListItemText primary={strings(item.title)} />
            </ListItem>
            <Divider sx={{ borderColor: '#000' }} />
          </Fragment>
          )
        }
      </List>
      <Box sx={{ mt: 'auto', mb: 0 }}>
        {
          wallet.isConnected() &&
            user != null &&
            user.image ?
            <Avatar alt='You' src={user.image} sx={{ width: 42, height: 42, }} />
            :
            <Avatar alt='You' src={'/assets/images/avatar_empty_black.svg'} sx={{ width: 42, height: 42, }} />
        }
        <List sx={{ pt: 0 }}>
          {
            userPaths.map((item, index) =>
              <Fragment key={item.title}>
                <ListItem
                  button
                  component="a"
                  disabled={item.disabled}
                  href={item.path}
                  sx={{ py: '4px', px: '5px' }}>
                  <StyledListItemText primary={strings(item.title)} />
                </ListItem>
                <Divider sx={{ borderColor: '#000' }} />
              </Fragment>
            )
          }
          <ListItem
            button
            onClick={() => {
              new Cookies().remove('account', { path: '/' });
              wallet.reset();
            }}
            sx={{ py: '4px', px: '5px' }}>
            <StyledListItemText primary={strings('POPPER_LOGOUT')} />
          </ListItem>
        </List>
      </Box>
    </Drawer >
  );
}