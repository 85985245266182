import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, TextField, Autocomplete } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { INftCollection, INftToken, IListOptions, Chains } from '../../models';
import { nftMint, uploadImage, uploadVideo } from '../../services/market';
import { useAppContext } from '../../components/hooks';
import { PageBase } from '../../components/page-base';
import { ImageDropzone, VideoDropzone } from '../../components/media-drop-zone';
import {
  listCollections, collectionId, collectionInfo,
} from '../../services/market';
import { UiContext } from '../../context/app';

const Row = ({ children, sx }: any) => (<Box sx={{ my: 3, ...sx }} >{children}</Box>)


export const PageNftMint = () => {
  const context = useAppContext();
  const { wallet, } = context;
  const { mobile, strings } = useContext(UiContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nftToken, setNftToken] = useState<INftToken>({});
  const [maxSupply, setMaxSupply] = useState('1');
  const [collections, setCollections] = useState<INftCollection[]>([]);
  const [nftImg, setNftImg] = useState<File>();
  const [nftVideo, setNftVideo] = useState<File>();
  const [collection, setCollection] = useState<INftCollection>();
  const [nftVideoUrl, setNftVideoUrl] = useState("");

  const dropzoneStyle = { height: 250, aspectRatio: "3/2", border: "3px dashed #CCCCCC", color: '#CCCCCC', borderRadius: 3, p: 0.5 };
  const requiredStyle = { '&::after': { color: 'red', content: '" *"' } };

  const disableSubmission = !wallet.isConnected() ||
    !nftImg ||
    !collection?.address ||
    !nftToken.meta?.name;

  const mintNft = async () => {
    try {
      if (!collection?.address) return;

      setLoading(true);
      setError(null);

      const imageUrl = nftImg ? await uploadImage(context, nftImg) : undefined;
      const colId = collectionId(wallet.chainId as Chains, collection?.address);
      const col = await collectionInfo(context, colId);

      const tokenInfo = {
        ...nftToken,
        contract: col?.address,
        chain: wallet.chainId as Chains,
        tags: wallet.chainId ? [wallet.chainId?.toString()] : [],
        maxSupply: parseInt(maxSupply),
        meta: {
          ...nftToken.meta,
          image: imageUrl
        }
      };

      if (nftVideo) {
        const videoUrl = await uploadVideo(context, nftVideo);
        tokenInfo.meta.animation_url = videoUrl;
      }

      const token = await nftMint(context, tokenInfo);

      alert(strings("MINT_SUCCESS").replace("[name]", token?.meta?.name || ''));

      if (!token || !token.tokenId) return;

      navigate(`/nft/${token.chain}/${collection.address}/${token.tokenId}`)
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const reload = async () => {
    try {
      setLoading(true);
      setError(null);
      let listOptions: IListOptions = {
        chain: wallet.chainId as Chains,
      };

      if (wallet.account)
        listOptions.owner = wallet.account

      const cols = await listCollections(context, listOptions);
      setCollections(cols.items);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    reload();
  }, [wallet.account, wallet.chainId])
  return (
    <PageBase coverLoading loading={loading} error={error} onReload={reload}>
      <Container>
        <Row>
          <Typography variant="h3">{strings("TITLE")}</Typography>
        </Row>

        <Row>
          <Typography
            variant='caption'
            sx={{ fontWeight: 'bold', '&::before': { color: 'red', content: '"* "' } }}
            paragraph>
            {strings("REQUIRED_TIPS")}
          </Typography>
          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("MEDIA_TITLE")}</Typography>
          <ImageDropzone
            sx={{ ...dropzoneStyle }}
            imgStyle={{ aspectRatio: "3/2", borderRadius: 3 }}
            acceptType="image/*"
            src={nftImg}
            onDrop={(files: any[]) => setNftImg(files[0])}
            onClear={() => setNftImg(undefined)}
          />

          <VideoDropzone
            sx={{ ...dropzoneStyle, mt: 3 }}
            acceptType="video/*"
            src={nftVideoUrl}
            onDrop={(files: any[]) => {
              setNftVideoUrl(URL.createObjectURL(files[0]));
              setNftVideo(files[0])
            }}
            onClear={() => {
              setNftVideo(undefined);
              setNftVideoUrl("");
            }}
          />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("NAME_TITLE")}</Typography>
          <TextField
            fullWidth
            required
            value={nftToken.meta?.name || ""}
            onChange={(e) => setNftToken({ ...nftToken, meta: { ...nftToken.meta, name: e.target.value } })} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("EXTERNAL_URL_TITLE")}</Typography>
          <TextField
            fullWidth
            value={nftToken.meta?.external_url || ""}
            onChange={(e) => setNftToken({ ...nftToken, meta: { ...nftToken.meta, external_url: e.target.value } })} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold' }}>{strings("DESCRIPTION_TITLE")}</Typography>
          <TextField
            multiline
            fullWidth
            minRows={4}
            value={nftToken.meta?.description || ""}
            onChange={(e) => setNftToken({ ...nftToken, meta: { ...nftToken.meta, description: e.target.value } })} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("MAX_SUPPLY")}</Typography>
          <TextField
            fullWidth
            required
            value={maxSupply}
            onChange={(e) => setMaxSupply(e.target.value)} />
        </Row>

        <Row>
          <Typography sx={{ fontWeight: 'bold', ...requiredStyle }}>{strings("COLLECTION_TITLE")}</Typography>
          <Autocomplete
            fullWidth
            freeSolo
            options={collections}
            getOptionLabel={(option) => option.meta?.name || ""}
            onChange={(evt, val) => {
              if (val)
                setCollection({ ...collection, address: typeof val === "string" ? val : val.address })
            }}
            onInputChange={(evt, val) => setCollection({ ...collection, address: val })}
            renderInput={(params) => <TextField {...params} required />} />
        </Row>

        <LoadingButton
          sx={{ my: 3 }}
          loading={loading}
          disabled={disableSubmission}
          variant="contained"
          onClick={mintNft}>{strings("CREATE")}</LoadingButton>

      </Container>

    </PageBase>
  )
}