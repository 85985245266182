import { useContext } from "react";
import { SxProps, Box, Typography, Stack } from "@mui/material";
import moment from "moment";
import Web3 from "web3";
import { AccessTime } from "@mui/icons-material";
import { Countdowner } from "../../../components/countdowner";
import { useAppContext } from "../../../components/hooks";
import { $price, sellButtonVisible } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftToken, SellStates, SellMethods } from "../../../models";
import { BuyButton, BidButton } from "../../../pages/page-nft-bid";
import { stateOf, convertEth } from "../../../services/market";

interface ActionCardProps {
  nft: INftToken,
  reload: () => void,
  sx?: SxProps
};

const timingOf = (state: SellStates | undefined, begin: number, end: number) => {
  if (!state) return '';
  let timing = '';
  switch (state) {
    case SellStates.COMMING:
      timing = moment.unix(begin).fromNow();
      break;
    case SellStates.SELLING:
      timing = moment.unix(end).fromNow();
      break;
    case SellStates.ENDING:
      timing = 'ending';
      break;
    default:
      timing = '';
      break;
  }
  return timing;
}


const AuctionBlock = ({ title, children, mobile }: any) =>
  <Box sx={{}}>
    <Typography variant="subtitle1" sx={{
      backgroundColor: '#737373',
      color: '#fff',
      px: '14px',
      pl: mobile ? '69px' : '14px',
      py: '7px',
      fontSize: '15px'
    }}>
      {title}
    </Typography>
    {children}
  </Box>

export default (props: ActionCardProps) => {
  const { nft, reload, sx } = props;
  const { strings, mobile } = useContext(UiContext); // don't remove this
  const { wallet } = useAppContext();
  const state = stateOf(nft);

  let price: string | undefined;
  let timing: string | undefined;

  if (nft.saleOptions) {
    price = $price(nft.saleOptions?.price || '0', 4);
    timing = timingOf(
      state,
      nft.saleOptions.duration?.begin || 0,
      nft.saleOptions.duration?.end || 0
    );
  }

  //@todo del below when offer button come back L:262
  if (!nft.saleOptions || !price) return <></>;

  return (
    <Box sx={{ flexGrow: 1, ...sx, maxWidth: mobile ? '100%' : 'fit-content' }}>
      {
        price &&
        <AuctionBlock mobile={mobile} title={strings("PRICE")}>
          <Stack direction={mobile ? 'column' : 'row'} spacing={mobile ? 1 : 2} sx={{ border: mobile ? 'inherit' : '1px solid #dcdcdc', py: 1, px: '21px', pl: mobile ? '79px' : '21px' }}>
            <Typography variant='h4' fontWeight='bold' >{strings('PRICE_ETH').replace('[price]', price)}</Typography>
            <Typography variant="subtitle1" sx={{ alignSelf: mobile ? 'inherit' : 'flex-end' }} >{`$${convertEth(Web3.utils.toWei(price))}`}</Typography>
          </Stack>
        </AuctionBlock>
      }

      {
        !!nft.saleOptions &&
        (state == SellStates.COMMING || state == SellStates.SELLING) &&
        <AuctionBlock
          mobile={mobile}
          title={state == SellStates.COMMING ?
            strings('SALE_START_AT') :
            strings('SALE_ENDS')
          }>
          <Countdowner targetTime={
            state == SellStates.COMMING ?
              nft.saleOptions.duration?.begin || 0 :
              nft.saleOptions.duration?.end || 0
          } />
        </AuctionBlock >
      }

      {
        nft.owner !== wallet.account && <Box mt='4px'>
          {
            sellButtonVisible(nft, wallet.account) &&
            <BuyButton
              buttonProps={{
                sx: { borderRadius: 0, fontSize: '15px' },
                fullWidth: true
              }}
              nft={nft} >
              {strings('BUY_NOW')}
            </BuyButton>
          }

          {
            sellButtonVisible(nft, wallet.account, "BID") &&
            <BidButton
              buttonProps={{
                sx: { borderRadius: 0, fontSize: '15px' },
                fullWidth: true,
              }}
              nft={nft}>
              {strings('PLACE_BID')}
            </BidButton>
          }
          {state === SellStates.COMMING &&
            <Box sx={{ display: 'flex', backgroundColor: 'primary.main', color: 'white', py: '7px', justifyContent: 'center' }}>
              <AccessTime fontSize='small' /> &nbsp;
              <Typography fontSize='15px'>{nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER ? strings('BIDDING_SOON') : strings('OPENING_SOON')}</Typography>
            </Box>}
        </Box>
      }
    </Box >
  )
}