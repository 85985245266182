import { 
  User as FirebaseUser,
  UserCredential,
  getAuth, 
  setPersistence, 
  signInWithCustomToken, 
  signOut, 
  browserSessionPersistence, 
  onAuthStateChanged, 
} from "firebase/auth";
import firebase from "firebase/compat";

import { IUser, IContext } from "../../models";
import { document, save } from '../db-utils';


export const previousUser = () =>
  getAuth().currentUser;


export const observeUserState =
  (onchange: (user: FirebaseUser | null) => void) =>
    onAuthStateChanged(getAuth(), onchange);


// spec: 當瀏覽器中找不到 token 時，需要要求使用者透過錢包簽署取得 token
export const authByWallet =
  async (context: IContext):
    Promise<UserCredential | void> => {
    const { wallet, web3 } = context;

    if (!wallet.isConnected())
      throw new Error("Wallet not connected.");

    const auth = getAuth();

    const { data: ticket } = await firebase
      .functions()
      .httpsCallable('ticket')({
        chainId: wallet.chainId,
        account: wallet.account
      });

    const sign = await web3.eth.personal.sign(ticket, wallet.account);

    const { data: token } = await firebase
      .functions()
      .httpsCallable('tokenByWallet')({ ticket, sign });

    await setPersistence(auth, browserSessionPersistence);

    const cred = await signInWithCustomToken(auth, token);

    return cred;

  }


export const resetAuth =
  async (): Promise<void> =>
    signOut(getAuth());

    
export const isRegistered =
  async (account: string):
    Promise<boolean> => {
    const { data } = await firebase
      .functions()
      .httpsCallable("isAccountRegistered")({ account });
    return data;
  }


export const userProfileEdit = 
  async (context: IContext, profile: IUser): 
    Promise<void> => {

      if(!profile.address) 
        throw new Error('Incorrect profile format.');

      await authByWallet(context);
      await save('users', profile.address, profile);
    }


export const userInfo =
  async (context: IContext, address?: string):
    Promise<IUser | null> => {
    const { wallet } = context;
    const userAddress = address || wallet.account || null;

    if(!userAddress) return null;
    
    return (await document('users', userAddress)) as IUser;
  }



// helper functions
export const isRole =
  async (contract:any, role:string, account:string): Promise<boolean> => {
    if(!account) return false;
    const ROLE = await contract.methods[role]().call();
    const result = await contract.methods.hasRole(ROLE, account).call();
    return result;
  }

export const isAdmin =
  async (context: IContext): Promise<boolean> => 
    isRole(context.market, "ADMIN_ROLE", context.wallet.account);

export const isDealer =
  async (context: IContext): Promise<boolean> => 
    isRole(context.market, "DEALER_ROLE", context.wallet.account);