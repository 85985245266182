import { IListOptions, INftToken, IPaged, Sorting, IContext } from "../../models";
import { listNfts as coreListNfts } from "../../services/market";
const { data: nfts } = require('../soyl-nfts.json');

export const listNfts = async (context: IContext, options: IListOptions):
  Promise<IPaged<INftToken>> => {
  if(process?.env?.REACT_APP_CONTRACT) {
    return coreListNfts(context, { ...options, contract: process?.env?.REACT_APP_CONTRACT });
  }

  const opt = {
    page: 0,
    perPage: 50,
    sort: Sorting.RECENTLY_LISTED,
    ...options
  }

  return {
    page: opt.page,
    perPage: opt.perPage,
    pages: nfts.length / opt.perPage,
    total: nfts.size,
    hasNext:false, // @todo handle hasNext
    items: nfts
  }
}