import { useContext, useState, ReactNode } from 'react';
import { useAppContext } from '../../components/hooks';
import {
  Box,
  Button, ButtonProps,
} from "@mui/material";
import { INftToken } from '../../models';
import { MarketDialog, MarketDialogActions } from '../../components/dialog-market';
import { cancelBook } from '../../services/market';
import { LoadingButton } from '@mui/lab';
import { UiContext } from '../../context/app';

interface CancelBookButtonProps {
  nft?: INftToken,
  finished?: () => void,
  buttonProps?: ButtonProps,
  children?: ReactNode,
}

export const CancelBookButton = (props: CancelBookButtonProps) => {
  const { strings } = useContext(UiContext);
  const context = useAppContext();

  const [isCancelingBook, setIsCancelingBook] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const target = props.nft?.saleOptions?.id || '';

  const onCancelBook = async () => {
    try {
      setLoading(true);
      setError(null);
      // @todo: should pass id of saleOptions(v1) or saleConfig(v2)
      await cancelBook(context, target)
      setIsCancelingBook(false);
      if (props.finished)
        props.finished();
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Button
        {...props.buttonProps}
        variant={props.buttonProps?.variant || 'contained'}
        onClick={() => setIsCancelingBook(true)}>
        {props.children}
      </Button>

      <MarketDialog
        fullWidth
        open={isCancelingBook}
        onClose={() => setIsCancelingBook(false)}
        title={strings("CANCEL_TITLE")}>
        <MarketDialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            onClick={onCancelBook}>
            {strings("CONFIRM")}
          </LoadingButton>
        </MarketDialogActions>
      </MarketDialog>
    </Box>
  )
}
