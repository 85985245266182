import { IContext, INftCollection, INftToken } from "../../../models";
import * as NftTrade from './nft-trade';
import * as NftMarket from './nft-market';

interface INftProvider {
  createCollection(context:IContext, options:INftCollection):Promise<INftCollection|null>;
  createToken(context:IContext, options:INftToken):Promise<INftToken|null>;
}

const NftProvider:string = process?.env?.REACT_APP_TRADE_PROVIDER || 'NftMarket'; // NftTrade
const NftServices:{[key:string]:INftProvider} = {
  "NftTrade": NftTrade as INftProvider,
  "NftMarket": NftMarket as INftProvider
}

export const createCollection = NftServices[NftProvider].createCollection;
export const createToken = NftServices[NftProvider].createToken;

// @deprecated
export const collectionDeploy =
  (context: IContext, options: INftCollection):
    Promise<INftCollection | null> => 
    createCollection(context, options);

// @deprecated
export const nftMint =
  async (context: IContext, options: INftToken):
    Promise<INftToken | null> => 
    createToken(context, options);