import { createContext, useCallback, useContext, useReducer } from "react";
import { IUser } from "../../models";


export const enum UserActions {
  SET,
  UPDATE
}

export type UserAction = {
  type: UserActions,
  payload: any,
}

type UserContextProps = {
  user: IUser | null;
  setUser: (user:IUser | null) => void;
  updateUser: (updates:IUser) => void;
  dispatchUser: (action:UserAction) => void,
}

const InitialUserContext = {
  user: null,
  setUser: (user:IUser | null) => {},
  updateUser: (udpates:IUser) => {},
  dispatchUser: (action:UserAction) => {},
}

const reducer = (state:IUser | null, action:UserAction) => {
  let next = {...state};
  switch(action.type) {
    case UserActions.SET:
      next = action.payload;
      break;
    case UserActions.UPDATE:
      next = {...next, ...action.payload};
      break;
  }
  return next;
}

type UserReducer =
  (state:IUser | null, action: UserAction) => IUser | null;

export const UserContext = createContext<UserContextProps>(InitialUserContext);
export const WithUserContextProvider = (props:any) => {
  const [user, dispatchUser] = useReducer<UserReducer>(reducer, null);
  const setUser = useCallback((payload:IUser | null) =>
    dispatchUser({type:UserActions.SET, payload}),
    [dispatchUser]
  );
  const updateUser = useCallback((payload:IUser)=>
    dispatchUser({type:UserActions.UPDATE, payload}),
    [dispatchUser]
  );
  return (
    <UserContext.Provider value={{
      user,
      setUser,
      updateUser,
      dispatchUser
    }}>
      {props.children}
    </UserContext.Provider>
  )
}