import { IContext, INftCollection, INftToken } from "../../../models";
import { authByWallet, isDealer } from "../service-auth";
import { contractAt, send, create as createNftService } from "../crypto";
import { nftId } from "../func-pure";
import { signTypedData } from "../crypto/utils/sign";
import { append, save } from "../../db-utils";
import NFTWithErc1155UnmintedPersonal from '.././../../contracts/NFTWithErc1155UnmintedPersonal.json';
import firebase from "firebase/compat";
import moment from "moment";


// 1. 不指定版稅：版稅相關欄位都應該是 undefined
// 2. 指定版稅：版稅相關欄位都應該有正確的值，收款帳號必須是錢包地址，版稅比例必須是1/10000為單位的比例。
// 3. 底層服務應確保在option規格有錯時，報錯並顯示可辨識的錯誤訊息，並在option規格正確時，確保流程正確運行。
// 4. 關於 Erc1155UnmintedPersonal 的 Constructor 設計，應檢查其設計，當不使用 Royalty 機制時，應如何正確傳參數。
export async function createCollection(
    context:IContext, 
    options:INftCollection
  ):Promise<INftCollection|null> {
    await authByWallet(context);

    const { web3, wallet, trader } = context;

    if (!wallet.account) throw new Error("Wallet not connected.");
    if (!options.chain) throw new Error("Unknown Chain.");
    if ((options.meta?.seller_fee_basis_points && !options.meta?.fee_recipient)
      || (!options.meta?.seller_fee_basis_points && options.meta?.fee_recipient)
    ) throw new Error("Incorrect Royalty Setting.");

    const { data: contractIndex } = await firebase
      .functions()
      .httpsCallable('createContractIndex')();
    const META_BASE_URI = process?.env?.REACT_APP_META_BASE_URI;
    // const contractMetaURI = `${META_BASE_URI}/collections/${contractIndex}`;
    const tokenBaseURI = `${META_BASE_URI}/tokens/${contractIndex}/{id}`;
    const colData: INftCollection = {
      ...options,
      chain: options.chain,
      creator: wallet.account,
      owner: wallet.account, // @todo: must sync with chain
      timestamp: moment().unix()
    };
  
    await save('draft', contractIndex, colData);

    const deployer = await contractAt({
      web3, 
      chain: wallet.chainId,
      artifacts: require('../../../contracts/NftContractDeployer.json')
    });

    await send(deployer.methods.deployErc1155(
      contractIndex,
      options.meta?.name || "NFT",
      "NFT",
      tokenBaseURI,
      "1.0",
      wallet.account,
      options.meta?.fee_recipient || wallet.account,
      options.meta?.seller_fee_basis_points || 0,
      trader.options.address
    ), {from:wallet.account});
  
    return colData;
  }

export async function createToken(
    context:IContext, 
    options:INftToken
  ):Promise<INftToken|null> {
    if (!options.chain) throw new Error('Unknown Chain');
    if (!options.contract) throw new Error('Unknown Contract');
    const { web3, wallet } = context;
    const { chain, contract, maxSupply = 1 } = options;
    await authByWallet(context);

    // Mint Token
    const nftc = await createNftService(web3, chain, contract);

    // @todo: convert to uint256 number
    const genTokenId = () => web3.utils.toBN(web3.utils.randomHex(32)).toString();
    
    let availableId = genTokenId();
    while(await nftc.exists(availableId))
      availableId = genTokenId();

    const tokenId = availableId;

    const signer = wallet.account;
    const message = { id: tokenId, maxSupply };
    const type = 'MintData';
    const signature = await signTypedData({ 
      artifacts: NFTWithErc1155UnmintedPersonal,
      address: contract,
      web3, chain, signer, type, message 
    });
    const mintSig = `${signature}${web3.eth.abi.encodeParameter('uint256', maxSupply).replace('0x','')}`;

    const id = nftId(options.chain, options.contract, tokenId);
    const nftData: INftToken = {
      ...options,
      id,
      tokenId,
      chain: options.chain,
      contract: options.contract,
      owner: wallet.account, // @todo: must sync with chain
      owners: [wallet.account],
      timestamp: moment().unix(),
      mintSig
    }

    await save('nfts', id, nftData);
    
    return nftData;
  }