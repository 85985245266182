import { Typography, Stack } from "@mui/material";
import moment from "moment";
import { useContext, useState, useEffect } from "react";
import { UiContext } from "../../context/app";
import { Column } from "../page-base";

const digit = (value: number) => {
  if (value < 0) value = 0;
  return Math.floor(value).toString().padStart(2, '0');
}

const CountCell = (props: any) =>
  <Column>
    <Typography variant="h5" fontWeight='bold' width="50px" align='center'>{props.value}</Typography>
    <Typography variant="subtitle2" fontWeight='normal' align='center'>{props.title}</Typography>
  </Column>

export const Countdowner = ({ targetTime = moment().unix(), stackProps }: { targetTime?: number, stackProps?: any }) => {
  const { mobile, strings } = useContext(UiContext);
  const [counter, setCounter] = useState(0);
  const duration = moment.duration(moment.unix(targetTime).diff(moment()));

  useEffect(() => {
    var loop = setInterval(() => { setCounter(counter + 1) }, 1000);
    return () => clearInterval(loop);
  }, [counter]);

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{
        border: mobile ? 'inherit' : '1px solid #dcdcdc',
        py: 1,
        px: '21px',
        pl: mobile ? '79px' : '21px'
      }}
      {...stackProps}>
      {duration.days() > 0 && <CountCell title={strings('DAYS')} value={digit(duration.asDays())} />}
      <CountCell title={strings('HOURS')} value={digit(duration.hours())} />
      <CountCell title={strings('MINUTES')} value={digit(duration.minutes())} />
      <CountCell title={strings('SECONDS')} value={digit(duration.seconds())} />
    </Stack>

  )
}