import { ReactNode, useEffect, useState } from 'react';
import { useAppContext, useWeb3 } from '../../components/hooks';
import { styled } from "@mui/material/styles";
import AdapterMoment from '@mui/lab/AdapterMoment';
import {
  Alert, TextField,
  Button, ButtonProps,
  Box,
  Typography,
  Select, MenuItem, Divider, Checkbox, FormControlLabel, Link,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider, TimePicker, LoadingButton } from "@mui/lab";
import { LocalOffer } from '@mui/icons-material';
import moment from 'moment';
import Web3 from 'web3';
import { $price, genStrings } from "../../components/utils";
import { IOfferOptions } from '../../models/options/offer-options';
import { MarketDialog, MarketDialogContent, MarketDialogActions } from '../../components/dialog-market';
import { CURRENCY_DEFAULT, ICoin, INftToken } from '../../models';
import { convertEth, listCoins, makeOffer } from '../../services/market';
import { CurrencySelector } from '../../components/currency-selector';

const STRINGS = {
  "OFFER.TITLE": "Make an offer",
  "OFFER.EXPIRATION": "Offer Expiration",
  "OFFER.EXPIRATION_DAYS": "[day] days",
  "OFFER.EXPIRATION_MONTH": "A month",
  "OFFER.EXPIRATION_CUSTOM": "Custom date",
  "OFFER.SUBMIT_BUTTON": "Make Offer",
  "PRICE": "Price",
  "BALANCE": "Balance: [balance] ETH",
  "SYMBOL": "ETH",
  "DOLLAR_EXCHANGE": "$ [dollars]",
  "REMINDER": "By checking this box, I agree to ",
  "REMINDER_SERVICE": "Terms Of Service",
}
const strings = genStrings(STRINGS);

const NoneBorderField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
});

const PriceInput = ({ strings, coins, options, onChange }: any) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: 3 }}>
      <CurrencySelector
        sx={{
          width: '120px',
          "& .MuiOutlinedInput-root": {
            "& fieldset": { border: "none" }
          }
        }}
        val={options.currency}
        coins={coins}
        onChange={(currency: any) => onChange({ currency })}
      />

      <Divider orientation="vertical" flexItem />

      <NoneBorderField
        sx={{ flexGrow: 1 }}
        type="number"
        value={$price(options.price)}
        onChange={(e) => onChange({ price: Web3.utils.toWei(e.target.value || "0") })} />

      <Divider orientation="vertical" flexItem />

      <Typography
        sx={{
          width: 60,
          textOverflow: "ellipsis",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          mx: 2
        }}>
        {strings("DOLLAR_EXCHANGE").replace("[dollars]", convertEth(options.price))}
      </Typography>
    </Box>
  )
}

interface OfferButtonProps {
  nft?: INftToken,
  finished?: (res: any) => void;
  buttonProps?: ButtonProps,
  children?: ReactNode,
}


export const OfferButton = (props: OfferButtonProps) => {

  const context = useAppContext();
  const { wallet } = context;
  const web3 = useWeb3();

  const [isOffering, setIsOffering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const [offerOptions, setOfferOptions] = useState<IOfferOptions>({
    target: props.nft?.id as string,
    price: "",
    duration: {
      begin: moment().unix(),
      end: moment().add(7, 'd').unix()
    },
    currency: CURRENCY_DEFAULT
  })
  const [expirateType, setExpirateType] = useState("7");
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [coins, setCoins] = useState<ICoin[]>([]);

  const onMakeOffer = async () => {
    try {
      setLoading(true);
      setError(null);
      const res = await makeOffer(context, offerOptions)
      if (props.finished)
        props.finished(res);
      setIsOffering(false)
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const reloadCoins = async () => {
    if (!wallet.chainId) return;
    try {
      const coins = await listCoins(wallet.chainId);
      setCoins(coins);
    } catch (error: any) {
      setError(error);
    }
  }

  useEffect(() => {
    if (!isOffering) return;
    setOfferOptions({
      target: offerOptions.target,
      price: "",
      duration: {
        begin: moment().unix(),
        end: moment().add(7, 'd').unix()
      },
      currency: CURRENCY_DEFAULT
    });
    setExpirateType("7");
    setAcceptedPolicy(false);
  }, [isOffering]);

  useEffect(() => { reloadCoins() }, [wallet])

  return (
    <Box>
      <Button
        {...props.buttonProps}
        startIcon={props.buttonProps?.startIcon || <LocalOffer />}
        variant={props.buttonProps?.variant || 'outlined'}
        onClick={() => setIsOffering(true)} >
        {props.children}
      </Button>

      <MarketDialog
        fullWidth
        open={isOffering}
        onClose={() => setIsOffering(false)}
        title={strings("OFFER.TITLE")}>
        <MarketDialogContent>
          <Box>
            <Typography>{strings("PRICE")}</Typography>
            <PriceInput
              strings={strings}
              coins={coins}
              options={offerOptions}
              onChange={(val: any) => setOfferOptions({ ...offerOptions, ...val })} />
            <Typography gutterBottom>
              {
                strings("BALANCE")
                  .replace('[balance]', wallet.isConnected() ? $price(wallet.balance, 4) : $price('0', 4))
              }
            </Typography>
          </Box>
          <Box>
            <Typography>{strings("OFFER.EXPIRATION")}</Typography>
            <Box sx={{ display: 'flex' }}>
              <Select value={expirateType} onChange={(e) => setExpirateType(e.target.value)}>
                <MenuItem value={"3"}>{strings("OFFER.EXPIRATION_DAYS").replace("[day]", 3)}</MenuItem>
                <MenuItem value={"7"}>{strings("OFFER.EXPIRATION_DAYS").replace("[day]", 7)}</MenuItem>
                <MenuItem value={"30"}>{strings("OFFER.EXPIRATION_MONTH")}</MenuItem>
                <MenuItem value={"CUSTOM"}>{strings("OFFER.EXPIRATION_CUSTOM")}</MenuItem>
              </Select>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {
                  expirateType === "CUSTOM" ?
                    <DateTimePicker
                      value={moment.unix(offerOptions?.duration.end)}
                      renderInput={(props) => <TextField {...props} fullWidth />}
                      onChange={(end) => setOfferOptions({ ...offerOptions, duration: { begin: moment().unix(), end: moment(end).unix() } })} />
                    :
                    <TimePicker
                      value={moment.unix(offerOptions.duration?.end)}
                      renderInput={(props) => <TextField {...props} fullWidth />}
                      onChange={(end) => setOfferOptions({ ...offerOptions, duration: { begin: moment().unix(), end: moment(end).unix() } })} />
                }
              </LocalizationProvider>
            </Box>
          </Box>
          <FormControlLabel
            label={
              <Typography>
                {strings("REMINDER")}
                <Link
                  underline="none"
                  href="/terms"
                  target="_blank">
                  {strings("REMINDER_SERVICE")}
                </Link>
              </Typography>
            }
            control={
              <Checkbox
                checked={acceptedPolicy}
                onChange={(e) => setAcceptedPolicy(e.target.checked)} />
            } />
          {
            error &&
            <Alert severity='error'>{error.message}</Alert>
          }
        </MarketDialogContent>
        <MarketDialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            onClick={onMakeOffer}
            disabled={!wallet.isConnected() || !offerOptions.price || !acceptedPolicy}>
            {strings("OFFER.SUBMIT_BUTTON")}
          </LoadingButton>
        </MarketDialogActions>
      </MarketDialog>
    </Box>
  )
}