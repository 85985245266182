import { INftToken } from "../../models";
import { createView } from "../../views";

const { default: NftCardView } = createView('nft-card-view');

export const NftCard = ({ nft }: { nft: INftToken | undefined }) => {
    return (
        <NftCardView nft={nft} />
    )
}


