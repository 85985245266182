import { createView } from "../../views";

const { default: NftTitleView } = createView('nft-title-view');

export const NftTitleCard = ({ nft }: any) => {

  return (
    <NftTitleView nft={nft} />
  )
}
