import LocalizedStrings from 'react-localization';
import us from '../assets/language/en-US';
import tw from '../assets/language/zh-TW';

const langPackages: any = {
  "en-US": us,
  "zh-TW": tw,
};

export const localization = new LocalizedStrings<string>(langPackages);

// @deprecated, use uiContext.strings instead
export const strings = (key: string) => {
  if (!(key in localization)) return key
  return localization.getString(key)
};