export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
export const EMPTY_SIGN = '0x0000000000000000000000000000000000000000';
export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export enum Chains {
  ETHEREUM_MAINNET = 1,
  ETHEREUM_ROPSTEN_TESTNET = 3,
  ETHEREUM_RINKEBY_TESTNET = 4,
  BIANCE_MAINNET = 56,
  BIANCE_TESTNET = 97,
  GANACHE_TESTNET = 1337,
}

export enum Communities {
  WEBSITE = "WEBSITE",
  DISCORD = "DISCORD",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
}

export enum Summaries {
  VISITED = "VISITED",
  FAVORITED = "FAVORITED",
  TOTAL_SUPPLY = "TOTAL_SUPPLY",
  OWNERS = "OWNERS",
  FLOOR_PRICE = "FLOOR_PRICE",
  VOLUME_TRADED = "VOLUME_TRADED",
  TOP_BID = "TOP_BID",
  COLLECTION_NAME = "COLLECTION_NAME",
  COLLECTION_OWNER = "COLLECTION_OWNER",
  COLLECTION_ICON = "COLLECTION_ICON",
  COLLECTION_PROTOCOL = "COLLECTION_PROTOCOL",
  LATEST_DEALED_PRICE = "LATEST_DEALED_PRICE",
  LATEST_DEALED_TIME = "LATEST_DEALED_TIME",
  TOPEST_DEALED_PRICE = "TOPEST_DEALED_PRICE",
  LOWEST_DEALED_PRICE = "LOWEST_DEALED_PRICE",
  TOTAL_DEALED_TIMES = "TOTAL_DEALED_TIMES",
  TOTAL_DEALED_AMOUNT = "TOTAL_DEALED_AMOUNT",
  PROTOCOL = "PROTOCOL",
}

export interface IBanner {
  id?: string,
  title?: string,
  image?: string,
  link?: string,
  state: 'PUBLISHED' | 'DRAFT',
  availableFrom: Date,
  expireAfter: Date,
  createAt: Date,
}

export interface IPeriod {
  begin: number; // seconds
  end: number; // seconds
}

export interface IOwnerable {
  owner?: string;
}

export interface ICoin {
  symbol: string;
  name: string;
  address: string;
}

export interface IFees {
  serviceFee: number;
  creatorRoality: number;
}