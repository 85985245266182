import { useEffect, useState, Fragment, useContext } from "react";
import {
  Container, Grid, Box,
  Typography, Divider,
  Button, Link,
} from "@mui/material";
import { Swiper as SwiperList, SwiperSlide } from "swiper/react/swiper-react";
import Swiper from 'swiper/types/swiper-class';
import { Navigation, Grid as SwiperGrid } from "swiper";
import { useAppContext } from "../../../components/hooks";
import { IListOptions, INftToken } from "../../../models";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import '../../../assets/swiper-button.css';
import { NftCard } from "../../../components/card-soyl-nft";
import { UiContext } from "../../../context/app";
import { useNavigate } from "react-router-dom";
import { EMPTY_DATA, article } from "../../../campaign-20220316/articles";
import { PageBaseOfCampaign } from "../../../campaign-20220316/components/page-base-campaign";
import { StyledTitle } from "../../../campaign-20220316/components/styled-title";
import { SoylIcon } from "../../../campaign-20220316/icons";
import { listNfts } from "../../../services/market";

const ContentItem = (props: any) => (
  <Box {...props}>

    <Typography variant="caption"  >
      {props.title}
    </Typography>

    <Typography variant="h1" sx={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
      {props.subtitle}
    </Typography>

  </Box>
);

const NavButton = (props: any) =>
  <Button fullWidth href={props.href} sx={{
    backgroundColor: 'inherit',
    fontSize: '22px',
    fontWeight: 'bold',
    p: 0,
    borderRadius: 0,
    ...props.sx
  }}
    startIcon={< Box width={'31px'} />} endIcon={<SoylIcon icon={props.icon} size={31} />}  >
    {props.children}
  </Button >


export default () => {
  const context = useAppContext();
  const { mobile, lang, strings } = useContext(UiContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(EMPTY_DATA);
  const [nfts, setNfts] = useState<INftToken[]>([]);
  const [listOptions, setListOptions] = useState<IListOptions>({});
  const [swiper, setSwiper] = useState<Swiper>();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const reload = async () => {
    try {
      setLoading(true);
      setError(null);
      const rs = await listNfts(context, listOptions);
      setNfts(rs.items);
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    reload();
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang]);

  return (
    <PageBaseOfCampaign >
      <Box sx={{ backgroundColor: '#242424' }}>
        <Container disableGutters>

          <Grid container p={0} m={0}>

            {/* Banner */}

            <Grid item xs={12}>
              <img
                style={{ height: mobile ? '80vw' : '', width: '100%', display: 'block' }}
                src="/assets/images/home-cover.png"
              />
            </Grid>


            {/* Artist Info */}

            <Grid item xs={12}
              px={mobile ? 2 : 5}
              py={2}
              sx={{
                display: 'flex',
                alignItems: mobile ? 'flex-start' : 'center',
                color: 'black',
                backgroundColor: 'white',
                flexDirection: mobile ? 'column' : 'row',
              }}>
              <Typography variant="body1" p={2} display={mobile ? 'none' : 'inherit'}>
                {data.intro}
              </Typography>
              <Divider flexItem sx={{ margin: '10px', display: mobile ? 'none' : 'inherit' }} orientation={mobile ? 'horizontal' : 'vertical'} />
              <ContentItem p={'10.5px'} title='Creator' subtitle={data.creator} />
              <Divider flexItem sx={{ margin: '10px' }} orientation={mobile ? 'horizontal' : 'vertical'} />
              <ContentItem p={'10.5px'} title='Collection' subtitle={data.collection} />
              <Divider flexItem sx={{ margin: '10px', display: mobile ? 'inherit' : 'none' }} orientation={'horizontal'} />
              <Typography variant="body1" p={2} display={mobile ? 'inherit' : 'none'}>
                {data.intro}
              </Typography>
            </Grid>
          </Grid>

        </Container>

        {/**
         * 此處因為使用 Grid spacing 會有邊界跑掉的問題，因此需要設定 overflow-x: hidden
         * 利用外層的退縮來修正 Grid 的偏移量。
         * 參考：https://stackoverflow.com/questions/61797942/reactjs-using-material-ui-grid-spacing
         */}
        <Container
          disableGutters
          sx={{
            overflowX: 'hidden',
            background: 'linear-gradient(to bottom, #3b3b3b, #575757 27%, #545353 59%, #111 104%)',
          }}>

          <Grid container p={5} spacing={5} px={mobile ? 5 : 19}>
            {/* Artist Spotlight */}
            <Grid item xs={12}>
              <Typography variant="h2" mb={4} align="center" sx={{ color: 'white' }}>
                <StyledTitle>
                  {data.creator}
                </StyledTitle>
              </Typography>
            </Grid>
            {
              !mobile ?
                <Grid item xs={12} display='flex'>
                  <Box sx={{ flex: '1 1 372px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                    <Box sx={{ ml: '-29px', height: '2px', backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff 10%, #fff)' }} />
                    <img style={{ maxWidth: '372px', width: '100%' }} src="/assets/images/ill-artist-2@2x.png" />
                  </Box>
                  <Box sx={{ width: '2px', my: '-20px', backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 5%, #fff 96%, rgba(255, 255, 255, 0))' }} />
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ maxWidth: '528px', width: '100%', objectFit: 'cover' }} src="/assets/images/ill-artist-1@2x.png" />
                  </Box>
                </Grid>
                :
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }} >
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '65%', alignSelf: 'flex-start' }} >
                    <img style={{ width: 'calc(100% - 2px)', }} src="/assets/images/ill-artist-3@2x.png" />
                    <Box sx={{
                      width: '2px',
                      mt: '-15px',
                      backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 10%, #fff)'
                    }} />
                  </Box>
                  <Box sx={{
                    height: '2px',
                    width: 'calc(100% + 34px)',
                    mx: '-17px',
                    backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff 5%, #fff 96%, rgba(255, 255, 255, 0))'
                  }} />
                  <img style={{ width: '100%', objectFit: 'cover' }} src="/assets/images/ill-artist-1@2x.png" />
                </Grid>
            }
            <Grid item xs={12} md={12} pb={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="body1" sx={{ color: 'white' }}>
                {data.spotlightIntro}
              </Typography>
            </Grid>

          </Grid>
          <NavButton icon="navigate" href="/spotlight">Spotlight</NavButton>
        </Container>
        <Container disableGutters sx={{ backgroundColor: '#f5f5f5' }}>
          {/* marketplace */}
          <SwiperList
            navigation
            modules={[Navigation]}
            slidesPerView={mobile ? 1.6 : 3}
            centeredSlides={true}
            initialSlide={mobile ? 0 : 1}
            onInit={setSwiper}
            spaceBetween={mobile && swiper?.slides[0] ? (windowSize - swiper?.slides[0]?.clientWidth * 1.2) / 2 : 80}
            style={{ padding: mobile ? '20px 0px 10px 0px' : '20px 80px 10px 80px' }}
            onResize={() => setWindowSize(window.innerWidth)}>
            {
              nfts.map(nft =>
                <SwiperSlide key={nft.id} style={{ height: 'auto' }}>
                  <NftCard nft={nft}
                    sx={{ backgroundColor: 'inherit' }}
                    contentSx={{ p: 1 }}
                    mediaSx={{ height: mobile ? '50vh' : 'inherit' }}
                    hidePrice
                    hideButton
                  />
                </SwiperSlide>)
            }
          </SwiperList>
          {
            mobile &&
            <Box px={'0px'}>
              <Divider />
              <SwiperList
                pagination
                modules={[SwiperGrid]}
                slidesPerView={4}
                slidesPerGroup={4}
                grid={{ rows: 2, fill: 'column' }}
                spaceBetween={10}
                style={{ padding: '10px 20px 36px 20px', height: 'calc(48vw + 56px)' }}>
                {nfts.map((nft: any, index: number) => (
                  <SwiperSlide key={nft.id} style={{ height: '24vw' }} >
                    <Button
                      sx={{ p: 0, borderRadius: 0, height: '100%', width: '100%', overflow: 'hidden' }}
                      onClick={() => {
                        if (swiper)
                          swiper.slideTo(index)
                      }}>
                      <img
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                        src={nft.meta.image}
                      />
                    </Button>
                  </SwiperSlide>
                ))}
              </SwiperList>
            </Box>
          }
          <NavButton icon="navigate" href="/market" sx={{ color: 'black' }}>COLLECTION</NavButton>

        </Container>

        <Container disableGutters>

          {/* Viewpoints */}
          <Grid
            item
            xs={12}
            sx={{
              py: 5,
              pb: 15,
              px: mobile ? 5 : 15,
              background: 'linear-gradient(to bottom, #c4c4c4, #ececec 11%, #ececec 90%, #c4c4c4)'
            }}>
            <Typography color="black" variant="h2" mb={10} align="center">
              <StyledTitle>
                VIEWPOINT
              </StyledTitle>
            </Typography>
            {
              data.viewpoints.map((dt: any, index: number) => (
                <Fragment key={dt.author}>
                  <Typography color="black" variant="h3" mb={3} fontWeight='bold'>
                    <Box sx={{
                      width: '22px',
                      height: '5px',
                      margin: '0 0 5px 0',
                      backgroundColor: '#fff',
                    }} />
                    {dt.author}
                  </Typography>
                  <Typography color="black" variant="body1" mb={5}>
                    {dt.intro}
                    <Link color="#555555" href={`/TeppeiKaneuji/viewpoint/${index}`}>{strings('MORE')}</Link>
                  </Typography>
                </Fragment>
              ))
            }

          </Grid>

        </Container>
      </Box>
    </PageBaseOfCampaign >
  );
}