import {
  Container, Grid, Box,
  Typography,
  useTheme, useMediaQuery,
} from "@mui/material";
import { useContext, useMemo } from "react";
import { PageBaseOfCampaign } from "../../../campaign-20220316/components/page-base-campaign";
import { UiContext } from "../../../context/app";

export const PageNewsletter = () => {
  const { strings, mobile } = useContext(UiContext); // don't remove this


  return (
    <PageBaseOfCampaign sx={{
      background: 'url(/assets/images/about-bg.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <Container disableGutters sx={{ overflow: 'hidden' }}>

        <Grid container p={0} m={0}>

          <Grid item xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              height: mobile ? 'calc(100vh - 180px)' : 'calc(100vh - 128px)',
              justifyContent: 'center',
            }}>
            <Box mx={mobile ? 5 : 24} sx={{
              backgroundColor: 'rgba(255,255,255,0.9)',
            }}>
              <Typography variant="h1" mt={5.5} mx={mobile ? 5 : 26} align="center" mb={3.25} sx={{
                color: '#333333',
                fontSize: '42px'
              }}>
                NEWSLETTER
              </Typography>
              <Typography variant="body1" align='center' ml={mobile ? 4.25 : 11.25} mr={mobile ? 5 : 11.25} mb={7.5} sx={{
                color: '#000000',
                fontSize: '15px'
              }}>
                COMMING SOON
              </Typography>
            </Box>


          </Grid>
        </Grid>
      </Container>
    </PageBaseOfCampaign>
  )
}