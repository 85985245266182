import { useEffect, useState, useCallback } from 'react';
import { Box, Stack, Typography, Container, TextField, Grid, Popper, InputAdornment, IconButton } from '@mui/material';
import { ContentCopy, Web } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IUser } from '../../models';
import { useAppContext } from '../../components/hooks';
import { useNavigate } from 'react-router-dom';
import { PageBase } from '../../components/page-base';
import { delImage, uploadImage, userInfo, userProfileEdit } from '../../services/market';
import { ImageDropzone } from '../../components/media-drop-zone';
import { strings } from '../../strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Cookies from 'universal-cookie';

const Row = ({ children, sx }: any) => (<Box sx={{ display: 'flex', ...sx }} >{children}</Box>)

export const PageSetting = () => {
  const navigate = useNavigate();
  const context = useAppContext();
  const { wallet } = context;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [tempProfileImg, setTempProfileImg] = useState<File | string>("");
  const [tempBannerImg, setTempBannerImg] = useState<File | string>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userProfile, setUserProfile] = useState<IUser>({ 
    name: "",
    description: "",
    email: "",
    communities: {}
  });
  const isCopied = Boolean(anchorEl);

  const updateProfile = (changes:any) => 
    setUserProfile(profile => ({...profile, ...changes}));

  const updateCommunity = (changes:any) =>
    setUserProfile(profile => ({
      ...profile, communities: { 
        ...profile.communities, 
        ...changes
      }}));

  
  
  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      let imgUrl = tempProfileImg as string;
      let bannerImgUrl = tempBannerImg as string;

      if (tempProfileImg !== userProfile.image) {
        imgUrl = tempProfileImg === '' ? '' : await uploadImage(context, tempProfileImg as File);
        userProfile.image !== '' && await delImage(context, userProfile.image as string);
      }
      if (tempBannerImg !== userProfile.bannerImage) {
        bannerImgUrl = tempBannerImg === '' ? '' : await uploadImage(context, tempBannerImg as File);
        userProfile.bannerImage !== '' && await delImage(context, userProfile.bannerImage as string);
      }
      await userProfileEdit(context, { 
        ...userProfile, 
        address: wallet.account || undefined,
        image: imgUrl, 
        bannerImage: bannerImgUrl 
      });
      reload();
      alert('Profile Saved')
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }, [wallet, userProfile, tempProfileImg, tempBannerImg]);

  const reload = useCallback(async () => {
    try {
      setLoading(true)
      setError(null);
      const profile = await userInfo(context);
      if (profile) {
        setUserProfile(profile)
        !!profile.image && setTempProfileImg(profile.image);
        !!profile.bannerImage && setTempBannerImg(profile.bannerImage);
      }
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [context]);

  useEffect(() => {
    reload()
  }, [wallet]);

  useEffect(() => {
    const account = new Cookies().get('account');
    if (!account)
      navigate('/connect-wallet');

    if (wallet && wallet.isConnected() && wallet.account !== account)
      navigate('/connect-wallet');
  }, [wallet]);

  return (
    <PageBase coverLoading loading={loading} error={error} onReload={reload}>
      <Container sx={{ my: 5, height: '100%' }}>
        <Typography variant='h3' paragraph>{strings('PROFILE_SETTINGS')}</Typography>
        <Grid container rowGap={2} columnSpacing={5} >
          <Grid item sm={12} md={6}>
            <Row >
              <Typography variant='caption' sx={{ fontWeight: 'bold', color: 'red' }}>* &nbsp;</Typography>
              <Typography variant='caption' sx={{ fontWeight: 'bold' }} paragraph >required fields</Typography>
            </Row>

            <Stack spacing={2} direction="column">
            
            <TextField
              required
              label={strings('USERNAME')}
              value={userProfile.name}
              placeholder={strings('USERNAME_PLACEHOLDER')}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) => updateProfile({name: event.target.value})}
            />

            <TextField
              label={strings('BIO')}
              value={userProfile.description}
              placeholder={strings('BIO_PLACEHOLDER')}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) => updateProfile({description: event.target.value})}
            />

            <TextField
              label={strings('EMAIL')}
              value={userProfile.email ? userProfile?.email : ''}
              placeholder={strings('EMAIL_PLACEHOLDER')}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) => updateProfile({email: event.target.value})}
            />

            <TextField
              label={strings('LINKS')}
              value={userProfile.communities ? userProfile.communities['TWITTER'] : ''}
              placeholder={strings('LINKS_TWITTER')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <FontAwesomeIcon icon={faTwitter} size='lg' />
                </InputAdornment>,
              }}
              fullWidth
              onChange={(event) => updateCommunity({"TWITTER": event.target.value})}
            />

            <TextField
              value={userProfile.communities ? userProfile.communities['INSTAGRAM'] : ''}
              placeholder={strings('LINKS_INSTAGRAM')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <FontAwesomeIcon icon={faInstagram} size='lg' />
                </InputAdornment>,
              }}
              fullWidth
              onChange={(event) => updateCommunity({"INSTAGRAM": event.target.value})}
            />

            <TextField
              value={userProfile.communities ? userProfile.communities['WEBSITE'] : ''}
              placeholder={strings('LINKS_WEBSITE')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <Web />
                </InputAdornment>,
              }}
              fullWidth
              onChange={(event) => updateCommunity({"WEBSITE": event.target.value})}
            />

            <TextField
              label={strings('WALLET_ADDRESS')}
              value={wallet.account || ''}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={(event: any) => {
                      if (userProfile.address) {
                        navigator.clipboard.writeText(userProfile.address)
                        setAnchorEl(event?.currentTarget)
                      };
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>,
              }}
              onMouseLeave={() => setAnchorEl(null)}
              fullWidth />

            </Stack>

          </Grid>
          <Grid item sm={12} md={6} >
            <Typography variant='body1' fontWeight='bold' >{strings('PROFILE_IMAGE')}</Typography>
            <Box sx={{ height: '200px', width: '200px', m: 2 }}>
              <ImageDropzone
                sx={{ height: '200px', width: '200px', backgroundColor: '#bdbdbd', borderRadius: '50%', }}
                imgStyle={{ height: '100%', width: '100%', borderRadius: '50%' }}
                acceptType="image/*"
                src={tempProfileImg}
                onDrop={(files: any[]) => setTempProfileImg(files[0])}
                onClear={() => setTempProfileImg('')}
              />
            </Box>

            <Typography variant='body1' fontWeight='bold' >{strings('PROFILE_BANNER')}</Typography>
            <ImageDropzone
              sx={{ height: '220px', width: '100%', m: 2, backgroundColor: '#bdbdbd', borderRadius: 5 }}
              imgStyle={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5, }}
              acceptType="image/*"
              src={tempBannerImg}
              onDrop={(files: any[]) => setTempBannerImg(files[0])}
              onClear={() => setTempBannerImg('')}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <LoadingButton variant='contained' fullWidth={false} onClick={onSave} loading={loading}>{strings('SAVE')}</LoadingButton>
          </Grid>
        </Grid>

        <Popper open={isCopied} anchorEl={anchorEl} placement={'top'}  >
          <Box sx={{ p: 1, mb: 0.5, borderRadius: 3, color: 'black', backgroundColor: 'white' }}>
            Copied!
          </Box>
        </Popper>
      </Container>
    </PageBase >
  )
}