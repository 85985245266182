import { Avatar, Box, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NamedUser } from "../../../components/named-user";
import { $summary } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftCollection, Summaries } from "../../../models";

export default ({ collection }: { collection: INftCollection }) => {
  const { strings } = useContext(UiContext);
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea href={`/collection/${collection.chain}/${collection.address}`}>
        <CardMedia
          sx={{ objectFit: 'cover', height: 200, width: '100%' }}
          component="img"
          image={collection.bannerImage}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {collection.meta && collection.meta.image && <Avatar alt="logo" src={collection.meta.image} />}
          <Typography variant="h5">{collection.meta?.name}</Typography>
          <Box display='flex'>
            <Typography>
              {strings("MY_COLLECTIONS_BY")}&ensp;
            </Typography>
            <Typography onClick={(e) => {
              e.preventDefault();
              navigate(`/profile/${collection.owner}`);
            }}>
              <NamedUser account={collection.owner} />
            </Typography>
          </Box>
          <Typography sx={{
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {strings("MY_COLLECTIONS_DESCRIPTION").replace("[name]", collection.meta?.name || '')}
          </Typography>
          <Typography>{strings("MY_COLLECTIONS_TOTAL_SUPPLY").replace("[total]", $summary(collection, Summaries.TOTAL_SUPPLY))}</Typography>
        </CardContent>
      </CardActionArea>
    </Card >
  )
}