import { useState, useEffect } from 'react';
import { userInfo } from '../../services/market';
import { useAppContext } from "../hooks";
import { $addr } from '../utils';

type NamedUserProps = {
  account?:string
}

export const NamedUser = (props:NamedUserProps) => {
  const context = useAppContext();
  const { account } = props;
  const [ name, setName] = useState<string | undefined>();

  let displayName = account;

  if(account && account.startsWith('0x'))
    displayName = $addr(account);

  if(name)
    displayName = name;

  useEffect(() => { 
    if(!account?.startsWith('0x')) return;
    userInfo(context, props.account)
      .then(user => setName(user?.name))
      .catch(console.error);
  }, [account]);

  return <>{displayName}</>;
}