import { createView } from "../../views";

const { default: NftDetailView } = createView('nft-detail-view');

export const NftDetailCard = ({ nft }: any) => {

  return (
    <NftDetailView nft={nft} />
  )
}
