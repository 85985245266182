// SPEC: Data Identity
// - 一個 Collection 只能對應一個合約
// - 一個 Collection 只能在一個鏈上
// - Collection ID = hash( chain + contractAddress )
// - NFT ID = hash( chain + contractAddress + tokenId )
//   ex. hash("ETHEREUM/")
// SPEC: Data Behaviors
// - 所有鏈上的資料都要先設法複製到中心化的資料庫內
// - 可能變更的資料優先嘗試取得鏈上正確資訊，在整合中心化資訊，合併後再提供給前端顯示
//   ex. NFT 擁有者，每次讀取時，先嘗試從鏈上取得真實擁有者地址，再合併在 NFT 資料一起出去
//   ex. NFT meta，每次讀取時，先嘗試透過 tokenURI 取得最新 meta，再合併在 NFT 資料一起出去

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process?.env?.REACT_APP_FB_API_KEY,
  authDomain: process?.env?.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process?.env?.REACT_APP_FB_PROJECT_ID,
  storageBucket: process?.env?.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process?.env?.REACT_APP_FB_MESSAGE_SENDER_ID,
  appId: process?.env?.REACT_APP_FB_APP_ID,
  measurementId: process?.env?.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
