
import { useEffect, useState } from "react";
import { useAppContext } from "../../components/hooks";
import { collectionId, collectionInfo } from "../../services/market";
import { INftCollection } from "../../models";
import { createView } from "../../views";

const { default: CollectionInfoView } = createView('collection-info-view');

export const CollectionInfoCard = ({ chainId, contract }: any) => {
  const context = useAppContext();
  const [collection, setCollection] = useState<INftCollection | null>(null);

  useEffect(() => {
    collectionInfo(context, collectionId(chainId, contract))
      .then(setCollection)
      .catch((err: any) => console.error(err.message));
  }, []);

  return (
    <CollectionInfoView collection={collection} />
  )
}


