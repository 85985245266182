import { Box } from "@mui/material";

export const Row = (props: any) =>
  <Box {...props} sx={{
    display: 'flex',
    flexDirection: 'row',
    ...props.sx,
  }}>
    {props.children}
  </Box>;

export const Column = (props: any) =>
  <Box {...props} sx={{
    display: 'flex',
    flexDirection: 'column',
    ...props.sx,
  }}>
    {props.children}
  </Box>;