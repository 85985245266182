import { Fragment, ReactNode, useContext, useEffect, useState } from "react";
import { useAppContext } from "../../components/hooks";
import {
  Box, Stack,
  Card, CardActions, CardContent, CircularProgress,
  Button, ButtonProps,
  Typography,
  Alert, Divider, Checkbox, FormControlLabel, Link, IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CheckCircle, Facebook, Twitter, Telegram, AccountBalanceWalletOutlined } from "@mui/icons-material";
import LinkIcon from '@mui/icons-material/Link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"
import { IFees, INftToken, Summaries } from "../../models";
import { MarketDialog, MarketDialogContent, MarketDialogActions } from "../../components/dialog-market";
import { convertEth, purchase, serviceFees } from "../../services/market";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { $addr, $ipfs, $percentage, $price } from "../../components/utils";
import { UiContext } from "../../context/app";

// TODO: should get it from env
const ETHER_SCAN = "https://rinkeby.etherscan.io/tx/";

const TotalPrice = ({ price }: any) => <Box>
  <Stack direction='row' alignItems="center" justifyContent={"space-between"} sx={{ minWidth: 25 }} spacing={1}>
    <FontAwesomeIcon icon={faEthereum} />
    <Typography>{$price(price)}</Typography>
  </Stack>
  <Stack direction='row' alignItems="center" justifyContent={"space-between"} sx={{ minWidth: 25 }} spacing={1}>
    <FontAwesomeIcon icon={faDollarSign} />
    <Typography>{convertEth(price)}</Typography>
  </Stack>
</Box>

const PurchaseCommunities = (nft: INftToken) =>
  <Fragment>
    <IconButton><Twitter /></IconButton>
    <IconButton><Facebook /></IconButton>
    <IconButton><Telegram /></IconButton>
    <IconButton onClick={() => navigator.clipboard.writeText(`${window.location}/nft/${nft.chain}/${nft?.contract}/${nft?.tokenId}`)}><LinkIcon /></IconButton>
  </Fragment>

interface BuyButtonProps {
  nft?: INftToken,
  buttonProps?: ButtonProps,
  children?: ReactNode,
}

export const BuyButton = (props: BuyButtonProps) => {
  const [isBuying, setIsBuying] = useState(false);
  const [txId, setTxId] = useState('');
  const [fees, setFees] = useState<IFees>()

  const navigate = useNavigate();
  const context = useAppContext();
  const { wallet } = context;
  const { strings } = useContext(UiContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [purchaseOptions, setPurchaseOptions] = useState({
    nftDocId: props.nft?.id as string,
    bookDocId: props.nft?.saleOptions?.id as string
  })

  const nft = props.nft ? props.nft : {};
  const price = nft.saleOptions?.price;

  const buyNow = async () => {
    try {
      setLoading(true);
      setError(null);

      await purchase(context, purchaseOptions);

      // todo: 處理 txId 不會立刻拿到的問題

      setIsBuying(false);
    } catch (error: any) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const getFees = async () => {
    if (!isBuying) return;
    try {
      setLoading(true);
      setError(null);
      setFees(await serviceFees(context, { contract: nft.contract as string, tokenId: nft.tokenId as string }))
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => { getFees() }, [isBuying]);
  useEffect(() => {
    if (isBuying) {
      const account = new Cookies().get('account');
      if (!account && !wallet.isConnected())
        navigate('/connect-wallet');

      if (wallet && wallet.isConnected() && wallet.account !== account)
        navigate('/connect-wallet');
    }
  }, [wallet, isBuying])

  return (
    <Box>
      <Button
        {...props.buttonProps}
        startIcon={props.buttonProps?.startIcon || <AccountBalanceWalletOutlined />}
        variant={props.buttonProps?.variant || 'contained'}
        onClick={() => setIsBuying(true)}
      >
        {props.children}
      </Button>

      <MarketDialog
        fullWidth
        open={isBuying}
        onClose={() => {
          setIsBuying(false);
          setError(null)
        }}
        title={strings("BUY_NOW_TITLE")}>
        <MarketDialogContent>
          <Stack direction="row" justifyContent={'space-between'} >
            <Typography>{strings("BUY_NOW_ITEM_TITLE")}</Typography>
            <Typography>{strings("BUY_NOW_SUBTOTAL")}</Typography>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center" spacing={1} sx={{ py: 2 }}>
            <img
              alt="nft"
              src={$ipfs(nft.meta?.image)}
              style={{
                border: "1px solid #eee",
                borderRadius: 4,
                width: 100,
                aspectRatio: "1",
              }} />
            {/* TODO: 1. display when collection has royalties */}
            <Box sx={{ flex: 1 }}>
              <Link
                underline="none"
                href={`/collection/${nft.chain}/${nft.contract}`}>
                {nft.summaries && nft.summaries[Summaries.COLLECTION_NAME]}
              </Link>
              <Typography>{nft.meta?.name}</Typography>
              <Typography variant="subtitle2">
                {strings("BUY_NOW_CREATOR_FEE")}&nbsp;{$percentage(fees?.creatorRoality)}
              </Typography>
            </Box>
            <TotalPrice strings={strings} price={price || 0} />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 1 }}>
            <Typography gutterBottom>{strings("BUY_NOW_TOTAL")}</Typography>
            <TotalPrice strings={strings} price={price || 0} />
          </Stack>
          <Divider />
          {/* TODO: Service's path */}
          <FormControlLabel
            label={<Typography>
              {strings("REMINDER")}
              <Link
                underline="none"
                href="/terms"
                target="_blank">
                {strings("REMINDER_SERVICE")}
              </Link>
            </Typography>
            }
            control={
              <Checkbox
                checked={acceptedPolicy}
                onChange={(e) => setAcceptedPolicy(e.target.checked)} />
            } />
          {
            error &&
            <Alert sx={{ overflowWrap: 'anywhere' }} severity='error'>{error.message}</Alert>
          }
        </MarketDialogContent>
        <MarketDialogActions>
          <LoadingButton
            variant='contained'
            disabled={!wallet.isConnected() || !acceptedPolicy}
            loading={loading}
            onClick={buyNow}>
            {strings("BUY_NOW_SUBMIT_BUTTON")}
          </LoadingButton>
        </MarketDialogActions>
      </MarketDialog>

      <MarketDialog
        fullWidth
        open={!!txId}
        onClose={() => setTxId("")}
        title={strings("PURCHASE_PROCESS_TITLE")}>
        <MarketDialogContent>
          <Typography sx={{ textAlign: 'center' }}>
            {strings("PURCHASE_PROCESS_SUBTITLE").replace("[nft]", nft.meta?.name as string)}
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <img alt="nft" src={$ipfs(nft.meta?.image)} width='300' />
          </Box>
          <Card variant="outlined">
            <CardContent sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1 }}>{strings("PURCHASE_PROCESS_STATUS")}</Typography>
              <Typography sx={{ flex: 1 }}>{strings("PURCHASE_PROCESS_TRANSACTION_HASH")}</Typography>
            </CardContent>

            <Divider />

            <Box sx={{ display: 'flex' }}>
              <CardContent sx={{ display: 'flex', flex: 1 }}>
                {
                  loading ?
                    <>
                      <CircularProgress />
                      <Typography>{strings("PURCHASE_PROCESS_PROCESSING")}</Typography>
                    </> :
                    <>
                      <CheckCircle />
                      <Typography>{strings("PURCHASE_PROCESS_COMPLETE")}</Typography>
                    </>
                }
              </CardContent>

              <CardActions sx={{ flex: 1 }}>
                <Link href={ETHER_SCAN + txId}>{$addr(txId)}</Link>
              </CardActions>
            </Box>
          </Card>
        </MarketDialogContent>
      </MarketDialog>
    </Box>
  )
}

