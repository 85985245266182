import { useEffect, useMemo } from 'react';
import { useWallet } from 'use-wallet';
import Cookies from 'universal-cookie';
import Web3 from 'web3';

// const COINS: { [key: string]: string } = {
//   '1': '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
//   '4': '0xc778417E063141139Fce010982780140Aa0cD5Ab',
//   '1337': (require('../../contracts/MainCoin.json')).networks[1337].address,
// }

const DEFAULT_CHAIN_ID = process?.env?.REACT_APP_DEFAULT_CHAIN_ID as string | undefined;

export const useWeb3 = () => {
  const web3 = useMemo(() => new Web3(Web3.givenProvider), []);
  return web3;
}

export const useContract = (artifacts: any, address?: string) => {
  const wallet = useWallet();
  const web3 = useWeb3();
  const contract = useMemo(() => {
    if (!web3) return null;

    let addr = address;

    if (!addr) {
      let networkId = wallet.isConnected() ?
        wallet.chainId as number
        : parseInt(process?.env?.REACT_APP_DEFAULT_CHAIN_ID as string);

      if (!artifacts.networks[networkId]) return null;

      addr = artifacts.networks[networkId].address;
    }

    if (!addr) return null;

    let abi = artifacts.abi;
    let contract = new web3.eth.Contract(abi, addr);

    return contract;
  }, [wallet, artifacts, web3]);
  return contract;
}

export const useNftBase = (address: string) =>
  useContract(require('../../contracts/NftBase.json'), address);

export const useAppContext = () => {
  const wallet = useWallet();
  const web3 = useWeb3();
  const market = useContract(require('../../contracts/NftMarket.json'));
  const resaller = useContract(require('../../contracts/NftMarketResaller.json'));
  const trader = useContract(require('../../contracts/NftTrade.json'));

  //!!! Dangerous !!!
  if(!!DEFAULT_CHAIN_ID && wallet.status !== 'connected') {
    wallet.chainId = parseInt(DEFAULT_CHAIN_ID);
  }

  useEffect(() => {
    if (wallet.isConnected())
      new Cookies().set('account', wallet.account, { path: '/' });
  }, [wallet]);

  return {
    wallet,
    web3,
    market,
    resaller,
    trader,
  };
}
