import { Card, CardMedia } from "@mui/material"
import { $ipfs } from "../../../components/utils"
import { INftToken } from "../../../models"

export default ({ nft }: { nft: INftToken }) => {
  return (
    <Card variant='outlined' sx={{ flexGrow: 1, borderRadius: '8px', boxShadow: 0, border: 0 }}>
      <CardMedia
        component={nft.meta?.animation_url ? "video" : "img"}
        image={$ipfs(nft.meta?.animation_url) || $ipfs(nft.meta?.image) || ''}
        poster={$ipfs(nft.meta?.image) || ''}
        controls={!!nft.meta?.animation_url}
        sx={{ objectFit: 'contain', maxHeight: '641px' }} />
    </Card>
  )
}