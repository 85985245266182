import { createView } from "../../views";

const { default: NftAuctionView } = createView('nft-auction-view');

export const NftAuctionCard = ({ nft }: any) => {

  return (
    <NftAuctionView nft={nft} />
  )
}
