import { useContext } from "react";
import { Box, Stack, Typography, Link, Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import BN from 'bn.js'
import { useAppContext } from "../../../components/hooks";
import { $summary, $price, $addr } from "../../../components/utils";
import { IBidConfigs, Summaries, SellMethods, INftToken } from "../../../models";
import { convertEth } from "../../../services/market";
import { DataGridHeader } from "../styled-components";
import { UiContext } from "../../../context/app";

const ZERO = new BN('0')
const HUNDRED = new BN('100');

export default ({ nft, offers }: { nft: INftToken, offers: IBidConfigs[] }) => {
  const context = useAppContext();
  const { web3 } = context;
  const { strings } = useContext(UiContext);


  const floorPrice = $summary(nft, Summaries.FLOOR_PRICE);

  const offersColumns: GridColDef[] = [
    {
      field: 'price',
      headerName: strings('DATAGRID_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 70,
      renderHeader: DataGridHeader,
      renderCell: (params) =>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEthereum} />
          <Typography>{$price(params.value)} ETH</Typography>
        </Stack>,
    },
    {
      field: 'USDPrice',
      headerName: strings('DATAGRID_USDPRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderHeader: DataGridHeader,
      valueGetter: (params) => `${convertEth(params.row.price)}`,
      renderCell: (params) =>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <Typography>{`$${params.value}`}</Typography>
        </Stack>
    },
    {
      field: 'floorDifference',
      headerName: strings('DATAGRID_FLOOR_DIFFERENCE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 130,
      renderHeader: DataGridHeader,
      valueGetter: (params) => {
        if (!floorPrice) return '';
        const fp = new BN(floorPrice);
        const p = new BN(params.row.price);
        const diff = p.sub(fp);
        if (diff.gt(ZERO))
          return `${diff.div(fp).mul(HUNDRED).toString()} %`;
        if (diff.lt(ZERO))
          return `${diff.div(fp).mul(HUNDRED).abs().toString()} % below`;
        if (diff.eq(ZERO))
          return '0 %';
        return '';
      },
      renderCell: (params) => <Typography>{params.value}</Typography>
    },
    {
      field: 'expiration',
      headerName: strings('DATAGRID_EXPIRATION'),
      editable: false,
      sortable: false,
      renderHeader: DataGridHeader,
      valueGetter: (params) => {
        if (nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER)
          return moment.unix(nft.saleOptions?.duration.end).fromNow()
        else
          return moment.unix(params.row.duration?.end).fromNow()
      },
      renderCell: (params) => <Typography>{params.value}</Typography>

    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      editable: false,
      sortable: false,
      renderHeader: DataGridHeader,
      valueGetter: (params) => {
        if (nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER)
          return (params.row.bidder)
        else
          return (params.row.from)
      },
      renderCell: (params) =>
        <Link variant='body1' href={`/profile/${params.value}`}>{$addr(params.value)}</Link >
    }
  ];

  return (
    <Box sx={{ flex: 1, flexWrap: 'nowrap', overflowX: 'scroll' }}>
      <DataGrid
        columns={offersColumns}
        rows={offers}
        disableColumnMenu
        hideFooter
        autoHeight
        selectionModel={'none'}
        sx={{ borderWidth: 0 }}
        components={{
          NoRowsOverlay: () =>
            <Alert severity="info">
              {strings('NO_OFFERS')}
            </Alert>,
        }}
      />
    </Box>
  )
}