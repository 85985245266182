import firebase from "firebase/compat";
import { collection, doc, getFirestore, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { NftContractInfo, SubscriptionCallback, UnSubscriber, _getNftCollectionSummary } from ".";
import { ADDRESS_ZERO, BookStates, IContext, INftToken, ISellConfigs, OfferStates, TradingMethods } from "../../../models";
import { asSellConfigs, SaleConfig } from "../../../models/trading";
import { contractAt, web3ReadOnly } from "../crypto";
import { nftId } from "../func-pure";
import { trimSale } from "../../../models/typed";
import { documentsMatched } from "../../db-utils";
import NftTrade from "../../../contracts/NftTrade.json";
import moment from "moment";

export const subscribeNftInfo = (
  context: IContext,
  options: NftContractInfo & SubscriptionCallback & any
): UnSubscriber => {
  const { chain, contract, tokenId, onResult } = options;
  const target = nftId(chain, contract, tokenId);
  const web3 = web3ReadOnly(chain);

  firebase
    .functions()
    .httpsCallable("syncNft")({ chain, contract, tokenId })
    .catch(console.error);

  const db = getFirestore();
  const data: any = {
    colSummaries: null,
    listings: null,
    nft: null,
    sell: null
  };


  const exportToken = (): INftToken => ({
    ...data.nft,
    saleOptions: data.sell,
    allSaleOptions: data.listing,
    summaries: {
      ...data.nft?.summaries,
      ...data.colSummaries
    }
  });

  const fillCollectionInfo = async (token: INftToken) => {
    if (!!data.colSummaries) return;
    data.colSummaries = await _getNftCollectionSummary(token);
  }

  const unsubNft = onSnapshot(
    doc(db, 'nfts', target),
    async (nftDoc) => {
      const token = nftDoc.data() as INftToken;

      if (!token) return;
      data.nft = token;

      if (!data.colSummaries) {
        await fillCollectionInfo(token);
      }

      onResult(exportToken());
    }
  )

  const q = query(
    collection(db, 'listing'),
    where('chain', '==', chain),
    where('nftContract', '==', contract),
    where('tokenId', '==', tokenId),
    where('bookState', '==', BookStates.BOOKED),
    where('expireTime', '>=', moment().unix())
  );

  const unsubSell = onSnapshot(q, async (querySnapshot) => {
    const saleConfigs: any = []
    const saleOptions: ISellConfigs[] = [];
    querySnapshot.forEach((doc) => {
      const saleConfig = { id: doc.id, ...doc.data() as SaleConfig };
      saleConfigs.push(saleConfig);
    });

    const sortByPrice = () => {
      saleOptions.sort((a, b) => {
        const pa = web3.utils.toBN(a.price || web3.utils.toWei('9999'));
        const pb = web3.utils.toBN(b.price || web3.utils.toWei('9999'));
        if (pa.eq(pb)) return 0;
        if (pa.gt(pb)) return 1;
        return -1;
      });

      data.listing = saleOptions;
      if (saleOptions.length > 0)
        data.sell = saleOptions[0];

      if (saleConfigs.length === 0)
        data.sell = undefined;
    }

    Promise.all(
      saleConfigs.map(async (saleConfig: any) => {
        const saleOption = asSellConfigs(saleConfig);
        const sale = trimSale(saleConfig);
        const { currency, nftContract, tokenId, quantity, price, method, seller, beginTime, expireTime } = sale;
        switch (saleConfig.method) {
          case TradingMethods.SELL_WITH_DECLINING_PRICE:
            const buyer = ADDRESS_ZERO;
            const nonce = moment().unix();
            const offer = { currency, nftContract, tokenId, quantity, price, method, seller, buyer, nonce, beginTime, expireTime };
            const trader = contractAt({ chain, artifacts: NftTrade });
            saleOption.price = await trader.methods.priceOf(sale, offer).call();
            break;
          case TradingMethods.SELL_TO_HIGHEST_BIDDER:
            const offers = await documentsMatched('offering', {
              chain, currency, nftContract, tokenId, quantity, method, offerState: OfferStates.OFFERING
            }, {
              queryArgs: [
                where('expireTime', '>', moment().unix()),
                orderBy('expireTime', 'desc'),
                orderBy('price', 'desc'), // todo: need to sort price
                limit(1)
              ]
            });
            let isGtnAuctionLowest = web3.utils.toBN(offers[0].price).gte(web3.utils.toBN(saleConfig.price))
            saleOption.price = offers.length > 0 && isGtnAuctionLowest ? offers[0].price : saleConfig.price;
            break;
          default:
            saleOption.price = saleConfig.price;
            break;
        }
        saleOptions.push(saleOption);
      }))
      .then(sortByPrice)
      .then(() => onResult(exportToken()))

  },
    error => { console.error(error) }
  );
  return {
    unsubscribe: () => {
      unsubNft();
      unsubSell();
      data.nft = null;
      data.sell = null;
      data.listing = null;
    }
  }
}

