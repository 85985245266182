import { $price } from "../../components/utils";

let ratioUSD: number;

(async () => {
  const rsp = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
  const data = await rsp.json();
  ratioUSD = parseFloat(data.USD);
})();


// @todo: rename to ethToUSD
export const convertEth = (eth: string): string => {
  if (!ratioUSD) return 'Loading...';
  return (Math.round(parseFloat($price(eth)) * ratioUSD * 1000) / 1000).toString()
}