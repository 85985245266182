import { useContext, useEffect, useState } from "react";

import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { PageBaseOfCampaign } from "../../components/page-base-campaign";
import { EMPTY_DATA, article } from '../../articles';
import { UiActions, UiContext, UiLangs } from "../../../context/app";
import { StyledTitle } from "../../components/styled-title";

export const PageSpotlight = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState<any>(EMPTY_DATA);
  const { lang } = useContext(UiContext);
  const { strings } = useContext(UiContext); // don't remove this

  useEffect(() => {
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang]);

  return (
    <PageBaseOfCampaign sx={{background:'linear-gradient(to bottom, #1f1f1f, #575757)', color:'white'}}>
      <Container disableGutters>
        <Grid container /*pl={mobile ? 5.25 : 24} pr={mobile ? 5.25 : 22}*/>
          {/* mobile 用的頂部元件, md=none */}
          <Grid item xs={12} mt={6.25} display={{ md: 'none' }}>
            <Typography variant="h1" ml={8}>
              <StyledTitle>
                {data.spotlight.creator}
              </StyledTitle>
            </Typography>
          </Grid>

          {/* 圖片1 */}
          <Grid item xs={12} md={6} mt={mobile ? 4 : 21} mb={mobile ? 4 : 8} pl={mobile ? 0 : 24}>
            <Box sx={{textAlign: mobile ? 'center' : 'right'}}>
              <img style={{ maxWidth: '100%', marginRight: '36px' }} src={mobile ? data.spotlight.imageassets.xs[0] : data.spotlight.imageassets.md[0]} />
            </Box>
          </Grid>

          {/* 人名+文字1 */}
          <Grid item xs={12} md={6} pb={5} mt={mobile ? 0 : 21} mb={mobile ? 3 : 8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} pl={mobile ? 5.25 : 2} pr={mobile ? 5.25 : 21.5}>
            <Box>
              <Typography variant="h1" ml={5.25} mt={5.25} mb={6.75} display={{ xs: 'none', md:'block' }}>
                <StyledTitle>
                  {data.spotlight.creator}
                </StyledTitle>
              </Typography>
              <Typography variant="body1" sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[0]}
              </Typography>
            </Box>
          </Grid>

          {/* 圖片2, 本區段 mobile only */}
          <Grid item xs={12} display={{md: 'none'}}>
            <Box sx={{textAlign:"center"}}>
              <img style={{ maxWidth: '100%'}} src={data.spotlight.imageassets.xs[1]} />
            </Box>
            <Typography variant="caption" sx={{textAlign:'center', fontSize: '12px', display:'block', lineHeight:"1.5"}} mb={4.75}>
            {data.spotlight.imageassets.xsalt[1]}
            </Typography>
          </Grid>

          {/* 文字2 + quote */}
          <Grid item xs={12} md={6} mb={mobile? 0 : 8} >
            <Box>
              <Typography variant="body1" sx={{lineHeight: '1.67'}} mb={3.75} pl={mobile ? 5.25 : 24} pr={mobile ? 5.25 : 3}>
                {data.spotlight.textassets[1]}
              </Typography>

              <Box sx={{mb:0, backgroundColor:'rgba(0, 0, 0, 0.23)'}} ml={mobile ? 0 : 24} mr={mobile ? 0 : 3} mt={mobile ? 0 : 5.25}>
                <Typography variant="h1" pt={4.5} pl={3.25}>
                “
                </Typography>
                <Typography variant="h3" pl={5} pr={4} sx={{lineHeight: '1.82', fontSize: '22px'}}>
                {data.spotlight.quotetext}
                </Typography>
                <Typography variant="h1" pr={3.75} sx={{textAlign:'right'}}>
                ”
                </Typography>
                <Typography variant="h3" pr={3.75} sx={{textAlign:'right', fontSize: '20px'}} pb={4.5}>
                — {data.spotlight.creator}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* 圖片2, 本區段 desktop only */}
          <Grid item xs={12} md={6} mb={8} display={{xs:'none', md:'block'}} pr={21.5}>
            <Box>
              <img style={{ maxWidth: '100%', marginLeft: '16px'}} src={data.spotlight.imageassets.md[1]} />
            </Box>
            <Typography variant="caption" sx={{textAlign:'right', fontSize: '12px', display:'block', width:'407px'}} mt={1.25} ml={2}>
            {data.spotlight.imageassets.mdalt[1]}
            </Typography>
          </Grid>

          {/* 圖片3, 本區段 mobile only */}
          <Grid item xs={12} display={{md: 'none'}}>
            <Box sx={{textAlign:"center"}}>
              <img style={{ width:'100%'}} src={data.spotlight.imageassets.xs[2]} />
            </Box>
            <Typography variant="caption" sx={{textAlign:'right', fontSize: '10px', display:'block', lineHeight:"1.5"}} pr={5.25}>
            {data.spotlight.imageassets.xsalt[2]}
            </Typography>
          </Grid>

          {/* title + 文字3 */}
          <Grid item xs={12} mt={6.25} mb={mobile ? 2.75 : 3.75}>
            <Typography variant="h1" ml={mobile ? 5.25 : 24}>
            {strings('ARTIST_PROFILE')}
                <Box sx={{
                  display: 'inline-block', 
                  width: '24px',
                  height: '7px',
                  marginLeft: '16px',
                  backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)',
                  marginBottom: '11px'
                }} />

            </Typography>
            <Typography variant="body1" mt={5} pl={mobile ? 5.25 : 24} pr={mobile ? 5.25 : 21.5}>
              {data.spotlight.textassets[2]}
            </Typography>

            {data.spotlight.textassets[3] != "" &&
            <Box display={{xs:'block', md:'none'}} pl={5.25} mt={4} mb={4} pr={5.25}>
              <Typography variant="body1" sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[3]}
              </Typography>
            </Box>
            }

          </Grid>

          {/* 圖片3, 本區段 desktop only */}
          <Grid item xs={12} mb={8.75} display={{xs:'none', md:'block'}} pl={24} pr={21.5}>
            <img style={{ maxWidth: '100%'}} src={data.spotlight.imageassets.md[2]} />
            <Typography variant="caption" sx={{textAlign:'right', fontSize: '12px', display:'block', lineHeight:"1.5"}}>
            {data.spotlight.imageassets.mdalt[2]}
          </Typography>
          </Grid>

          <Grid item xs={6}>
            {data.spotlight.textassets[3] != "" &&
            <Box display={{xs:'none', md:'block'}} pl={24} mb={10}>
              <Typography variant="body1" sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[3]}
              </Typography>
            </Box>
            }

            <Box pr={mobile ? 0 : 4.5} pl={mobile ? 5.25 : 24} sx={{textAlign:"center"}}>
              <img style={{ maxWidth: '100%' }} src={mobile ? data.spotlight.imageassets.xs[3] : data.spotlight.imageassets.md[3]} />
            </Box>
            <Typography variant="caption" sx={{textAlign: mobile ? 'center' : 'right', fontSize: mobile ? '10px' : '12px', display:'block', lineHeight:"2"}} mb={4.75} pr={mobile ? 0 : 4.5} pl={mobile ? 5.25 : 24}>
              {data.spotlight.imageassets.xsalt[3]}
            </Typography>
          </Grid>

          <Grid item xs={6} pr={5.25}>
            {data.spotlight.textassets[4] != '' &&
            <Box display={{xs:'none', md:'block'}} pr={21.5}>
              <Typography variant="body1" ml={5.25} sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[4]}
              </Typography>
              <Typography variant="body1" ml={5.25} mt={4} sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[5]}
              </Typography>
            </Box>
            }
            <Box sx={{textAlign:"center"}} pl={mobile? 0 :4.5} pt={mobile ? 0 : 7} pr={mobile ? 0 : 21.5}>
                <img style={{ maxWidth: '100%' }} src={mobile ? data.spotlight.imageassets.xs[4] : data.spotlight.imageassets.md[4]} />
            </Box>
            <Typography variant="caption" sx={{textAlign:'center', fontSize: mobile ? '10px' : '12px', display:'block', lineHeight:"2"}} mb={4.75} pr={mobile ? 0 : 21.5} pl={mobile ? 0: 4.5}>
              {data.spotlight.imageassets.xsalt[4]}
            </Typography>

            {data.spotlight.textassets[6] != '' &&
            <Box display={{xs:'none', md:'block'}} pr={21.5} mt={7}>
              <Typography variant="body1" ml={2} sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[6]}
              </Typography>
              <Typography variant="body1" ml={2} mt={4} sx={{lineHeight: '1.67'}}>
                {data.spotlight.textassets[7]}
              </Typography>
            </Box>
            }
          </Grid>

          <Grid item xs={12} display={{md:'none'}} mt={0} pl={5.25} pr={5.25}>
            <Box>
              <Typography variant="body1" sx={{lineHeight: '1.67'}}>
                { data.spotlight.textassets[4] == '' ? data.spotlight.textassets[6] : data.spotlight.textassets[4]}
              </Typography>
              <Typography variant="body1" mt={4} sx={{lineHeight: '1.67'}}>
                { data.spotlight.textassets[5] == '' ? data.spotlight.textassets[7] : data.spotlight.textassets[5]}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mt={7} mb={mobile ? 14.5 : 32} pl={mobile ? 2 : 24} pr={mobile ? 2 : 21.5}>
            <Box sx={{textAlign:"center"}}>
            <img src={mobile ? data.spotlight.imageassets.xs[5] : data.spotlight.imageassets.md[5]} style={{ width:'100%'}} />
            </Box>
            <Typography variant="caption" sx={{textAlign: mobile ? 'center' : 'right', fontSize: mobile ? '10px' : '12px', display:'block', lineHeight:"2"}} mb={4.75}>
              {data.spotlight.imageassets.xsalt[5]}
            </Typography>
          </Grid>

        </Grid>
      </Container>
    </PageBaseOfCampaign>
  );
}