import { makeStyles } from "@material-ui/styles";
import { Search } from "@mui/icons-material";
import { Stack, Grid, GridProps, OutlinedInput, InputAdornment, Select, MenuItem, Button, Fab, useTheme } from "@mui/material";
import { useContext } from "react";
import { UiContext } from "../../../context/app";
import { Sorting, IListOptions } from "../../../models";

const strings: { [key: string]: string } = {
  'RECENTLY_LISTED': 'Recently Listed',
  'RECENTLY_CREATED': 'Recently Created',
  'RECENTLY_SOLD': 'Recently Sold',
  'RECENTLY_RECEIVED': 'Recently Received',
  'ENDING_SOON': 'Ending Soon',
  'PRICE_LOW_TO_HIGH': 'Price: Low to High',
  'PRICE_HIGH_TO_LOW': 'Price: High to Low',
  'HIGHEST_LAST_SALE': 'Highest Last Sale',
  // 'MOST_VIEWED': 'Most Viewed',
  // 'MOST_FAVORITED': 'Most Favorited',
  'OLDEST': 'Oldest',
}
let isOpen = false;
const AVAILABLE_SORTINGS = Object.values(Sorting);
const GRIDPROPS = { rowSpacing: '11px', columnSpacing: '9px' };

type ListOptionToolbarProps = {
  sx?: any,
  search?: string,
  sorting?: string,
  onChange?: (changes: IListOptions) => void
  onOpenFilter?: () => void;
}
export default ({ search, sorting, onChange, onOpenFilter }: ListOptionToolbarProps) => {
  const { mobile } = useContext(UiContext);
  const gridFlexDirection = mobile ? 'column-reverse' : 'row';
  const classes = useStyles({ mobile });

  return (
    <Grid
      container
      {...GRIDPROPS}
      sx={{
        flexDirection: gridFlexDirection,
        justifyContent: 'space-between',
      }}>
      <Grid item xs={12} sm={12} md={3} >
        <Select
          className={classes.select}
          defaultValue={sorting}
          value={sorting}
          variant='outlined'
          onOpen={() => isOpen = true}
          onClose={() => isOpen = false}
          IconComponent={() =>
            <img src='/assets/images/bb/filter-more-green.svg'
              style={{ position: 'absolute', right: '8px', transform: isOpen ? 'rotate(180deg)' : 'none', pointerEvents: 'none' }} />
          }
          onChange={(event: any) =>
            !!onChange && onChange({ sorting: event.target.value || sorting })}>
          {
            AVAILABLE_SORTINGS.map((s) =>
              strings[s] && <MenuItem key={s} value={s} >{strings[s]}</MenuItem>
            )
          }
        </Select>
      </Grid>
      <Grid item xs={12} sm={12} md={5.5}>
        <Stack direction='row' spacing={'9px'} alignItems='center'>
          <Button onClick={onOpenFilter} className={classes.filterButton}>
            <img src='/assets/images/bb/btn-filter.svg' height='42px' />
          </Button>
          <OutlinedInput
            className={classes.search}
            value={search}
            onChange={(event: any) =>
              !!onChange && onChange({ search: event?.target?.value || undefined })}
            startAdornment={
              !!mobile &&
              <InputAdornment position="start" >
                <Search className={classes.searchIcon} fontSize='large' />
              </InputAdornment>
            }
            endAdornment={
              !mobile &&
              <InputAdornment position="end">
                <Search className={classes.searchIcon} fontSize='large' />
              </InputAdornment>
            }
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles({
  select: {
    display: 'flex',
    flex: 1,
    maxHeight: '42px',
    fontWeight: 'bold',
    fontSize: '16px',
    '& fieldset': {
      border: '3px solid #c7c7c7',
      borderRadius: '8px'
    },
  },
  filterButton: {
    display: (props: any) => props.mobile ? 'flex' : 'none',
    p: 0,
    minWidth: '42px',
    maxWidth: '42px'
  },
  search: ((props: any) =>
    props.mobile ?
      {
        display: 'flex',
        flex: 1,
        maxHeight: '42px',

        '& fieldset': {
          border: '3px solid #c7c7c7',
          borderRadius: '8px'
        }
      }
      :
      {
        display: 'flex',
        flex: 1,
        maxHeight: '42px',
        backgroundColor: '#252525',
        '& fieldset': {
          border: '3px solid #5c5c5c',
          borderRadius: '8px',
        }
      }
  ),
  searchIcon: {
    color: (props: any) => props.mobile ? '#fff' : "#00fff0",
  },
})