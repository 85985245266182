import exp from "constants";
import moment from "moment";
import Web3 from 'web3';
import { INftCollection, INftToken, SellMethods, Summaries, UserPower } from "../../models";

export const $addr = (address: string = '') =>
  address ? `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}` : '';

export const $price = (price: string, rounding?: number) => {
  if (!price) return '';
  if (!rounding) return Web3.utils.fromWei(price);
  return (Math.round(parseFloat(Web3.utils.fromWei(price)) * Math.pow(10, rounding)) / Math.pow(10, rounding)).toString();
}

export const $percentage = (number?: number) => {
  return new Intl.NumberFormat(
    "en-US",
    {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }
  ).format(number || 0);
}

export const $val = (obj: any, key: string, defaultValue: any = undefined) =>
  key in obj ? obj[key] : defaultValue;

export const $summary = (obj: any, key: string, defaultValue: any = undefined) =>
  !!obj.summaries ? $val(obj.summaries, key, defaultValue) : defaultValue;

export const $ipfs = (url?: string) =>
  !!url ? url.replace('ipfs://', 'https://ipfs.io/ipfs/') : undefined;

export const useStrings = (mapping: any) => {
  return (key: string) => {
    if (!(key in mapping)) return key;
    return mapping[key];
  }
}

export const genStrings = (mapping: any) => {
  return (key: string) => {
    if (!(key in mapping)) return key;
    return mapping[key];
  }
}

export const sellButtonVisible = (nft: INftToken, account: string | null | undefined, btnType: string = "BUY") => {
  if (nft.owner === account) return false;

  switch (btnType) {
    case "OFFER":
      return !nft.saleOptions ||
        nft.saleOptions.method === SellMethods.NOT_FOR_SELL;
    case "BID":
      return nft.saleOptions &&
        nft.saleOptions.method === SellMethods.SELL_TO_HIGHEST_BIDDER &&
        moment()
          .isBetween(moment.unix(nft.saleOptions.duration.begin),
            moment.unix(nft.saleOptions.duration.end))
    case "BUY":
      return nft.saleOptions &&
        (nft.saleOptions.method === SellMethods.FIXED_PRICE ||
          nft.saleOptions.method === SellMethods.SELL_WITH_DECLINING_PRICE) &&
        moment()
          .isBetween(moment.unix(nft.saleOptions.duration.begin),
            moment.unix(nft.saleOptions.duration.end));
  }
}

export const saleButtonType =
  (
    account: string | null | undefined, btnType: UserPower,
    options: {
      nft?: INftToken,
      collection?: INftCollection,
      expectMaxSupply?: number, availableAmount?: number, onSaleTokens?: number
    }
  ) => {
    const { nft, expectMaxSupply, availableAmount, onSaleTokens, collection } = options;

    switch (btnType) {
      case UserPower.NFT_CAN_SELL:
        if (account && $summary(nft, Summaries.COLLECTION_OWNER) === account)
          return (
            expectMaxSupply !== undefined &&
            availableAmount !== undefined &&
            (expectMaxSupply > $summary(nft, Summaries.TOTAL_SUPPLY, 0) || availableAmount > 0)
          );
        return (
          nft &&
          account &&
          (nft?.owners?.includes(account) || nft?.owner === account) &&
          availableAmount !== undefined &&
          availableAmount > 0
        );
      case UserPower.NFT_CANCEL_SELL:
        return (
          !!(
            nft &&
            account &&
            (nft.owners?.includes(account) || nft.owner === account) &&
            onSaleTokens !== undefined &&
            onSaleTokens > 0)
        );
      case UserPower.COLLECTION_ADD_ITEM:
        return (
          !!(collection && account && collection.owner === account)
        );
    }
  }