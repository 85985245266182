import { useContext, useState, useEffect } from "react";
import {
  Box, Stack, Card,
  Select, MenuItem,
  Button, Link, Typography,
  Alert
} from "@mui/material";
import { ChildFriendly, SwapHoriz, PanTool, ShoppingCart, LocalOffer, NewReleases, Close } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useAppContext } from "../../../components/hooks";
import { NamedUser } from "../../../components/named-user";
import { $price } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { ActivityType, INftToken, INftTokenActivities, IPaged } from "../../../models";
import { DataGridHeader } from "../styled-components";

const eventIconMapping: { [key: string]: JSX.Element } = {
  'MINTED': <ChildFriendly />,
  'TRANSFER': <SwapHoriz />,
  'Bid': <PanTool />,
  'DEAL': <ShoppingCart />,
  'BOOKED': <LocalOffer />,
  'Cancel': <NewReleases />,
};

interface NftActivityViewProps {
  nft: INftToken,
  activies: IPaged<INftTokenActivities>,
  reloadActivities: (types?: string[]) => void,
  loading?: boolean
  error?: any | null;
}

export default ({ nft, activies, reloadActivities, loading, error }: NftActivityViewProps) => {
  const context = useAppContext();
  const { strings } = useContext(UiContext);
  const { wallet } = context;
  const [selected, setSelected] = useState<string[]>([]);

  const activitiesSelects = [
    { name: 'LISTINGS', val: ActivityType.Booked },
    { name: 'SALES', val: ActivityType.Deal },
    { name: 'TRANSFERS', val: ActivityType.Transfer }
  ];

  const ActivityColumns: GridColDef[] = [
    {
      field: 'type',
      headerName: strings('DATAGRID_EVENT'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
      renderCell: (params) =>
        <Stack direction='row' spacing={1} >
          {eventIconMapping[params.value]}
          <Typography>{strings(params.value)}</Typography>
        </Stack>
    },
    {
      field: 'price',
      headerName: strings('DATAGRID_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
      renderCell: (params) => params.value ?
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEthereum} />
          <Typography >{$price(params.value)}</Typography>
        </Stack>
        :
        <></>

    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
      renderCell: (params) =>
        <Link href={`/profile/${params.value}`}>
          {params.value === wallet.account ? 'you' : <NamedUser account={params.value} />}
        </Link>
    },
    {
      field: 'to',
      headerName: strings('DATAGRID_TO'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
      renderCell: (params) =>
        <Link href={`/profile/${params.value}`}>
          {params.value === wallet.account ? 'you' : <NamedUser account={params.value} />}
        </Link>
    },
    {
      field: 'timestamp',
      headerName: strings('DATAGRID_DATE'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
      renderCell: (params) => moment.unix(params.value).fromNow()
    }
  ]


  const handleSelectChange = (value: string[]) => {
    let arr = [...selected, ...value]
    setSelected(value)
  }
  const handleDelete = (chipToDelete: any) => () => {
    const types = selected.filter((chip: any) => chip !== chipToDelete)
    setSelected(types);
    reloadActivities(types);
  };

  const errorHint = (
    error ?
      <Alert severity="error">{error.message}</Alert>
      :
      <Alert severity="info">No Activitiy found for this token</Alert >
  )

  useEffect(() => { if (Object.values(nft).length !== 0) reloadActivities() }, [nft]);


  return (
    <Card variant='outlined' sx={{ flexGrow: 1, border: 0, boxShadow: 0 }} key={"activity"}>
      <Select
        fullWidth
        multiple
        displayEmpty
        defaultValue={selected}
        value={selected}
        renderValue={() => <Typography>Filter</Typography>}
        onChange={(evt) => handleSelectChange(evt.target.value as string[])}
        onClose={() => reloadActivities(selected)} >
        {
          activitiesSelects.map((select) => (
            <MenuItem
              key={select.val}
              value={select.val}>
              {strings(select.name)}
            </MenuItem>
          ))
        }
      </Select>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, m: 1 }}>
        {
          selected.map((select: string) => (
            <Button
              key={select}
              sx={{ background: 'linear-gradient(to bottom, #737373, #575757 42%, #000)' }}
              variant='contained'
              onClick={handleDelete(select)}
              endIcon={<Close />}>
              {strings(select)}
            </Button>
          ))
        }
        {
          selected.length > 0 && <Button
            sx={{ color: '#737373' }}
            onClick={() => {
              setSelected([]);
              reloadActivities([])
            }}>
            Clear
          </Button>
        }
      </Box>
      <DataGrid
        sortModel={[{ field: 'timestamp', sort: 'desc' }]}
        components={{
          NoRowsOverlay: () => errorHint,
        }}
        loading={loading}
        sx={{
          borderWidth: 0,
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
        }}
        rows={activies?.items && activies?.items || []}
        columns={ActivityColumns}
        disableColumnMenu
        hideFooter
        autoHeight
        selectionModel={'none'} />
    </Card >
  )

}