import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../components/hooks";
import { Box, Grid, Button, Card, CardActions, CardHeader, Container, Avatar, Link } from "@mui/material";
import { PageBase } from "../../components/page-base";
import { BrowserView, MobileView } from 'react-device-detect';

const isWalletInstalled = () => 
  typeof window.ethereum !== 'undefined';

export const PageConnectWallet = () => {
  const navigate = useNavigate();
  const { wallet } = useAppContext();
  
  const connectWallet = async () => {
    await wallet.connect("injected");
    navigate(-1);
  }
  
  return (
    <PageBase>
      <Container>
        <Grid container sx={{height:'60vh', justifyContent: 'center', alignItems: 'center'}} p={4}>
          <Grid item xs={12} md={4}>

            { isWalletInstalled() && 
              <Card sx={{ alignItems: "center", justifyContent: 'center' }}>
                <CardHeader 
                  avatar={<Avatar sx={{width: 80, height: 80}} src="/assets/images/metamask-fox.svg" />}
                  title="Connect your Wallet"
                  subheader="In order to sign crypto transaction, this site require a connection to your wallet."
                  />
                <CardActions>
                  <Button 
                    fullWidth
                    variant='contained' 
                    onClick={connectWallet}>
                      Connect MeatMask Wallet
                  </Button>
                </CardActions>
              </Card>
            }

            { !isWalletInstalled() &&
              <Card sx={{ alignItems: "center", justifyContent: 'center' }}>
                <CardHeader 
                  avatar={<Avatar sx={{width: 80, height: 80}} src="/assets/images/metamask-fox.svg" />}
                  title="Install MetaMask Wallet"
                  subheader="Before you start purchasing NFT, you'll need a crypto wallet to sign your transactions. Please visit the following link to learn about how to install MetaMask wallet to your browser."
                  />
                <BrowserView>
                  <CardActions>
                    <Button 
                      fullWidth
                      variant='contained' 
                      href="https://metamask.io/download/"
                      >
                        Download &amp; Install MetaMask Wallet
                    </Button>
                  </CardActions>
                </BrowserView>
                <MobileView>
                  <CardActions>
                    <Button
                      fullWidth
                      variant='contained'
                      href="https://metamask.app.link/dapp/soyl.one"
                      >
                        Install / Open MetaMask App
                    </Button>
                  </CardActions>
                </MobileView>
              </Card>
            }

          </Grid>
        </Grid>
      </Container>
    </PageBase>
  )
}