import { useContext } from "react";
import { Box, Alert, Link, Tooltip, Typography, Stack, Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { NamedUser } from "../../../components/named-user";
import { $price } from "../../../components/utils";
import { UiContext } from "../../../context/app"
import { INftToken, ISellConfigs } from "../../../models";
import { convertEth } from "../../../services/market";
import { ListingBuyButton } from "../button-buy";
import { useAppContext } from "../../../components/hooks";
import { CancelBookButton } from "../button-cancel-book";
import { makeStyles } from "@material-ui/styles";
import { BBIcon } from "../../../assets/icons/bb";

export default ({ listings, nft }: { listings: ISellConfigs[], nft: INftToken }) => {
  const { strings, mobile } = useContext(UiContext);
  const { wallet } = useAppContext();
  const classes = useStyle({ mobile });

  const ListAction = ({ listing }: any) =>
    listing.seller === wallet.account ?
      <CancelBookButton
        nft={nft}
        buttonProps={{ variant: 'outlined', sx: { padding: '0', fontSize: '13px' } }}>
        {strings('CANCEL')}
      </CancelBookButton>
      :
      <ListingBuyButton
        nft={nft}
        listing={listing}
        buttonProps={{ variant: 'outlined', sx: { padding: '0', fontSize: '13px' } }}>
        {strings('BUY')}
      </ListingBuyButton>

  const listingColumns: GridColDef[] = [
    {
      field: 'unitPrice',
      headerName: strings('DATAGRID_UNIT_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => $price(params.row.price),
      renderCell: (params) =>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <BBIcon icon='ic_ethereum' size={16} />
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{params.value}</Typography>
          <Typography className={classes.text}>ETH</Typography>
        </Stack>,
    },
    {
      field: 'USDUnitPrice',
      headerName: strings('DATAGRID_USD_UNIT_PRICE'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => convertEth(params.row.price),
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{`$${params.value}`}</Typography>
        </Box>
    },
    {
      field: 'quantity',
      headerName: strings('DATAGRID_QUANTITY'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row.quantity,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{params.value}</Typography>
        </Box>
    },
    {
      field: 'expiration',
      headerName: strings('DATAGRID_EXPIRATION'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row.duration?.end || '',
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Tooltip title={params.value ? moment.unix(params.value).format('lll') : ''} arrow placement='top'>
            <Typography className={classes.text} >{moment.unix(params.value).fromNow()}</Typography>
          </Tooltip>
        </Box>
    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.row.seller,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Link href={`/profile/${params.value}`}>
            <NamedUser account={params.value} />
          </Link>
        </Box>
    },
    {
      field: ' ',
      editable: false,
      sortable: false,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return <ListAction listing={params.row} />
      }
    }
  ]

  return (
    <Card className={classes.listingsCard}>
      <DataGrid
        columns={listingColumns}
        rows={listings}
        components={{
          NoRowsOverlay: () =>
            <Alert severity="info" sx={{ flex: 1 }}>
              {strings('NO_OFFERS')}
            </Alert>,
        }}
      />
    </Card>
  )
};

const useStyle = makeStyles({
  listingsCard: {
    flexGrow: 1,
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    padding: ({ mobile }: any) => mobile ? 0 : '50px 30px 50px 30px',
    background: '#131313'
  },
  eleBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '15px',
    color: '#c7c7c7',
    textAlign: 'center',
  }
})