import {
  getFirestore,
  collection,
  doc, getDoc, getDocs,
  setDoc, updateDoc, 
  addDoc,
  deleteDoc,
  query,
  where,
  QueryConstraint,
  limit,
  startAfter,
} from "firebase/firestore";

export const document = async (docPath:string, id:string):Promise<any | null> => {
  const db = getFirestore();
  const snap = await getDoc(doc(db, docPath, id));
  
  return (snap.exists()) ?
    {id, ...snap.data()} : null;
}

type QueryOptions = {
  page?:number,
  perPage?:number,
  parse?:(data:any) => any,
  queryArgs?:any[]
}

export const documents = async (
    collectionPath:string, options:QueryOptions = {}
  ):Promise<any[]> => {
    const opts = {
      parse: (data:any) => data,
      queryArgs: [],
      ...options
    };
    const db = getFirestore();
    const colRef = collection(db, collectionPath);
    const q = query(colRef, ...opts.queryArgs);
    
    const querySnapshot = await getDocs(q);
    const output:any[] = [];
    querySnapshot.forEach(doc => output.push(opts.parse({id: doc.id, ...doc.data()})));
    return output;
  }

export const documentsMatched = async (
  collectionPath:string, matches:any, options:QueryOptions = {}
):Promise<any[]> => {
  const opts = {
    page: 0,
    perPage: 50,
    parse: (data:any) => data,
    queryArgs: [] as QueryConstraint[],
    ...options
  };
  for(let attr in matches) {
    if(matches[attr])
      opts.queryArgs.push(where(attr, '==', matches[attr]));
  }

  const db = getFirestore();
  const colRef = collection(db, collectionPath);
  let hasNext = false;

  if (opts.page > 0) {
    const skipArgs = [...opts.queryArgs];
    skipArgs.push(limit(opts.page * opts.perPage));
    const skipq = query(colRef, ...skipArgs);
    const skipSnapshots = await getDocs(skipq);
    const skipRef = skipSnapshots.docs[skipSnapshots.docs.length - 1];
    if (!skipRef)
      hasNext = false;
    else
      opts.queryArgs.push(startAfter(skipRef));
  }

  opts.queryArgs.push(limit(opts.perPage));

  const q = query(colRef, ...opts.queryArgs);
  const querySnapshot = await getDocs(q);
  const output:any[] = [];

  querySnapshot.forEach(doc => 
    output.push(opts.parse({id: doc.id, ...doc.data()})));
  return output;
}

export const update = (docPath:string, id:string, data:any):Promise<any> => 
  updateDoc(doc(getFirestore(), docPath, id), data);

export const save = (docPath:string, id:string, data:any):Promise<any> =>
  setDoc(doc(getFirestore(), docPath, id), data);

export const append = (collectionPath:string, data:any):Promise<any> =>
  addDoc(collection(getFirestore(), collectionPath), data);

export const remove = (docPath:string, id:string):Promise<any> =>
  deleteDoc(doc(getFirestore(), docPath, id));