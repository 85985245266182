import { nftId } from "../services/market"
import { IOfferConfigs, OfferStatus } from "./options/offer-options"
import { BookStates, ISellConfigs, OfferStates, SellMethods, TradingMethods } from "./options/sell-options"
import { IBidConfigs } from "./options/bid-options";

export const DURATION_AFTER_AUCTION_END = 8 * 86400; // 8 days in seconds

export type Sale = {
  currency:string,        // address
  nftContract:string,     // address
  tokenId:string,         // uint256
  quantity:number,        // uint256
  price:string,           // uint256
  acceptMinPrice:string,  // uint256

  method:number,          // uint8

  seller:string,          // address
  buyer:string,           // address
  nonce:number,           // uint256
  beginTime:number,       // uint256
  expireTime:number,      // uint256
  maxFee:string,          // uint256
}

export type SaleConfig = Sale & {
  id?:string,
  chain:number,
  createAt:number,
  auctionEndAt:number,
  sellerSig:string,
  mintSig?:string, // @todo: 使用新版的 1155 就不需要這欄了
  transactionHash?:string,
  bookState:BookStates,
}

export const asPricing = (config:SaleConfig):any => {
  const pricing:any = {};
  switch(config.method) {
    case TradingMethods.FIXED_PRICE:
      pricing.method = SellMethods.FIXED_PRICE;
      pricing.price = config.price;
      break;
    case TradingMethods.SELL_TO_HIGHEST_BIDDER:
      pricing.method = SellMethods.SELL_TO_HIGHEST_BIDDER;
      pricing.startingPrice = config.price;
      pricing.reservePrice = config.acceptMinPrice;
      pricing.price = config.price; // use startingPrice as default while not knowing heighest bidding
      break;
    case TradingMethods.SELL_WITH_DECLINING_PRICE:
      pricing.method = SellMethods.SELL_WITH_DECLINING_PRICE;
      pricing.startingPrice = config.price;
      pricing.endingPrice = config.acceptMinPrice;
      pricing.price = config.price; // use startingPrice as default while not calculating time diff
      break;
    case TradingMethods.NOT_FOR_SELL:
    default:
      pricing.method = SellMethods.NOT_FOR_SELL;
      pricing.price = '0';
      break;
  }
  return pricing;
}

export const asSellConfigs = (config:SaleConfig):ISellConfigs => ({
  id: config.id,
  target: nftId(config.chain, config.nftContract, config.tokenId),
  duration: { 
    begin: config.beginTime, 
    // NOTE: The actual expire time of SELL_TO_HIGHEST_BIDDER must later than auction end, 
    //       for backend to process auto end bid
    end: config.method == TradingMethods.SELL_TO_HIGHEST_BIDDER ? 
      config.expireTime - DURATION_AFTER_AUCTION_END :
      config.expireTime
  },
  currency: config.currency,
  quantity: config.quantity,

  ...asPricing(config),

  seller: config.seller,
  timestamp: config.createAt,
  bookState: config.bookState,
  version: 2
});

export type Offer = {
  currency:string,        // address
  nftContract:string,     // address
  tokenId:string,         // uint256
  quantity:number,        // uint256
  price:string,           // uint256

  method:number,          // uint8

  seller:string,          // address
  buyer:string,           // address
  nonce:number,           // uint256
  beginTime:number,       // uint256
  expireTime:number,      // uint256
}

export type OfferConfig = Offer & {
  id?:string,
  chain:number,
  createAt:number,
  buyerSig:string,
  transactionHash?:string,
  offerState:OfferStates,
}


export const asOfferConfigs = (config:OfferConfig):IOfferConfigs => ({
  id:config.id,
  from: config.buyer,
  target: nftId(config.chain, config.nftContract, config.tokenId),
  duration: { begin: config.beginTime, end: config.expireTime },
  currency: config.currency,
  quantity: config.quantity,
  price: config.price,
  timestamp: config.createAt,
  status: OfferStatus.OFFERING, // @todo: might have multiple status
  version: 2
});


export const asBiddingConfigs = (config:OfferConfig):IBidConfigs => ({
  id:config.id,
  nftDocId: nftId(config.chain, config.nftContract, config.tokenId), // @drepecated
  bookDocId: 'ANYONE', // @drepecated
  bidder: config.buyer,
  target: nftId(config.chain, config.nftContract, config.tokenId), // @drepecated
  currency: config.currency,
  price: config.price,
  timestamp: config.createAt,
  state: config.offerState,
  version: 2
});


export type DealedConfig = SaleConfig & OfferConfig & {
  dealedPrice: string,
  dealedTime: number,
  orderId: number
};