import { IContext, INftCollection, INftToken, IPaged, IUser } from "../../models"
import { document, save, remove, documents } from "../db-utils"
import { exploreCollections, exploreNfts, exploreProfiles } from "./service-listing";

const like = async (context:IContext, type:string, target:string, isLike?:boolean):Promise<boolean> => {
  const { wallet } = context;
  const { account } = wallet;
  const docPath = `users/${account}/${type}`;
  
  if(!wallet.isConnected())
    return false;
    
  if(isLike !== undefined) {
    if(true == isLike)
      await save(docPath, target, { target, isLike });
    if(false == isLike)
      await remove(docPath, target);
  }

  const rec = await document(docPath, target);

  return !!rec;
}

const favorites = async (context:IContext, type:string):Promise<string[]> => {
  const { wallet } = context;
  const { account } = wallet;
  const docPath = `users/${account}/${type}`;
  if(!wallet.isConnected()) return [];
  return (await documents(docPath)).map(d => d.id);
}

export const likeNft = async (context:IContext, target:string, isLike?:boolean):Promise<boolean> =>
  like(context, 'favorite-nfts', target, isLike);

export const likeCollection = (context:IContext, target:string, isLike?:boolean):Promise<boolean> => 
  like(context, 'favorite-collections', target, isLike);

export const likeProfile = (context:IContext, target:string, isLike?:boolean):Promise<boolean> => 
  like(context, 'favorite-profiles', target, isLike);

export const favoriteNfts = async (context:IContext):Promise<IPaged<INftToken>> => {
  const highlights = await favorites(context, 'favorite-nfts');
  return exploreNfts(context, {highlights});
}

export const favoriteCollections = async (context:IContext):Promise<IPaged<INftCollection>> => {
  const highlights = await favorites(context, 'favorite-nfts');
  return exploreCollections(context, {highlights});
}

export const favoriteProfiles = async (context:IContext):Promise<IPaged<IUser>> => {
  const highlights = await favorites(context, 'favorite-nfts');
  return exploreProfiles(context, {highlights});
}