import { Box, Container, Typography } from "@mui/material";

const block = (
  <Box sx={{
    display: 'inline-block',
    width: '24px',
    height: '7px',
    marginBottom: '12px',
    marginLeft: '20px',
    backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)'
  }} />
)

export default ({ data }: any) =>
  <Container maxWidth='md' sx={{ pt: 8 }}>
    <Box display='flex' alignItems='center'>
      <Typography variant='h4' paragraph>
        {data.commonMarketTitle}
      </Typography>
      {block}
    </Box>
    <Typography sx={{ fontSize: "26px" }} paragraph>{data.commonMarketIntro}</Typography>
  </Container>