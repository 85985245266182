import {
  Button, Container, Grid,
} from "@mui/material";
import { useAppContext } from '../../components/hooks';
import { PageBase } from '../../components/page-base';
import Cookies from 'universal-cookie';

export const PageTestConnection = () => {
  const { wallet, web3 } = useAppContext();

  return (
    <PageBase>
      <Container>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button href="/connect-wallet">Connect</Button>
            <Button onClick={() => wallet.reset()}>Reset Connection</Button>
            <Button onClick={() => new Cookies().remove('account', { path: '/' })}>Reset Cookie</Button>
          </Grid>
        </Grid>
      </Container>

    </PageBase>
  )
}