import { useRef, useContext, useState, useEffect } from "react";
import {
  Box,
  Card, CardMedia, CardContent, CardActions, CardActionArea,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Countdowner } from "../../../components/countdowner";
import { useAppContext } from "../../../components/hooks";
import { NamedUser } from "../../../components/named-user";
import { Column, Row } from "../../../components/page-base";
import { $addr, $ipfs, $price } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Summaries, SellMethods, SellStates, INftToken } from "../../../models";
import { stateOf } from "../../../services/market";
import moment from "moment";

const stringOf = (obj: any, key: string): string | undefined => {
  if (!obj) return undefined;
  if (key in obj && typeof obj[key] == 'string') return obj[key];
  return undefined;
}

const timingOf = (state: SellStates | undefined, begin: number, end: number) => {
  if (!state) return '';
  let timing = '';
  switch (state) {
    case SellStates.COMMING:
      timing = moment.unix(begin).fromNow();
      break;
    case SellStates.SELLING:
      timing = moment.unix(end).fromNow();
      break;
    case SellStates.ENDING:
      timing = 'ending';
      break;
    default:
      timing = '';
      break;
  }
  return timing;
}
interface NftCardProps {
  nft: INftToken,
  showCountdown?: boolean,
}
export default (props: NftCardProps) => {
  const { nft } = props;
  const { strings, mobile } = useContext(UiContext);
  const { wallet } = useAppContext();
  const cardRef = useRef<any>();
  const classes = useStyles(mobile);
  const [cardMediaWidth, setCardMediaWidth] = useState();

  const state = stateOf(nft);

  let collectionName: string = '';
  let tokenName: string = '';
  let collectionOwner: string | undefined;
  let tokenStatusHint: any | undefined;
  let timing: string | undefined;
  let action: string | undefined = strings("VIEW");
  let href: string = '#';
  let isMine: boolean = nft.owner == wallet.account;
  const saleString =
    state === SellStates.COMMING ?
      strings('SALE_START_AT')
      : strings('SALE_ENDS');
  const targetTime =
    state === SellStates.COMMING ?
      nft.saleOptions?.duration?.begin || 0
      : nft.saleOptions?.duration?.end || 0;

  if (!!nft.contract && !!nft.tokenId) {
    href = `/nft/${nft.chain}/${nft.contract}/${nft.tokenId}`;
  }

  if (nft.summaries) {
    collectionName =
      stringOf(nft.summaries, Summaries.COLLECTION_NAME) ||
      $addr(nft.contract);
    collectionOwner =
      stringOf(nft.summaries, Summaries.COLLECTION_OWNER);
  }

  if (nft.meta) {
    tokenName = nft.meta?.name || '';
  }

  if (nft.chain && nft.contract && nft.tokenId)
    href = `/nft/${nft.chain}/${nft.contract}/${nft.tokenId}`;

  if (!!nft.saleOptions) {
    switch (nft.saleOptions?.method) {
      case SellMethods.FIXED_PRICE:
      case SellMethods.SELL_WITH_DECLINING_PRICE: {
        action = isMine ? strings('VIEW') : strings("BUY_NOW");
        tokenStatusHint = <>
          <FontAwesomeIcon icon={faEthereum} />
          &nbsp;
          {$price(nft.saleOptions.price || '0', 4)}
        </>;
        timing = timingOf(
          state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.SELL_TO_HIGHEST_BIDDER: {
        action = isMine ? strings('VIEW') : strings("PLACE_BID");
        tokenStatusHint = <>
          {strings('CARD_TOP_BID')}
          &nbsp;
          <FontAwesomeIcon icon={faEthereum} />
          &nbsp;
          {$price(nft.saleOptions.price || '0', 4)}
        </>;
        timing = timingOf(state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.NOT_FOR_SELL:
      default:
        action = strings("VIEW");
        tokenStatusHint = undefined;
        timing = undefined;
        break;
    }
  }

  const videoPlayer = (event: any, state?: string) => {
    let playPromise = event.target.play();
    if (playPromise !== undefined && state === 'play')
      playPromise.then(() => { }).catch((error: any) => { })
    if (playPromise && state === 'pause')
      playPromise.then(() => { event.target.pause() }).catch((error: any) => { })
  }

  useEffect(() => {
    setCardMediaWidth(cardRef?.current?.clientWidth)
    window.addEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth))
    return () => window.removeEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth));
  }, [])

  return (
    <Card ref={cardRef} className={classes.card}>
      <CardActionArea href={href} className={classes.cardActionArea}>
        <CardMedia
          component='video'
          sx={{ objectFit: 'cover', height: cardMediaWidth && cardMediaWidth * 1.27, width: '100%' }}
          src={$ipfs(props.nft.meta?.animation_url)}
          poster={$ipfs(props.nft.meta?.image)}
          onClick={(event: any) => videoPlayer(event, 'play')}
          onMouseEnter={(event: any) => videoPlayer(event, 'play')}
          onMouseLeave={(event: any) => videoPlayer(event, 'pause')}
          loop
        />
        <CardContent className={classes.cardContent}>

          <Column className={classes.column}>

            <Typography className={classes.collectionName}>
              {`${collectionName} (${tokenName})`}
            </Typography>

            {
              !!collectionOwner &&
              <Typography>
                <NamedUser account={collectionOwner} />
              </Typography>
            }

            {
              (!!tokenStatusHint || !!timing) &&
              <Row className={classes.row}>
                <Typography>{tokenStatusHint}</Typography>
                <Typography>{timing}</Typography>
              </Row>
            }
          </Column>

          {
            props.showCountdown &&
            (state === SellStates.COMMING || state === SellStates.SELLING) &&
            <>
              <Divider className={classes.divider} />
              <Box>
                <Typography>{saleString}</Typography>
                <Countdowner targetTime={targetTime} />
              </Box>
            </>
          }
        </CardContent>
      </CardActionArea>

      {
        !!action && !!href &&
        <CardActions className={classes.cardAction}>
          <Button
            className={classes.button}
            href={href}>
            {action}
          </Button>
        </CardActions>
      }
    </Card>
  )
}

const useStyles = makeStyles(mobile => ({
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 'none',
  },
  cardActionArea: { flex: 1 },
  cardContent: {
    flex: 1,
    height: '100%',
    mt: 'auto',
    mb: 0,
    px: 0,
    backgroundColor: 'white',
    textAlign: 'left',
  },
  cardAction: { backgroundColor: 'inherit', px: 0 },
  button: { borderRadius: '20px', color: '#707070', px: 4 },
  collectionName: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  row: { justifyContent: 'space-between', pt: '25.6px' },
  column: { flex: 1 },
  divider: { my: mobile ? '4px' : 1 }
}))