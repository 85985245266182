import {
  getFirestore,
  doc, getDoc, where, orderBy, 
} from "firebase/firestore";
import firebase from 'firebase/compat/app';
import moment from 'moment';

import {
  IBidConfigs, IBidOptions,
  ISellConfigs, ISellOptions, SellMethods,
  IOfferConfigs, IOfferOptions, 
  CURRENCY_DEFAULT,
  IContext,
  IListOptions,
  Sorting,
  Status,
} from '../../../models';

import { send as _send } from '../crypto/utils/send';
import { approveCoin, approveNft } from '../crypto-access';
import { nftInfo } from '../service-info';
import { document } from "../../db-utils";
import { asSellConfigs } from "../../../models/trading";
import { nftId } from "../func-pure";

// @todo: move this flow to cloud or backend
// @todo: make sure the user own the db resource
export const bookForSell =
  async (context: IContext, options: ISellOptions):
    Promise<ISellConfigs> => {
    // /sells/:nftId
    const { wallet, market, resaller } = context;
    const { target, method, duration } = options;

    // @todo: check ownership

    const db = getFirestore();
    const docRef = doc(db, 'sells', target);
    const snap = await getDoc(docRef);

    if (snap.exists())
      throw new Error("This nft is already on sale.");

    const timestamp = moment().unix();
    let priceOptions: string[] = [];

    if (
      !duration || !duration.end || !duration.begin ||
      typeof duration.end !== 'number' ||
      typeof duration.begin !== 'number' ||
      duration.end - duration.begin < 300 || // duration must > 3 minute
      duration.end <= timestamp)
      throw new Error("Duration setting incorrect.");

    switch (method) {
      case SellMethods.FIXED_PRICE:
        if (!options.price || typeof options.price != 'string')
          throw new Error("Price format incorrect.");
        priceOptions = [options.price];
        break;
      case SellMethods.SELL_TO_HIGHEST_BIDDER:
        if (!options.startingPrice || typeof options.startingPrice != 'string')
          throw new Error("Starting Price format incorrect.");
        if (options.reservePrice && typeof options.reservePrice != 'string')
          throw new Error("Reserve Price format incorrect.");
        if (!options.currency || options.currency === CURRENCY_DEFAULT)
          throw new Error("This methods only supports ERC20 payment.");

        priceOptions = [
          options.startingPrice,
          options.reservePrice || options.startingPrice
        ];

        break;
      case SellMethods.SELL_WITH_DECLINING_PRICE:
        if (!options.startingPrice || typeof options.startingPrice != 'string')
          throw new Error("Starting Price format incorrect.");
        if (!options.endingPrice || typeof options.endingPrice != 'string')
          throw new Error("Ending Price format incorrect.");
        priceOptions = [options.startingPrice, options.endingPrice];
        break;
      case SellMethods.NOT_FOR_SELL:
        throw new Error("Unsupported selling method.");
    }

    const data: ISellConfigs = {
      ...options,
      timestamp
    }

    await firebase
      .functions()
      .httpsCallable("booking")(data);

    const nftInfoData = await nftInfo(context, data.target);
    
    if (!market) throw new Error('contract not ready.');
    if (!nftInfoData.contract || !nftInfoData.tokenId) 
      throw new Error('contract not ready.');

    let hasError = null;

    try{

      await approveNft(
        context, 
        nftInfoData.contract, 
        nftInfoData.tokenId, 
        market.options.address);
  
      await _send(resaller.methods.book(
        data.currency || CURRENCY_DEFAULT,
        nftInfoData.contract,
        nftInfoData.tokenId,
        data.duration.begin,
        data.duration.end,
        [
          "NOT_FOR_SELL",
          "FIXED_PRICE",
          "SELL_TO_HIGHEST_BIDDER",
          "SELL_WITH_DECLINING_PRICE"
        ].indexOf(data.method as SellMethods),
        priceOptions
      ), { from: wallet.account });
  
      await firebase
        .functions()
        .httpsCallable("booked")(context.wallet.chainId);

    } catch(err) {
      hasError = err;

      await firebase
        .functions()
        .httpsCallable("bookingFailed")(data);
    }

    if(hasError)
      throw hasError;

    return data;
  }

export const cancelBook =
  async (context: IContext, target: string):
    Promise<void> => {
    const { wallet, resaller } = context;
    const nft = await nftInfo(context, target);

    await firebase
      .functions()
      .httpsCallable("canceling")(target);

    if (!!nft.saleOptions?.bookId) {
      await _send(
        resaller.methods.cancelBook(nft.saleOptions?.bookId), 
        { from: wallet.account }
      );
    }

    await firebase
      .functions()
      .httpsCallable("syncNft")(target);

    await firebase
      .functions()
      .httpsCallable("canceled")(wallet.chainId);

  }

// @todo firebase collection bids 相同bidder目前不會覆蓋之前的bid
// for book.method == "SELL_TO_HIGHEST_BIDDER"
export const makeBid =
  async (context: IContext, options: IBidOptions):
    Promise<void> => {
    // /sells/:nftId/bids
    const { wallet, market, resaller } = context;
    let { target, nftDocId } = options;

    if(!nftDocId && !!target) {
      // todo: remove target after migrated to NftTrade
      nftDocId = target;
      console.warn('!!DEPRECATED: BidOptions.target was deprecated, use BidOptions.nftDocId instead.');
    }
    
    const nftInfoData = await nftInfo(context, nftDocId);
    const sellOptions = nftInfoData.saleOptions;
    const timestamp = moment().unix();

    if (!sellOptions || !sellOptions)
      throw new Error("ERR_SALE_NOT_SELLING");

    if (sellOptions.method !== SellMethods.SELL_TO_HIGHEST_BIDDER)
      throw new Error("ERR_SALE_NOT_SUPPORT_BIDDING");

    if (timestamp < sellOptions.duration.begin)
      throw new Error("ERR_SALE_NOT_STARTED");

    if (timestamp > sellOptions.duration.end)
      throw new Error("ERR_SALE_EXPIRED");
    
    if (!nftInfoData.saleOptions?.bookId) 
      throw new Error("ERR_UNRECOGNIZED");

    const data: IBidConfigs = {
      ...options,
      bidder: wallet.account,
      timestamp
    }

    await firebase
        .functions()
        .httpsCallable("bidding")(data);
      
    if (sellOptions.currency && sellOptions.currency !== CURRENCY_DEFAULT) 
      await approveCoin(
        context, 
        sellOptions.currency, 
        market.options.address, 
        data.price
      );

    await _send(
      resaller.methods.bid(nftInfoData.saleOptions?.bookId, data.price),
      { from: wallet.account });

    await firebase
      .functions()
      .httpsCallable("bidded")(wallet.chainId);
  }


// for book.method == "FIXED_PRICE"
// for book.method == "SELL_WITH_DECLINING_PRICE"???
export const purchase =
  async (context: IContext, options: { nftDocId: string, bookDocId: string }):
    Promise<void> => {
    // /sells/:nftId
    const { wallet, market, resaller } = context;
    const { nftDocId, bookDocId } = options;

    const db = getFirestore();
    const docRef = doc(db, 'sells', bookDocId);
    const snap = await getDoc(docRef);
    const sellOptions = snap.data() as ISellConfigs;
    const timestamp = moment().unix();

    if (!snap.exists() || !sellOptions)
      throw new Error("ERR_SALE_NOT_SELLING");

    if (sellOptions.method !== SellMethods.FIXED_PRICE &&
      sellOptions.method !== SellMethods.SELL_WITH_DECLINING_PRICE)
      throw new Error("ERR_SALE_NOT_SUPPORT_PURCHASE");

    if (timestamp < sellOptions.duration.begin)
      throw new Error("ERR_SALE_NOT_STARTED");

    if (timestamp > sellOptions.duration.end)
      throw new Error("ERR_SALE_EXPIRED");

    if (!sellOptions.bookId)
      throw new Error("ERR_UNRECOGNIZED");

    if (!sellOptions.currency)
      sellOptions.currency = CURRENCY_DEFAULT;

    const isChainCurrency = ((currency?: string) => {
      if (!currency) return true;
      if (currency == CURRENCY_DEFAULT) return true;
      return false;
    })(sellOptions.currency);

    const price = await resaller.methods.priceOf(sellOptions.bookId).call();

    if (!isChainCurrency) 
      await approveCoin(
        context, 
        sellOptions.currency, 
        market.options.address, 
        price
      );

    await firebase
      .functions()
      .httpsCallable("buying")({ 
        buyer: wallet.account,
        target: nftDocId 
      });

    await _send(
      resaller.methods.buy(sellOptions.bookId), {
      from: wallet.account,
      value: isChainCurrency ? price : '0'
    }, 1.2);

    await firebase
      .functions()
      .httpsCallable("dealed")(wallet.chainId);
  }


//@todo: to backend
export const makeOffer =
  async (context: IContext, options: IOfferOptions):
    Promise<IOfferConfigs> => {
    // /offers/:nftId/offers/:offerId
    throw new Error('Feature not available.');
  }

//@todo: to backend
export const cancelOffer =
  async (context: IContext, target: string):
    Promise<void> => {
    // todo: cancel offer from chain
    throw new Error('Feature not available.');
  }

//@todo: to backend
export const acceptOffer =
  async (context: IContext, target: string):
    Promise<any> => {
    // /offers/:nftId/offers/:offerId
    throw new Error('Feature not available.');
  }

export const listSales =
  async (context: IContext, options: IListOptions):
  Promise<ISellConfigs[]> => {
    const { chain, contract, tokenId } = options;
    if(!chain || !contract || !tokenId)
      return [];
    const saleOption = await document('sells', nftId(chain, contract, tokenId));
    return !!saleOption ? [saleOption]: [];
  }