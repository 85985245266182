import { Box, List, ListItem, Typography, Link } from "@mui/material";
import { useContext } from "react";
import { $addr } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Chains, INftToken } from "../../../models";

export default ({ nft }: { nft: INftToken }) => {
  const { strings } = useContext(UiContext);

  const tokenChain = !!nft.chain && strings(Chains[nft.chain]);

  return (
    <List disablePadding>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('CONTRACT_ADDRESS')}</Typography>
        <Link
          variant='body1'
          component='button'
          href={`/collection/${nft.chain}/${nft.contract}`} >
          {$addr(nft.contract)}
        </Link>
      </ListItem>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('TOKEN_ID')}</Typography>
        <Typography sx={{ textAlign: 'right', }}>{nft.tokenId}</Typography>
      </ListItem>
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('TOKEN_STANDARD')}</Typography>
        {/* @todo check if Standard always ERC_721 */}
        <Typography sx={{ textAlign: 'right' }}>ERC-721</Typography>
      </ListItem >
      <ListItem disableGutters sx={{ justifyContent: 'space-between' }} >
        <Typography>{strings('BLOCKCHAIN')}</Typography>
        <Typography sx={{ textAlign: 'right' }}>{tokenChain}</Typography>
      </ListItem>
    </List >
  )
}