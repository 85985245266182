const lang = {
  // nav
  "MARKETPLACE": "Marketplace",
  "SPOTLIGHT": "SPOTLIGHT",
  "CRITIC": "CRITIC",
  "VIEWPOINT": "VIEWPOINT",
  "LOGIN": "LOGIN",
  "LANG_EN": "EN",
  "LANG_CH": "CH",
  "BUILDING": "Building...",
  "MY_COLLECTIONS": "My Collections",
  "COLLECTIONS": "Collections",
  "PROFILE": "Profile",
  "SETTING": "Setting",
  "CANCEL": "Cancel",
  "POPPER_MARKETPLACE": "Marketplace",
  "POPPER_HOME": "Home",
  "POPPER_LOGOUT": "Logout",
  "HOME": "Home",
  "LOGOUT": "Logout",
  "EXPLORE": "Explore",
  "ART": "Art",
  "COLLECTABLE": "Collectable",
  "POLICY": "Policy",
  "TERMS": "Terms",
  "SEARCH_TITLE": "SEARCH",
  "SEARCH": "Search",

  //bottum Nav
  "ABOUT_US": "About Us",
  "SERVICE": "Service",
  "NEWSLETTER": "Newsletter",
  "MEMBER_CENTER": "Member Center",
  "PRIVACY_POLICY": "Privacy Policy",
  "TERMS_OF_SERVICE": "Terms of Service",
  "FOLLOW_US": "Follow Us",

  //Drawer
  "DRAWER_MARKETPLACE": "Marketplace",
  "DRAWER_SPOTLIGHT": "Spotlight",
  "DRAWER_VIEWPOINT": "Viewpoint",

  //page home
  "GO": "GO",
  "CREATOR": "Creator",
  "COLLECTION": "Collection",
  "MORE": "繼續閱讀",
  "LOAD_MORE": "Load More",

  //page collection create
  "REQUIRED_TIPS": "Required fields",
  "LOGO_TITLE": "Logo Image",
  "FEATURED_TITLE": "Featured Image",
  "BANNER_TITLE": "Banner image",
  "URL_TITLE": "URL",
  "DESC_TITLE": "Description",
  "DESC_CONTENT": "0 of 1000 characters used.",
  "CATEGORY_TITLE": "Category",
  "ADD_CATEGORY": "Add Category",
  "LINKS_TITLE": "Links",
  "ROYALTIES_TITLE": "Royalties",
  "ROYALTIES_ADDRESS": "Your payout wallet address",
  "ROYALTIES_FEE": "Percentage fee",
  "CHAIN_TITLE": "Blockchain",
  "PAYMENT_TOKEN_TITLE": "Payment tokens",
  "ADD_PAYMENT_TOKEN": "Add Token",
  "CREATE": "Create",
  "ERROR_SELLER_FEE": "Creator earnings cannot be greater than 10%",

  //page auction create
  "AUCTION_CREATE_TITLE": "List Item For Sale",
  "AUCTION_CREATE_SALE_TYPE": "Type",
  "PRICE": "Price",
  "AUCTION_CREATE_DURATION": "Duration",
  "AUCTION_CREATE_FIXED_PRICE": "Fixed Price",
  "AUCTION_CREATE_TIME_AUCTION": "Time Auction",
  "AUCTION_CREATE_SYMBOL_ETH": "ETH",
  "AUCTION_CREATE_SYMBOL_WETH": "WETH",
  "AUCTION_CREATE_CREAT_BUTTON": "Complete listing",
  "AUCTION_CREATE_METHOD": "Method",
  "AUCTION_CREATE_STARTING_PRICE": "Starting price",
  "AUCTION_CREATE_ENDING_PRICE": "Ending price",
  "AUCTION_CREATE_SELL_TO_HIGHEST_BIDDER": "Sell to highest bidder",
  "AUCTION_CREATE_SELL_WITH_DECLINING_PRICE": "Sell with declining price",
  "AUCTION_CREATE_PREVIEW": "Preview",
  "AUCTION_CREATE_SALED_TITLE": "Your item is now listed for sale",
  "AUCTION_CREATE_SHARE_LIST": "Share your listing",
  "AUCTION_CREATE_VIEW_BUTTON": "View item",
  "FEES": "Fees",
  "SERVICE_FEE": "Service Fee",
  "CREATOR_ROYALITY": "Creator Royality",

  //page collection
  "COLLECTION_EMPTY": "There's no NFT yet.",
  "ADD_ITEM": "Add Item",
  "COLLECTION_DIFF_CHAIN": "您目前檢視的項目發行於 [COLLECTION_CHAIN], 與您錢包設定的區塊鏈網路不相符，請於錢包頁面切換至正確的網路。",
  "COLLECTION_ITEMS": "Items",
  "COLLECTION_OWNERS": 'Owners',
  "COLLECTION_FLOOR_PRICE": 'Floor price',
  "COLLECTION_VOLUME": "Volume",
  "APPLY": "Apply",
  "FILTER": "Filter",
  "CREATED_BY": "Created by",

  //page nft mint
  "TITLE": "Create New Item",
  "MEDIA_TITLE": "Image, Video",
  "MEDIA_PREVIEW": "Preview Image",
  "NAME_TITLE": "Name",
  "EXTERNAL_URL_TITLE": "External link",
  "DESCRIPTION_TITLE": "Description",
  "COLLECTION_TITLE": "Collection",
  "MINT_SUCCESS": "You created [name]",
  "MAX_SUPPLY": "Max supply",

  //page nft detail
  "DETAIL_DIFF_CHAIN": "您目前檢視的項目發行於 [NFT_CHAIN], 與您錢包設定的區塊鏈網路不相符，如欲交易此項目，請於錢包頁面切換至正確的網路。",
  "ETHEREUM_MAINNET": "Ethereum",
  "ETHEREUM_ROPSTEN_TESTNET": "Ropsten",
  "ETHEREUM_RINKEBY_TESTNET": "Rinkeby",
  "BIANCE_MAINNET": "Biance",
  "BIANCE_TESTNET": "Biance Testnet",
  "GANACHE_TESTNET": "Ganache Testnet",
  "OWNERS": "[NUMBER] owners",
  "VIEWS": "[NUMBER] views",
  "FAVORITE": "[NUMBER] favorite",
  "OWNED_BY": "owned by:",
  "DESCRIPTION": "Description",
  "VIEW_ARTWORK": "View Artwork",
  "PROPERTIES": "Properties",
  "ABOUT": "About",
  "COLLECT": "Collect",
  "DETAILS": "Details",
  "CONTRACT_ADDRESS": "Contract Address",
  "TOKEN_ID": "Token ID",
  "TOKEN_STANDARD": "Token Standard",
  "BLOCKCHAIN": "Blockchain",
  "SALE_START_AT": "Auction will start at",
  "SALE_ENDS": "Auction ends in",
  "MAKE_OFFER": "Make Offer",
  "PLACE_BID": "Place Bid",
  "BUY_NOW": "Buy Now",
  "BUY": "BUY",
  "OPENING_SOON": "OPENING SOON",
  "BIDDING_SOON": "BIDDING SOON",
  "SELL": "Sell",
  "CANCEL_SELLING": "Cancel Selling",
  "ACCEPT": "Accept",
  "TRANSFER": "Transfer",
  "MINTED": "Minted",
  "DEAL": "Sale",
  "BOOKED": "List",
  "TRANSFERS": "Transfers",
  "SALES": "Sales",
  "LISTINGS": "Listings",
  "CURRENT_BID": "Current bid",
  "PRICE_ETH": "[price] ETH",
  "DAYS": "Days",
  "HOURS": "Hours",
  "MINUTES": "Minutes",
  "SECONDS": "Seconds",
  "PRICE_HISTORY_TITLE": "Price History",
  "OFFERS": "Offers",
  "ITEM_ACTIVITY": "Item Activity",
  "PERIOD_ALL": "All Time",
  "PERIOD_DAYS": "Last [day] Days",
  "PERIOD_YEAR": "Last Year",
  "DAYS_AVG_PRICE": "[day] Day Avg. Price",
  "DAYS_VOLUME": "[day] Day Volume",
  "YEAR_AVG_PRICE": "Yearlong Avg. Price",
  "YEAR_VOLUME": "Yearlong Volume",
  "ALL_AVG_PRICE": "All Time Avg. Price",
  "EMPTY_RECORD": "No price history yet",
  "RETRY": "Retry",
  "NO_OFFERS": "No offers found for this token.",
  "NO_LISTINGS": "No listings found for this token.",
  "DATAGRID_EVENT": "Event",
  "DATAGRID_PRICE": "Price",
  "DATAGRID_FROM": 'From',
  "DATAGRID_TO": "To",
  "DATAGRID_DATE": "Date",
  "DATAGRID_USDPRICE": "USD Price",
  "DATAGRID_FLOOR_DIFFERENCE": "Floor Difference",
  "DATAGRID_EXPIRATION": "Expiration",
  "DATAGRID_UNIT_PRICE": "Unit Price",
  "DATAGRID_USD_UNIT_PRICE": "USD Unit Price",
  "DATAGRID_QUANTITY": "Quantity",
  "CANCEL_TITLE": "Are you sure you want to cancel your listing?",
  "CONFIRM": "Confirm",
  "CLEAR": "Clear",

  //Buy button
  "BUY_NOW_CREATOR_FEE": "Creator Fee",
  "BUY_NOW_TITLE": "Complete checkout",
  "BUY_NOW_ITEM_TITLE": "Item",
  "BUY_NOW_SUBTOTAL": "Subtotal",
  "BUY_NOW_TOTAL": "Total",
  "BUY_NOW_SUBMIT_BUTTON": "Confirm checkout",
  "REMINDER": "By checking this box, I agree to ",
  "REMINDER_SERVICE": "Terms Of Service",
  "DOLLAR_EXCHANGE": "$ [dollars]",
  "LESS_THAN_MIN_DOLLARS": "< $ [dollars]",
  "BALANCE": "Balance: [balance] ETH",

  "PURCHASE_PROCESS_TITLE": "Your purchase is processing!",
  "PURCHASE_PROCESS_SUBTITLE": "You just purchased [nft]",
  "PURCHASE_PROCESS_STATUS": "Status",
  "PURCHASE_PROCESS_TRANSACTION_HASH": "Transaction Hash",
  "PURCHASE_PROCESS_SHARE": "Share",
  "PURCHASE_PROCESS_PROCESSING": "Processing",
  "PURCHASE_PROCESS_COMPLETE": "Complete",

  //bid-button
  "BID_TITLE": "Place a bid",
  "BID_SUBMIT_BUTTON": "Place Bid",
  "SYMBOL": "ETH",
  "INVALID_PRICE": "Invalid Price",

  //page setting
  "PROFILE_SETTINGS": "Profile Settings",
  "USERNAME": "Username",
  "USERNAME_PLACEHOLDER": "Enter username",
  "BIO": "Bio",
  "BIO_PLACEHOLDER": "Tell the world your story!",
  "EMAIL": "Email Address",
  "EMAIL_PLACEHOLDER": "Enter Email",
  "LINKS": "Links",
  "LINKS_TWITTER": "Your twitter handle",
  "LINKS_INSTAGRAM": "Your instagram handle",
  "LINKS_WEBSITE": "Your website.io",
  "WALLET_ADDRESS": "Wallet Address",
  "PROFILE_IMAGE": "Profile Image",
  "PROFILE_BANNER": "Profile Banner",

  //community
  "TWITTER": "Twitter",
  "INSTAGRAM": "Instagram",
  "WEBSITE": "Website",
  "DISCORD": "Discord",

  //page profile
  "ACTIVE_LISTINGS": "Active listings",
  "UNNAMEED": "Unnamed",
  "JOINED": "Joined",
  "COLLECTED": "Collected",
  "ACTIVITY": "Activity",
  "UNNAMED": "UNNAMED",

  //page My Collection
  "MY_COLLECTIONS_TITLE": "My Collections",
  "MY_COLLECTIONS_CREATE_BUTTON": "Create a collection",
  "MY_COLLECTIONS_DESCRIPTION": "Explore the [name] collection",
  "MY_COLLECTIONS_BY": "by",
  "MY_COLLECTIONS_YOU": "you",
  "MY_COLLECTIONS_TOTAL_SUPPLY": "[total] items",

  //card-nft 
  "CARD_TOP_BID": "Top bid",
  "CARD_PRICE": "Price [number] ETH",
  "CARD_LAST": "Last [number] ETH",
  "BID": "Bid",
  "VIEW": "View",

  "ABOUT_US_DESC": "取英文單字soil （土壤） 諧音，我們撒下藝術、時尚、音樂與科技結合的NFT種子，灌溉養分，期許所有的創作在這片沃土，穩健成長。中文名「所有」，象徵創作者所擁有，一個劃時代、全新的展示渠道，並透過NFT顛覆傳統溯源與認證方式的特性，賦予收藏者對作品獨特的「所有權」。我們將持續拓展NFT「所有」的可能性，納入「所有」優秀的創作，歡迎「所有人」進來一探究竟。",
  "SERVICES_DESC": "非常感謝您的關注，無論您是藏家、創作者、藝廊、公私營藝術機構，請洽<a href='mailto:service@soyl.one'>service@soyl.one</a>我們會針對您的需求，提供點對點的客製化服務。",

  //spotlight
  "ARTIST_PROFILE": "創作自述",
}
export default lang;