import { useState, useContext } from "react";
import { Box, Typography, Select, MenuItem, Alert } from "@mui/material";
import { Axis, Grid, XYChart, BarSeries, LineSeries } from "@visx/xychart";
import moment from "moment";
import { $price } from "../../../components/utils";
import { INftToken, INftTokenPricingHistory } from "../../../models";
import { UiContext } from "../../../context/app";
import { makeStyles } from "@material-ui/styles";
import { ClassNames } from "@emotion/react";

const PERIODS = [0, 7, 14, 30, 60, 90, 365];
const defaultMargin = { top: 30, right: 20, bottom: 30, left: 10 };

const determinePeriodType = (days: number) => {
  if (days === 0) return "ALL";
  if (days >= 365) return "YEAR";
  return "DAYS";
}

const caculateAveragePrice = (records: INftTokenPricingHistory[]) => {
  const priceList = records.map((record: any) => parseFloat($price(record.price)));
  return priceList.reduce((pre, current) => pre + current) / priceList.length;
}

const caculateVolume = (records?: any[]) => {
  // TODO: confirm the logic of volumn
  return 0;
}

const PeriodPriceDetail = (props: any) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography sx={{ color: '#c7c7c7', fontSize: '14px' }}>{props.title}&ensp;</Typography>
    <Typography sx={{ color: '#00fff0', fontWeight: "bold" }}>{`${props.price}`}</Typography>
  </Box >
)

export default ({ nft, allPricing }: { nft: INftToken, allPricing: INftTokenPricingHistory[] }) => {
  const { strings, mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });
  const [historyPeriod, setHistoryPeriod] = useState(0);

  const avgPrice = (allPricing && allPricing.length !== 0) ? caculateAveragePrice(allPricing) : '0';
  const volume = caculateVolume(allPricing);
  const xAccessor = (d: INftTokenPricingHistory) => `${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('M')}/${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('D')}`;
  const yAccessor = (d: INftTokenPricingHistory) => $price(d.price);
  const pricing = PERIODS[historyPeriod] == 0 ? allPricing :
    allPricing.filter(history => {
      return history.timestamp > moment().add(-PERIODS[historyPeriod], 'days').unix()
    });

  let isOpen = false;
  return (
    <Box className={classes.historyBox}>
      <Box className={classes.pricingSelectBox}>
        <Select
          className={classes.select}
          value={historyPeriod}
          onOpen={() => isOpen = true}
          onClose={() => isOpen = false}
          IconComponent={() =>
            <img src='/assets/images/bb/filter-more-green.svg'
              style={{
                position: 'absolute',
                right: '8px',
                transform: isOpen ? 'rotate(180deg)' : 'none',
                pointerEvents: 'none'
              }} />
          }

          onChange={(e) => setHistoryPeriod(Number(e.target.value))}>
          {
            PERIODS.map(period => (
              <MenuItem
                key={period}
                value={period}>
                {strings(`PERIOD_${determinePeriodType(period)}`).replace('[day]', period.toString())}
              </MenuItem>))
          }
        </Select>

        <PeriodPriceDetail
          title={strings(`${determinePeriodType(historyPeriod)}_AVG_PRICE`).replace('[day]', historyPeriod.toString())}
          price={avgPrice} />

        {
          determinePeriodType(historyPeriod) !== "ALL" && <PeriodPriceDetail
            title={strings(`${determinePeriodType(historyPeriod)}_VOLUME`).replace('[day]', historyPeriod.toString())}
            price={volume}
          />
        }
      </Box>

      {/* TODO: 1. dynamic width & height  */}
      {
        !pricing || pricing.length === 0 ?
          <Alert severity="info">There is no records</Alert>
          :
          <XYChart
            height={Math.max(300 - defaultMargin.bottom - defaultMargin.top, 250)}
            xScale={{ type: "band", padding: 0.8 }}
            yScale={{ type: "linear" }} >
            <Axis orientation='left' numTicks={5} />
            <Axis orientation='bottom' numTicks={Math.max(5, pricing.length)} />
            <Grid columns={false} />
            <BarSeries data={pricing} dataKey={"priceBar"} xAccessor={xAccessor} yAccessor={yAccessor} />
            <LineSeries data={pricing} dataKey={"priceLine"} xAccessor={xAccessor} yAccessor={yAccessor} />
          </XYChart>
      }
    </Box>
  );
};

const useStyle = makeStyles({
  historyBox: {
    background: '#131313',
    padding: ({ mobile }: any) => mobile ? '20px 11px 20px 11px' : '31px 30px 31px 30px',
  },
  select: {
    minWidth: '215.5px',
    maxHeight: '42px',
    fontWeight: 'bold',
    fontSize: '16px',
    '& fieldset': {
      border: '3px solid #c7c7c7',
      borderRadius: '8px'
    },
  },
  pricingSelectBox: {
    display: 'flex',
    flexDirection: ({ mobile }: any) => mobile ? 'column' : 'row',
    gap: ({ mobile }: any) => mobile ? '10px' : '32px',
    justifyContent: 'center'
  }
})