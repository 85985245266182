import { useContext, useEffect, useState } from "react";
import {
  Box, Container, Grid,
  ImageList, ImageListItem,
  Link,
} from "@mui/material"
import { makeStyles } from "@material-ui/styles";
import millify from "millify";
import { banners, featuredList, } from "../../../services/market";
import { useAppContext } from "../../../components/hooks";
import { UiContext } from "../../../context/app";
import { IBanner, INftCollection, INftToken, IUser, Summaries } from "../../../models";
import { TitleBar } from "./title-bar";
import { WaterfallCard } from "./card-view-waterfall";
import { CircleCardView } from "./card-view-collection-circle";
import { SquareCardView } from "./card-view-square";
import { $addr, $summary } from "../../../components/utils";

import { Swiper as SwiperList, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Mousewheel, Pagination } from "swiper";

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

interface homeViewProps {
  topCollections?: INftCollection[],
  trendNft?: INftToken[],
}
const shadowColor = ['rgba(249, 45, 94, 0.28)', 'rgba(0, 255, 0, 0.4)', 'rgba(0, 0, 255, 0.28)'];

const WaterfallList = ({ nfts }: { nfts: INftToken[] }) => {
  const { mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });
  return (
    <Box className={classes.waterfallBox}>
      <ImageList
        variant='masonry'
        cols={3}
        gap={30}
        className={classes.waterfall}
        sx={{
          animation: "scrollY 120s infinite linear -5s",
          transform: 'translateY(-100%)',
          "@keyframes scrollY": {
            from: { transform: "translateY(-100%)" },
            to: { transform: "translateY(100%)" },
          },
        }} >
        {
          nfts.length > 0 &&
          nfts.map((nft: INftToken) =>
            <ImageListItem key={nft.id}>
              <WaterfallCard
                image={nft.meta?.image}
                logo={$summary(nft, Summaries.COLLECTION_ICON, '')}
                title={nft?.meta?.name}
                href={`nft/${nft.chain}/${nft.contract}/${nft.tokenId}`}
                randomColor={shadowColor[Math.floor(Math.random() * 3)]} />
            </ImageListItem>
          )
        }
      </ImageList>
      <ImageList
        variant='masonry'
        cols={3}
        gap={30}
        className={classes.waterfall}
        sx={{
          animation: "scrollY2 120s infinite linear 55s",
          opacity: 0,
          transform: 'translateY(-100%)',
          "@keyframes scrollY2": {
            from: { opacity: 1, transform: "translateY(-100%)" },
            to: { opacity: 1, transform: "translateY(100%)" },
          },
        }} >
        {
          nfts.length > 0 &&
          nfts.map((nft: INftToken) =>
            <ImageListItem key={nft.id}>
              <WaterfallCard
                image={nft.meta?.image}
                logo={$summary(nft, Summaries.COLLECTION_ICON, '')}
                title={nft?.meta?.name}
                href={`nft/${nft.chain}/${nft.contract}/${nft.tokenId}`}
                randomColor={shadowColor[Math.floor(Math.random() * 3)]} />
            </ImageListItem>
          )
        }
      </ImageList>
      {
        nfts &&
        nfts.length > 0 &&
        <Box sx={{
          position: 'absolute', bottom: 0, top: 0,
          height: '100%',
          width: '100%',
          boxShadow: 'inset 0 -200px 200px -120px #000',
          pointerEvents: 'none',
        }} />
      }
    </Box>
  )
}



export default ({ }: homeViewProps) => {
  const { mobile } = useContext(UiContext);
  const classes = useStyle({ mobile });
  const context = useAppContext();

  const [waterfallNfts, setWaterfallNfts] = useState<INftToken[]>([]);
  const [advBanners, setAdvBanners] = useState<IBanner[]>([]);
  const [topCollections, setTopCollections] = useState<INftCollection[]>([]);
  const [trendNfts, setTrendNfts] = useState<INftToken[]>([]);
  const [featuredNfts, setFeaturedNfts] = useState<INftToken[]>([]);
  const [featuredProfiles, setFeatureProfiles] = useState<IUser[] | null>([]);

  const reload = async () => {
    try {
      const [nextWaterfallNfts, nextBanners, nextTopCollections, nextTrendNfts, nextFeaturedNfts, nextProfiles]
        = await Promise.all([
          featuredList(context, 'WATERFALL_NFTS'),
          banners(context, 'HOME_BANNER'),
          featuredList(context, 'TOP_COLLECTIONS'),
          featuredList(context, 'TRENDING_AUCTIONS'),
          featuredList(context, 'FEATURED_NFTS'),
          featuredList(context, 'FEATURED_PROFILES'),
        ])
      setWaterfallNfts(nextWaterfallNfts as INftToken[]);
      setAdvBanners(nextBanners);
      setTopCollections(nextTopCollections);
      setTrendNfts(nextTrendNfts as INftToken[]);
      setFeaturedNfts(nextFeaturedNfts as INftToken[]);
      setFeatureProfiles(nextProfiles as IUser[]);

    } catch (error: any) {
      console.error(error)
    }
  }

  const BannerList = ({ banners }: { banners: IBanner[] }) => {
    return (
      <SwiperList
        pagination
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        spaceBetween={90}
        autoHeight
        loop={banners.length > 1}
        autoplay={banners.length > 1 ? ({ delay: 4000, disableOnInteraction: true }) : false}
        className={classes.banners} >
        {
          banners.map(banner =>
            <SwiperSlide key={banner.id} >
              <Link className={classes.bannerBox} href={banner.link || '/#'}>
                <img src={banner.image} className={classes.bannerImg} />
              </Link>
            </SwiperSlide>
          )
        }
      </SwiperList >
    )
  }

  useEffect(() => {
    reload();
  }, [])

  return (
    <Box>
      <Container style={{ maxWidth: mobile ? '100vw' : '1440px' }} disableGutters={mobile}>
        <WaterfallList nfts={waterfallNfts} />
        <BannerList banners={advBanners} />
      </Container >

      <Container style={{ maxWidth: mobile ? '100vw' : '1440px' }}>
        {
          topCollections.length > 0 &&
          <TitleBar
            title={'TOP COLLECTION'}
            onClickAll={() => { }}
            setPeriod={value => { }}
            setCurrency={value => { }}>
            <Box sx={{ position: 'relative' }}>
              <SwiperList
                mousewheel
                modules={[Mousewheel]}
                slidesPerView={'auto'}
                spaceBetween={mobile ? 30 : 52}>
                {
                  topCollections.map((collection, index) =>
                    <SwiperSlide key={collection.id} >
                      <CircleCardView collection={collection} index={index + 1} key={collection.id} />
                    </SwiperSlide>
                  )

                }
              </SwiperList>
              <Box className={classes.topCollectionShadow} />
            </Box>
          </TitleBar>
        }
        {
          trendNfts.length > 0 &&
          <TitleBar
            title={'TRENDING AUCTIONS'}
            loadMoreHref={'/#'}
            onClickAll={() => { }}
            setPeriod={value => { }}
            setCurrency={value => { }}>
            <Box>
              <Grid container rowSpacing={mobile ? '0px' : '30px'} columnSpacing={mobile ? '15px' : '38px'} >
                {
                  trendNfts.map((nft: INftToken) =>
                    <Grid item xs={6} sm={6} md={4} key={nft.id} >
                      <SquareCardView
                        target={nft.id}
                        type={'nft'}
                        randomColor={shadowColor[Math.floor(Math.random() * 3)]}
                        href={`nft/${nft.chain}/${nft.contract}/${nft.tokenId}`}
                        image={nft?.meta?.image}
                        animation={nft?.meta?.animation_url}
                        logo={$summary(nft, Summaries.COLLECTION_ICON, '')}
                        title={nft?.meta?.name}
                        favorites={millify($summary(nft, Summaries.FAVORITED, 0), { precision: 2 })}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Box>
          </TitleBar>
        }
        {
          featuredNfts.length > 0 &&
          <TitleBar
            title={'FEATURED NFTS'}
            loadMoreHref={'/#'}
            onClickAll={() => { }}
            setPeriod={value => { }}
            setCurrency={value => { }}>
            <Box>
              <Grid container rowSpacing={mobile ? '24px' : '30px'} columnSpacing={mobile ? '15px' : '38px'} >
                {
                  featuredNfts.map((nft: INftToken) =>
                    <Grid item xs={6} sm={6} md={4} key={nft.id} >
                      <SquareCardView
                        target={nft.id}
                        type={'nft'}
                        randomColor={shadowColor[Math.floor(Math.random() * 3)]}
                        href={`nft/${nft.chain}/${nft.contract}/${nft.tokenId}`}
                        image={nft?.meta?.image}
                        animation={nft?.meta?.animation_url}
                        logo={$summary(nft, Summaries.COLLECTION_ICON, '')}
                        title={nft?.meta?.name}
                        favorites={millify($summary(nft, Summaries.FAVORITED, 0), { precision: 2 })}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Box>
          </TitleBar>
        }
        {
          featuredProfiles &&
          featuredProfiles.length > 0 &&
          <TitleBar
            title={'FEATURED PROFILES'}
            onClickAll={() => { }}
            setPeriod={() => { }}
            setCurrency={() => { }}>
            <Box>
              <Grid container rowSpacing={mobile ? '0px' : '30px'} columnSpacing={mobile ? '15px' : '38px'} >
                {
                  featuredProfiles.map((profile: IUser) =>
                    <Grid item xs={6} sm={6} md={3} key={profile.address} >
                      <SquareCardView
                        target={profile.address}
                        type={'profile'}
                        href={`profile/${profile.address}`}
                        image={profile?.image}
                        logo={'/assets/images/bb/ic-person.svg'}
                        title={profile?.name || $addr(profile.address)}
                        favorites={millify($summary(profile, Summaries.FAVORITED, 0), { precision: 2 })}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Box>
          </TitleBar>
        }
      </Container >
    </Box >

  )
};

const useStyle = makeStyles({
  waterfallBox: {
    position: 'relative',
    height: ({ mobile }) => mobile ? '400px' : '620px',
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
      '& > *': { animationPlayState: 'paused' }
    }
  },
  waterfall: {
    overflowY: 'hidden',
    paddingRight: '7px',
    position: "absolute",
    width: ({ mobile }) => mobile ? '200%' : '',
    left: ({ mobile }) => mobile ? 'calc(-50% + 7px)' : '0',
  },
  banners: {
    padding: ({ mobile }: any) => mobile ? '20px 16px 20px 16px' : '20px 0px 32px 0px',
    '& .swiper-pagination-bullets': {
      bottom: '0px',
    },
    '& .swiper-pagination-bullet': {
      width: '24.5px',
      height: '4px',
      borderRadius: '0',
      background: 'rgba(255, 255, 255, 0.6)',
      '&-active': {
        background: '#fff'
      },
    }
  },
  bannerBox: {
    height: ({ mobile }: any) => mobile ? '27vw' : '255px',
    maxWidth: 'calc(100% - 5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerImg: {
    height: ({ mobile }: any) => mobile ? '27vw' : '255px',
    width: '100%',
    objectFit: 'cover',
    boxShadow: '5px -5px rgba(255, 0, 0, 0.4)',
    borderRadius: '12px',
  },
  topCollectionBox: {
    display: 'flex',
    gap: ({ mobile }: any) => mobile ? '38px' : '52px',
    overflow: 'scroll',
  },
  topCollectionShadow: {
    position: 'absolute',
    top: 0, bottom: 0,
    height: '100%',
    width: '100%',
    boxShadow: 'inset -100px 0 100px -100px #000',
    zIndex: 1,
    pointerEvents: 'none',
  }

})