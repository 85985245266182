import { Container } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

export default ({ data }: any) => {
  const classes = useStyles();
  return (
    <Container maxWidth='md'>

    </Container>
  )
}
const useStyles = makeStyles({
})