import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../components/hooks';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Container, Stack, Grid,
  Typography,
  Tabs, Tab, Alert, Snackbar,
} from '@mui/material';
import {
  Ballot,
  ExpandMore,
  SwapVert,
  Toc, Timeline, LocalOffer, PriceChangeOutlined, ListAlt,
} from '@mui/icons-material';
import { PageBase } from '../../components/page-base';
import { INftToken, Chains } from '../../models';
import { availableTokenAmount, expectedMaxSupply, nftId, nftInfo, onSaleAmount, subscribeNftInfo } from '../../services/market';
import { UiContext } from '../../context/app';

import { NftToolbarCard } from './card-nft-toolbar';
import { NftViewerCard } from './card-nft-viewer';
import { NftTitleCard } from './card-nft-title';
import { NftAuctionCard } from './card-nft-auction';
import { NftDetailCard } from './card-nft-detail';
import { NftActivityCard } from './card-nft-activity';
import { NftOfferCard } from './card-nft-offer';
import { NftPricingCard } from './card-nft-pricing';
import { NftListingCard } from './card-nft-listing';
import { createSpacings } from './spacings';
import { makeStyles } from '@material-ui/styles';
import { BBIcon } from '../../assets/icons/bb';

const {
  DETAIL_CONTAINER_MT,
  DETAIL_OWNCONTAINER_MT,
  DETAIL_COL_SPACING,
  DETAIL_ROW_SPACING
} = createSpacings();

const TABS = {
  LISTINGS: 'LISTINGS',
  OFFERS: 'OFFERS',
  PRICE_HISTORY: 'PRICE_HISTORY',
  DETAILS: 'DETAILS',
  ITEM_ACTIVITY: 'ITEM_ACTIVITY'
};
// const expandIcon= <BBIcon icon='ic_drop_open' size={40} color='#787878'/> //bb
const expandIcon = <ExpandMore />

const ExpandSet = ({ disableGutters, defaultExpanded, icon, title, children, detailsSx }: any) => {
  const { mobile } = useContext(UiContext);
  const classes = useStyles({})
  return (
    <Accordion className={classes.accordion} disableGutters={disableGutters || false} defaultExpanded={defaultExpanded || false} >
      <AccordionSummary className={classes.summary} expandIcon={expandIcon}>
        <Stack direction='row' spacing={1}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }} >
            {icon}
          </Typography>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }} >
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className={classes.details} sx={{ p: 0, ...detailsSx }}>
        {children}
      </AccordionDetails>
    </Accordion >)
}

const tabLable = (title: string) => <Typography variant='subtitle2'>{title}</Typography>

export const PageNftDetail = () => {
  const context = useAppContext();
  const { wallet } = context;
  const { contract, tokenId, chainId } = useParams();
  const { strings, mobile } = useContext(UiContext);

  const [error, setError] = useState<any | null>(null);
  const [selectedTab, setSelectedTab] = useState(TABS.ITEM_ACTIVITY);
  const [nft, setNft] = useState<INftToken>({});
  const [availableAmount, setAvailableAmount] = useState<number | undefined>(undefined);
  const [onSaleTokens, setOnSaleTokens] = useState<number | undefined>(undefined);

  const loading = useMemo(() => Object.keys(nft).length == 0, [nft]);

  useEffect(() => {
    if (!tokenChainId || !contract || !tokenId) return;
    const subscription = subscribeNftInfo(
      context, { chain: tokenChainId, contract, tokenId, onResult: setNft }
    );
    return () => {
      return subscription.unsubscribe();
    };
  }, [wallet.account, wallet.chainId, setNft]);
  useEffect(() => {
    if (wallet.account && nft) {
      availableTokenAmount(context, wallet.account, nft)
        .then(setAvailableAmount)
        .catch(console.error);
      onSaleAmount(context, wallet.account, nft)
        .then(setOnSaleTokens)
        .catch(console.error);
    }
  }, [nft])

  const tokenChainId = chainId ? parseInt(chainId) : undefined;
  const tokenChain = tokenChainId ? strings(Chains[tokenChainId]) : '';
  const expectMaxSupply = useMemo(() => expectedMaxSupply(nft?.mintSig, nft.chain), [nft]);
  const isOwner = (wallet.account && (nft.owners?.includes(wallet.account) || nft.owner === wallet.account));
  const MT = (() => {
    if (mobile)
      return isOwner ? '67px' : '10px';
    return (isOwner && !mobile ? DETAIL_OWNCONTAINER_MT : DETAIL_CONTAINER_MT);
  })();


  const hint = (
    <Snackbar open={wallet.chainId !== tokenChainId} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert severity="warning" variant='filled' sx={{ py: 0, mt: mobile ? '51px' : '36px' }}>
        {strings('DETAIL_DIFF_CHAIN').replace('[NFT_CHAIN]', tokenChain)}
      </Alert>
    </Snackbar>
  )
  const desktopInfoCards = (
    <>
      <Tabs
        value={selectedTab}
        sx={{ borderBottom: '1px solid silver', mb: 1 }}
        onChange={(event: any, value: any) => setSelectedTab(value)}
        centered>
        <Tab label={tabLable("Listings")} value={TABS.LISTINGS} iconPosition='start' icon={<LocalOffer />} />
        <Tab label={tabLable("Offers")} value={TABS.OFFERS} iconPosition='start' icon={<PriceChangeOutlined />} />
        <Tab label={tabLable("Price History")} value={TABS.PRICE_HISTORY} iconPosition='start' icon={<BBIcon icon='ic_monitoring' size={18} />} />
        <Tab label={tabLable("Details")} value={TABS.DETAILS} iconPosition='start' icon={<ListAlt />} />
        <Tab label={tabLable("Item Activity")} value={TABS.ITEM_ACTIVITY} iconPosition='start' icon={<SwapVert />} />
      </Tabs>
      {selectedTab === TABS.LISTINGS && <NftListingCard nft={nft} />}
      {selectedTab === TABS.OFFERS && <NftOfferCard nft={nft} />}
      {selectedTab === TABS.PRICE_HISTORY && <NftPricingCard nft={nft} />}
      {selectedTab === TABS.DETAILS && <NftDetailCard nft={nft} />}
      {selectedTab === TABS.ITEM_ACTIVITY && <NftActivityCard nft={nft} />}
    </>
  )
  const mobileInfoCards = (
    <Stack spacing={DETAIL_ROW_SPACING}>
      <ExpandSet
        title={strings('LISTINGS')}
        icon={<LocalOffer />}
        disableGutters
        defaultExpanded>
        <NftListingCard nft={nft} />
      </ExpandSet>
      <ExpandSet
        title={strings('OFFERS')}
        icon={<PriceChangeOutlined />}
        disableGutters>
        <NftOfferCard nft={nft} />
      </ExpandSet>
      <ExpandSet
        title={strings('PRICE_HISTORY_TITLE')}
        icon={<BBIcon icon='ic_monitoring' size={18} />}
        disableGutters
        detailsSx={{ borderTop: '1px solid #5c5c5c' }}>
        <NftPricingCard nft={nft} />
      </ExpandSet>
      <ExpandSet
        title={strings('DETAILS')}
        icon={<ListAlt />}
        disableGutters
        detailsSx={{ borderTop: '1px solid #5c5c5c' }}>
        <NftDetailCard nft={nft} />
      </ExpandSet>
      <ExpandSet
        title={strings('ITEM_ACTIVITY')}
        icon={<SwapVert />}
        disableGutters
        defaultExpanded>
        <NftActivityCard nft={nft} />
      </ExpandSet>
    </Stack >
  )
  return (
    <PageBase
      loading={loading}
      error={error}
      nft={nft}
      availableAmount={availableAmount}
      onSaleTokens={onSaleTokens}
      expectMaxSupply={expectMaxSupply}>

      {hint}

      {
        isOwner && <NftToolbarCard
          nft={nft}
          onSaleTokens={onSaleTokens}
          availableAmount={availableAmount}/>
      }
      <Container sx={{ marginTop: MT, py: mobile ? 0 : DETAIL_ROW_SPACING }}>
        <Grid container columnSpacing={DETAIL_COL_SPACING} rowSpacing={DETAIL_ROW_SPACING} >
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <NftViewerCard nft={nft} />
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7}>
            <NftTitleCard nft={nft} />
            <NftAuctionCard nft={nft} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} >
            {mobile ? mobileInfoCards : desktopInfoCards}
          </Grid>
        </Grid>
      </Container>
    </PageBase>
  )
};

const useStyles = makeStyles({
  accordion: {
    // background: '#131313',         //bb
    // border: '1px solid #5c5c5c',   //bb
    borderRadius: '8px',
    // boxShadow: 'none',             //bb
    '&:before': {
      display: 'none',
    },
    '&:first-child': {
      // boxShadow: 'none',           //bb
      // border: '1px solid #5c5c5c', //bb
      borderRadius: '8px',
    },
    '&:last-child': {
      // boxShadow: 'none',           //bb
      // border: '1px solid #5c5c5c', //bb
      borderRadius: '8px',
    },
  },
  summary: {
    background: 'transparent',
    padding: '0px 12px 0px 12px',
    height: '68px',
    '&.Mui-expanded': {
      background: 'transparent',
    },
  },
  details: {
    background: 'transparent',
    '& > *': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    }
  }
})
