import { useContext } from "react";
import { SxProps, Box, Stack, Typography, useTheme, Divider } from "@mui/material";
import moment from "moment";
import Web3 from "web3";
import { AccessTime } from "@mui/icons-material";
import { Countdowner } from "../../../components/countdowner";
import { useAppContext } from "../../../components/hooks";
import { $price, sellButtonVisible } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftToken, SellStates, SellMethods } from "../../../models";
import { BuyButton, BidButton } from "../../../pages/page-nft-bid";
import { stateOf, convertEth } from "../../../services/market";
import { makeStyles } from "@material-ui/styles";

interface ActionCardProps {
  nft: INftToken,
  reload: () => void,
  sx?: SxProps
};

const timingOf = (state: SellStates | undefined, begin: number, end: number) => {
  if (!state) return '';
  let timing = '';
  switch (state) {
    case SellStates.COMMING:
      timing = moment.unix(begin).fromNow();
      break;
    case SellStates.SELLING:
      timing = moment.unix(end).fromNow();
      break;
    case SellStates.ENDING:
      timing = 'ending';
      break;
    default:
      timing = '';
      break;
  }
  return timing;
}


const AuctionBlock = ({ title, children, classes }: any) =>
  <Box className={classes.auctionBlock}>
    <Typography variant="subtitle2" color={'#c7c7c7'} className={classes.auctionTitle}>
      {title}
    </Typography>
    {children}
  </Box>

export default (props: ActionCardProps) => {
  const { nft, reload, sx } = props;
  const { strings, mobile } = useContext(UiContext);
  const { wallet } = useAppContext();
  const theme = useTheme();
  const classes = useStyle({ theme, mobile });
  const state = stateOf(nft);

  let price: string | undefined;
  let timing: string | undefined;

  if (nft.saleOptions) {
    price = $price(nft.saleOptions?.price || '0', 4);
    timing = timingOf(
      state,
      nft.saleOptions.duration?.begin || 0,
      nft.saleOptions.duration?.end || 0
    );
  }
  const buttonSelect = (
    nft.owner !== wallet.account &&
    <Box className={classes.buttonArea}>
      {
        sellButtonVisible(nft, wallet.account) &&
        <BuyButton
          buttonProps={{
            variant: 'outlined',
            fullWidth: true,
            className: classes.auctionButton
          }}
          nft={nft}>
          {strings('BUY_NOW')}
        </BuyButton>
      }

      {
        sellButtonVisible(nft, wallet.account, "BID") &&
        <BidButton
          buttonProps={{
            variant: 'outlined',
            fullWidth: true,
            className: classes.auctionButton
          }}
          nft={nft}>
          {strings('PLACE_BID')}
        </BidButton>
      }
      {
        state === SellStates.COMMING &&
        <Box className={classes.comming}>
          <AccessTime fontSize='small' /> &nbsp;
          <Typography className={classes.auctionButton}>
            {
              nft.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER ?
                strings('BIDDING_SOON') :
                strings('OPENING_SOON')
            }
          </Typography>
        </Box>
      }
    </Box>
  )



  if (!nft.saleOptions || !price) return <></>;

  return (
    <Box sx={{ flexGrow: 1, ...sx, maxWidth: mobile ? '100%' : 'fit-content' }}>
      {
        price &&
        <AuctionBlock classes={classes} title={strings("PRICE")}>
          <Stack direction='row' spacing={'9px'} className={classes.priceArea} >
            <img src='/assets/images/bb/ic-ethereum.svg' />
            <Typography variant='h4'>{price}</Typography>
            <Typography variant="subtitle2" alignSelf='flex-end' >{`($${convertEth(Web3.utils.toWei(price))})`}</Typography>
          </Stack>
          <Divider className={classes.auctionDivider} />

          {
            !!nft.saleOptions &&
            (state == SellStates.COMMING || state == SellStates.SELLING) &&
            <Box className={classes.countDownerArea}>
              <Typography variant="subtitle2" className={classes.saleTitle}>
                {state == SellStates.COMMING ? strings('SALE_START_AT') : strings('SALE_ENDS')
                }
              </Typography>
              <Countdowner
                targetTime={
                  state == SellStates.COMMING ?
                    nft.saleOptions.duration?.begin || 0 :
                    nft.saleOptions.duration?.end || 0
                }
                stackProps={{
                  className: classes.countDowner,
                  spacing: '35px'
                }}
              />
            </Box>
          }
          {buttonSelect}
        </AuctionBlock>
      }
    </Box >
  )
};

const useStyle = makeStyles({
  auctionBlock: {
    minWidth: '368px',
    padding: '30px 10px 0 10px',
    paddingBottom: ({ mobile }: any) => mobile ? '10px' : '24px',
    borderRadius: ({ mobile }: any) => mobile ? '8px' : '30px',
    background: ({ theme }: any) => theme.palette.secondary.main,
  },
  auctionTitle: {
    padding: '0 20px 0 22px',
    display: ({ mobile }: any) => mobile ? 'none' : 'inline',
    fontWeight: 'normal',
  },
  buttonArea: {
    margin: ({ mobile }: any) => mobile ? '20px 0 0 0' : '30px 20px 0px 22px'
  },
  auctionButton: {
    fontSize: '18px',
    fontWeight: 'bold',
    maxHeight: '36px',
    width: '100%',
    borderRadius: ({ mobile }: any) => mobile && '8px',
  },
  priceArea: {
    padding: '0 20px 0 22px'
  },
  countDownerArea: {
    marginBottom: ({ mobile }: any) => mobile ? '20px' : '21px',
  },
  saleTitle: {
    padding: '0 20px 0 22px',
    margin: '20px 0 10px 0',
    fontWeight: 'normal',
  },
  countDowner: {
    border: 0,
    padding: '0 20px 0 22px'
  },
  auctionDivider: {
    borderColor: '#000',
    margin: ({ mobile }) => mobile ? '20px -10px 0 -10px' : '14px -10px 0 -10px'
  },
  comming: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '7px'
  },

})