import { useRef, useContext, useState, useEffect } from "react";
import {
  Box,
  Card, CardMedia, CardContent, CardActions, CardActionArea,
  Typography,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import { Countdowner } from "../../../components/countdowner";
import { useAppContext } from "../../../components/hooks";
import { NamedUser } from "../../../components/named-user";
import { Column, Row } from "../../../components/page-base";
import { $addr, $ipfs, $price } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Summaries, SellMethods, SellStates, INftToken } from "../../../models";
import { stateOf } from "../../../services/market";
import moment from "moment";

const stringOf = (obj: any, key: string): string | undefined => {
  if (!obj) return undefined;
  if (key in obj && typeof obj[key] == 'string') return obj[key];
  return undefined;
}
const $tokenId = (tokenId: string): string | undefined => {
  if (!tokenId) return undefined;
  return (tokenId.length > 5 ? '...' : '') + tokenId.substring(tokenId.length - 5);
}

const timingOf = (state: SellStates | undefined, begin: number, end: number) => {
  if (!state) return '';
  let timing = '';
  switch (state) {
    case SellStates.COMMING:
      timing = moment.unix(begin).fromNow();
      break;
    case SellStates.SELLING:
      timing = moment.unix(end).fromNow();
      break;
    case SellStates.ENDING:
      timing = 'ending';
      break;
    default:
      timing = '';
      break;
  }
  return timing;
}
interface NftCardProps {
  nft: INftToken,
  showCountdown?: boolean,
}
export default (props: NftCardProps) => {
  const { nft } = props;
  const { strings, mobile } = useContext(UiContext);
  const { wallet } = useAppContext();
  const cardRef = useRef<any>();
  const [cardMediaWidth, setCardMediaWidth] = useState();
  const classes = useStyles({ mobile, cardMediaWidth });

  const state = stateOf(nft);
  let collectionName: string = '';
  let tokenName: string = '';
  let collectionOwner: string | undefined;
  let tokenId: string | undefined = '';
  let tokenStatusHint: any | undefined;
  let timing: string | undefined;
  let action: string | undefined = strings("VIEW");
  let href: string = '#';
  let isMine: boolean = nft.owner == wallet.account;

  const saleString =
    state === SellStates.COMMING ?
      strings('SALE_START_AT')
      : strings('SALE_ENDS');
  const targetTime =
    state === SellStates.COMMING ?
      nft.saleOptions?.duration?.begin || 0
      : nft.saleOptions?.duration?.end || 0;

  if (!!nft.contract && !!nft.tokenId) {
    href = `/nft/${nft.chain}/${nft.contract}/${nft.tokenId}`;
    tokenId = nft.tokenId;
  }

  if (nft.summaries) {
    collectionName =
      stringOf(nft.summaries, Summaries.COLLECTION_NAME) ||
      $addr(nft.contract);
    collectionOwner =
      stringOf(nft.summaries, Summaries.COLLECTION_OWNER);
  }

  if (nft.meta) {
    tokenName = nft.meta?.name || '';
  }

  if (nft.chain && nft.contract && nft.tokenId)
    href = `/nft/${nft.chain}/${nft.contract}/${nft.tokenId}`;

  if (!!nft.saleOptions) {
    switch (nft.saleOptions?.method) {
      case SellMethods.FIXED_PRICE:
      case SellMethods.SELL_WITH_DECLINING_PRICE: {
        action = isMine ? strings('VIEW') : strings("BUY_NOW");
        tokenStatusHint =
          <Row alignItems='center'>
            <img src='/assets/images/bb/ic-ethereum.svg' className={classes.ethImg} />
            &nbsp;
            <Typography variant='subtitle2'> {$price(nft.saleOptions.price || '0', 4)}</Typography>
          </Row>;
        timing = timingOf(
          state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.SELL_TO_HIGHEST_BIDDER: {
        action = isMine ? strings('VIEW') : strings("PLACE_BID");
        tokenStatusHint =
          <Row alignItems='center'>
            {strings('CARD_TOP_BID')}
            &nbsp;
            <img src='/assets/images/bb/ic-ethereum.svg' className={classes.ethImg} />
            &nbsp;
            <Typography variant='subtitle2'> {$price(nft.saleOptions.price || '0', 4)}</Typography>
          </Row>;
        timing = timingOf(state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.NOT_FOR_SELL:
      default:
        action = strings("VIEW");
        tokenStatusHint = undefined;
        timing = undefined;
        break;
    }
  }
  const videoPlayer = (event: any, state?: string) => {
    let playPromise = event.target.play();
    if (playPromise !== undefined && state === 'play')
      playPromise.then(() => { }).catch((error: any) => { })
    if (playPromise && state === 'pause')
      playPromise.then(() => { event.target.pause() }).catch((error: any) => { })
  }

  useEffect(() => {
    setCardMediaWidth(cardRef?.current?.clientWidth)
    window.addEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth))
    return () => window.removeEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth));
  }, [])
  return (
    <Card ref={cardRef} className={classes.card}>
      <CardActionArea href={href} className={classes.cardActionArea}>
        <CardMedia
          component='video'
          className={classes.cardMedia}
          src={$ipfs(props.nft.meta?.animation_url)}
          poster={$ipfs(props.nft.meta?.image)}
          onClick={(event: any) => videoPlayer(event, 'play')}
          onMouseEnter={(event: any) => videoPlayer(event, 'play')}
          onMouseLeave={(event: any) => videoPlayer(event, 'pause')}
          loop
        />
        <CardContent className={classes.cardContent}>

          <Column className={classes.column}>

            <Typography className={classes.collectionName}>
              {collectionName}
            </Typography>
            {
              (!!tokenName || !!tokenId) &&
              <Row className={classes.row} sx={{ alignItems: 'right' }}>
                {
                  !!tokenName &&
                  <Typography variant='subtitle2' className={classes.tokenName}>
                    {tokenName}
                  </Typography>
                }
                {
                  tokenId &&
                  <Tooltip title={'#' + tokenId} arrow placement='top'>
                    <Typography variant='subtitle2'>{`#${$tokenId(tokenId)}`}</Typography>
                  </Tooltip>
                }
              </Row>
            }
            <Divider className={classes.dividerStyles} />
            {
              (!!tokenStatusHint || !!timing) &&
              <Row className={classes.row}>
                <Box>{tokenStatusHint}</Box>
              </Row>
            }
          </Column>

          {
            props.showCountdown &&
            (state === SellStates.COMMING || state === SellStates.SELLING) &&
            <>
              <Divider className={classes.divider} />
              <Box>
                <Typography>{saleString}</Typography>
                <Countdowner targetTime={targetTime} />
              </Box>
            </>
          }
        </CardContent>
      </CardActionArea>

      {
        !!action && !!href &&
        <CardActions className={classes.cardAction}>
          <Typography variant="subtitle2">{timing}</Typography>
          <Button
            variant='outlined'
            className={classes.button}
            href={href}>
            {action}
          </Button>
        </CardActions>
      }
    </Card>
  )
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 'none',
    background: 'transparent',
  },
  cardActionArea: { flex: 1 },
  cardMedia: {
    objectFit: 'cover',
    height: (props: any) => props.cardMediaWidth && props.cardMediaWidth * 1,
    width: '100%',
    borderRadius: '10px',
  },
  cardContent: {
    flex: 1,
    height: '100%',
    marginTop: 'auto',
    marginBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'left',
    background: 'transparent',
  },
  secondLineRow: {
    justifyContent: 'space-between',
  },
  dividerStyles: {
    borderColor: '#616161',
    margin: '10px 0 10px 0',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    paddingRight: 0,
    paddingLeft: 0,
    background: 'transparent',
  },
  button: {
    px: 4,
    padding: '0px 16px 0px 16px'
  },
  collectionName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(255, 255, 255, 0.8)'
  },
  row: {
    justifyContent: 'space-between',
  },
  tokenName: {
    verticalAlign: 'bottom'
  },
  column: { flex: 1 },
  divider: { my: (props: any) => props.mobile ? '4px' : 1 },
  ethImg: { height: '18.2px' }
});