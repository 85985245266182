import firebase from "firebase/compat";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";

import { BookStates, IContext, INftToken, ISellConfigs } from "../../../models";
import { NftContractInfo, SubscriptionCallback, UnSubscriber, _getNftCollectionSummary } from ".";
import { nftId } from "../func-pure";
  
export const subscribeNftInfo = (
  context: IContext, 
  options: NftContractInfo & SubscriptionCallback & any
):UnSubscriber => {
  const { chain, contract, tokenId, onResult } = options;
  const target = nftId(chain, contract, tokenId);

  firebase
    .functions()
    .httpsCallable("syncNft")({chain, contract, tokenId})
    .catch(console.error);

  const db = getFirestore();
  const data:any = {
    colSummaries: null,
    nft: null,
    sell: null
  };

  const exportToken = ():INftToken => ({
      ...data.nft,
      saleOptions: data.sell?.bookState === BookStates.BOOKED ?
        data.sell : undefined,
      summaries: {
        ...data.nft.summaries, 
        ...data.colSummaries
      }
    });

  const fillCollectionInfo = async (token:INftToken) => {
    if(!!data.colSummaries) return;
    data.colSummaries = await _getNftCollectionSummary(token);
  }

  const unsubNft = onSnapshot(
    doc(db, 'nfts', target),
    async (nftDoc) => {
      const token = nftDoc.data() as INftToken;        
      
      if(!token) return;
      data.nft = token;

      if(!data.colSummaries) {
        await fillCollectionInfo(token);
      }
      
      onResult(exportToken());
    }
  )

  const unsubSell = onSnapshot(
    doc(db, 'sells', target),
    (sellDoc) => {
      data.sell = sellDoc.data() as ISellConfigs;

      if(data.nft != null)
        onResult(exportToken());
    }
  )

  return {
    unsubscribe: () => {
      unsubNft();
      unsubSell();
      data.nft = null;
      data.sell = null;
    }
  };
}
