import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Container, Grid,
  List, ListItem, ListItemText,
  Card, CardMedia, CardActions, CardContent, Typography, Select, MenuItem,
} from "@mui/material";
import { PageBase } from '../../components/page-base';
import { useAppContext } from '../../components/hooks';
import {
  Chains,
  INftCollection, INftToken,
  SellMethods, ISellOptions, IOfferOptions, IBidOptions,
  Summaries,
  ICoin,
} from '../../models';
import {
  bookForSell,
  makeOffer,
  makeBid,
  acceptOffer,
  collectionId,
  collectionInfo,
  collectionDeploy,
  listNfts,
  nftMint,
  uploadImage,
  purchase,
  listCoins,
  cancelBook
} from '../../services/market';
import Web3 from 'web3';
import moment from 'moment';

const strings = {
  "NOT_FOR_SELL": "未銷售",
  "FIXED_PRICE": "固定價格",
  "SELL_TO_HIGHEST_BIDDER": "價高者得",
  "SELL_WITH_DECLINING_PRICE": "期間遞減",
}

export const PageTestMarket = () => {
  const context = useAppContext();
  const { wallet } = context;
  const [collection, setCollection] = useState<INftCollection | undefined>();
  const [tokens, setTokens] = useState<INftToken[] | undefined>();
  const [image, setImage] = useState<File | undefined>();
  const [currencies, setCurrencies] = useState<ICoin[]>([]);
  const [currency, setCurrency] = useState<ICoin>();

  const reload = useCallback(async () => {
    try {
      if (!collection) return;

      const nfts = await listNfts(
        context, { contract: collection.address });

      if (nfts) setTokens(nfts.items);

    } catch (err: any) {
      console.error(err.message);
      alert(err.message);
    }
  }, [context, collection, setTokens]);

  const connectWallet = useCallback(() => {
    wallet.connect('injected');
  }, [wallet]);

  const deployContract = useCallback(async () => {
    try {

      const col = await collectionDeploy(context, {
        chain: wallet.chainId as Chains,
        tags: wallet.chainId ? [wallet.chainId?.toString()] : [],
        category: 'ART',
        state: 'NEW',
        timestamp: moment().unix(),
        meta: {
          name: "NftDemo",
          description: "This is a demo contract deployed by nft-market."
        }
      });

      if (!col) return;

      setCollection(col);
    } catch (err: any) {
      alert(err.message);
    }
  }, [context, setCollection]);

  const selectContract = useCallback(async () => {
    try {

      const address = prompt("Enter contract address");
      if (!address) return;

      const id = collectionId(wallet.chainId as Chains, address);

      const col = await collectionInfo(context, id);

      if (!col) throw new Error("Contract not found");

      const nfts = await listNfts(context, { contract: col.address });

      setCollection(col);
      if (nfts) setTokens(nfts.items);

    } catch (err: any) {
      console.error(err.message);
      alert(err.message);
    }
  }, [context, setCollection]);

  const mint = useCallback(async () => {
    if (!collection) return;

    try {

      const imageUrl = !!image ? await uploadImage(context, image) : "";

      const token = await nftMint(context, {
        chain: collection.chain,
        contract: collection.address,
        tags: wallet.chainId ? [wallet.chainId?.toString()] : [],
        category: 'ART',
        state: 'NEW',
        timestamp: moment().unix(),
        meta: {
          name: "NftDemoToken",
          description: "This is a demo token from testing",
          image: imageUrl
        }
      });

      if (!token) return;

      const newTokens = [];

      if (tokens)
        newTokens.push(...tokens);

      newTokens.push(token);

      setImage(undefined);
      setTokens(newTokens);

      alert(`Token #${token.tokenId} was minted!`);

    } catch (err: any) {
      alert(err.message);
    }

  }, [context, collection, image, tokens, setTokens, setImage]);

  const book = useCallback(async (options: ISellOptions) => {
    try {
      await bookForSell(context, options);
      await reload();
      alert('Item booked!');
    } catch (err: any) {
      console.error(err);
      console.info(options);
      alert(err.message);
    }
  }, [context]);

  const offer = useCallback(async (options: IOfferOptions) => {
    try {
      await makeOffer(context, options);
      await reload();
      alert('Offer delivered!');
    } catch (err: any) {
      alert(err.message);
    }
  }, [context]);

  const bid = useCallback(async (options: IBidOptions) => {
    try {
      await makeBid(context, options);
      await reload();
      alert('Bidded!')
    } catch (err: any) {
      alert(err.message);
    }
  }, [context]);

  const buy = useCallback(async (options: { nftDocId: string, bookDocId: string }) => {
    try {
      await purchase(context, options);
      await reload();
      alert('You have the item now!')
    } catch (err: any) {
      alert(err.message);
    }
  }, [context]);

  const accept = useCallback(async (options: { target: string }) => {
    try {
      await acceptOffer(context, options.target);
      await reload();
      alert('Item booked!')
    } catch (err: any) {
      alert(err.message);
    }
  }, [context]);

  const cancel = useCallback(async (options: { target: string }) => {
    try {
      await cancelBook(context, options.target);
      await reload();
      alert('Item canceled!')
    } catch (err: any) {
      alert(err.message);
    }
  }, [context])

  useEffect(() => {
    if (!wallet.chainId) return;
    var cs: ICoin[] = [];
    listCoins(wallet.chainId)
      .then(rs => { cs = rs; return rs; })
      .then(setCurrencies)
      .then(() => setCurrency(cs[0]))
      .catch(console.error);
  }, [wallet.chainId]);

  return (
    <PageBase>
      <Container>

        <Grid container columnGap={2}>

          <Grid item md={4} xs={12}>

            <Box component="form">

              <Button
                onClick={connectWallet}
                sx={{ m: 1 }}
                disabled={wallet.isConnected()}
                variant='contained'
                fullWidth>Connect Wallet</Button>

              <Button
                onClick={deployContract}
                sx={{ m: 1 }}
                disabled={!wallet.isConnected() || collection != null}
                variant='contained'
                fullWidth>Deploy Contract</Button>

              <Button
                onClick={selectContract}
                sx={{ m: 1 }}
                disabled={!wallet.isConnected() || collection != null}
                variant='contained'
                fullWidth>Select Contract</Button>

              {
                image &&
                <img
                  src={URL.createObjectURL(image)}
                  style={{ margin: 8, width: '100%', maxHeight: 300, flex: 1, objectFit: "cover", borderRadius: 4 }}
                  alt="img" />
              }

              <Button
                component="label"
                sx={{ m: 1 }}
                disabled={!wallet.isConnected() || collection == null}
                variant='contained'
                fullWidth>

                Select Token Image

                <input
                  accept="image/*,audio/*,video/*"
                  id="logo-upload"
                  type="file"
                  hidden
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    if (e.currentTarget?.files && e.currentTarget?.files.length > 0)
                      setImage(e.currentTarget.files[0]);
                  }}
                />
              </Button>

              <Button
                onClick={mint}
                sx={{ m: 1 }}
                disabled={!wallet.isConnected() || collection == null}
                variant='contained'
                fullWidth>Mint Token</Button>

            </Box>

          </Grid>

          <Grid container md={8} xs={12} spacing={2}>
            <Grid item md={12}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Account"
                    secondary={wallet.isConnected() ? wallet.account : "Wallet not connected!"}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Contract"
                    secondary={!collection ? "none" : collection.address}
                  />
                </ListItem>

              </List>
            </Grid>

            {!!tokens && tokens.map(nft =>
              <Grid key={nft.id} item sm={6} xs={12}>
                <Card>
                  {nft.meta?.image &&
                    <CardMedia
                      component="img"
                      image={nft.meta.image} />
                  }

                  <CardContent>
                    {nft.summaries &&
                      <Typography variant='h4'>
                        {nft.summaries[Summaries.COLLECTION_NAME]}
                      </Typography>
                    }
                    <Typography variant='h5'>
                      {`#${nft.tokenId} ${nft.meta?.name}`}
                    </Typography>
                    <Typography variant='body2'>
                      {nft.meta?.description}
                    </Typography>

                    {nft.saleOptions &&
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={[
                              strings[nft.saleOptions.method as SellMethods],
                              ': $',
                              Web3.utils.fromWei(
                                nft.saleOptions?.price ||
                                nft.saleOptions?.startingPrice ||
                                '0'),
                              ` ETH`
                            ].join('')}
                            secondary={moment.unix(nft.saleOptions.duration.end).fromNow()}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            secondary={nft.saleOptions?.currency}
                          />
                        </ListItem>
                      </List>
                    }
                  </CardContent>

                  {!nft.saleOptions &&
                    <CardActions>
                      <Select onChange={e => {
                        const value = e.target.value as number;
                        setCurrency(currencies[value]);
                      }}>
                        {
                          currencies.map((c: ICoin, index: number) =>
                            <MenuItem
                              selected={currency?.address === c.address}
                              value={index}>
                              {`${c.name}(${c.symbol})`}
                            </MenuItem>)
                        }
                      </Select>
                      <Button onClick={() => (nft.id && book({
                        target: nft.id,
                        currency: currency?.address,
                        price: Web3.utils.toWei('1'),
                        method: SellMethods.FIXED_PRICE,
                        duration: {
                          begin: moment().unix(),
                          end: moment().add(1, 'days').unix(),
                        }
                      }))}>
                        {`直售 $1 ${currency?.symbol}`}
                      </Button>
                    </CardActions>
                  }

                  {!nft.saleOptions &&
                    <CardActions>
                      <Button onClick={() => (nft.id && book({
                        target: nft.id,
                        startingPrice: Web3.utils.toWei('0.01'),
                        reservePrice: Web3.utils.toWei('0.5'),
                        method: SellMethods.SELL_TO_HIGHEST_BIDDER,
                        duration: {
                          begin: moment().unix(),
                          end: moment().add(1, 'days').unix(),
                        }
                      }))}>
                        競標拍賣
                      </Button>
                      <Button onClick={() => (nft.id && book({
                        target: nft.id,
                        startingPrice: Web3.utils.toWei('10'),
                        endingPrice: Web3.utils.toWei('0.01'),
                        method: SellMethods.SELL_WITH_DECLINING_PRICE,
                        duration: {
                          begin: moment().unix(),
                          end: moment().add(1, 'days').unix(),
                        }
                      }))}>降價拍賣</Button>
                      <Button onClick={() => (nft.id && wallet.account && offer({
                        target: nft.id,
                        price: Web3.utils.toWei('1'),
                        duration: {
                          begin: moment().unix(),
                          end: moment().add(1, 'days').unix(),
                        }
                      }))}>出價 $10</Button>
                    </CardActions>
                  }

                  {nft.saleOptions &&
                    nft.saleOptions.method == SellMethods.SELL_TO_HIGHEST_BIDDER &&
                    <CardActions>
                      <Button onClick={() => (nft.id && bid({
                        target: nft.id,
                        nftDocId: nft.id || 'NOT_SUPPORTED',
                        bookDocId: nft?.saleOptions?.id || 'NOT_SUPPORTED',
                        price:
                          Web3.utils.toBN(
                            nft.saleOptions?.price ||
                            nft.saleOptions?.startingPrice ||
                            Web3.utils.toWei('1'))
                            .add(Web3.utils.toBN(Web3.utils.toWei('0.01')))
                            .toString()
                      }))}>Bid +0.01</Button>
                      <Button onClick={() => (nft.id && cancel({
                        target: nft.saleOptions?.id as string
                      }))}>停售</Button>
                    </CardActions>
                  }

                  {nft.saleOptions &&
                    [
                      SellMethods.SELL_WITH_DECLINING_PRICE,
                      SellMethods.FIXED_PRICE,
                    ].indexOf(nft.saleOptions.method as SellMethods) >= 0 &&
                    <CardActions>
                      <Button onClick={() => (nft.id && buy({
                        nftDocId: nft.id as string,
                        bookDocId: nft.saleOptions?.id as string
                      }))}>直接購買</Button>
                      <Button onClick={() => (nft.id && cancel({
                        target: nft.saleOptions?.id as string
                      }))}>停售</Button>
                    </CardActions>
                  }
                </Card>
              </Grid>
            )}
          </Grid>

        </Grid>

      </Container>
    </PageBase>
  )
}