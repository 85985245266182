import { useEffect } from 'react';
import { UseWalletProvider } from 'use-wallet';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { WithUiContextProvider, WithUserContextProvider } from './context/app';
import Cookies from 'universal-cookie/es6';

import {
  PageHome,
  PageProfile, PageSetting,
  PageNftCollectionCreate, PageMyCollections,
  PageNftMint, PageNftAuctionCreate, PageConnectWallet,
  PageNftCollection, PageNftDetail
} from './pages';

import {
  PageServices,
  PageAbout,
  PagePrivacyPolicy,
  PageTermsOfService
} from './pages/static-pages';

import {
  PageHome as PageHomeSW
} from './pages-sw';

import {
  PageSpotlight, PageViewpoint,
  PageNewsletter, PageMemberCenter
} from './campaign-20220316/pages';

import {
  PageHomeTTC, PageSpotlightTTC, PageViewpointTTC,
} from './campaign-TTC/pages';

import {
  PageSpotlightHHH, PageViewpointHHH, PageHomeHHH
} from './campaign-HHH/pages';

import { PageTest, PageTestMarket, PageTestConnection, PageTestBatch } from './tests';

const autoConnect = !!new Cookies().get('account');
const appName = process.env.REACT_APP_NAME?.toLocaleLowerCase() || 'soyl';
const { theme } = require(`./views/${appName}/theme`);

const App = () => {
  useEffect(() => {
    document.title = process?.env?.REACT_APP_NAME || 'NFT';
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/collection" element={<PageNftCollection />} />
          <Route path="/collection/:chainId" element={<PageNftCollection />} />
          <Route path="/collection/:chainId/:contract" element={<PageNftCollection />} />
          <Route path="/collection/create" element={<PageNftCollectionCreate />} />
          <Route path="/nft/:contract/mint" element={<PageNftMint />} />
          <Route path="/nft/:chainId/:contract/:tokenId" element={<PageNftDetail />} />
          <Route path="/nft/:chainId/:contract/:tokenId/sell" element={<PageNftAuctionCreate />} />
          <Route path="/profile" element={<PageProfile />} />
          <Route path="/profile/:address" element={<PageProfile />} />
          <Route path="/setting" element={<PageSetting />} />
          <Route path="/my-collections" element={<PageMyCollections />} />
          <Route path="/connect-wallet" element={<PageConnectWallet />} />
          {process?.env?.NODE_ENV === 'development' && (
            <>
              <Route path="/test" element={<PageTest />} />
              <Route path="/test-market" element={<PageTestMarket />} />
              <Route path="/test-connection" element={<PageTestConnection />} />
              <Route path="/test-batch" element={<PageTestBatch />} />
            </>
          )}


          <Route path="/about" element={<PageAbout />} />
          <Route path="/services" element={<PageServices />} />
          <Route path="/newsletters" element={<PageNewsletter />} />
          <Route path="/member" element={<PageMemberCenter />} />
          <Route path="/policy" element={<PagePrivacyPolicy />} />
          <Route path="/terms" element={<PageTermsOfService />} />

          {
            process?.env?.REACT_APP_NAME === "bb" &&
            <>
              <Route path="/market" element={<PageNftCollection />} />
              <Route path="/spotlight" element={<PageSpotlight />} />
              <Route path="/viewpoint" element={<PageViewpoint />} />
              <Route path="/viewpoint/:id" element={<PageViewpoint />} />
              <Route path="/*" element={<PageHome />} />
            </>
          }

          {
            process?.env?.REACT_APP_NAME === "sw" &&
            <>
              <Route path="/market" element={<PageNftCollection />} />
              <Route path="/spotlight" element={<PageSpotlight />} />
              <Route path="/viewpoint" element={<PageViewpoint />} />
              <Route path="/viewpoint/:id" element={<PageViewpoint />} />
              <Route path="/*" element={<PageHomeSW />} />
            </>
          }

          {
            process?.env?.REACT_APP_NAME === "Soyl" &&
            <>
              <Route path="/market" element={<PageNftCollection />} />
              <Route path="/spotlight" element={<PageSpotlightHHH />} />
              <Route path="/viewpoint" element={<PageViewpointHHH />} />
              <Route path="/viewpoint/:id" element={<PageViewpointTTC />} />
              <Route path="/*" element={<PageHomeHHH />} />

              <Route path="/HaiHsinHuang/spotlight" element={<PageSpotlightHHH />} />
              <Route path="/HaiHsinHuang/viewpoint" element={<PageViewpointHHH />} />
              <Route path="/HaiHsinHuang/viewpoint/:id" element={<PageViewpointHHH />} />
              <Route path="/HaiHsinHuang" element={<PageHomeHHH />} />

              <Route path="/TingTongChang/spotlight" element={<PageSpotlightTTC />} />
              <Route path="/TingTongChang/viewpoint" element={<PageViewpointTTC />} />
              <Route path="/TingTongChang/viewpoint/:id" element={<PageViewpointTTC />} />
              <Route path="/TingTongChang" element={<PageHomeTTC />} />

              <Route path="/TeppeiKaneuji/spotlight" element={<PageSpotlight />} />
              <Route path="/TeppeiKaneuji/viewpoint" element={<PageViewpoint />} />
              <Route path="/TeppeiKaneuji/viewpoint/:id" element={<PageViewpoint />} />
              <Route path="/TeppeiKaneuji" element={<PageHome />} />
            </>
          }


        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const WrappedApp = () => (
  <WithUiContextProvider>
    <WithUserContextProvider>
      <UseWalletProvider
        connectors={{ injected: {} }}
        autoConnect={autoConnect}>
        <App />
      </UseWalletProvider>
    </WithUserContextProvider>
  </WithUiContextProvider>
);

export default WrappedApp;