import { AppBar, Toolbar, Stack, Button, Box } from "@mui/material"
import { useContext } from "react"
import { UiContext } from "../../../context/app"
import { INftToken } from "../../../models"
import { CancelBookButton } from "../button-cancel-book"

interface NftToolbarViewProps {
  nft: INftToken,
  finished?: () => void,
}
export default ({ nft, finished }: NftToolbarViewProps) => {
  const { strings, mobile } = useContext(UiContext);

  return (<></>
    // <Box>
    //   <AppBar position='fixed' color={'inherit'} sx={{ zIndex: 2, top: mobile ? '56px' : '88px' }}>
    //     <Toolbar sx={{ justifyContent: 'flex-end' }}>
    //       <Stack direction={'row'} spacing={2}>
    //         <CancelBookButton
    //           nft={nft}
    //           finished={finished}>
    //           {strings('CANCEL_SELLING')}
    //         </CancelBookButton>
    //         {
    //           !nft.saleOptions &&
    //           <Button
    //             variant='contained'
    //             href={`/nft/${nft.chain}/${nft.contract}/${nft.tokenId}/sell`}>
    //             {strings('SELL')}
    //           </Button>
    //         }
    //       </Stack>
    //     </Toolbar>
    //   </AppBar>
    // </Box >
  )
}