import moment from "moment";
import { where } from "firebase/firestore";
import { IContext, IBidOptions, IOfferConfigs, IOfferOptions, ISellConfigs, ISellOptions, BookStates, IListOptions, CURRENCY_DEFAULT, TradingMethods, EMPTY_SIGN, SellMethods, OfferStates } from "../../../models";
import { asOfferConfigs, asSellConfigs, Offer, OfferConfig, Sale, SaleConfig } from "../../../models/trading";
import { asOffer, asBidOffer, asSale, trimSale, trimOffer } from "../../../models/typed";
import { append, document, documents, update } from "../../db-utils";
import { availableTokenAmount, expectedMaxSupply, nftInfo } from "../../market";
import { approveCoin } from "../../market/crypto-access";
import { authByWallet } from "../service-auth";
import { create as createNftService } from '../crypto/service-nft';
import { maxFee } from "../service-fee";
import { send } from "../crypto/utils/send";
import { signTypedData } from "../crypto/utils/sign";
import { NftProtocol } from "../crypto/service-nft/abstract";
import { trackTraded } from "../service-tracking";

export const bookForSell =
  async (context: IContext, options: ISellOptions):
  Promise<ISellConfigs> => {
    // @todo:
    // 處理銷售才鑄造的流程
    // 1. 當用戶建立NFT時，先存MintSign在資料庫。
    // 2. 銷售時，需將 MintSign 一起簽名。
    // 3. 售出時，需將 MintSign 一起傳入。
    const { wallet, trader, web3 } = context;
    const { target, method, duration } = options;

    const seller = wallet.account;
    const nft = await nftInfo(context, target);

    if(!seller) 
      throw new Error('Wallet not connected.');
      
    if(!nft.chain || !nft.contract || !nft.tokenId) 
      throw new Error('Nft not regisited.');

    // business logic
    const timestamp = moment().unix();
    let priceOptions: string[] = [];

    if (
      !duration || !duration.end || !duration.begin ||
      typeof duration.end !== 'number' ||
      typeof duration.begin !== 'number' ||
      duration.end - duration.begin < 300 || // duration must > 3 minute
      duration.end <= timestamp)
      throw new Error("Duration setting incorrect.");

    switch (method) {
      case SellMethods.FIXED_PRICE:
        if (!options.price || typeof options.price != 'string')
          throw new Error("Price format incorrect.");
        priceOptions = [options.price];
        break;
      case SellMethods.SELL_TO_HIGHEST_BIDDER:
        if (!options.startingPrice || typeof options.startingPrice != 'string')
          throw new Error("Starting Price format incorrect.");
        if (options.reservePrice && typeof options.reservePrice != 'string')
          throw new Error("Reserve Price format incorrect.");
        if (!options.currency || options.currency === CURRENCY_DEFAULT)
          throw new Error("This methods only supports ERC20 payment.");

        priceOptions = [
          options.startingPrice,
          options.reservePrice || options.startingPrice
        ];

        break;
      case SellMethods.SELL_WITH_DECLINING_PRICE:
        if (!options.startingPrice || typeof options.startingPrice != 'string')
          throw new Error("Starting Price format incorrect.");
        if (!options.endingPrice || typeof options.endingPrice != 'string')
          throw new Error("Ending Price format incorrect.");
        priceOptions = [options.startingPrice, options.endingPrice];
        break;
      case SellMethods.NOT_FOR_SELL:
        throw new Error("Unsupported selling method.");
    }

    const nftc = await createNftService(web3, nft.chain, nft.contract);
    // @todo: find a way to identify site creator, which allow to sell before mint
    const isSiteCreator = seller === (await nftc.owner());
    const ownedSupply = await availableTokenAmount(context, seller, nft);
    if(!isSiteCreator && ownedSupply === 0)
      throw new Error("No tokens left for sale.");
    if(ownedSupply && ownedSupply <=0 )
      throw new Error("No tokens left for sale.");

    if(false == await nftc.isApproved(nft.tokenId, seller, trader.options.address))
      await nftc.setApproveForAll(nft.tokenId, trader.options.address, true, {from: seller});
    // spec: mintSig無論什麼情形都放
    const data = !!nft.mintSig ? nft.mintSig : '0x';

    const fee = await maxFee(context, nft.chain, nft.contract, nft.tokenId);
    const sale = asSale(seller, nft, options, fee);
    const saleSig = await signTypedData({
      web3,
      chain: wallet.chainId,
      contract: trader,
      signer: seller,
      type: 'Sale',
      message: sale,
      data,
    });
    
    const saleConfig:SaleConfig = {
      ...sale,
      chain:nft.chain,
      createAt: moment().unix(),
      auctionEndAt: options.duration.end, // note: auctionEndAt = SaleConfig.expireTime - DURATION_AFTER_AUCTION_END
      sellerSig: saleSig,
      mintSig: data,
      bookState: BookStates.BOOKED
    }

    await append(`listing`, saleConfig);
    
    return asSellConfigs(saleConfig);
  }

// todo: bid target should be listing id
export const cancelBook =
  async (context: IContext, target: string):
  Promise<void> => {
    // todo: 要把取消的 Nonce 寫到鏈上
    await authByWallet(context);
    await update('listing', target, {bookState:BookStates.CANCELED});
  }

// options.target = listing/:id
export const makeBid =
  async (context: IContext, options: IBidOptions):
  Promise<void> => {
    const { web3, wallet, trader } = context;

    const buyer = wallet.account;
    const nft = await nftInfo(context, options.nftDocId);

    if(!buyer) 
      throw new Error('Wallet not connected.');
      
    if(!nft.chain || !nft.contract || !nft.tokenId) 
      throw new Error('Nft not regisited.');

    const saleConfig = await document('listing', options.bookDocId) as SaleConfig;

    if(saleConfig.method !== TradingMethods.SELL_TO_HIGHEST_BIDDER)
      throw new Error('The sale is not an auction.');

    if(!saleConfig.currency || saleConfig.currency === CURRENCY_DEFAULT)
      throw new Error('Currency not supported for bidding.');

    if(saleConfig.bookState !== BookStates.BOOKED)
      throw new Error('This auction is not available.');

    if(moment().unix() >= saleConfig.auctionEndAt)
      throw new Error('This auction is no longer available.');

    // TBD: The check is meaningless since creator may sell before mint.
    // const nftc = await createNftService(web3, nft.chain, nft.contract);
    // const owned = await nftc.ownedQuantity(nft.tokenId, saleConfig.seller);
    // if(owned < saleConfig.quantity)
    //   throw new Error('This auction is no longer available.');

    await approveCoin(
      context, 
      saleConfig.currency, 
      trader.options.address,
      options.price);
    
    const data = saleConfig.mintSig || '0x';
    const offer = asBidOffer(buyer, saleConfig, options);
    const offerSig = await signTypedData({
      web3,
      chain: wallet.chainId,
      contract: trader,
      signer: buyer,
      type: 'Offer',
      message: offer,
      data,
    });

    const offerConfig:OfferConfig = {
      ...offer,
      chain:nft.chain,
      createAt: moment().unix(),
      buyerSig: offerSig,
      offerState: OfferStates.OFFERING
    }

    if(!await trader.methods.validateOffer(offer,data,offerSig).call())
      throw new Error('Signature validation failed.');

    // note: currently, no way to cancel bid
    await append(`offering`, offerConfig);
    
    return;
  }

export const purchase =
  async (context: IContext, options: { nftDocId: string, bookDocId: string }):
  Promise<void> => {
    const { wallet, trader, web3 } = context;
    const { nftDocId, bookDocId } = options;

    const buyer = wallet.account;
    const nft = await nftInfo(context, nftDocId);

    if(!buyer) 
      throw new Error('Wallet not connected.');
      
    if(!nft.chain || !nft.contract || !nft.tokenId) 
      throw new Error('Nft not regisited.');
    
    const saleConfig:SaleConfig = await document('listing', bookDocId);
    
    const data = saleConfig.mintSig || '0x';
    const dealTime = moment();
    const buyerNonce = dealTime.valueOf();
    const sale:Sale = trimSale(saleConfig);
    const saleSig:string = saleConfig.sellerSig;
    const offer:Offer = {
      // note: Be aware, the attr sequence of Sale must follow contract
      currency: sale.currency,
      nftContract: sale.nftContract,
      tokenId: sale.tokenId,
      quantity: sale.quantity,
      price: sale.price,
      method: sale.method,
      seller: sale.seller,
      buyer,
      nonce: buyerNonce,
      beginTime: sale.beginTime,
      expireTime: sale.expireTime,
    }
    const offerSig:string = EMPTY_SIGN;

    const price:string = await trader
      .methods
      .priceOf(sale, offer)
      .call();

    offer.price = price;

    const isERC20Currency = !!saleConfig.currency && saleConfig.currency !== CURRENCY_DEFAULT;
    const sendOptions = { from: buyer, value: isERC20Currency ? undefined : price };

    if(isERC20Currency) {
      await approveCoin(
        context, 
        saleConfig.currency, 
        trader.options.address,
        price
      );
    }

    await send(
      trader.methods.deal(sale, saleSig, offer, offerSig, data),
      sendOptions,
      1.2
    );

    const { chain, nftContract, tokenId, quantity, seller, method } = saleConfig;

    trackTraded(
      context,
      {
        chain,
        nftContract,
        tokenId,
        quantity,
        seller,
        buyer,
        price,
        nonce: buyerNonce,
        method: TradingMethods[method]
      }
    );

  }

export const makeOffer =
  async (context: IContext, options: IOfferOptions):
    Promise<IOfferConfigs> => {
    // /offers/:nftId/offers/:offerId
    const { wallet, trader, web3 } = context;

    const buyer = wallet.account;
    const nft = await nftInfo(context, options.target);

    if(!buyer) 
      throw new Error('Wallet not connected.');
      
    if(!nft.chain || !nft.contract || !nft.tokenId) 
      throw new Error('Nft not regisited.');

    if(!options.currency || options.currency === CURRENCY_DEFAULT)
      throw new Error('Not support offering with given currency');

    await approveCoin(
      context, 
      options.currency, 
      trader.options.address, 
      options.price);

    // todo: 研究如果還沒鑄造如何出價購買
    // 1. 檢查持有者是不是發行者
    // 2. 檢查發行者是否有持有
    // 3. 檢查發行者是否還能鑄造
    // 4. 其實這可以在發行者接受價格時才決定，但不確定是不是要事先 sign
    const data = '0x'; //nft.mintSig || '0x';
    const offer = asOffer(buyer, nft, options);
    const offerSig = await signTypedData({
      web3,
      chain: wallet.chainId,
      contract: trader,
      signer: buyer,
      type: 'Offer',
      message: offer,
      data,
    });

    const offerConfig:OfferConfig = {
      ...offer,
      chain: nft.chain,
      buyerSig: offerSig,
      createAt: moment().unix(),
      offerState: OfferStates.OFFERING
    }

    if(!await trader.methods.validateOffer(offer,data,offerSig).call())
      throw new Error('Signature validation failed.');

    await append(`offering`, offerConfig);

    return asOfferConfigs(offerConfig);
  }


export const cancelOffer =
  async (context: IContext, target: string):
    Promise<void> => {
      // todo: 要把取消的 Nonce 寫到鏈上
    await authByWallet(context);
    await update('offering', target, {offerState: OfferStates.CANCELED});
  }


export const acceptOffer =
  async (context: IContext, target: string ):
    Promise<any> => {
      const { wallet, trader, web3 } = context;
  
      const seller = wallet.account;
      const nft = await nftInfo(context, target);
  
      if(!seller) 
        throw new Error('Wallet not connected.');
        
      if(!nft.chain || !nft.contract || !nft.tokenId) 
        throw new Error('Nft not regisited.');
  
      const nftc = await createNftService(web3, nft.chain, nft.contract);
      if(false == await nftc.isApproved(nft.tokenId, seller, trader.options.address))
        await nftc.setApproveForAll(nft.tokenId, trader.options.address, true, {from: seller});

      const dealTime = moment();
      const sellerNonce = dealTime.valueOf();
      // todo: 研究如果還沒鑄造如何出價購買
      // 1. 檢查持有者是不是發行者
      // 2. 檢查發行者是否有持有
      // 3. 檢查發行者是否還能鑄造
      // 4. 其實這可以在發行者接受價格時才決定，但不確定是不是要事先 sign
      const data = '0x'; //nft.mintSig || '0x'; // todo: if mint in transfer, must include mintSig
      const fee = await maxFee(context, nft.chain, nft.contract, nft.tokenId);
      const offerConfig:OfferConfig = await document('offering', target);
      const offer:Offer = trimOffer(offerConfig);
      const offerSig:string = offerConfig.buyerSig;
      const sale:Sale = {
        // note: Be aware, the attr sequence of Sale must follow contract
        currency: offer.currency,
        nftContract: offer.nftContract,
        tokenId: offer.tokenId,
        quantity: offer.quantity,
        price: offer.price,
        acceptMinPrice: offer.price,
        method: offer.method,
        seller: offer.seller,
        buyer: offer.buyer,
        nonce: sellerNonce,
        beginTime: offer.beginTime,
        expireTime: offer.expireTime,
        maxFee: fee
      };
      const saleSig = EMPTY_SIGN; // won't validate
      const sendOptions = { from: seller };
      await send(
        trader.methods.deal(sale, saleSig, offer, offerSig, data),
        sendOptions,
        1.2
      );


    const { chain, nftContract, tokenId, quantity, buyer, method, price } = offerConfig;

    trackTraded(
      context,
      {
        chain,
        nftContract,
        tokenId,
        quantity,
        seller,
        buyer,
        price,
        nonce: sellerNonce,
        method: TradingMethods[method]
      }
    );
  }

export const listSales =
  async (context: IContext, options: IListOptions):
  Promise<ISellConfigs[]> => {
    const opt:any = {
      ...options // chain, contract, tokenId, quentity, seller, currency
    };
    const matches = ['chain','contract','tokenId','quantity','seller','currency','state'];
    const attrMap:{[key:string]:string} = {'contract':'nftContract','state':'bookState'};
    const mapAttr = (attr:string):string => (attr in attrMap) ? attrMap[attr] : attr;
    const queryArgs:any[] = [];

    for(const m of matches)
      if(m in opt)
        queryArgs.push(where(mapAttr(m), '==', opt[m]));

    queryArgs.push(where('expireTime', '>', moment().unix()));

    const saleConfigs = await documents('listing', { queryArgs, parse: asSellConfigs })
      .catch(err => {
        console.error('ERROR on saleOptions', err); 
        return [];
      });

    // todo: retrieve live pricing from db & chain
    return saleConfigs;
  }