import { 
  ActivityType,
  IBidConfigs, 
  IContext, 
  INftCollection, 
  INftToken, 
  INftTokenActivities, 
  INftTokenPricingHistory, 
  IOfferConfigs, 
  IPaged, 
  ISellConfigs, 
  IUser, 
  Sorting
} from "../../../models";
import * as ListProviderTrade from './list-provider-trade';
import * as ListProviderMarket from './list-provider-market';
import { nftInfo } from "../service-info";

export * from './list-common';

export type listOptions = {
  chain?:number,
  contract?:string,
  tokenId?:string,
}

type ExploreMatches = any & {
  // collection & nft
  chain?: number,
  contract?: string,
  tokenId?: string,
  owner?: string,
  creator?: string,
  publisher?: string,
  category?: string,
}

type RangeMatches = {
  field: string,
  upperBound: number,
  lowerbound: number,
  exclude?: boolean,
}

export type ExploreOptions = {
  page?: number,
  perPage?: number,
  highlights?: string[], // id
  matches?: ExploreMatches,
  ranges?: RangeMatches[],
  sorting?: Sorting,
}
export interface IListProvider {
  listOffers(context: IContext, options: listOptions & { from?: string }):Promise<IPaged<IOfferConfigs>>;
  listBiddings(context: IContext, options: listOptions & { bidder?: string }):Promise<IPaged<IBidConfigs>>;
  listListing(context: IContext, options: listOptions & { seller?: string }):Promise<IPaged<ISellConfigs>>
  listActivities(context: IContext, options: listOptions & { types?: ActivityType[] }):Promise<IPaged<INftTokenActivities>>;
  listPricings(context: IContext, options: listOptions):Promise<INftTokenPricingHistory[]>;
  exploreNfts(context: IContext, options: ExploreOptions):Promise<IPaged<INftToken>>;
  exploreCollections(context: IContext, options: ExploreOptions):Promise<IPaged<INftCollection>>;
  exploreProfiles(context: IContext, options: ExploreOptions):Promise<IPaged<IUser>>;
}

const NftTradingType:string = process?.env?.REACT_APP_TRADE_PROVIDER || 'NftMarket'; // NftTrade
const ListProviders:{[key:string]:IListProvider} = {
  "NftTrade": ListProviderTrade as IListProvider,
  "NftMarket": ListProviderMarket as IListProvider
}



const migrate = (func:any) => {
  return async (context:IContext, options:listOptions & {target:string} & any) => {
    const { chain, contract, tokenId, target } = options;
    if(!chain && !contract && !tokenId && !!target) {
      const info = await nftInfo(context, target);
      options.chain = info.chain;
      options.contract = info.contract;
      options.tokenId = info.tokenId;
    }
    return func(context, options);
  }
}

export const listOffers = migrate(ListProviders[NftTradingType].listOffers);
export const listListing = migrate(ListProviders[NftTradingType].listListing);
export const listActivities = migrate(ListProviders[NftTradingType].listActivities);
export const listPricings = migrate(ListProviders[NftTradingType].listPricings);
export const exploreNfts = ListProviders[NftTradingType].exploreNfts;
export const exploreCollections = ListProviders[NftTradingType].exploreCollections;
export const exploreProfiles = ListProviders[NftTradingType].exploreProfiles;

// @deprecated
export const listBiddings = migrate(ListProviders[NftTradingType].listBiddings);