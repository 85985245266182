import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { UiContext } from "../../../context/app";
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  Link,
  Tabs,
  Tab,
  Avatar,
  SxProps,
  Card,
  CardHeader,
  CardContent
} from "@mui/material";
import { PageBaseOfCampaign } from "../../components/page-base-campaign";
import { EMPTY_DATA, article } from '../../articles';

export const PageViewpoint = () => {
  const { mobile, lang } = useContext(UiContext);
  const { id } = useParams();
  const [data, setData] = useState<any>(EMPTY_DATA);
  const [tab, setTab] = useState(parseInt(id || '0'));
  const doc = data.viewpoints[tab];

  useEffect(() => {
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang]);

  const tabIndicatorProps = {
    children: <Box sx={{ width: '22px', maxWidth: '22px', backgroundColor: 'black' }} />,
    style: {
      height: '5px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    }
  };
  const tabButtonStyle: SxProps = {
    "&.Mui-selected": {
      color: 'black',
      fontWeight: 'bold',
    },
    fontSize: '15px',
    fontWeight: 'normal',
    color: 'black',
  }

  return (
    <PageBaseOfCampaign sx={{ background: 'linear-gradient(to bottom, #ccc, #e3e3e3 25%, #eee 76%, #c2c2c2)' }}>
      <Container disableGutters maxWidth="md" className="article-viewpoint" sx={{ color: "#3a3a3a" }}>

        <Tabs centered TabIndicatorProps={tabIndicatorProps} value={tab} onChange={(event, newValue) => setTab(newValue)} sx={{ marginTop: 4 }}>
          {data.viewpoints.map((doc: any) =>
            <Tab key={doc.author}
              label={doc.author}
              sx={tabButtonStyle} />
          )}
        </Tabs>

        {/* 畫是畫出來了, 但click 之類的還得補,  這做法不大現實
        <Box>
        <Grid container p={0} m={0}>
        <Grid item xs={6}>
          <Box sx={{textAlign:"right"}} mr={10} ml={'auto'}>
            <Box sx={{marginLeft:'auto',width:'fit-content'}}>
              <Box mb={1}>姓名一</Box>
              <Box sx={{
                      width: '22px',
                      height: '5px',
                      backgroundColor: '#3a3a3a',
                      margin:'0 auto',
                    }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
        <Box sx={{textAlign:"right"}} ml={10} mr={'auto'}>
            <Box sx={{marginRight:'auto',width:'fit-content'}}>
              <Box mb={1}>姓名二</Box>
              <Box sx={{
                      width: '22px',
                      height: '5px',
                      backgroundColor: '#3a3a3a',
                      margin:'0 auto',
                    }} />
            </Box>
          </Box>
        </Grid>
        </Grid>
        </Box>
*/}

        <hr style={{ margin: mobile ? '20px 40px 0 40px' : '20px 0 0 0' }} />

        {doc &&
          <Box mx={mobile ? 5 : 0} sx={{ border: 4 }} p={mobile ? 3 : 5} mt={6.25} >
            <Grid container p={0} m={0}>
              <Grid item xs={12}>
                <Typography variant="h1" >
                  {doc.title.split('\\n').map((item: string) => <>{item}<br /></>)}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={mobile ? 6.25 : 5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  flexDirection: 'row',
                }}>
                <img style={{ width: '42px', height: '42px', borderRadius: '50%', margin: '0' }} src={doc.avatar} />
                <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }} p={2}>{doc.author}</Typography>
                <Divider flexItem sx={{ margin: '10px' }} orientation={mobile ? 'horizontal' : 'vertical'} />
                <Typography variant="subtitle2" p={2} display={{xs:'none', md:'block'}}>
                  {doc.desc}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} m={0} mb={3.75} display={{xs:'block', md:'none'}}>
              <Divider flexItem sx={{ marginBottom: '15px', marginTop: '15px' }} orientation='horizontal' />
              <Typography variant="body1" component="span" sx={{ color:'#3a3a3a'}}>{doc.desc}</Typography>
            </Grid>

            <Grid item xs={12} m={0}>
              <Typography variant="body1" sx={{ whiteSpace: 'nowrap', color:'#aaaaaa' }} component="span" >{doc.date}</Typography>
            </Grid>

          </Box>
        }

        {doc && <Box mx={mobile ? 5 : 0} mt={mobile ? 5 : 7.5} mb={12.5} dangerouslySetInnerHTML={{ __html: doc.innerHTML }} />}

      </Container>
    </PageBaseOfCampaign>
  );
}