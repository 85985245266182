import { RoyaltyInfo } from ".";
import { IContext } from "../../../models";
import { contractAt, ContractAtProps, send } from "../crypto";
import RoyaltyFeeRegistry from "../../../contracts/RoyaltyFeeRegistry.json";
import RoyaltyFeeManager from "../../../contracts/RoyaltyFeeManager.json";
  
export async function comission(context: IContext, chain: number):Promise<number>{
  const fee = await contractAt({chain, artifacts: require('../../../contracts/NftTrade.json')})
    .methods
    .adminFee()
    .call();

  return parseInt(fee) * 0.0001;
}
export async function setComission(context: IContext, chain: number, comission:number):Promise<void>{
  await send(
    contractAt({chain, artifacts: require('../../../contracts/NftTrade.json')})
      .methods
      .setAdminFee(Math.floor(comission * 10000)),
      { from: context.wallet.account }
  )
}

export async function royaltyInfo(context: IContext, contractProps:ContractAtProps, tokenId:string):Promise<RoyaltyInfo>{
  const { chain, address } = contractProps;

  const rs = await contractAt({chain, artifacts: RoyaltyFeeManager})
    .methods
    .calculateRoyaltyFeeAndGetRecipient(address, tokenId, '10000')
    .call();


  const asAry = (obj:any) => 
    Object.keys(obj).map((key) => obj[key]);
  
  const [account, fee] = asAry(rs);
  
  return {
    account,
    rate: parseInt(fee) * 0.0001
  }
}

export async function setupRoyaltyInfo(context: IContext, contractProps:ContractAtProps, config: RoyaltyInfo):Promise<void>{
  const { wallet, web3 } = context;
  const { chain, address } = contractProps;
  const artifacts = require('../../../contracts/RoyaltyFeeSetter.json');
  const feeConfigs = contractAt({chain, web3, artifacts});

  const [account, setter, fee] = await feeConfigs
    .methods
    .royaltyFeeInfoCollection(address)
    .call();

  const method = await feeConfigs.methods.checkForCollectionSetter(address).call();
  const newReceiver = config.account;
  const newFee = Math.floor(config.rate * 10000).toString()

  switch(method){
    
    case 0: // * 0: Royalty setter is set in the registry
      await send(
        feeConfigs
          .methods
          .updateRoyaltyInfoForCollectionIfSetter(
            newReceiver, setter, account, newFee
          ),
        { from: wallet.account }
      );
      break;

    case 2: // * 2: setter can be set using owner()
      await send(
        feeConfigs
          .methods
          .updateRoyaltyInfoForCollectionIfOwner(
            newReceiver, setter, account, newFee
          ),
        { from: wallet.account }
      );
      break;

    case 3: // * 3: setter can be set using admin()
      await send(
        feeConfigs
          .methods
          .updateRoyaltyInfoForCollectionIfAdmin(
            newReceiver, setter, account, newFee
          ),
        { from: wallet.account }
      );
      break;

    case 1: // * 1: ERC2981 and no setter
      throw new Error("Unable to chagne royality setting, reason: Target contract supports ERC2981 and has no setter");

    case 4: // * 4: setter cannot be set, nor support for ERC2981
      throw new Error("Unable to chagne royality setting, reason: Setter cannot be set, nor support for ERC2981");

    default:
      throw new Error("Unable to chagne royality setting");
  }
  
}

export async function maxFee(
    context: IContext, 
    chain: number, 
    nftContract: string, 
    tokenId: string
  ):Promise<string> {

  const { web3 } = context;
  const $bn = web3.utils.toBN;

  const [,royaltyFee] = Object.values(
      await contractAt({
        chain, 
        web3, 
        artifacts: require('../../../contracts/RoyaltyFeeManager.json')
      })
      .methods
      .calculateRoyaltyFeeAndGetRecipient(nftContract, tokenId, '10000')
      .call()
    );

  const adminFee = await contractAt({
      chain, 
      web3, 
      artifacts: require('../../../contracts/NftTrade.json')
    })
    .methods
    .adminFee()
    .call();

  return $bn(adminFee)
    .add($bn(royaltyFee))
    .toString();
}