import { FilterAlt, Search } from "@mui/icons-material";
import { Stack, Grid, GridProps, OutlinedInput, InputAdornment, Select, MenuItem, Button, Fab, useTheme } from "@mui/material";
import { IListOptions, Sorting } from '../../../models';

const strings: { [key: string]: string } = {
  'RECENTLY_LISTED': 'Recently Listed',
  'RECENTLY_CREATED': 'Recently Created',
  'RECENTLY_SOLD': 'Recently Sold',
  'RECENTLY_RECEIVED': 'Recently Received',
  'ENDING_SOON': 'Ending Soon',
  'PRICE_LOW_TO_HIGH': 'Price: Low to High',
  'PRICE_HIGH_TO_LOW': 'Price: High to Low',
  'HIGHEST_LAST_SALE': 'Highest Last Sale',
  // 'MOST_VIEWED': 'Most Viewed',
  // 'MOST_FAVORITED': 'Most Favorited',
  'OLDEST': 'Oldest',
}

const AVAILABLE_SORTINGS = Object.values(Sorting);
const GRIDPROPS = { rowSpacing: '11px', columnSpacing: '9px' };

type ListOptionToolbarProps = {
  search?: string,
  sorting?: string,
  onChange?: (changes: IListOptions) => void
  onOpenFilter?: () => void
}
export default ({ search, sorting, onChange, onOpenFilter }: ListOptionToolbarProps) => {
  const theme = useTheme();
  return (
    <Grid container {...GRIDPROPS}>
      <Grid item xs={12} sm={12} md={9} >
        <OutlinedInput
          sx={{ display: 'flex', flex: 1 }}
          value={search}
          placeholder={'Search'}
          onChange={(event: any) =>
            !!onChange && onChange({ search: event?.target?.value || undefined })}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} >
        <Stack direction='row' spacing={GRIDPROPS?.rowSpacing}>
          <Select
            sx={{ display: 'flex', flex: 1 }}
            defaultValue={sorting}
            value={sorting}
            variant='outlined'
            onChange={(event: any) =>
              !!onChange && onChange({ sorting: event.target.value || sorting })}>
            {
              AVAILABLE_SORTINGS.map((s) =>
                strings[s] && <MenuItem key={s} value={s}>{strings[s]}</MenuItem>
              )
            }
          </Select>
          {!!onOpenFilter &&
            <Button
              variant='outlined'
              onClick={onOpenFilter}
              sx={{ border: '1px solid #C4C4C4' }}>
              <FilterAlt />
            </Button>
          }
        </Stack>
      </Grid>
    </Grid>
  )
};