import { createTheme } from "@mui/material";
import type { } from '@mui/x-data-grid/themeAugmentation';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#252525',
    },
    background: {
      default: '#000',
      paper: '#000',
    },
    divider: '#d1d1d1',
  },
  typography: {
    h4: { fontSize: '32px', fontWeight: 'bold' },
    h5: { fontSize: '28px', fontWeight: 'bold' },
    h6: { fontSize: '20px', fontWeight: 'bold' },
    subtitle1: { fontSize: '20px' },
    subtitle2: { fontSize: '16px', fontWeight: 'bold' }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: 'bold',
            color: '#74FBEF',
            borderRadius: 50,
            borderColor: '#74FBEF',
            borderWidth: '3px',
            '&:hover': {
              borderWidth: '3px',
              color: '#fff',
            }
          }
        }
      ]
    },
    MuiLink: {
      defaultProps: { underline: 'none' },
      styleOverrides: { root: { color: '#74FBEF' } }
    },
    MuiAccordion: {
      defaultProps: { disableGutters: true },
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            backgroundColor: '#e739f7',
            height: '5px',
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 255, 240, 0.6)',
          '&:hover': {
            color: 'rgba(0, 255, 240, 1)',
          },
          "&.Mui-selected": {
            "color": "#fff"
          }
        }
      }
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        hideFooter: true,
        autoHeight: true,
        headerHeight: 30,
        rowHeight: 42,
        selectionModel: 'none',
        sortModel: [{ field: 'timestamp', sort: 'desc' }],
      },
      styleOverrides: {
        root: {
          border: 0,
          borderWidth: 0,
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0,
            background: '#393939',
            borderTop: '1px solid #5c5c5c',
            borderBottom: '1px solid #5c5c5c'
          }
        }
      }
    }
  }
});
