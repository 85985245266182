import { useState, useEffect } from "react";
import { useAppContext } from "../../components/hooks";
import { INftTokenPricingHistory } from "../../models";
import { listPricings } from "../../services/market";
import { createView } from "../../views";

const { default: NftPricingView } = createView('nft-pricing-view');
export const NftPricingCard = ({ nft }: any) => {
  const [allPricing, setAllPricing] = useState<INftTokenPricingHistory[]>([]);
  const context = useAppContext();
  const { chain, contract, tokenId } = nft;
  const options = { chain, contract, tokenId };

  useEffect(() => {
    listPricings(context, options)
      .then(setAllPricing)
      .catch(console.error);
  }, [nft]);

  return (
    <NftPricingView nft={nft} allPricing={allPricing} />
  )
}
