import { MenuItem, TextField } from "@mui/material";
import { CURRENCY_DEFAULT } from "../../models";
import { genStrings } from "../utils";

const STRINGS = {
  "CURRENCY_SELECTOR.SYMBOL_ETH": "ETH",
}

export const CurrencySelector = ({ sx, disableETH, coins, val, onChange }: any) =>
  <TextField sx={sx} value={val} select onChange={(e) => onChange(e.target.value)}>
    {
      coins.map((coin: { address: string; name: string; }) => {
        if (disableETH && coin.address === CURRENCY_DEFAULT) return;
        return <MenuItem key={coin.address} value={coin.address}>{coin.name}</MenuItem>
      })
    }
  </TextField>