import { Box } from "@mui/material";
import { Paragraph } from ".";

export const ZhPolicy = () => <Box py={'26.5px'}>
  <Paragraph>
    在 SOYL，我們的主要優先事項之一是訪問者的隱私。本隱私政策文件包含 SOYL 收集和記錄的資訊類型以及我們如何使用這些資訊。
    如果您有其他問題或需要有關我們隱私政策的更多資訊，請隨時與我們聯繫。
    本隱私政策僅適用於我們的線上活動，對我們網站的訪問者在 SOYL 中共享或收集的資訊有效。本政策不適用於線下或通過本網站以外的渠道收集的任何信息。
  </Paragraph>
  <Paragraph title={'同意'}>
    通過使用我們的網站，您在此同意我們的隱私政策條款。
  </Paragraph>
  <Paragraph title={'我們收集的資訊'}>
    您被要求提供的個人資訊，以及要求您提供這些資訊的原因，將在我們要求您提供個人資訊時向您說明。
    如果您直接與我們聯繫，我們可能會收到有關您的其他資訊，例如您的姓名、電子郵件地址、電話號碼、您可能發送給我們的消息和/或附件的內容，以及您可能選擇提供的任何其他資訊。
    當您註冊帳戶時，我們可能會要求您提供聯繫資訊，包括姓名、公司名稱、地址、電子郵件地址和電話號碼等項目。
  </Paragraph>
  <Paragraph title={'我們如何使用您的資訊'}>
    我們以各種方式使用我們收集的資訊，包括：<br />
    ■ 提供、運營和維護我們的網站<br />
    ■ 改進、個性化和擴展我們的網站<br />
    ■ 了解和分析您如何使用我們的網站<br />
    ■ 開發新產品、服務、特性和功能<br />
    ■ 直接或通過我們的合作夥伴之一與您溝通，包括為客戶服務，為您提供與網站相關的更新和其他信息，以及用於營銷和促銷目的<br />
    ■ 向您發送電子郵件<br />
    ■ 發現並防止欺詐<br />
  </Paragraph>
  <Paragraph title={'紀錄檔案'}>
    SOYL 遵循使用記錄檔案的標準程序。這些檔案在用戶瀏覽SOYL平台時記錄。日誌文件收集的資訊包括互聯網協議 (IP) 地址、瀏覽器類型、互聯網服務提供商 (ISP)、日期和時間、引用/退出頁面以及可能的點擊次數。以上這些資訊與任何可識別個人身份的資訊無關。資訊的目的是分析趨勢、管理網站、紀錄用戶在網站上的活動以及收集統計資訊。
  </Paragraph>
  <Paragraph title={'使用說明'}>
    與任何其他網站一樣，SOYL 使用“cookies”。這些 cookie 用於儲存資訊，包括訪問者的偏好以及訪問的網站頁面。這些資訊使用於在根據訪問者的瀏覽器類型或其他資訊來優化用戶體驗。
    有關 cookie 的更多一般資訊，請閱讀生成隱私政策網站上的 Cookie 文章。
  </Paragraph>
  <Paragraph title={'合作夥伴隱私政策'}>
    您可以查閱此列表以查找 SOYL 的每個廣告合作夥伴的隱私政策。
    第三方廣告服務器或廣告網絡使用 cookie、JavaScript 或網絡信標等技術，這些技術用於其各自的廣告和 SOYL 上出現的鏈接，這些鏈接直接發送到用戶的瀏覽器。發生這種情況時，他們會自動收到您的 IP 地址。這些技術用於衡量其廣告活動的有效性和/或個性化您在訪問的網站上看到的廣告內容。
    請注意，SOYL 無法訪問或控制第三方廣告商使用的這些 cookie。
  </Paragraph>
  <Paragraph title={'第三方隱私政策'}>
    SOYL 的隱私政策不適用於其他廣告商或網站。因此，我們建議您查閱這些第三方廣告服務器各自的隱私政策以獲取更多詳細資訊。它可能包括他們關於如何選擇退出某些選項的做法和說明。
    您可以通過您的個人瀏覽器選項選擇禁用 cookie。要了解有關特定網絡瀏覽器的 cookie 管理的更多詳細資訊，可以在瀏覽器各自的網站上找到。
  </Paragraph>
  <Paragraph title={'CCPA 隱私權聲明'}>
    根據 CCPA，除其他權利外，消費者有權利要求以下事項：<br/>
    收集消費者個人數據的企業披露企業收集的有關消費者的個人數據的類別和具體部分。<br/>
    企業刪除企業收集的有關消費者的任何個人數據。<br/>
    出售消費者個人數據的企業，而不是出售消費者個人數據。<br/>
    如果您提出請求，我們有一個月的時間回复您。如果您想行使這些權利中的任何一項，請與我們聯繫。<br/>
  </Paragraph>
  <Paragraph title={'GDPR 數據保護權利'}>
    我們希望確保您充分了解您的所有數據保護權利。每個用戶都有權獲得以下權利：<br />
    訪問權——您有權索取您的個人數據的副本。我們可能會為此服務向您收取少量費用。<br />
    更正權——您有權要求我們更正您認為不准確的任何資訊。您還有權要求我們填寫您認為不完整的資訊。<br />
    刪除權——在某些情況下，您有權要求我們刪除您的個人數據。<br />
    限制處理的權利——您有權要求我們在某些​​條件下限制對您的個人數據的處理。<br />
    反對處理的權利——在某些情況下，您有權反對我們處理您的個人數據。<br />
    數據可移植性的權利——在某些條件下，您有權要求我們將收集到的數據傳輸給另一個組織，或直接傳輸給您。<br />
    如果您提出請求，我們有一個月的時間回复您。如果您想行使這些權利中的任何一項，請與我們聯繫。<br />
  </Paragraph>
  <Paragraph title={'兒少保護聲明'}>
    我們優先考慮的另一部分是在使用互聯網時增加對兒童的保護。我們鼓勵父母和監護人觀察、參與和/或監控和指導他們的在線活動。
    SOYL 不會故意收集 13 歲以下兒童的任何個人身份資訊。如果您認為您的孩子在我們的網站上提供了此類信息，我們強烈建議您立即與我們聯繫，我們將盡最大努力及時刪除我們記錄中的此類信息。
  </Paragraph>
</Box>