import { useContext } from "react";
import { Box, Container, Avatar, Typography, Link, AppBar, Button, Toolbar, Stack, IconButton } from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import ReactMarkdown from "react-markdown";
import { NamedUser } from "../../../components/named-user";
import { UiContext } from "../../../context/app";
import { Communities, INftCollection, Summaries } from "../../../models";
import { useAppContext } from "../../../components/hooks";
import { $summary } from "../../../components/utils";
import { faDiscord, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Web } from "@mui/icons-material";

const summaries = [
  { title: 'COLLECTION_ITEMS', target: Summaries.TOTAL_SUPPLY, icon: undefined },
  { title: 'COLLECTION_OWNERS', target: Summaries.OWNERS, icon: undefined },
  { title: 'COLLECTION_FLOOR_PRICE', target: Summaries.LOWEST_DEALED_PRICE, icon: <img src='/assets/images/bb/ic-ethereum.svg' /> },
  { title: 'COLLECTION_VOLUME', target: Summaries.VOLUME_TRADED, icon: <img src='/assets/images/bb/ic-ethereum.svg' /> }
];
const icons = {
  WEBSITE: <Web fontSize='medium' />,
  DISCORD: <FontAwesomeIcon color="inherit" icon={faDiscord} />,
  TWITTER: <FontAwesomeIcon color="inherit" icon={faTwitter} />,
  INSTAGRAM: <FontAwesomeIcon color="inherit" icon={faInstagram} />,
}

interface CollectionInfoProps {
  collection: INftCollection,
}

const CollectionToolbar = ({ href }: { href: string }) => {
  const { strings } = useContext(UiContext);
  return (
    <AppBar position='fixed' color={'inherit'} sx={{ top: '48px', zIndex: 2 }}    >
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' href={href}>{strings('ADD_ITEM')}</Button>
      </Toolbar>
    </AppBar>
  )
}

const CommunitiyButtonGroup = (props: any) => {
  const communities = ['WEBSITE', 'DISCORD', 'TWITTER', 'INSTAGRAM'];
  const { mobile } = useContext(UiContext);
  const classes = useStyles({ mobile });
  if (!props.communities || Object.keys(props.communities).length === 0) return <></>;

  return (
    <>
      {
        communities.map(item => {
          if (!props.communities[item] || props.communities[item].length === 0) return;
          return (
            <IconButton
              className={classes.communitiyButton}
              key={item}
              target="_blank"
              href={props.communities[item]}>
              {icons[item as Communities]}
            </IconButton>
          )
        }
        )}
    </>
  )
}


export default ({ collection }: CollectionInfoProps) => {
  const { mobile, strings } = useContext(UiContext);
  const { wallet } = useAppContext();

  const COMMUNITY_BOX = mobile ? 'COMMUNITY_BOX_SX_MOBILE' : 'COMMUNITY_BOX_SX_DEFAULT'

  const styleOptions = {
    isMine: collection?.owner === wallet.account,
    mobile,
  };
  const classes = useStyles(styleOptions);

  const toolbar = styleOptions.isMine &&
    <CollectionToolbar href={`/nft/${collection?.address}/mint`} />

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.banner} sx={{ backgroundImage: `url(${collection?.bannerImage}), url(/assets/images/bb/img_default/default_cover.jpg)` }} />

      <Container maxWidth='md' className={classes.container} disableGutters={mobile}>
        <Box className={classes.topicArea}>
          <Avatar
            className={classes.logo}
            src={collection?.meta?.image} />
          <Typography variant='h4'>
            {collection?.meta?.name}
          </Typography>
          {
            collection?.owner &&
            <Typography>
              {strings('CREATED_BY')}&ensp;
              <Link variant='subtitle1' underline='none' href={`/profile/${collection?.owner}`} >
                @<NamedUser account={collection?.owner} />
              </Link>
            </Typography>
          }
        </Box>
        <Box className={classes[COMMUNITY_BOX]}>
          <CommunitiyButtonGroup communities={collection?.communities} />
        </Box>
        <Stack
          className={classes.summaries}
          direction='row'>
          {
            summaries.map(item =>
              <Box key={item.title} className={classes.summary}>
                <Typography className={classes.summaryNumber}>
                  {!!item.icon && <> {item.icon}&ensp;</>}
                  {$summary(collection || {}, item.target, 0)}
                </Typography>
                <Typography>{strings(item.title)}</Typography>
              </Box>
            )
          }
        </Stack>
        <Container className={classes.content}>
          <ReactMarkdown>
            {
              collection?.meta?.description ?
                collection.meta.description.replace(/\\n/g, "\n") : ''
            }
          </ReactMarkdown>
        </Container>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles({
  wrapper: {
    marginBottom: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  banner: {
    flex: '0 0 400px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderBottom: '1px solid black',
  },
  container: { alignItems: 'center', position: 'relative' },
  topicArea: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-66px',
  },
  logo: {
    height: 132, width: 132,
    border: '4px solid #000',
  },
  COMMUNITY_BOX_SX_MOBILE: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    margin: '20px 0 30px',
  },
  COMMUNITY_BOX_SX_DEFAULT: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    gap: '12px',
    top: '70px',
    right: '-5%',
  },
  communitiyButton: {
    height: '40px',
    width: '40px',
    p: '6px',
    border: '3px solid #00fff0',
    color: '#00fff0'
  },
  summaries: {
    marginTop: ({ mobile }: any) => mobile ? '' : '36px',
    padding: '16px 0 16px 0',
    justifyContent: 'space-around',
    background: '#252525',
    borderRadius: ({ mobile }: any) => mobile ? 0 : 50,
  },
  summary: {
    textAlign: 'center'
  },
  summaryNumber: {
    display: 'flex',
    fontSize: ({ mobile }: any) => mobile ? '23px' : '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center'
  },
  content: {
    padding: ({ mobile }: any) => mobile ? '31px 0 31px 0' : '35px 32px 58px 32px'

  }
});