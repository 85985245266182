import { Box } from "@mui/material";
import { Paragraph } from "../page-privacy-policy";

export const ZhTerms = () => <Box py={'26.5px'}>
  <Paragraph title={'前言'}>
    『SOYL』所有，NFT創藝整合交易平台提供購買和銷售藝術、時尚、音樂創作品等非同質化代幣NFT（Non-Fungible Token）的服務，包括但不限於視覺藝術作品、音頻藝術作品、音樂作品或其他創意作品。本平台展售創作者的作品（統稱為創意作品），個人可以瀏覽創意作品並進行購買該創意作品（購買收藏者僅持有該創意作品的所有權憑證與該創意作品所有權憑證的交易權，並不具有該創意作品本身的智慧財產權等等衍伸使用權利）。本平台提供創意作品內容預覽服務，使您能夠查看或聆聽創意作品的部份內容，部分作品享有僅購買者可查看或聆聽完整的創意作品。購買本平台之創意作品後也可於本平台上架進行銷售，詳細銷售流程與手續費用明細等細則請至Market Place查閱。
    <p />
    『SOYL』所有是以以太坊公鏈（Ethereum）為技術與平台開發基礎，SOYL平台上所有創意作品之所有權憑證與交易皆運行與紀錄於以太鏈上，創意作品之檔案則存放於SOYL平台伺服器上，以Meta中心化的形式進行管理且不公開智能合約。
    就這些條款而言，「用戶」、「您」和「您的」是指您作為服務的用戶。如果您代表公司或其他單位使用服務，則「您」包括您和該單位，並且您聲明並保證您是該單位的授權代表，有權約束該實體遵守這些條款，並且您代表單位同意這些條款。
    <p />
    點擊接受或使用我們的服務，即表示您同意受這些條款以及本文的所有條款的約束。如果您不同意這些條款，您將無法瀏覽或使用SOYL平台。
    下列聲明與服務條款將約束您瀏覽和使用SOYL之平台、API、應用程式以及與我們服務相關聯的任何其他軟體、工具、特性或功能等等；包括但不限於使用我們的服務進行查閱、購買和販售，並自行決定使用我們的工具與他人聯繫以在公共區塊鏈上購買、出售或轉讓 NFT（統稱為「服務」）。這些條款中的NFT是指在區塊鏈（例如以太坊區塊鏈）上使用智能合約連接或以其他方式與某些內容或數據相關聯的不可取代的代幣或類似的數位項目。
  </Paragraph>
  <Paragraph title={'服務聲明'}>
    SOYL不是加密錢包提供商、交易所、經紀人、金融機構或債權人。 SOYL提供點對點 web3.0之應用服務，幫助用戶在SOYL平台瀏覽和交易創意作品。要使用我們的服務，您必須使用可進行交易的第三方加密錢包。
    SOYL不參與任何用戶之間的任何協議。您將承擔從其他用戶處購買之創意作品的身份、合法性和真實性承擔全部責任，我們不對用戶或創意作品（以及任何相關內容）的身份、合法性、功能或真實性做出任何聲明。
    您的區塊鏈地址將成為您在SOYL上的身份。因此，您將需要一個區塊鏈地址和一個第三方加密錢包來訪問該服務。您在SOYL上的帳戶將與您的區塊鏈地址相關聯。
    <p />
    您在SOYL平台上的帳戶將與您連接的第三方區塊鏈加密錢包地址相關聯，並顯示該區塊鏈地址的 NFT。將您的錢包使用於SOYL的服務，即表示您同意根據適用的錢包提供商的條款和條件使用該錢包。錢包不由 SOYL營運、維護或附屬於SOYL，SOYL無法保管或控制您錢包的內容，也無法檢索或轉移其內容。 SOYL對於您使用錢包不承擔任何責任或義務，也不對服務將如何與任何特定錢包一起運行作出任何陳述或保證。您全權負責確保您的錢包安全，並且您不應與任何人分享您的錢包憑證或助記詞。如果您發現與您的錢包相關的問題，請聯繫您的錢包提供商。同樣，您對您的帳戶和任何相關的錢包問題負全部責任，我們不對您與您的帳戶有關的任何行為或不作為或由於您的帳戶或錢包被盜用而導致的任何行為或疏忽承擔責任。如果您發現或以其他方式懷疑與服務或您的帳戶相關的任何安全問題，您同意立即通知我們（您可以在此處與我們聯繫）。
  </Paragraph>
  <Paragraph title={'法律聲明'}>
    您需聲明並保證您在使用SOYL服務時將遵守所有適用的法律。在不限制上述規定的情況下，通過使用SOYL的服務，您聲明並保證：<br />
    (1) 您不在受當地政府禁運的國家；<br />
    (2) 您未被確定為特別指定國民或被列入任何政府禁止、制裁或限制名單。如果您在境外訪問或使用服務，您應全權負責確保您在該國家、地區或司法管轄區瀏覽和使用服務不違反任何適用法律。<br />
    <p />
    SOYL可能會在某些情況下要求您提供額外的資訊和文件，例如應任何政府機構的要求，按照任何適用法律或法規的規定，或調查可能違反這些條款的行為。在這種情況下，SOYL可自行決定禁用您的帳戶並阻止您使用 SOYL平台服務，直到SOYL處理此類附加資訊和文件。如果您沒有提供完整和準確的資訊來響應此類請求，SOYL可能會拒絕恢復您的平台使用權限。
    <p />
    您對 SOYL平台的瀏覽和使用可能會不時因多種原因而中斷，包括但不限於設備故障、定期更新、維護或維修服務或 SOYL自行決定的其他行為。
    我們要求所有用戶至少年滿 18 歲。如果您年滿 13 歲但未滿 18 歲，您只能通過父母或監護人的帳戶並在他們的批准和監督下使用 SOYL。該帳戶持有人應對您使用該帳戶的行為負責。如果您未滿 13 歲，則禁止使用我們的服務。
  </Paragraph>
  <Paragraph title={'所有權聲明'}>
    SOYL平台的設計（例如。文字、圖形、圖像、標誌、按鈕等視覺設計）、內容、資訊和其他素材，包括但不限於SOYL標誌和所有設計、文案、圖形、圖片、數據、軟體、聲音檔案等其他文件均為SOYL或我們的關聯單位、許可人或用戶（如適用）的專有財產，您同意不採取任何與此類所有權利益不一致的行動。我們和我們的關聯單位、許可人和用戶（如適用）保留與本服務及其內容有關的所有權利，包括但不限於創造衍生作品的專屬權利。
    <p />
    SOYL的名稱、標誌、商標以及任何SOYL產品或服務的名稱、設計、標誌都是SOYL或我們的關聯單位或許可人的知識產權，未經我們事先書面許可，不得複製、模仿或使用全部或部分內容。未經我們事先書面許可，您不得使用「SOYL」、「所有」、「SOYL所有」 或任何其他衍伸之名稱、商標、關聯單位或許可方的產品或服務名稱。此外，SOYL平台的「外觀 」構成SOYL的服務標誌、商標或品牌識別，未經SOYL書面許可，不得全部或部分複製、模仿或使用。
  </Paragraph>
  <Paragraph title={'用戶意見回饋聲明'}>
    本服務中提到的所有其他第三方商標、註冊商標和產品名稱，或與本服務中顯示的任何創意作品NFT相關聯的內容中所包含的商標、註冊商標和產品名稱，是其各自創作者的財產，未經適用的知識產權持有人的許可，不得全部或部分複製、模仿或使用。以名稱、商標、製造商、供應商或其他方式提及任何產品、服務、程序或其他信息，並不構成或暗示SOYL的認可、贊助或推薦。
    <p />
    我們歡迎對SOYL服務的意見回饋、評論和改進建議。您承認並明確同意，任何回饋的貢獻不會給予您在本平台服務的任何權利、所有權或利益。您同意SOYL可以以任何方式、為任何目的使用和公佈意見回饋，而無需進一步通知您或向您提供補償，且您無保留任何意見回饋的所有權或其他權利或索賠。您在此向SOYL轉讓您在任何意見回饋中可能擁有的所有權利和利益（包括但不限於任何專利、版權、商業機密、商標、技術展示、訣竅、精神權利及所有其他知識產權）。
  </Paragraph>
  <Paragraph title={'1. 條款'}>
    訪問本網站（可從 SOYL 訪問），即表示您同意受這些網站使用條款和條件的約束，並同意您對與任何適用的當地法律達成的協議負責。如果您不同意這些條款中的任何一項，則禁止您訪問本網站。本網站所含素材受版權和商標法保護。
  </Paragraph>
  <Paragraph title={'2. 使用許可'}>
    允許暫時下載 SOYL 網站的一份素材副本，僅供個人、非商業臨時查看。這是許可的授予，而不是所有權的轉讓，根據本許可，您不得：<br />
    修改或複製材料；<br />
    將這些材料用於任何商業目的或任何公開展示；<br />
    試圖對 SOYL 網站上包含的任何軟體進行逆向工程；<br />
    從素材中刪除任何版權或其他專有符號；<br />
    將素材轉移給其他人或將素材“鏡像”到任何其他服務器上。<br />
    這將使 SOYL 在違反任何這些限制時終止。終止後，您的瀏覽權也將終止，您應銷毀您擁有的任何下載材料，無論是印刷版還是電子版。<br />
  </Paragraph>
  <Paragraph title={'3. 免責聲明'}>
    SOYL 網站上的所有材料均按“原樣”提供。 SOYL 不作任何明示或暗示的保證，因此否定所有其他保證。此外，SOYL 不對使用其網站上的材料或與此類材料或與本網站鏈接的任何網站相關的準確性或可靠性作出任何陳述。
  </Paragraph>
  <Paragraph title={'4. 限制'}>
    SOYL 或其供應商不對使用或無法使用 SOYL 網站上的材料造成的任何損害負責，即使 SOYL 或本網站的授權代表已被口頭或書面通知這種損害的可能性。某些司法管轄區不允許限制默示保證或對附帶損害的責任限制，這些限制可能不適用於您。
  </Paragraph>
  <Paragraph title={'5. 修訂和勘誤表'}>
    SOYL 網站上出現的素材可能包括技術、印刷或攝影錯誤。 SOYL 不承諾本網站中的任何內容是準確、完整或最新的。 SOYL 可隨時更改其網站上的內容，恕不另行通知。 SOYL 不承諾更新內容。
  </Paragraph>
  <Paragraph title={'6. 連結'}>
    SOYL 並未審查與其網站連接的所有網站，並且不對任何此網站的內容負責。任何連結的存在並不意味著 SOYL 對該網站的認可。使用任何連接網站的風險由用戶自行承擔。
  </Paragraph>
  <Paragraph title={'7. 網站使用條款修改'}>
    SOYL 可隨時修改其網站的這些使用條款，恕不另行通知。通過使用本網站，您同意受這些使用條款和條件的當前版本的約束。
  </Paragraph>
  <Paragraph title={'8.您的隱私'}>
    請閱讀我們的隱私政策。
  </Paragraph>
  <Paragraph title={'9. 準據法'}>
    與 SOYL 網站有關的任何索賠均受TW法律管轄，不考慮其法律衝突規定。
  </Paragraph>
</Box >