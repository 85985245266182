export const EMPTY_DATA = {
  creator: '',
  collection: '',
  intro: '',
  spotlightIntro: '',
  marketplaceIntro: '',
  commonMarketTitle: '',
  commonMarketIntro: '',
  profile: {},
  statement: {},
  viewpoints: [{
    author: '',
    avatar: '',
    intro: '',
    title: '',
    desc: '',
    date: '',
    content: {},
    innerHTML: '',
  }],
  marketplace: {
    creator: '',
    collection: '',
    content: '',
  },
  spotlight: {
    creator: '',
    subtitle: '',
    quotetext: '',
    textassets: <string[]>[],
    imageassets: {
      md: <string[]>[],
      mdalt: <string[]>[],
    },
  }
};
const domToObj = (dom: Document) => {
  const output = { ...EMPTY_DATA };
  const node: HTMLDataElement = dom.getElementsByTagName('data')[0];
  output.creator = node.getAttribute('creator') || '';
  output.collection = node.getAttribute('collection') || '';
  output.intro = node.getAttribute('intro') || '';
  output.spotlightIntro = node.getAttribute('spotlightIntro') || '';
  output.marketplaceIntro = node.getAttribute('marketplaceIntro') || '';
  output.commonMarketTitle = node.getAttribute('commonMarketTitle') || '';
  output.commonMarketIntro = node.getAttribute('commonMarketIntro') || '';

  output.profile = node.getElementsByTagName('profile')[0];
  output.statement = node.getElementsByTagName('statement')[0];
  output.viewpoints = [];
  const viewpoints = node.getElementsByTagName('viewpoint');
  for (var i = 0; i < viewpoints.length; i++) {
    const elm = viewpoints[i];
    output.viewpoints.push({
      innerHTML: elm.innerHTML || '',
      author: elm.getAttribute('author') || '',
      avatar: elm.getAttribute('avatar') || '',
      intro: elm.getAttribute('intro') || '',
      title: elm.getAttribute('title') || '',
      desc: elm.getAttribute('desc') || '',
      date: elm.getAttribute('date') || '',
      content: elm.childNodes
    });
  }
  const spotlight = node.getElementsByTagName('spotlight')[0];
  output.spotlight = {
    creator: '',
    subtitle: '',
    quotetext: '',
    textassets: <string[]>[],
    imageassets: {
      md: <string[]>[],
      mdalt: <string[]>[],
    }
  };
  output.spotlight.creator = spotlight.getAttribute('creator') || '';
  output.spotlight.subtitle = spotlight.getAttribute('subtitle') || '';

  const textassets = spotlight.getElementsByTagName('textassets')[0].getElementsByTagName('p');
  for (var i = 0; i < textassets.length; i++) {
    const elm = textassets[i];
    if (elm.className == "quote") {
      output.spotlight.quotetext = elm.innerHTML;
      continue;
    }
    output.spotlight.textassets.push(elm.innerHTML || "");
  }
  const imageassets = spotlight.getElementsByTagName('imageassets')[0].getElementsByTagName('img');
  for (var i = 0; i < imageassets.length; i++) {
    const elm = imageassets[i];
    if (elm.className == "md") {
      output.spotlight.imageassets.md.push(elm.getAttribute('src') || '')
      output.spotlight.imageassets.mdalt.push(elm.getAttribute('alt') || '')
    }
  }
  return output;
}
const cache: { [key: string]: any } = {
  'zh-TW': undefined,
  'en-US': undefined
}
export const article = async (lang: string): Promise<any> => {
  if (!!cache[lang]) return cache[lang];
  const res = await fetch(`/assets/data/TTC-${lang}.xml`);
  const xmlasstring = await res.text();
  const dom = new DOMParser().parseFromString(xmlasstring, "text/xml");
  cache[lang] = domToObj(dom);
  return cache[lang];
}