import { ThemeProvider, createTheme } from "@mui/material";
import { PageBase } from "../../../components/page-base";

const campaignTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#7f7f7d',
    },
    divider: '#d1d1d1',
  },
  typography: {
    caption: {
      fontSize: 13,
    },
    h1: {
      fontSize: 32,
      fontWeight: 900,
    },
    h2: {
      fontSize: 28,
      fontWeight: 900,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 13,
    },
  },
});

export const PageBaseOfCampaign = (props:any) => 
  <PageBase {...props}>
    <ThemeProvider theme={campaignTheme}>
      {props.children}
    </ThemeProvider>
  </PageBase>;