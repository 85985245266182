import { createContext, useCallback, useEffect, useReducer } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { localization } from '../../strings';
import Cookies from 'universal-cookie';

const PREV_LANG = (() => {
  let l = new Cookies().get('lang');
  if(!l) l = localization.getLanguage();
  if(l !== 'en-US' && l !== 'zh-TW') l = 'en-US';
  return l
})();

export enum UiLangs {
  EN = 'en-US',
  CH = 'zh-TW',
}

export enum UiActions {
  SET_LANG,
  SET_MOBILE,
  SET_DRAWER,
  SET_USER_MENU,
  SET_LANG_MENU,
}

type UiAction = {
  type: UiActions;
  payload: any;
}

type UiState = {
  lang: string;
  mobile: boolean;
  showDrawer: boolean;
  showUserMenu: boolean;
  showLangMenu: boolean;
}

export const InitialUiState = {
  lang: PREV_LANG,
  mobile: false,
  showDrawer: false,
  showUserMenu: false,
  showLangMenu: false,
}

export const InitialUiContext = {
  ...InitialUiState,
  dispatchUi: (action:UiAction) => {},
  setLang: (value:UiLangs) => {},
  setDrawer: (value:boolean) => {},
  setUserMenu: (value:boolean) => {},
  setLangMenu: (value:boolean) => {},
  strings: (value:string):string => { return value; },
}

const reducer = (state:UiState, action:UiAction) => {
  const nextState = {...state};
  switch(action.type){
    case UiActions.SET_LANG:
      nextState.lang = action.payload; break;
    case UiActions.SET_MOBILE:
      nextState.mobile = action.payload; break;
    case UiActions.SET_DRAWER:
      nextState.showDrawer = action.payload; break;
    case UiActions.SET_USER_MENU:
      nextState.showUserMenu = action.payload; break;
    case UiActions.SET_LANG_MENU:
      nextState.showLangMenu = action.payload; break;
  }
  return nextState;
}

export const UiContext = createContext(InitialUiContext);
export const WithUiContextProvider = (props:any) => {
  const [ui, dispatchUi] = useReducer(reducer, InitialUiState);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const setLang = useCallback((payload:UiLangs) => 
    dispatchUi({type: UiActions.SET_LANG, payload}),
    [dispatchUi]
  )

  const setDrawer = useCallback((payload:boolean) => 
    dispatchUi({type: UiActions.SET_DRAWER, payload}), 
    [dispatchUi]
  );

  const setUserMenu = useCallback((payload:boolean) => 
    dispatchUi({type: UiActions.SET_USER_MENU, payload}), 
    [dispatchUi]
  );

  const setLangMenu = useCallback((payload:boolean) => 
    dispatchUi({type: UiActions.SET_LANG_MENU, payload}), 
    [dispatchUi]
  );

  const strings = useCallback((key:string) => {
    return localization.getString(key, ui.lang);
  }, [ui.lang]);

  useEffect(()=>{
    new Cookies().set('lang', ui.lang, { path: '/' });
    localization.setLanguage(ui.lang);
  }, [ui.lang]);

  return (
    <UiContext.Provider value={{
      ...ui, 
      mobile,
      strings,
      setLang,
      setDrawer,
      setUserMenu,
      setLangMenu,
      dispatchUi
      }}>
      {props.children}
    </UiContext.Provider>
  )
}