import { useState, useEffect } from 'react';
import {
  Button, Container, Grid,
  Typography, TextField,
  Card, CardContent, CardHeader, CardActions,
  ListItemButton, ListItemText, ListItem,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../components/hooks';
import { PageBase } from '../../components/page-base'
import { SellMethods } from '../../models/options/sell-options';
import { acceptOffer, listBiddings, listOffers, nftId, nftInfo, syncNftFromChain } from '../../services/market';
import { Chains, IBidConfigs, INftToken, IOfferConfigs, IPaged } from '../../models';
import { BidButton, BuyButton, OfferButton } from '../../pages/page-nft-bid';
import { sellButtonVisible } from '../../components/utils';

export const PageTest = () => {
  const navigate = useNavigate();
  const context = useAppContext();
  const { wallet, web3 } = context;
  const [tokenId, setTokenId] = useState<string | undefined>();
  const [contract, setContract] = useState<string | undefined>();
  const [offers, setOffers] = useState<IPaged<IOfferConfigs>>();
  const [bids, setBids] = useState<IPaged<IBidConfigs>>();
  const [nftToken, setNftToken] = useState<INftToken>();

  const accept = async (target?: string) => {
    if (!target || !nftToken) return;
    try {
      await acceptOffer(context, target);
      alert('accepted offer');
      const offers = await listOffers(context, { target: nftToken.id });
      setOffers(offers)
    } catch (error: any) {
      alert(error.message);
    }
  }

  const finishNft = async () => {
    if (!nftToken || !nftToken.id) return;
    try {
      // const res = await finishBid(context, {sellId:nftToken.id });
      // alert(`The buyer is ${res.dealTxInfo.buyer}`);
    } catch (error: any) {
      alert(error.message);
    }
  }

  const syncNft = async () => {
    if (!wallet || !contract || !tokenId || !wallet.chainId) return;

    return syncNftFromChain(
      wallet.chainId, contract, tokenId);
  }

  const reload = async () => {
    if (!context || !contract || !tokenId || !wallet.isConnected()) return;
    try {
      setOffers(undefined);
      setBids(undefined);

      const id = nftId(wallet.chainId as Chains, contract, tokenId);
      const tokenInfo = await nftInfo(context, id);

      setNftToken(tokenInfo);

      if (!tokenInfo.saleOptions ||
        tokenInfo.saleOptions.method === SellMethods.NOT_FOR_SELL ||
        tokenInfo.saleOptions.method === SellMethods.SELL_WITH_DECLINING_PRICE) {
        const offers = await listOffers(context, { target: tokenInfo.id });
        setOffers(offers);
      }

      if (tokenInfo.saleOptions && tokenInfo.saleOptions.method === SellMethods.SELL_TO_HIGHEST_BIDDER) {
        const bids = await listBiddings(context, { target: id });
        setBids(bids);
      }

    } catch (error: any) {
      alert(error.message);
    }
  }

  useEffect(() => {
    reload();
  }, [contract, tokenId, wallet]);

  return (
    <PageBase>
      <Container>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ m: 1 }}>Page Route</Typography>
            <Button variant="outlined" sx={{ m: 1 }} onClick={() => navigate('/')}>Home</Button>
            <Button variant="outlined" sx={{ m: 1 }} onClick={() => navigate('/collection/create')}>Create Collection</Button>
            <Button variant="outlined" sx={{ m: 1 }} onClick={() => navigate(`/nft/${contract}/mint`)}>Mint Nft</Button>

            <Button
              sx={{ m: 1 }}
              variant="outlined"
              onClick={() => navigate(`/collection/${contract || ""}`)}>
              Nft collection
            </Button>

            <Button
              variant="outlined"
              sx={{ m: 1 }}
              disabled={!tokenId}
              onClick={() => navigate(`/nft/${nftToken?.contract}/${nftToken?.tokenId}/sell`)}>
              Create Auction
            </Button>
            <Button
              variant="outlined"
              sx={{ m: 1 }}
              onClick={() => navigate(`/nft/${contract}/${tokenId}`)}>
              Nft detail
            </Button>

            <Button
              variant="outlined"
              sx={{ m: 1 }}
              onClick={syncNft}>
              Sync NFT
            </Button>
          </Grid>

          <Grid item md={6}>
            <Card sx={{ p: 1 }}>
              <TextField
                sx={{ m: 1 }}
                variant="outlined"
                label="Collection Contract"
                value={contract}
                onChange={(e) => setContract(e.target.value)} />
              <TextField
                sx={{ m: 1 }}
                variant="outlined"
                label="Token Id"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)} />
              {
                nftToken && <CardActions>
                  {
                    sellButtonVisible(nftToken, wallet.account) &&
                    <BuyButton
                      buttonProps={{ disabled: !wallet.isConnected() }}>
                      Buy Now
                    </BuyButton>
                  }

                  {
                    sellButtonVisible(nftToken, wallet.account, "BID") &&
                    <>
                      <BidButton
                        nft={nftToken}
                        buttonProps={{ disabled: !wallet.isConnected() }}>
                        Place Bid
                      </BidButton>
                      {
                        nftToken.owner === wallet.account && <Button
                          sx={{ mx: 1 }}
                          variant='outlined'
                          disabled={!wallet.isConnected() || !contract || !tokenId}
                          onClick={() => finishNft()}>
                          Finish Bid
                        </Button>
                      }
                    </>
                  }

                  {
                    sellButtonVisible(nftToken, wallet.account, "OFFER") &&
                    <OfferButton
                      nft={nftToken}
                      buttonProps={{ disabled: !wallet.isConnected() }}>
                      Make Offer
                    </OfferButton>
                  }
                </CardActions>
              }
            </Card>

            {
              offers && offers.items.length > 0 && <Card sx={{ my: 1 }}>
                <CardHeader title="Offers" subTitle={"Click to accpet offer"} />
                <CardContent>
                  {
                    offers.items
                      .sort((pre, cur) => Number(cur.price) - Number(pre.price))
                      .map(item => <ListItemButton key={item.id} onClick={() => accept(item.id)}>
                        <ListItemText primary={item.from} secondary={`PRICE: ${web3.utils.fromWei(item.price)} ETH`} />
                      </ListItemButton>)
                  }
                </CardContent>
              </Card>
            }

            {
              nftToken?.saleOptions?.method === SellMethods.SELL_TO_HIGHEST_BIDDER &&
              bids &&
              bids.items.length > 0 &&
              <Card sx={{ my: 1 }}>
                <CardHeader title="Bids" />
                <CardContent>
                  {
                    bids.items
                      .sort((pre, cur) => Number(cur.price) - Number(pre.price))
                      .map(item => <ListItem key={item.id}>
                        <ListItemText primary={item.bidder} secondary={`PRICE: ${web3.utils.fromWei(item.price)} ETH`} />
                      </ListItem>)
                  }
                </CardContent>
              </Card>
            }
          </Grid>
        </Grid>
      </Container>

    </PageBase >
  )
}