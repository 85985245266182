import { INftToken } from "../../models";
import { createView } from "../../views";

const { default: NftToolbarView } = createView('nft-toolbar-view');

interface NftToolbarCardProps {
  nft: INftToken,
  availableAmount?: number,
  onSaleTokens?: number,
}

export const NftToolbarCard = ({ nft, availableAmount, onSaleTokens }: NftToolbarCardProps) => {
  return (
    <NftToolbarView
      nft={nft}
      onSaleTokens={onSaleTokens}
      availableAmount={availableAmount} />
  )
}
