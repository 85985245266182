import firebase from "firebase/compat";
import { IContext, BookStates, INftCollection, INftToken, Summaries, IFees, IUser, IBanner } from "../../models";
import { document, documentsMatched } from "../db-utils";
import { readResaller } from "./crypto-readonly";
import { collectionId, expectedMaxSupply } from "./func-pure";
import { listSales } from "./service-trading";
import { createReadOnly as createReadOnlyNftService, web3ReadOnly } from "./crypto";
import { comission, royaltyInfo } from "./service-fee";
import { create as createNftService } from "./crypto/service-nft";
import moment from "moment";
import { exploreCollections, exploreNfts, exploreProfiles } from "./service-listing";


const _getNftCollectionSummary = 
  async (context:IContext, nft:INftToken):
  Promise<any> => {

    const output:any = {};

    if(!nft.chain || !nft.contract) return output;
    const web3 = web3ReadOnly(nft.chain);
    const nftc = await createNftService(web3, nft.chain, nft.contract);

    const colId = collectionId(nft.chain, nft.contract);
    const colData = (await document('collections', colId)) as INftCollection;
    if(!colData) return output;
    
    if(!!nftc.protocol)
      output[Summaries.COLLECTION_PROTOCOL] = nftc.protocol;
      
    if (!!colData?.meta?.name)
      output[Summaries.COLLECTION_NAME] = colData.meta.name;

    if (!!colData?.owner)
      output[Summaries.COLLECTION_OWNER] = colData.owner;
    
    if (!!colData?.meta?.image)
      output[Summaries.COLLECTION_ICON] = colData.meta.image;

    return output;

  }

const _getNftSellOptions =
  async (context: IContext, nft:INftToken):Promise<any> => {
    
    if(!nft.id) return undefined;

    const { chain, contract, tokenId } = nft;

    const sells = await listSales(context, { chain, contract, tokenId, state: BookStates.BOOKED });

    if(sells.length == 0)
      return undefined;

    const saleOptions = sells[0];

    if(!!saleOptions && saleOptions.version != 2 && !!nft.chain && !!saleOptions.bookId) {
      const resaller = readResaller(nft.chain);
      saleOptions.price = await resaller
        .methods
        .priceOf(saleOptions.bookId)
        .call();
    }

    return saleOptions;
  }

export const syncNftFromChain =
  async (chain:number, contract:string, tokenId:string):
  Promise<any> => firebase
    .functions()
    .httpsCallable("syncNft")({chain, contract, tokenId});

export const syncNftsOfCollectionFromChain =
  async (context: IContext, target: string):
  Promise<any> => firebase
    .functions()
    .httpsCallable("syncNftsOfCollectionFromChain")(target);



export const fillNftInfo = 
  async (context:IContext, nft:INftToken):Promise<INftToken> => {
    const [summaries, saleOptions] = await Promise.all([
      _getNftCollectionSummary(context, nft),
      _getNftSellOptions(context, nft)
    ]);
    if(!!summaries)
      nft.summaries = {...nft.summaries, ...summaries};

    if(!!saleOptions)
      nft.saleOptions = saleOptions;

    return nft;
  }

export const collectionInfo =
  async (context: IContext, id: string):
    Promise<INftCollection | null> => {
    const data = (await document('collections', id)) as INftCollection;

    if(!data || !data?.chain || !data?.address) return null;

    const nftc = await createReadOnlyNftService(data.chain, data.address);
    data.owner = await nftc.owner();

    return data;
  }

export const nftInfo =
  async (context: IContext, id: string, sync: boolean = false):
    Promise<INftToken> => {

    const nft = (await document('nfts',id)) as INftToken;
    const { chain, contract, tokenId } = nft;

    if (sync)
      firebase
        .functions()
        .httpsCallable("syncNft")({ chain, contract, tokenId });

    if(!nft) throw new Error('Token not exist.');
    
    return fillNftInfo(context, nft);
  }


// readonly helper functions

export const serviceFees =
  async (context: IContext, options: { chainId?:number, contract: string, tokenId: string }):
    Promise<IFees> => {
    const { contract, tokenId } = options;
    const { wallet } = context;

    const chainId = options.chainId || wallet.chainId;

    let serviceFee: number = 0;
    let creatorRoality: number = 0;

    try {
      serviceFee = await comission(context, chainId);
      creatorRoality = (await royaltyInfo(context, {chain: chainId, address: contract}, tokenId)).rate;
    } catch (err: any) {
      console.error(err.message);
      serviceFee = NaN;
      creatorRoality = NaN;
    }

    return {
      serviceFee,
      creatorRoality
    }
  }

export const onSaleAmount = 
async (context:IContext, seller:string, nft:INftToken) => {
  const listings = await documentsMatched('listing', {
    nftContract: nft.contract,
    tokenId: nft.tokenId,
    seller,
    bookState: BookStates.BOOKED
  });
  const onSale:number = listings.reduce((p,c) => p+c.quantity, 0);
  return onSale;
}

export const availableTokenAmount =
  async (context:IContext, seller:string, nft:INftToken) => {
    const { web3, trader } = context;
    if(!nft.chain || !nft.contract || !nft.tokenId)
      throw new Error("Nft data incorrect.");
    const nftc = await createNftService(web3, nft.chain, nft.contract);
    const isOfficialContract = await nftc.isOfficial(trader.options.address);
    const maxSupply = await nftc.maxSupply(nft.tokenId);
    const expectMaxSupply = expectedMaxSupply(nft?.mintSig, nft.chain);
    const owned:number = await nftc.ownedQuantity(nft.tokenId, seller);
    const listings = await documentsMatched('listing', {
      nftContract: nft.contract,
      tokenId: nft.tokenId,
      seller,
      bookState: BookStates.BOOKED
    });
    const onSale:number = listings.reduce((p,c) => p+c.quantity, 0);
    if(isOfficialContract){
      if(maxSupply === 0){
        return expectMaxSupply - onSale;
      }
    } 
    return owned - onSale;
  }


export const categories = async () =>
  (await document('configs','static')).categories;

// @todo 已搬至 base-types
type Banner = {
  id?:string,
  title?:string,
  image?:string,
  link?:string,
  state:'PUBLISHED'|'DRAFT',
  availableFrom:Date,
  expireAfter:Date,
  createAt:Date,
}

export const banners = async (context:IContext, key:string):Promise<IBanner[]> => {
  const results = await documentsMatched(`configs/site/banners/${key}/items`, { state: 'PUBLISHED' } );
  return results.filter(r => 
      moment().unix() >= r.availableFrom && 
      moment().unix() <= r.expireAfter
    );
};

export const featuredList = async (context:IContext, key:string):Promise<INftCollection[] | INftToken[] | IUser[]> => {
  let params = await document('configs/site/featured-lists', key);
  if(!params) return [];
  switch(params.type) {
    case 'COLLECTION':
      const rsCols = await exploreCollections(context, params);
      return rsCols.items;
    case 'NFT':
      const result = await exploreNfts(context, params);
      return result.items;
    case 'PROFILE':
      const rsUsers = await exploreProfiles(context, params);
      return rsUsers.items;
    default:
      return [];
  }
}