import { useState, useEffect } from "react";
import { useAppContext } from "../../components/hooks";
import { IBidConfigs, INftToken, SellMethods } from "../../models";
import { listBiddings, listOffers } from "../../services/market";
import { createView } from "../../views";

const { default: NftOfferView } = createView('nft-offer-view');

export const NftOfferCard = ({ nft }: { nft: INftToken }) => {
  const [offers, setOffers] = useState<IBidConfigs[]>([]);
  const context = useAppContext();

  const { chain, contract, tokenId } = nft;
  
  useEffect(() => {
    if (!chain || !contract || !tokenId) return;

    listOffers(context, { chain, contract, tokenId })
      .then((rs) => setOffers(rs.items))
      .catch(console.error);
      
  }, [chain, contract, tokenId]);

  return (
    <NftOfferView nft={nft} offers={offers} />
  )
}
