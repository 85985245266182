import { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { PageBaseOfCampaignTTC as PageBaseOfCampaign } from "../../components/page-base-campaign";
import { EMPTY_DATA, article } from '../../articles';
import { UiContext } from "../../../context/app";
import { StyledTitleTTC as StyledTitle } from "../../components/styled-title";

const ImgWithCaption = (props: any) =>
  <Box sx={{ ...props.sx }}>
    <img src={props.src} style={{ width: '100%', objectFit: 'fill' }} />
    <Typography
      align={'right'}
      sx={{ mt: '-4px', fontSize: '12px', fontStyle: props.fontStyle || 'normal' }}>
      {props.caption}
    </Typography>
  </Box>

export const PageSpotlightTTC = () => {
  const [data, setData] = useState<any>(EMPTY_DATA);
  const { mobile, lang } = useContext(UiContext);

  useEffect(() => {
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang]);

  return (
    <PageBaseOfCampaign sx={{ background: 'linear-gradient(to bottom, #1f1f1f, #575757)', color: 'white' }}>
      <Box sx={{ py: '167px' }}>
        <Container disableGutters maxWidth='md' sx={{ px: mobile ? 5 : 0 }}>
          <Grid container columnSpacing='43px' rowSpacing={mobile ? '49px' : '58px'}>
            <Grid item xs={12} display={mobile ? 'block' : 'none'}>
              <Typography variant="h1" >
                <StyledTitle>
                  {data.spotlight.creator}
                </StyledTitle>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} textAlign='center'>
              <img src={data.spotlight.imageassets.md[0]} style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
              <Typography variant="h1" display={mobile ? 'none' : 'block'}>
                <StyledTitle>
                  {data.spotlight.creator}
                </StyledTitle>
              </Typography>
              <Typography mt={mobile ? '0' : '41px'} dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[0] }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography
                dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[1] }} />
              <ImgWithCaption
                sx={{
                  mt: mobile ?
                    '38px' : lang === 'en-US' ?
                      '68px' : '120px'
                }}
                src={mobile ? data.spotlight.imageassets.md[1] : data.spotlight.imageassets.md[2]}
                caption={mobile ? data.spotlight.imageassets.mdalt[1] : data.spotlight.imageassets.mdalt[2]} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ mt: mobile ? -3 : 0 }}>
                <ImgWithCaption
                  src={mobile ? data.spotlight.imageassets.md[2] : data.spotlight.imageassets.md[1]}
                  caption={mobile ? data.spotlight.imageassets.mdalt[2] : data.spotlight.imageassets.mdalt[1]} />
                <Typography
                  mt={'38px'}
                  paragraph
                  dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[2] }} />
                <Typography dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[3] }} />
              </Box>
            </Grid>


            <Grid item xs={12} sm={12} md={12} mt={'32px'}>
              <Typography variant="h1" >
                <StyledTitle>
                  {data.spotlight.subtitle}
                </StyledTitle>
              </Typography>
              <Typography
                sx={{ mt: '30px' }}
                dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[4] }} />
              <ImgWithCaption
                sx={{ mt: '30px' }}
                src={data.spotlight.imageassets.md[3]}
                fontStyle={'italic'}
                caption={data.spotlight.imageassets.mdalt[3]} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}
              display={lang === 'en-US' ? 'normal' : 'none'}>
              <Typography
                // lang==='en-US' show this paragraph
                paragraph
                dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[5] }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} mt={lang === 'en-US' ? -3 : 0}>
              <ImgWithCaption
                src={data.spotlight.imageassets.md[4]}
                fontStyle={'italic'}
                caption={data.spotlight.imageassets.mdalt[4]} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} mt={lang === 'en-US' ? -3 : 0}>
              <Typography
                // lang==='en-US' don't show this paragraph
                display={lang === 'en-US' ? 'none' : 'normal'}
                paragraph
                dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[5] }} />
              <Typography
                paragraph
                dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[6] }} />

            </Grid>

            <Grid item xs={12} sm={12} md={12} mt={mobile ? -5 : -3}>
              <Typography dangerouslySetInnerHTML={{ __html: data.spotlight.textassets[7] }} />
            </Grid>
            {/* desktop bot quote */}
            <Grid item xs={12} sm={12} md={12} sx={{ display: mobile ? 'none' : 'normal' }}>
              <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.23)', p: '23px 28px 23px 28px' }}>
                <Typography variant='h1' align='left'>“</Typography>
                <Typography
                  variant='h4'
                  sx={{ pl: 2, lineHeight: 1.82 }}
                  dangerouslySetInnerHTML={{ __html: data.spotlight.quotetext }} />
                <Typography variant='h1' align='right'>”</Typography>
              </Box>
              <Box sx={{ display: 'flex', }}>
                <ImgWithCaption src={data.spotlight.imageassets.md[5]} />
                <ImgWithCaption
                  src={data.spotlight.imageassets.md[6]}
                  fontStyle={'italic'}
                  caption={data.spotlight.imageassets.mdalt[6]} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* mobile bot quote*/}
        <Box display={mobile ? 'normal' : 'none'}>
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.23)',
              mt: '30px',
              mb: '40px',
              p: '23px 28px 23px 28px',
            }}>
            <Typography variant='h1' align='left'>“</Typography>
            <Typography
              variant='h4'
              sx={{ pl: 2, lineHeight: 1.82 }}
              dangerouslySetInnerHTML={{ __html: data.spotlight.quotetext }} />
            <Typography variant='h1' align='right'>”</Typography>
          </Box>
          <Box sx={{ px: 5 }}>
            <ImgWithCaption src={data.spotlight.imageassets.md[5]} />
            <ImgWithCaption
              src={data.spotlight.imageassets.md[6]}
              fontStyle={'italic'}
              caption={data.spotlight.imageassets.mdalt[6]} />
          </Box>
        </Box>
      </Box>
    </PageBaseOfCampaign >
  );
}