import firebase from "firebase/compat";
import sha256 from "crypto-js/sha256";
import { IContext } from "../../models";


export const uploadImage =
  async (context: IContext, image: File, filename?: string):
    Promise<string> => {
    // todo: handle file mime type
    const imagePath = filename || `images/${sha256(`image-${new Date().getTime()}`)}.jpg`;
    const snapImg = await firebase
      .storage()
      .ref()
      .child(imagePath)
      .put(image);
    const imageUrl = await snapImg.ref.getDownloadURL();

    return imageUrl;
  }


export const uploadVideo =
  async (context: IContext, video: File, filename?: string):
    Promise<string> => {
    // todo: handle file mime type
    const imagePath = filename || `videos/${sha256(`video-${new Date().getTime()}`)}.mp4`;
    const snapImg = await firebase
      .storage()
      .ref()
      .child(imagePath)
      .put(video);
    const imageUrl = await snapImg.ref.getDownloadURL();

    return imageUrl;
  }

  
export const delImage = 
  async (context: IContext, imgUrl: string): Promise<void> => {
    if (!imgUrl) return;
    let name = imgUrl.substring(imgUrl.indexOf('%2F') + 3, imgUrl.indexOf('?'));
    name = name.replace('%20', ' ');
    await firebase
      .storage()
      .ref()
      .child(`images/${name}`)
      .delete();
  }