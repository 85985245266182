import { contractAt, web3ReadOnly } from "../utils/contractat";
import { INftFunc } from "./abstract";
import { NftFunc721 } from './nft-func-721';
import { NftFunc1155 } from './nft-func-1155';
import IERC165 from '../../../../contracts/IERC165.json';

const _INTERFACE_ID_ERC721 = '0x80ac58cd';
const _INTERFACE_ID_ERC1155 = '0xd9b67a26';

export const create = async (web3:any, chain: number, address:string):Promise<INftFunc> =>
{
  const erc165 = contractAt({
    chain, web3, address, 
    artifacts: IERC165
  });

  let func:INftFunc | null = null;

  if(await erc165.methods.supportsInterface(_INTERFACE_ID_ERC721).call().catch(()=>false))
    func = new NftFunc721(web3, chain, address);
  
  if(await erc165.methods.supportsInterface(_INTERFACE_ID_ERC1155).call().catch(()=>false))
    func = new NftFunc1155(web3, chain, address);

  if(func == null) 
    throw new Error("Unsupported contract protocol.");

  return func;
}

export const createReadOnly = (chain:number, address:string):Promise<INftFunc> =>
  create(web3ReadOnly(chain), chain, address);