import { useContext, useState } from "react";
import {
  Box, Stack, Drawer,
  OutlinedInput, Button, Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { UiContext } from "../../../context/app";
import { IListOptions } from "../../../models";
import { AdvanceOption } from "../styled-components";

const expandIcon = <img src='/assets/images/bb/filter-more.svg' />

export default ({ onApplyFilter, listOptions, showFilterDrawer, onClose }: any) => {
  const { strings, mobile } = useContext(UiContext);
  const classes = useStyles();
  const theme = useTheme();

  const [fileterListOptions, setFileterListOptions] = useState<IListOptions>({ ...listOptions, range: [0, 9999] });

  const BaseAdvOpts = () => {
    return (
      <>
        <Box>
          <AdvanceOption
            title='Price'
            expandIcon={expandIcon}
            accordionProps={{ defaultExpanded: true }}>
            <Stack direction='row' spacing='10px' sx={{ justifyContent: 'space-between' }}>
              <OutlinedInput
                placeholder='From'
                onChange={(e) =>
                  setFileterListOptions({
                    ...fileterListOptions,
                    range: [e.target.value, fileterListOptions.range && fileterListOptions?.range[1] || '9999']
                  })}
                value={fileterListOptions.range && fileterListOptions.range[0]}
                className={classes.fileterInput}
              />
              <OutlinedInput
                placeholder='To'
                onChange={(e) => setFileterListOptions({
                  ...fileterListOptions,
                  range: [fileterListOptions.range && fileterListOptions.range[0] || '0', e.target.value]
                })}
                value={fileterListOptions.range && fileterListOptions.range[1]}
                className={classes.fileterInput}
              />
            </Stack>
          </AdvanceOption>
          {/* @todo hide 尚無內容 */}
          {/* <AdvanceOption
          title='Tags'
          expandIcon={expandIcon}>
        </AdvanceOption> */}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: '20px',
        }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => {
              if (fileterListOptions.range && fileterListOptions.range[0] && fileterListOptions.range[1])
                onApplyFilter({
                  ...fileterListOptions,
                  page: 0,
                  range: [parseFloat(fileterListOptions.range[0]), parseFloat(fileterListOptions.range[1])]
                })
            }
            }
            sx={{ borderRadius: '8px' }}>
            {strings('APPLY')}
          </Button>
        </Box>
      </ >
    )
  }
  const MobileAdvDrawer = () => (
    <Drawer
      anchor="left"
      sx={{ zIndex: theme.zIndex.appBar - 1 }}
      open={showFilterDrawer}
      onClose={onClose}
      PaperProps={{
        sx: {
          mt: '55px',
          width: '100%',
          background: 'rgba(0, 0, 0, 0.95)',
        }
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: '20px 0 30px 0' }}>
        <Button
          sx={{ position: 'absolute', top: '16px', right: '8px' }}
          onClick={onClose}>
          Close
        </Button>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '22px',
            fontWeight: 'bold',
          }}>
          FILTER
        </Typography>
      </Box>
      <BaseAdvOpts />
    </Drawer>
  )
  const DesktopAdvOpts = () => {
    return (
      <Box sx={{ borderRadius: '12px', backgroundColor: theme.palette.secondary.main }}>
        <Typography
          variant='h6'
          paragraph
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '26px',
            pr: '16px'
          }}>
          {strings('FILTER')}
          <img src={'/assets/images/bb/btn-filter.svg'} />
        </Typography>
        <BaseAdvOpts />
      </Box>
    )
  }
  return (
    mobile ? <MobileAdvDrawer /> : < DesktopAdvOpts />
  )

};
const useStyles = makeStyles({
  fileterInput: {
    backgroundColor: ' #5c5c5c',
    borderRadius: '8px',
    maxHeight: '40px',
    '& fieldset': {
      border: 'none'
    },
    "& input::placeholder": {
      fontSize: '16px',
      fontWeight: 500,
    }
  },
  drawerFilterText: {
    fontSize: '22px',
    fontWeight: 'bold',
  }
})