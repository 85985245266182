import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import { NamedUser } from "../../../components/named-user";
import { $summary } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { INftCollection, Summaries } from "../../../models";

export default ({ collection }: { collection: INftCollection }) => {
  const { strings } = useContext(UiContext);
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea href={`/collection/${collection.chain}/${collection.address}`}>
        <CardMedia
          className={classes.cardMedia}
          component="img"
          image={collection.bannerImage} />
        <CardContent className={classes.cardContent}>
          <Avatar alt="logo" src={collection?.meta?.image} className={classes.avatar} />
          <Typography variant="h5">{collection.meta?.name}</Typography>
          <Box className={classes.contentBox}>
            <Typography>
              {strings("MY_COLLECTIONS_BY")}&ensp;
            </Typography>
            <Typography onClick={(e) => {
              e.preventDefault();
              navigate(`/profile/${collection.owner}`);
            }}>
              <NamedUser account={collection.owner} />
            </Typography>
          </Box>
          <Typography className={classes.collectionName}>
            {strings("MY_COLLECTIONS_DESCRIPTION").replace("[name]", collection.meta?.name || '')}
          </Typography>
          <Typography>{strings("MY_COLLECTIONS_TOTAL_SUPPLY").replace("[total]", $summary(collection, Summaries.TOTAL_SUPPLY))}</Typography>
        </CardContent>
      </CardActionArea>
    </Card >
  )
}
const useStyles = makeStyles({
  cardMedia: {
    objectFit: 'cover',
    height: 200,
    width: '100%'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentBox: { display: 'flex' },
  avatar: { background: 'inherit' },
  collectionName: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})