import { Box, Button, Card, CardHeader, CardContent, CardActions } from "@mui/material"
import { useStrings } from "../utils"

const STRINGS = {
  "ERROR_TITLE": "Sorry!",
  "ERROR_SUBTITLE": "There is a problem with the service, temporarily unable to read...",
  "ERROR_CONTENT": "Error: [message]",
  "RETRY": "Retry",
}

export const PageError = (props: any) => {
  const strings = useStrings(STRINGS);
  return (
    <Box>
    <Card sx={{ m: 4, p: 2 }}>
      <CardHeader
        title={strings("ERROR_TITLE")}
        subheader={strings("ERROR_SUBTITLE")}
      />
      <CardContent>
        <small>{strings("ERROR_CONTENT").replace("[message]", props.error.message)}</small>
      </CardContent>
      <CardActions>
        <Button onClick={props.onReload}>{strings("RETRY")}</Button>
      </CardActions>
    </Card>
    </Box>
  )
}