import { useContext, useState, useEffect } from "react";
import {
  Box, Stack, Card,
  Select, MenuItem,
  Button, Link, Typography,
  Alert,
  styled
} from "@mui/material";
import { ChildFriendly, SwapHoriz, PanTool, ShoppingCart, LocalOffer, NewReleases, Close } from "@mui/icons-material";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useAppContext } from "../../../components/hooks";
import { NamedUser } from "../../../components/named-user";
import { $price } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { ActivityType, INftToken, INftTokenActivities, IPaged } from "../../../models";
import { BBIcon } from "../../../assets/icons/bb";
import { SelectBB } from "../styled-components";

const eventIconMapping: { [key: string]: JSX.Element } = {
  'MINTED': <ChildFriendly />,
  'TRANSFER': <SwapHoriz />,
  'Bid': <PanTool />,
  'DEAL': <ShoppingCart />,
  'BOOKED': <LocalOffer />,
  'Cancel': <NewReleases />,
};

interface NftActivityViewProps {
  nft: INftToken,
  activies: IPaged<INftTokenActivities>,
  reloadActivities: (types?: string[]) => void,
  loading?: boolean
  error?: any | null;
}

export default ({ nft, activies, reloadActivities, loading, error }: NftActivityViewProps) => {
  const context = useAppContext();
  const { strings, mobile } = useContext(UiContext);
  const { wallet } = context;
  const [selected, setSelected] = useState<string[]>([]);
  const classes = useStyle({ mobile });

  const activitiesSelects = [
    { name: 'LISTINGS', val: ActivityType.Booked },
    { name: 'SALES', val: ActivityType.Deal },
    { name: 'TRANSFERS', val: ActivityType.Transfer }
  ];

  const ActivityColumns: GridColDef[] = [
    {
      field: 'type',
      headerName: strings('DATAGRID_EVENT'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        <Stack direction='row' spacing={1} >
          {eventIconMapping[params.value]}
          <Typography>{strings(params.value)}</Typography>
        </Stack>
    },
    {
      field: 'price',
      headerName: strings('DATAGRID_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.value ?
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <BBIcon icon={'ic_ethereum'} size={16} />
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{$price(params.value)}</Typography>
          <Typography className={classes.text}>ETH</Typography>
        </Stack>
        :
        <></>

    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Link href={`/profile/${params.value}`}>
            {params.value === wallet.account ? 'you' : <NamedUser account={params.value} />}
          </Link>
        </Box>
    },
    {
      field: 'to',
      headerName: strings('DATAGRID_TO'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Link href={`/profile/${params.value}`}>
            {params.value === wallet.account ? 'you' : <NamedUser account={params.value} />}
          </Link>
        </Box>

    },
    {
      field: 'timestamp',
      headerName: strings('DATAGRID_DATE'),
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        <Box className={classes.eleBox}>
          <Typography className={classes.text}>{moment.unix(params.value).fromNow()}</Typography>
        </Box>
    }
  ]


  const handleSelectChange = (value: string[]) => {
    setSelected(value)
  }
  const handleDelete = (chipToDelete: any) => () => {
    const types = selected.filter((chip: any) => chip !== chipToDelete)
    setSelected(types);
    reloadActivities(types);
  };

  const errorHint = (
    error ?
      <Alert severity="error">{error.message}</Alert>
      :
      <Alert severity="info">No Activitiy found for this token</Alert >
  )

  useEffect(() => { if (Object.values(nft).length !== 0) reloadActivities() }, [nft]);


  return (
    <Card variant='outlined' className={classes.activityCard} key={"activity"}>
      <SelectBB
        className={classes.select}
        fullWidth={!mobile}
        multiple
        displayEmpty
        defaultValue={selected}
        value={selected}
        renderValue={() => <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Filter</Typography>}
        onChange={(evt) => handleSelectChange(evt.target.value as string[])}
        onClose={() => reloadActivities(selected)} >
        {
          activitiesSelects.map((select) => (
            <MenuItem
              key={select.val}
              value={select.val}>
              {strings(select.name)}
            </MenuItem>
          ))
        }
      </SelectBB>
      <Box className={classes.selectBox}>
        {
          selected.map((select: string) => (
            <Button
              key={select}
              className={classes.selectButton}
              variant='contained'
              onClick={handleDelete(select)}
              endIcon={<Close />}>
              {strings(select)}
            </Button>
          ))
        }
        {
          selected.length > 0 && <Button
            className={classes.clear}
            onClick={() => {
              setSelected([]);
              reloadActivities([])
            }}>
            {strings('CLEAR')}
          </Button>
        }
      </Box>
      <DataGrid
        loading={loading}
        rows={activies?.items && activies?.items || []}
        columns={ActivityColumns}
        components={{
          NoRowsOverlay: () => errorHint,
        }} />
    </Card >
  )

};

const useStyle = makeStyles({
  activityCard: {
    flexGrow: 1,
    border: 0,
    borderTop: '1px solid #5c5c5c',
    borderRadius: 0,
    boxShadow: 'none',
    padding: ({ mobile }: any) => mobile ? 0 : '50px 30px 50px 30px',
    background: '#131313'
  },
  selectBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    margin: ({ mobile }: any) => mobile ? '11px' : '20px 0 20px 0'
  },
  select: {
    display: 'flex',
    maxHeight: '40px',
    margin: ({ mobile }: any) => mobile ? '20px 11px 0 11px' : '',
    color: '#fff',
    '& fieldset': {
      borderRadius: '8px',
      borderColor: '#c7c7c7',
      borderWidth: '3px',
    },
  },
  selectButton: {
    background: 'linear-gradient(to bottom, #737373, #575757 42%, #000)'
  },
  clear: { color: '#737373' },
  eleBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '15px',
    color: '#c7c7c7',
    textAlign: 'center',
  }
})