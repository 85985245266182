import { Box } from '@mui/material';

export const StyledTitle = (props:any) =>
  <>
    <Box sx={{
      display: 'inline-block',
      width: '24px',
      height: '7px',
      marginBottom: '14px',
      marginRight: '20px',
      backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)',
    }} />
    {props.children}
    <Box component="span" sx={{
      display: 'inline-block',
      width: '24px',
      height: '7px',
      marginBottom: '14px',
      marginLeft: '20px',
    }} />
  </>;