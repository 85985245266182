import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Container, Box, Grid, Drawer,
  Typography, OutlinedInput,
  Button,
  CircularProgress,
  useTheme,
  Stack,
} from '@mui/material';
import { Swiper as SwiperList, SwiperSlide } from "swiper/react/swiper-react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExploreOptions } from '../../services/market';
import { IListOptions, INftCollection, INftToken, Sorting } from '../../models';
import { useAppContext } from '../../components/hooks';
import { PageBase } from '../../components/page-base';
import { UiContext } from '../../context/app';
import { CollectionInfoCard } from './card-collectionInfo';
import { AdvCard } from './card-adv';
import { CollectionSimpleCard } from './card-collection-simple';
import { NftCard } from '../../components/card-nft';
import { SearchToolbarCard } from '../../components/search-toolbar-card';
import { AdvanceOptionsCard } from '../../components/advance-options-filter';
import { exploreCollections, exploreNfts } from '../../services/market/service-listing';


const COL_NUMBER = 3;
const DESKTOP_GAP = '16px';
const DESKTOP_GAP_DOUBLE = '32px';
const MOBILE_GAP = '8px';

const parseParams = (p: URLSearchParams, keys: string[]) => {
  let output: any = {};
  keys.forEach(k => {
    let dt = p.get(k);
    if (!!dt) output[k] = dt;
  });
  return output;
}


export const PageNftCollection = () => {
  const context = useAppContext();
  const { mobile } = useContext(UiContext);
  const theme = useTheme();
  // params
  const { contract, chainId } = useParams();
  const [searchParams] = useSearchParams();
  const { tags, sorting, range, status, search } = parseParams(
    searchParams, ['tags', 'sorting', 'range', 'status', 'search']);
  const chain = (() => {
    if (!!chainId) return parseInt(chainId);
    if (window.location.pathname === '/market') return 1;
    return undefined;
  })();
  // states
  const [nfts, setNfts] = useState<INftToken[]>([]);
  const [collections, setColletions] = useState<INftCollection[]>([]);
  const [listOptions, setListOptions] = useState<IListOptions>({
    page: 0,
    // perPage: 30,
    range: range || [0, 9999],
    tags: !!tags ? tags.split() : undefined,
    sorting: sorting || Sorting.RECENTLY_CREATED,
    status,
    contract,
    chain,
    search,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasNext, setHasNext] = useState(true);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const loadNext = () => {
    const curpage = listOptions?.page || 0;
    if (nfts.length !== 0)
      setListOptions({ ...listOptions, page: curpage + 1 });
  }

  const desktopAdvOptions = (
    <AdvanceOptionsCard
      listOptions={listOptions}
      onApplyFilter={(rs: any) => setListOptions(rs)} />
  )
  const MobileAdvDrawer = () => (
    <AdvanceOptionsCard
      listOptions={listOptions}
      showFilterDrawer={showFilterDrawer}
      onClose={() => setShowFilterDrawer(false)}
      onApplyFilter={(rs: any) => {
        setListOptions(rs);
        setShowFilterDrawer(false);
      }} />
  )
  
  const searchToolbar = (
    <SearchToolbarCard
      listOptions={listOptions}
      onChange={(changes: any) => {
        setListOptions({ ...listOptions, ...changes });
        setNfts([]);
      }}
      onOpenFilter={mobile ? () => setShowFilterDrawer(true) : undefined} />
  )

  useEffect(() => {
    const options: ExploreOptions = {
      matches: { chain },
      perPage: COL_NUMBER,
      sorting: Sorting.RECENTLY_CREATED,
    };
    setLoading(true);
    exploreCollections(context, options)
      .then((rs) => setColletions(rs.items))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [])
  useEffect(() => {
    const { page, perPage, sorting, status, chain, contract } = listOptions;
    const options: ExploreOptions = {
      page,
      perPage,
      sorting,
      matches: {},
    };
    if (!!chain) options.matches.chain = chain;
    if (!!contract) options.matches.contract = contract;
    if (listOptions.page && listOptions.page > 0) {
      setLoading(true);
      exploreNfts(context, options)
        .then((rs) => {
          setNfts([...nfts, ...rs.items]);
          setHasNext(rs.hasNext);
        })
        .catch((err) => {
          // setError(err);
          console.error(err)
          setNfts([]);
        })
        .finally(() => setLoading(false));
    }
    if (!listOptions.page || listOptions.page == 0) {
      setLoading(true);
      setNfts([]);
      exploreNfts(context, options)
        .then((rs) => {
          setNfts(rs.items);
          setHasNext(rs.hasNext);
        })
        .catch((err) => {
          // setError(err);
          console.error(err)
          setNfts([]);
        })
        .finally(() => setLoading(false));
    }

  }, [listOptions])

  return (
    <PageBase loading={loading} error={error} chainId={chainId} contract={contract}>

      {chainId && contract && <CollectionInfoCard chainId={chainId} contract={contract} />}
      {!contract && <AdvCard />}
      {!contract &&
        <Container maxWidth='md' disableGutters={mobile} sx={{ pb: DESKTOP_GAP_DOUBLE }}>
          <SwiperList
            slidesPerView={mobile ? 1.1 : COL_NUMBER}
            spaceBetween={16}
            centeredSlides={mobile}
            style={{ padding: DESKTOP_GAP }}
            onResize={(swiper) => { swiper.wrapperEl.style.justifyContent = mobile ? '' : 'center' }}>
            {
              collections.map(collection =>
                <SwiperSlide key={collection.id} style={{ maxWidth: mobile ? 'none' : '35vw' }} >
                  <CollectionSimpleCard collection={collection} />
                </SwiperSlide>
              )
            }
          </SwiperList>
        </Container>
      }

      <Container maxWidth='lg'>
        <Grid container rowSpacing={'32px'} columnSpacing={'28px'}>
          {
            !mobile &&
            <Grid item xs={12} sm={12} md={2.5} >
              {desktopAdvOptions}
            </Grid>
          }
          <Grid item xs={12} sm={12} md={9.5} px={DESKTOP_GAP}>
            {searchToolbar}
            <InfiniteScroll
              style={{ paddingTop: DESKTOP_GAP, paddingBottom: DESKTOP_GAP }}
              dataLength={nfts.length}
              next={loadNext}
              hasMore={hasNext}
              loader={<Box sx={{ pt: DESKTOP_GAP, textAlign: 'center' }}><CircularProgress /></Box>}>
              <Grid container spacing={'28px'}>
                {
                  nfts.map(nft =>
                    <Grid item xs={12} sm={12} md={4} key={nft.id} >
                      <NftCard nft={nft} />
                    </Grid>
                  )
                }
              </Grid>
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Container>

      {
        mobile && <MobileAdvDrawer />
      }

    </PageBase >
  );
};