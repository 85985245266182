import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import { IContext } from "../../models";
import { web3ReadOnly } from "./crypto";
import { nftId } from "./func-pure";
import { nftInfo } from "./service-info";

export const trackPageView = (context:IContext, page_path:string) => {
  const analytics = getAnalytics();
  setCurrentScreen(analytics, page_path);
  logEvent(analytics, "page_view", { page_path });
}

type TradedEventArgs = {
  chain:number,
  nftContract:string,
  tokenId:string,
  quantity:number,
  method:string, // TradingMethods in string
  seller:string,
  buyer:string,
  price:string,
  nonce:number,
}

export const trackTraded = async (context:IContext, eventArgs:TradedEventArgs) => {
  const { chain, nftContract, tokenId, } = eventArgs;
  const target = nftId(chain, nftContract, tokenId);
  const nft = await nftInfo(context, target);
  const web3 = web3ReadOnly(eventArgs.chain);
  const analytics = getAnalytics();
  const eventParams =  {
    value: web3.utils.fromWei(eventArgs.price),
    currency: 'ETH',
    transaction_id: eventArgs.nonce,
    items: [
      {
        name: nft?.meta?.name || `/nft/${nftContract}/${tokenId}`,
        target,
        chain,
        nftContract,
        tokenId,
      }
    ]
  };

  const options = { global: false };
  logEvent(analytics, "purchase");
}


// value?: EventParams['value'];
// currency?: EventParams['currency'];
// transaction_id: EventParams['transaction_id'];
// tax?: EventParams['tax'];
// shipping?: EventParams['shipping'];
// items?: EventParams['items'];
// coupon?: EventParams['coupon'];
// affiliation?: EventParams['affiliation'];