import { IContext, INftToken, INftCollection, Summaries } from "../../../models";
import * as NftTrade from './live-info-provider-trade';
import * as NftMarket from './live-info-provider-market';

import { collectionId } from "../func-pure";
import { document } from "../../db-utils";

export const _getNftCollectionSummary = 
  async (nft:INftToken):
  Promise<any> => {

    const output:any = {};

    if(!nft.chain || !nft.contract) return output;

    const colId = collectionId(nft.chain, nft.contract);
    const colData = (await document('collections', colId)) as INftCollection;

    if(!colData) return output;

    if (!!colData?.meta?.name)
      output[Summaries.COLLECTION_NAME] = colData.meta.name;

    if (!!colData?.owner)
      output[Summaries.COLLECTION_OWNER] = colData.owner;

    return output;

  }

export type UnSubscriber = {
  unsubscribe:()=>void;
}

export type NftContractInfo = {
  chain: number,
  contract: string,
  tokenId?: string,
}

export type SubscriptionCallback = {
  onResult: (token:INftToken) => void;
}

export interface ILiveInfoProvider {
  subscribeNftInfo(context:IContext, options: NftContractInfo & SubscriptionCallback & any):UnSubscriber
}


const LiveInfoProvider:string = process?.env?.REACT_APP_TRADE_PROVIDER || 'NftMarket'; // NftTrade
const LiveInfoProviders:{[key:string]:ILiveInfoProvider} = {
  "NftTrade": NftTrade as ILiveInfoProvider,
  "NftMarket": NftMarket as ILiveInfoProvider
}

export const subscribeNftInfo =  LiveInfoProviders[LiveInfoProvider].subscribeNftInfo;