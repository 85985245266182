import { Container, Paper } from "@mui/material";


export const StaticMessage = (props:any) => 
  <Container>
      <Paper sx={{
        p: 5,
        m: 5,
        ...props.sx,
      }}>
        {props.children}
      </Paper>
  </Container>