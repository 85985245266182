import { Box, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const DataGridHeader = (params: any) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{
      width: '5px',
      height: '11px',
      background: 'linear-gradient(to bottom, #c8c8c8, #808080)',
      mr: 1,
    }} />
    <Typography sx={{ textOverflow: 'ellipsis', fontSize: '13px' }}>
      {params.colDef.headerName}
    </Typography>
  </Box>

interface AdvanceOptionProps {
  title: string,
  children?: any,
  accordionProps?: any,
}
export const AdvanceOption = ({ title, children, accordionProps }: AdvanceOptionProps) =>
  <Accordion {...accordionProps} >
    <AccordionSummary expandIcon={<ExpandMore />}    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>;

export const StyledTooltip = (props: any) => {
  const { children, title, tooltipProps } = props;
  return (
    <Tooltip
      title={title || ''}
      placement='top'
      enterTouchDelay={0}
      PopperProps={{ sx: { "& .MuiTooltip-tooltip": { maxWidth: '80vw' } } }}>
      {children}
    </Tooltip>
  )
}