import { Box } from "@mui/material";
import { Paragraph } from "../page-privacy-policy";



export const EnTerms = () => <Box py={'26.5px'}>
  <Paragraph title={'Preface'}>
    The property of SOYL, this NFT creative art integrated trading platform provides services for the purchase and sale of non-fungible tokens (“NFTs”) such as art, fashion, and music creations, including but not limited to visual art works, sound art works, musical compositions, and other creative works. This platform displays and sells the works of creators (collectively referred to as “creative works”), and individuals can browse and purchase creative works on the platform (a collector who makes a purchase holds only the creative work’s certificate of ownership and the transaction rights to that certificate, and does not own any intellectual property rights or any right to derivative use with respect the creative work itself). This platform provides a service for previewing the content of creative works, enabling you to view or listen to a part of the creative work; some creative works can only be viewed or listened to in their entirety by the purchaser. After a creative work is purchased through this platform, it may also be offered for sale on this platform. For details of sales procedures and handling fees, please visit our Market Place.
    <p />
    The Ethereum blockchain provides the technical and platform development foundation for everything on the SOYL platform. All certificates of ownership and transactions of creative works are executed and recorded on the Ethereum blockchain, and the files of the creative works are stored on the SOYL platform server and managed through Meta centralization and non-public smart contracts.
    <p />
    For the purposes of these Terms and Conditions of Use (the “Terms”), “user”, “you” and “your” refer to you as a user of the services. If you are using the services on behalf of a company or other entity, “you” includes you and that entity, and you represent and warrant that you are the entity’s authorized representative, that you are authorized to bind that entity to these Terms, and that you agree to these Terms on behalf of the entity.
    <p />
    By clicking “accept” or by using our services, you agree to be bound by these Terms and all the terms and conditions herein. If you do not agree to these Terms, you will not be able to browse or use the SOYL platform.
    <p />
    The statements and terms of service below will be binding on you when you browse or use SOYL’s platform, APIs, applications, or any other software, tools, features, or functions associated with our services, including but not limited to your use of our services for viewing, purchase, or sale, and when, at your discretion, you use our tools to connect with others to purchase, sell, or transfer NFTs on a public blockchain (collectively, the “Services”). “NFT,” in these Terms, refers to an irreplaceable token or similar digital item on a blockchain (such as the Ethereum blockchain), which, through the use of smart contracts or other means, is linked to or otherwise associated with certain content or data.
  </Paragraph>
  <Paragraph title={'Statement of Services'}>
    SOYL is not a crypto wallet provider or an exchange, broker, financial institution, or creditor. SOYL provides peer-to-peer web3.0 application services to help users browse and trade creative works on the SOYL platform. To use our services, you must use a third-party crypto wallet with which you can undertake transactions.
    SOYL does not participate in any agreement between any users. You are solely responsible for the identity, legality, and authenticity of creative works purchased from other users, and we make no representations about the identity, legality, functionality, or authenticity of users or creative works, or any related content.
    Your blockchain address will become your identity on SOYL. You will therefore need a blockchain address and a third-party crypto wallet to access the services. Your account on SOYL will be linked to your blockchain address.
    <p />
    Your account on the SOYL platform will be linked to your connected third-party blockchain crypto wallet address, and will display NFTs for that blockchain address. By using your wallet for SOYL’s services, you agree to use the wallet in accordance with the terms and conditions of the applicable wallet provider. The wallet is not operated, maintained, or affiliated with SOYL, and SOYL cannot keep custody of or control the contents of your wallet, nor can it retrieve or transfer its contents. SOYL assumes no liability or obligation with respect to your use of the wallet, and makes no representations or warranties with regard to the way in which the services will function with any particular wallet. You are solely responsible for the security of your own wallet, and you should not share your wallet credentials or backup phrases with anyone. If you discover there is a problem with your wallet, please contact your wallet provider. Likewise, you are solely responsible for your account and any related wallet issues, and we are not responsible for any actions or omissions by you in relation to your account, or for any acts or omissions arising from the misappropriation of your account or wallet. If you become aware of or otherwise suspect any security issue in relation to the services or your account, you agree to notify us immediately (you can contact us here).
  </Paragraph>
  <Paragraph title={'Legal Statement'}>
    You represent and warrant that you will comply with all applicable laws when using SOYL services. Without limiting the foregoing, by using SOYL’s services, you represent and warrant that:<br />
    (1) You are not in a country embargoed by the local government;<br />
    (2) You have not been identified as a Specially Designated National or placed on a prohibited, sanctioned, or restricted list by any government. If you access or use the services from abroad, you are solely responsible for ensuring that your browsing and use of the Services in that country, region, or jurisdiction does not violate any applicable law.<br />
    <p />
    Under certain circumstances, SOYL may request additional information or documents from you, for example, at the request of a government agency, pursuant to the requirements of applicable laws or regulations, or in order to investigate conduct in possible violations of these Terms. In such an event, SOYL may, at its sole discretion, disable your account and prevent you from using the SOYL platform services until such time as SOYL has processed the additional information or documents. If you do not provide complete and accurate information in response to such a request, SOYL may refuse to restore your platform usage rights.
    <p />
    Your browsing and use of the SOYL platform may be interrupted at certain times for a variety of reasons, including but not limited to equipment failure, periodic updates, maintenance or repair services, or other actions which SOYL, at its sole discretion, may decide to take.
    <p />
    We require all users to be at least 18 years old. If you are 13 years of age or above but less than 18, you may only use SOYL through a parent or guardian’s account and with their approval and supervision, and the holder of that account shall be responsible for your use of it. If you are under the age of 13, use of our services is prohibited.
  </Paragraph>
  <Paragraph title={'Statement of Ownership'}>
    The design of the SOYL platform (e.g., the visual design of items such as text, graphics, images, logos, and buttons) and its content, information, and other materials, including but not limited to the SOYL logo and all designs, documents, graphics, pictures, data, software, audio files, and other documents, are the exclusive property of SOYL or our affiliates, licensors or users (as applicable), and you agree not to take any action inconsistent with such proprietary interests. We and our affiliates, licensors and users (as applicable), reserve all rights in relation to the services and its content, including but not limited to the exclusive right to create derivative works.
    <p />
    The SOYL name, logo, and trademark, and any SOYL product or service name, design, or logo are the intellectual property of SOYL or of our affiliates or licensors, and may not be reproduced, imitated, or used, in whole or in part, without our prior written permission. You may not use “SOYL,” “proprietary,” “SOYL owned,” or any other derivative name, trademark, or product or service name of an affiliate or licensor without our prior written permission. In addition, the “look” of the SOYL Platform constitutes a SOYL service mark, trademark, or brand identifier, and may not be reproduced, imitated, or used, in whole or in part, without the written permission of SOYL.
    <p />
    Any other third-party trademark, registered trademark, or product name mentioned in the services, and any trademark, registered trademark, or product name contained in content associated with any NFT creative work displayed in the services, is the property of its respective creator and may not be reproduced, imitated, or used, in whole or in part, without the permission of the applicable intellectual property holder. Reference to any product, service, program or other information through the use or mention of a name, trademark, manufacturer, supplier, or otherwise does not constitute or imply an endorsement, sponsorship, or recommendation by SOYL.
  </Paragraph>
  <Paragraph title={'Statement Regarding User Feedback'}>
    We welcome feedback, comments, and suggestions for the improvement of SOYL’s services. You acknowledge and expressly agree that a contribution of feedback does not confer upon you any right, ownership, or interest in the services of this platform. You agree that SOYL may use and publish your feedback in any manner and for any purpose without providing further notice or compensation, and that you will retain no ownership of or any other right or claim to any feedback. You hereby assign to SOYL any right of ownership or interest that you may have in any feedback, including but not limited to any patent, copyright, trade secret, trademark, presentation of technology, know-how, moral right, or any other intellectual property right.
  </Paragraph>
  <Paragraph title={'1.Terms'}>
    By accessing this website, accessible from SOYL, you are agreeing to be bound by these Terms, and agree that you are responsible for compliance with applicable local laws. If you disagree with any of these Terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.
  </Paragraph>
  <Paragraph title={'2. Use License'}>
    nload one copy of materials on SOYL’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:<br />
    modify or copy the materials;<br />
    use the materials for any commercial purpose or for any public display;<br />
    attempt to reverse engineer any software contained on SOYL’s website;<br />
    remove any copyright or other proprietary notations from the materials; or<br />
    transfer the materials to another person or “mirror” the materials on any other server.<br />
    <p />
    Violation of any of these restrictions will allow SOYL to terminate the use license. Upon termination, your right to browse the website will also be terminated, and you must destroy any downloaded materials in your possession, whether in printed or electronic format.
  </Paragraph>
  <Paragraph title={'3. Disclaimer'}>
    All materials on SOYL’s website are provided “as is.” SOYL makes no warranties, whether expressed or implied, and hereby negates all other warranties. Furthermore, SOYL makes no representations concerning the accuracy or reliability of the use of the materials on its website or otherwise in relation to such materials or to any sites linked to this Website.
  </Paragraph>
  <Paragraph title={'4. Limitations'}>
    In no event shall SOYL or its suppliers be liable for any damages that arise out of the use or inability to use the materials on SOYL’s Website, even if SOYL or an authorized representative of SOYL has been notified, orally or in writing, of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for incidental damages, these limitations may not apply to you.
  </Paragraph>
  <Paragraph title={'5. Revisions and Errata'}>
    The materials appearing on SOYL’s Website may include technical, typographical, or photographic errors. SOYL does not warrant that any of the materials on this website are accurate, complete, or current. SOYL may make changes to the materials contained on its website at any time without separate notice. SOYL does not make any commitment to update the materials.
  </Paragraph>
  <Paragraph title={'6. Links'}>
    SOYL has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The presence of a link does not imply endorsement by SOYL of the site. The use of any linked website is at the user’s own risk.
  </Paragraph>
  <Paragraph title={'7. Modifications to Site Terms and Conditions of Use'}>
    SOYL may revise these Terms and Conditions of Use for its website at any time without providing separate notice. By using this website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
  </Paragraph>
  <Paragraph title={'8. Your Privacy'}>
    Please read our Privacy Policy.
  </Paragraph>
  <Paragraph title={'9. Governing Law'}>
    Any claim related to the SOYL website shall be governed by the laws of Taiwan without regard to its conflict of law provisions.
  </Paragraph>
</Box>