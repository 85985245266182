type PathProps = {
  title: string;
  path: string;
  icon?: string;
  disabled?: boolean;
}
type PathsProps = {
  home: string;
  mainPaths: PathProps[];
  infoPaths: PathProps[];
  userPaths: PathProps[];
  communityPaths: PathProps[];
}
export const Paths: PathsProps = {
  home: '/',
  mainPaths: [
    { title: 'DRAWER_MARKETPLACE', path: '/market' },
    { title: 'DRAWER_SPOTLIGHT', path: '/spotlight' },
    { title: 'DRAWER_VIEWPOINT', path: '/viewpoint' },
  ],
  infoPaths: [
    { title: 'ABOUT_US', path: '/about' },
    { title: 'SERVICE', path: '/services' },
    { title: 'NEWSLETTER', path: '/newsletters' },
    { title: 'MEMBER_CENTER', path: '/member' },
    { title: 'PRIVACY_POLICY', path: '/policy' },
    { title: 'TERMS_OF_SERVICE', path: '/terms' },
  ],
  userPaths: Boolean(process?.env?.REACT_APP_ALLOW_PUBLIC_DEPLOY) == true ?
    [
      { title: 'PROFILE', path: '/profile' },
      { title: 'MY_COLLECTIONS', path: '/my-collections' },
      { title: 'SETTING', path: '/setting' }
    ] :
    [
      { title: 'PROFILE', path: '/profile' },
      { title: 'SETTING', path: '/setting' }
    ],
  communityPaths: [
    { icon: '/assets/images/ico-discode.svg', title: 'Discord', path: 'https://discord.gg/3EBYDY4myg' },
    { icon: '/assets/images/ico-fb.svg', title: 'Facebook', path: 'https://www.facebook.com/soylofficialtw/' },
    { icon: '/assets/images/ico-ig.svg', title: 'Instagram', path: 'https://www.instagram.com/soylofficial/' },
    { icon: '/assets/images/ico-line.svg', title: 'Line', path: 'https://lin.ee/xfs05mk' },
    // { icon: '/assets/images/ico-telegram.svg', title: 'Telegram', path: 'https://t.me/soylofficial' },
    { icon: '/assets/images/ico-twitter.svg', title: 'Twitter', path: 'https://twitter.com/soylofficialtw' },
  ],
}
type bbPathsProps = {
  bbMainPaths: PathProps[];
  bbInfoPaths: PathProps[];
  bbUserPaths: PathProps[];
}
export const bbPaths: bbPathsProps = {
  bbMainPaths: [
    { title: 'HOME', path: '/', icon: 'ic_home' },
    { title: 'MARKETPLACE', path: '/collection', icon: 'ic_market' },
  ],
  bbInfoPaths: [
    { title: 'POLICY', path: '/policy' },
    { title: 'TERMS', path: '/terms' },
  ],
  bbUserPaths: Boolean(process?.env?.REACT_APP_ALLOW_PUBLIC_DEPLOY) == true ?
    [
      { title: 'PROFILE', path: '/profile', icon: 'ic_person' },
      { title: 'MY_COLLECTIONS', path: '/my-collections',icon:'ic_collection' },
      { title: 'SETTING', path: '/setting', icon: 'ic_setting' }
    ] :
    [
      { title: 'PROFILE', path: '/profile', icon: 'ic_person' },
      { title: 'SETTING', path: '/setting', icon: 'ic_setting' }
    ],
}