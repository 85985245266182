import {
  Box, Typography, Button,
  ToggleButton, ToggleButtonGroup,
  Select, MenuItem, TextField,
  Grid, Container, Divider, IconButton,
  Snackbar, Alert,
} from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PageBase } from '../../components/page-base';
// import { NftCard } from '../../components/card-nft';
import { NftCard } from '../../components/card-soyl-nft';
import { $ipfs, genStrings } from '../../components/utils';
import { useAppContext } from '../../components/hooks';
import { ADDRESS_ZERO, Chains, CURRENCY_DEFAULT, ICoin, IFees, INftToken, ISellOptions, SellMethods, Summaries } from '../../models';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkIcon from '@mui/icons-material/Link';
import TelegramIcon from '@mui/icons-material/Telegram';
import moment from 'moment';
import { bookForSell, listCoins, nftId, nftInfo, serviceFees } from '../../services/market/';
import { MarketDialog, MarketDialogActions, MarketDialogContent } from '../../components/dialog-market';
import { CurrencySelector } from '../../components/currency-selector';
import { strings } from '../../strings';
import { LoadingButton } from '@mui/lab';

const Row = ({ children, sx }: any) => (<Box sx={{ my: 3, ...sx }}>{children}</Box>)

const TimeAuctionArea = ({ coins, sellOptions, onChange }: any) => {
  return <>
    <Row>
      <Typography>{strings("AUCTION_CREATE_METHOD")}</Typography>
      <Select
        fullWidth
        sx={{ flexGrow: 0.3 }}
        value={sellOptions.method}
        onChange={(e) => onChange({ method: e.target.value })}>
        <MenuItem
          value={SellMethods.SELL_TO_HIGHEST_BIDDER}>
          {strings(`AUCTION_CREATE_${SellMethods.SELL_TO_HIGHEST_BIDDER}`)}
        </MenuItem>
        <MenuItem
          value={SellMethods.SELL_WITH_DECLINING_PRICE}>
          {strings(`AUCTION_CREATE_${SellMethods.SELL_WITH_DECLINING_PRICE}`)}
        </MenuItem>
      </Select>
    </Row>

    <Row>
      <Typography>{strings("AUCTION_CREATE_STARTING_PRICE")}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CurrencySelector
          sx={{ flex: 0.3 }}
          val={sellOptions.currency}
          disableETH={sellOptions.method === SellMethods.SELL_TO_HIGHEST_BIDDER}
          coins={coins}
          onChange={(currency: any) => onChange({ currency })} />
        <TextField
          sx={{ flex: 1 }}
          type="number"
          value={sellOptions.startingPrice || ''}
          onChange={(e) => onChange({ startingPrice: e.target.value })} />
      </Box>
    </Row>

    <Row>
      <Typography>{strings("AUCTION_CREATE_DURATION")}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          type="datetime-local"
          sx={{ flex: 1 }}
          value={moment.unix(sellOptions.duration.begin).format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => onChange({ duration: { ...sellOptions.duration, begin: moment(e.target.value).unix() } })} />

        <TextField
          type="datetime-local"
          sx={{ flex: 1 }}
          value={moment.unix(sellOptions.duration.end).format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => onChange({ duration: { ...sellOptions.duration, end: moment(e.target.value).unix() } })} />
      </Box>
    </Row>

    {
      sellOptions.method === SellMethods.SELL_WITH_DECLINING_PRICE && <Row>
        <Typography>{strings("AUCTION_CREATE_ENDING_PRICE")}</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CurrencySelector
            sx={{ flex: 0.3 }}
            val={sellOptions.currency}
            coins={coins}
            onChange={(currency: any) => onChange({ currency })} />
          <TextField
            sx={{ flex: 1 }}
            type="number"
            value={sellOptions.endingPrice || ''}
            onChange={(e) => onChange({ endingPrice: e.target.value })} />
        </Box>
      </Row>
    }

  </>
}

const FixedPricerArea = ({ coins, sellOptions, onChange }: any) => {
  return <>
    <Row>
      <Typography>{strings("PRICE")}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CurrencySelector
          sx={{ flex: 0.3 }}
          val={sellOptions.currency}
          coins={coins}
          onChange={(currency: any) => onChange({ currency })} />
        <TextField
          type="number"
          sx={{ flex: 1 }}
          value={sellOptions.price || ''}
          onChange={(e) => onChange({ price: e.target.value })} />
      </Box>
    </Row>

    <Row>
      <Typography>{strings("AUCTION_CREATE_DURATION")}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          sx={{ flex: 1 }}
          type="datetime-local"
          value={moment.unix(sellOptions.duration.begin).format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => onChange({ duration: { ...sellOptions.duration, begin: moment(e.target.value).unix() } })} />
        <TextField
          sx={{ flex: 1 }}
          type="datetime-local"
          value={moment.unix(sellOptions.duration.end).format(moment.HTML5_FMT.DATETIME_LOCAL)}
          onChange={(e) => onChange({ duration: { ...sellOptions.duration, end: moment(e.target.value).unix() } })} />
      </Box>
    </Row>
  </>
}

const SaledDialog = ({ open, nft, onClose }: any) => {
  return (
    <MarketDialog
      fullWidth
      open={open}
      onClose={onClose}
      title={strings("AUCTION_CREATE_SALED_TITLE")}    >
      <MarketDialogContent>
        <Box sx={{ textAlign: 'center' }}>
          {
            nft &&
            nft.meta?.image && <img alt={nft.meta.name} src={$ipfs(nft.meta?.image)} width="300" />
          }
        </Box>
      </MarketDialogContent>
      <MarketDialogActions>
        <LoadingButton onClick={onClose}>
          {strings("AUCTION_CREATE_VIEW_BUTTON")}
        </LoadingButton>
      </MarketDialogActions>
    </MarketDialog >
  )
}

// TODO: share list click action
export const PageNftAuctionCreate = () => {
  const { chainId, contract = ADDRESS_ZERO, tokenId = '0' } = useParams();
  const navigate = useNavigate();
  const context = useAppContext();
  const { web3, wallet, market } = context;
  const [sellOptions, setSellOptions] = useState<ISellOptions>({
    method: SellMethods.FIXED_PRICE,
    currency: CURRENCY_DEFAULT,
    duration: {
      begin: moment().unix(),
      end: moment().add(7, 'd').unix()
    },
    target: ''
  })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nft, setNft] = useState<INftToken>();
  const [coins, setCoins] = useState<ICoin[]>([]);
  const [fees, setFees] = useState<IFees>();
  const [sellingViewer, setSellingViewer] = useState(false);
  const chain:number = !!chainId ? parseInt(chainId) : 1;
  const target = nftId(chain, contract, tokenId);

  const availableCoins = (sellOptions.method === SellMethods.SELL_TO_HIGHEST_BIDDER) ?
    coins.filter(coin => coin.address !== CURRENCY_DEFAULT) : coins;

  const invalidSubmission = (options: ISellOptions) => {
    let disabled = false;
    switch (options.method) {
      case SellMethods.FIXED_PRICE:
        disabled = !options.price || wallet.chainId !== chain;
        break;
      case SellMethods.SELL_TO_HIGHEST_BIDDER:
        disabled = !options.startingPrice || wallet.chainId !== chain;
        break;
      case SellMethods.SELL_WITH_DECLINING_PRICE:
        disabled = !options.startingPrice ||
          !options.endingPrice ||
          Number(options.startingPrice) < Number(options.endingPrice) ||
          wallet.chainId !== chain;
        break;
    }

    return disabled || !wallet.isConnected() || options.duration.end < options.duration.begin;
  }

  const book = useCallback(async () => {
    try {
      setError(null);
      setLoading(true);

      let options = {
        ...sellOptions,
        target
      };

      if (options.price) {
        options.price = web3.utils.toWei(options.price)
      }

      if (options.startingPrice) {
        options.startingPrice = web3.utils.toWei(options.startingPrice)
      }

      if (options.endingPrice) {
        options.endingPrice = web3.utils.toWei(options.endingPrice)
      }

      await bookForSell(context, options);
      setSellingViewer(true);
    } catch (error: any) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [sellOptions, context, setError, setLoading, setSellingViewer]);


  const reload = async () => {
    if (!contract || !tokenId) return;

    try{
      setError(null);
      setLoading(true);
      const [nextNft, nextFees, nextCoins] = await Promise.all([
        nftInfo(context, target),
        serviceFees(context, {chainId: chain, contract, tokenId}),
        listCoins(chain)
      ]);
      setNft(nextNft);
      setFees(nextFees);
      setCoins(nextCoins);
    }catch(err:any){
      setError(err);
    }finally{
      setLoading(false);
    }
  }

  const onChangeSellMethod = (method: SellMethods) => {
    if (method !== null) {
      setSellOptions({ ...sellOptions, method })
    }
  }

  useEffect(() => {
    reload()
  }, []);

  // useEffect(() => {
  //   reloadCoins();
  // }, [sellOptions.method, wallet]);

  return (
    <PageBase coverLoading loading={loading} error={error} onReload={reload}>
      <Snackbar open={wallet.chainId !== chain} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="warning" variant='filled' sx={{ py: 0, mt: '36px' }}>
          {strings('DETAIL_DIFF_CHAIN').replace('[NFT_CHAIN]', strings(Chains[chain]))}
        </Alert>
      </Snackbar>

      <Box
        onClick={() => navigate(-1)}
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer'
        }}>
        <ChevronLeftIcon />
        <img src={$ipfs(nft?.meta?.image)} style={{ width: 50, height: 50, objectFit: 'cover' }} />
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
          {
            nft?.summaries &&
            <Typography>{nft?.summaries[Summaries.COLLECTION_NAME]}</Typography>
          }
          <Typography>{nft?.meta?.name}</Typography>
        </Box>
      </Box>
      <Divider />
      <Container>
        <Grid container columnSpacing={10}>
          <Grid item xs={12} md={7} p={3}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold" }}>
              {strings("AUCTION_CREATE_TITLE")}</Typography>
            <Row>
              <Typography>{strings("AUCTION_CREATE_SALE_TYPE")}</Typography>
              <ToggleButtonGroup
                fullWidth
                exclusive
                value={sellOptions.method}
                onChange={(evt, val) => onChangeSellMethod(val)}>
                <ToggleButton value={SellMethods.FIXED_PRICE}>
                  {strings("AUCTION_CREATE_FIXED_PRICE")}
                </ToggleButton>
                <ToggleButton
                  selected={
                    [SellMethods.SELL_WITH_DECLINING_PRICE, SellMethods.SELL_TO_HIGHEST_BIDDER]
                      .includes(sellOptions.method)
                  }
                  value={SellMethods.SELL_TO_HIGHEST_BIDDER}>
                  {strings("AUCTION_CREATE_TIME_AUCTION")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Row>


            {
              sellOptions.method === SellMethods.FIXED_PRICE ?
                <FixedPricerArea
                  coins={availableCoins}
                  sellOptions={sellOptions}
                  onChange={(options: any) => setSellOptions({ ...sellOptions, ...options })} />
                :
                <TimeAuctionArea
                  coins={availableCoins}
                  sellOptions={sellOptions}
                  onChange={(options: any) => setSellOptions({ ...sellOptions, ...options })} />
            }
            <Divider />

            <Box my={3}>
              <Typography paragraph>{strings("FEES")}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body2'>{strings("SERVICE_FEE")}</Typography>
                <Typography variant='body2'>{100 * (fees?.serviceFee || 0)}%</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body2'>{strings("CREATOR_ROYALITY")}</Typography>
                <Typography variant='body2'>{100 * (fees?.creatorRoality || 0)}%</Typography>
              </Box>
            </Box>
            <Button
              disabled={invalidSubmission(sellOptions)}
              variant="contained"
              onClick={book}>
              {strings("AUCTION_CREATE_CREAT_BUTTON")}
            </Button>
          </Grid>

          <Grid item xs={12} md={5} sx={{ position: 'sticky', top: 0 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', my: 3 }}>
              {strings("AUCTION_CREATE_PREVIEW")}
            </Typography>
            {
              nft &&
              <Box sx={{ my: 3 }}>
                <NftCard nft={nft}
                  sx={{ backgroundColor: 'inherit' }}
                  contentSx={{ backgroundColor: 'inherit' }}
                  actionSx={{ backgroundColor: 'inherit' }}
                  hideButton />
              </Box>
            }
          </Grid>
        </Grid>
      </Container>

      {
        sellingViewer &&
        <SaledDialog
          open={sellingViewer}
          nft={nft}
          onClose={() => navigate(`/nft/${nft?.chain}/${nft?.contract}/${nft?.tokenId}`)} />
      }
    </PageBase>
  )
}