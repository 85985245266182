import { useState, useContext } from "react";
import { Box, Typography, Select, MenuItem, Alert } from "@mui/material";
import { Axis, Grid, XYChart, BarSeries, LineSeries } from "@visx/xychart";
import moment from "moment";
import { $price } from "../../../components/utils";
import { INftToken, INftTokenPricingHistory } from "../../../models";
import { UiContext } from "../../../context/app";

const PERIODS = [0, 7, 14, 30, 60, 90, 365];
const defaultMargin = { top: 30, right: 20, bottom: 30, left: 10 };

const determinePeriodType = (days: number) => {
  if (days === 0) return "ALL";
  if (days >= 365) return "YEAR";
  return "DAYS";
}

const caculateAveragePrice = (records: INftTokenPricingHistory[]) => {
  const priceList = records.map((record: any) => parseFloat($price(record.price)));
  return priceList.reduce((pre, current) => pre + current) / priceList.length;
}

const caculateVolume = (records?: any[]) => {
  // TODO: confirm the logic of volumn
  return 0;
}

const PeriodPriceDetail = (props: any) => (
  < Box >
    <Typography>{props.title}</Typography>
    <Typography sx={{ fontWeight: "bold" }}>{`Ξ${props.price}`}</Typography>
  </Box >
)

export default ({ nft, allPricing }: { nft: INftToken, allPricing: INftTokenPricingHistory[] }) => {
  const { strings } = useContext(UiContext);
  const [historyPeriod, setHistoryPeriod] = useState(0);

  const avgPrice = (allPricing && allPricing.length !== 0) ? caculateAveragePrice(allPricing) : undefined;
  const volume = caculateVolume(allPricing);
  const xAccessor = (d: INftTokenPricingHistory) => `${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('M')}/${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('D')}`;
  const yAccessor = (d: INftTokenPricingHistory) => $price(d.price);
  const pricing = PERIODS[historyPeriod] == 0 ? allPricing :
    allPricing.filter(history => {
      return history.timestamp > moment().add(-PERIODS[historyPeriod], 'days').unix()
    });

  if (!pricing || pricing.length === 0)
    return <Alert severity="info">There is no records</Alert>

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Select
          value={historyPeriod}
          onChange={(e) => setHistoryPeriod(Number(e.target.value))}>
          {
            PERIODS.map(period => (
              <MenuItem
                key={period}
                value={period}>
                {strings(`PERIOD_${determinePeriodType(period)}`).replace('[day]', period.toString())}
              </MenuItem>))
          }
        </Select>

        <PeriodPriceDetail
          title={strings(`${determinePeriodType(historyPeriod)}_AVG_PRICE`).replace('[day]', historyPeriod.toString())}
          price={avgPrice} />

        {
          determinePeriodType(historyPeriod) !== "ALL" && <PeriodPriceDetail
            title={strings(`${determinePeriodType(historyPeriod)}_VOLUME`).replace('[day]', historyPeriod.toString())}
            price={volume}
          />
        }
      </Box>

      {/* TODO: 1. dynamic width & height  */}

      <XYChart
        height={Math.max(300 - defaultMargin.bottom - defaultMargin.top, 250)}
        xScale={{ type: "band", padding: 0.8 }}
        yScale={{ type: "linear" }} >
        <Axis orientation='left' numTicks={5} />
        <Axis orientation='bottom' numTicks={Math.max(5, pricing.length)} />
        <Grid columns={false} />
        <BarSeries data={pricing} dataKey={"priceBar"} xAccessor={xAccessor} yAccessor={yAccessor} />
        <LineSeries data={pricing} dataKey={"priceLine"} xAccessor={xAccessor} yAccessor={yAccessor} />
      </XYChart>
    </>
  );
}