import { IContext, INftCollection, INftToken } from "../../../models";
import { collectionId, nftId } from "../func-pure";
import { isDealer } from "../service-auth";
import firebase from "firebase/compat";
import moment from "moment";

const deployNftContract =
  async (context: IContext, options: INftCollection, contractMetaURI: string, tokenBaseURI: string):
    Promise<string> => {
    const { wallet, web3 } = context;
    const artifects = require('../artifacts/NftWithRoality.json');
    const contract = new web3.eth.Contract(artifects.abi);
    const deployedContract = await contract.deploy({
      data: artifects.bytecode,
      arguments: [
        options.meta?.fee_recipient || wallet.account,
        options.meta?.seller_fee_basis_points || 0,
        options.meta?.name, "NFT",
        contractMetaURI,
        tokenBaseURI
      ]
    }).send({ from: wallet.account });
    return deployedContract.options.address;
  }

const mintNft =
  async (context: IContext, options: INftToken):
    Promise<string> => {
    const { wallet, web3 } = context;
    const artifects = require('../artifacts/NftWithRoality.json');
    const contract = new web3.eth.Contract(artifects.abi, options.contract);
    const { events, status } = await contract.methods.mint(wallet.account).send({ from: wallet.account });

    if (!status) throw new Error('Mint failed.');

    const tokenId = events.Transfer.returnValues.tokenId;

    return tokenId;
  }

export async function createCollection(
  context:IContext, 
  options:INftCollection
):Promise<INftCollection|null> {
  const { wallet } = context;
  if (!wallet.account) throw new Error("Wallet not connected.");
  if (!options.chain) throw new Error("Unknown Chain.");
  if (
    Boolean(process?.env?.REACT_APP_ALLOW_PUBLIC_DEPLOY) == false &&
    !await isDealer(context)) { throw new Error("Permission Denied."); }
    
  const { data: contractIndex } = await firebase
    .functions()
    .httpsCallable('createContractIndex')();
  const META_BASE_URI = process?.env?.REACT_APP_META_BASE_URI;
  const contractMetaURI = `${META_BASE_URI}/collections/${contractIndex}`;
  const tokenBaseURI = `${META_BASE_URI}/tokens/${contractIndex}/`;
  const address = await deployNftContract(context, options, contractMetaURI, tokenBaseURI);
  const id = collectionId(options.chain, address);
  const colData: INftCollection = {
    ...options,
    chain: options.chain,
    id,
    address,
    creator: wallet.account,
    owner: wallet.account, // @todo: must sync with chain
    timestamp: moment().unix()
  };

  await firebase
    .functions()
    .httpsCallable('registerCollection')({
      contractIndex,
      collectionId: id,
      data: colData
    });

  return colData;
}

export async function createToken(
  context:IContext, 
  options:INftToken
):Promise<INftToken|null> {
  if (!options.chain) throw new Error('Unknown Chain');
  if (!options.contract) throw new Error('Unknown Contract');
  const { wallet } = context;

  // Mint Token
  const tokenId = await mintNft(context, options);

  const id = nftId(options.chain, options.contract, tokenId);
  const nftData: INftToken = {
    ...options,
    id,
    tokenId,
    chain: options.chain,
    contract: options.contract,
    owner: wallet.account, // @todo: must sync with chain
    timestamp: moment().unix()
  }

  await firebase
    .functions()
    .httpsCallable('registerNft')(nftData);

  return nftData;
}