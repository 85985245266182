import { createView } from "../../views";

const { default: NftViewerView } = createView('nft-viewer-view');

export const NftViewerCard = ({ nft }: any) => {

  return (
    <NftViewerView nft={nft} />
  )
}
