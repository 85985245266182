import { IContext } from "../../../models";
import * as FeeProviderTrade from './fee-provider-trade';
import * as FeeProviderMarket from './fee-provider-market';

export type RoyaltyInfo = {
  rate: number, // 10% = 0.1
  account: string
}

export type ContractProps = {
  chain: number,
  address: string
}

export interface IServiceFeeProvidor {
  comission(context: IContext, chain: number):Promise<number>;
  setComission(context: IContext, chain: number, comission:number):Promise<void>;
  royaltyInfo(context: IContext, contractProps:ContractProps, tokenId:string):Promise<RoyaltyInfo>;
  setupRoyaltyInfo(context: IContext, contractProps:ContractProps, config: RoyaltyInfo):Promise<void>;
  maxFee(context: IContext, chain:number, nftContract:string, tokenId:string):Promise<string>;
}

const NftTradingType:string = process?.env?.REACT_APP_TRADE_PROVIDER || 'NftMarket'; // NftTrade
const RoyaltyServices:{[key:string]:IServiceFeeProvidor} = {
  "NftTrade": FeeProviderTrade as IServiceFeeProvidor,
  "NftMarket": FeeProviderMarket as IServiceFeeProvidor
}

export const comission = RoyaltyServices[NftTradingType].comission;
export const setComission = RoyaltyServices[NftTradingType].comission;
export const royaltyInfo = RoyaltyServices[NftTradingType].royaltyInfo;
export const setupRoyaltyInfo = RoyaltyServices[NftTradingType].setupRoyaltyInfo;
export const maxFee = RoyaltyServices[NftTradingType].maxFee;

