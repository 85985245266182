import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Link } from "@mui/material";

export default () => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography className={classes.text}>{'Copyright ©'}&nbsp;</Typography>
      <Link className={classes.text}>{'Bigamefi'}&nbsp;</Link>
      <Typography className={classes.text}>{'2022'}</Typography>
    </Box>
  )
};
const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
    margin: '76px 0 0',
    padding: '49px 0px 31px 0px',
    background: 'linear-gradient(to bottom, rgba(138, 138, 138, 0) 0%, #292929 100%)',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '13px',
  }
})