import { Chains, IContext } from "../../models";
import Web3 from 'web3';
import { contractAt, web3ReadOnly } from "./crypto/utils/contractat";

const INFURA_MAPPING:{[key:string]:string} = {
  '1': 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '3': 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '4': 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  '97': 'https://data-seed-prebsc-1-s1.binance.org:8545',
  '137': 'wss://rpc-mainnet.matic.network',
  '1337': 'http://192.168.50.189:7545',
  '80001': 'wss://matic-testnet-archive-ws.bwarelabs.com',
}

export const readWeb3 = (chain:Chains) => {
  if(!(chain in INFURA_MAPPING)) 
    throw new Error("Unidentified Chain.");

  return new Web3(INFURA_MAPPING[chain]);
}

export const readContract = (chain:Chains, artifacts: any, address?:string) => 
  contractAt({web3:web3ReadOnly(chain), chain, artifacts, address});

export const readMarket = (chain:Chains) => 
  readContract(chain, require('../../contracts/NftMarket.json'));

export const readResaller = (chain:Chains) => 
  readContract(chain, require('../../contracts/NftMarketResaller.json'));

export const readNftWithRoality = (chain:Chains, address:string) =>
  readContract(chain, require('../../contracts/NftWithRoality.json'), address);

export const readTrade = (chain:Chains) =>
  readContract(chain, require('../../contracts/NftTrade.json'));
