import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../components/hooks';
import {
  Container, Box, Grid,
  Tabs, Tab,
  Typography,
  Avatar,
  SxProps,
  Button,
  Alert,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { EventAvailable, Favorite, Gavel, LocalOfferOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import Cookies from 'universal-cookie';
import ReactMarkdown from 'react-markdown';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INftToken, IListOptions, Sorting, IUser, Chains } from '../../models';
import { listNfts, userInfo } from '../../services/market';
import { PageBase } from '../../components/page-base';
import { NftCard } from '../../components/card-nft';
import { Column, Row } from '../../components/layouts';
import { SearchToolbarCard } from '../../components/search-toolbar-card';
import { AdvanceOptionsCard } from '../../components/advance-options-filter';
import { UiContext } from "../../context/app";

import { TabCreated } from './tab-page-created';
import { TabFavorited } from './tab-page-favorited';
import { TabHidden } from './tab-page-hidden';
import { TabOffers } from './tab-page-offers';
import { TabActivity } from './tab-page-activity';
import { TabListings } from './tab-page-listings';
import { $addr } from '../../components/utils';

export const TABS = {
  COLLECTED: "COLLECTED",
  CREATED: "CREATED",
  FAVORITED: "FAVORITED",
  HIDDEN: "HIDDEN",
  ACTIVITY: "ACCTIVITY",
  OFFERS: "OFFERS",
  LISTINGS: "LISTINGS",
}

const UserInfo = (props: { user: IUser | null, navigate: NavigateFunction }) => {
  const { strings, mobile } = useContext(UiContext);
  const communitiesStyle: SxProps = mobile ?
    { display: 'flex', justifyContent: 'center', mt: '-35px' }
    : { position: 'relative', right: '-70%', top: '-35px' };

  return (
    <Box sx={{ mb: 4, position: 'relative', display: 'flex', flexDirection: 'column', boxShadow: '0 3px 5px 0 #d8d8d8', }}>
      <Box sx={{
        flex: '0 0 250px',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${props.user?.bannerImage})`,
        borderBottom: '1px solid black',
      }} />

      <Container maxWidth='md' sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            position: 'relative',
            left: 0,
            top: '-66px',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: mobile ? 'column' : 'row',
              alignItems: mobile ? 'center' : 'flex-end'
            }}>
            <Avatar
              sx={{
                height: 132, width: 132,
                border: '4px solid white',
                mr: mobile ? 0 : '33px'
              }}
              src={props.user?.image} />
            <Typography sx={{ fontWeight: 'bold', fontSize: '35px' }} variant='h4' paragraph={mobile ? true : false}>
              {props.user?.name || strings('UNNAMED')}
            </Typography>
            {
              !mobile &&
              <Box sx={{
                width: '7px',
                height: '24px',
                m: 'auto 32.6px 6px 30px',
                backgroundImage: 'linear-gradient(to bottom, #c8c8c8, #808080)'
              }} />
            }
            <Box display='flex'>
              <FontAwesomeIcon icon={faEthereum} size='lg' style={{ marginBottom: '8px' }} />&nbsp;
              <Tooltip
                PopperProps={{ sx: { "& .MuiTooltip-tooltip": { maxWidth: 'none' } } }}
                title={<Typography>{props.user?.address || ''}</Typography>}
                placement='bottom'>
                <Typography variant='h6' sx={{ fontSize: '15px', pb: '4px', textDecoration: 'underline' }}>
                  {$addr(props.user?.address)}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ ...communitiesStyle }}>
          <CommunitiyButtonGroup
            color='secondary'
            variant={mobile ? 'contain' : 'outlined'}
            sx={{ color: 'secondary.main' }}
            communities={props.user?.communities} />
        </Box> */}
        <Container sx={{
          position: 'relative',
          left: 0,
          top: '-40px',
        }}>
          <ReactMarkdown>{props.user?.description ? props.user?.description.replace(/\\n/g, "\n") : ''}</ReactMarkdown>
        </Container>
      </Container >
    </Box >
  )
};

const parseParams = (p: URLSearchParams, keys: string[]) => {
  let output: any = {};
  keys.forEach(k => {
    let dt = p.get(k);
    if (!!dt) output[k] = dt;
  });
  return output;
}

export const PageProfile = () => {
  // params
  const { address } = useParams(); // @todo: chagne parameter
  const [searchParams] = useSearchParams();
  const { tags, sorting, range, status } = parseParams(
    searchParams, ['tags', 'sorting', 'range', 'status']);
  const navigate = useNavigate();
  const context = useAppContext();
  const { wallet, web3 } = context;

  // state
  const { strings, mobile } = useContext(UiContext); // don't remove this
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>();
  const [selectedTab, setSelectedTab] = useState(TABS.COLLECTED);
  const [user, setUser] = useState<IUser | null>(null);
  const [nfts, setNfts] = useState<INftToken[]>([]);
  const [listing, setListing] = useState<INftToken[]>([])
  const [listOptions, setListOptions] = useState<IListOptions>({
    page: 0,
    perPage: 30,
    owner: address,
    tags: !!tags ? tags.split() : undefined,
    sorting: sorting || Sorting.PRICE_HIGH_TO_LOW,
    status,
    chain: address ? 1 : wallet.chainId as Chains
  });
  const [hasNext, setHasNext] = useState(true);

  const reload = useCallback(async () => {
    if (!listOptions.owner) return;
    try {
      setError(null);
      setLoading(true);
      let nftsRs = await listNfts(context, listOptions);
      setNfts([...nfts, ...nftsRs.items]);
      setHasNext(nftsRs.hasNext);
    } catch (error: any) {
      setError(error);
      setNfts([]);
    } finally {
      setLoading(false);
    }
  }, [listOptions]);

  const loadUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      setError(null)
      const userRs = await userInfo(context, listOptions.owner);
      setUser(userRs);
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [listOptions]);

  const loadNext = () => {
    const curpage = listOptions?.page || 0;
    setListOptions({ ...listOptions, page: curpage + 1 });
  }
  const changeListOptions = (changes: IListOptions) => {
    setListOptions({ ...listOptions, ...changes });
  }
  useEffect(() => {
    if (!wallet.isConnected() && !address) return;
    loadUserInfo()
  }, [loadUserInfo, listOptions]);

  useEffect(() => { reload() }, [listOptions, reload]);

  useEffect(() => {
    const account = new Cookies().get('account');
    if (!account && !address)
      navigate('/connect-wallet');

    if (wallet && wallet.isConnected() && wallet.account !== account)
      navigate('/connect-wallet');
    if (!address && wallet.isConnected() && wallet.account) {
      changeListOptions({ owner: wallet.account, chain: wallet.chainId })
      setNfts([]);
    }
  }, [wallet]);

  return (
    <PageBase
      loading={loading}
      error={error}
      onReload={reload}>

      <UserInfo user={user} navigate={navigate} />
      <Container>
        {!mobile ? <Tabs
          value={selectedTab}
          sx={{ borderBottom: '1px solid silver' }}
          onChange={(event: any, value: any) => setSelectedTab(value)}
          centered>
          <Tab label={strings('COLLECTED')} value={TABS.COLLECTED} iconPosition='start' icon={<Favorite />} />
          <Tab label={strings('ACTIVITY')} value={TABS.ACTIVITY} iconPosition='start' icon={<EventAvailable />} />
          <Tab label={strings('OFFERS')} value={TABS.OFFERS} iconPosition='start' icon={<Gavel />} />
          <Tab label={strings('LISTINGS')} value={TABS.LISTINGS} iconPosition='start' icon={<LocalOfferOutlined />} />
        </Tabs>
          :
          <Column alignItems='center' justifyContent='space-between' height='132px'>
            <Row sx={{ width: '216px', justifyContent: 'space-between' }}>
              <Button color={selectedTab === TABS.COLLECTED ? 'primary' : 'secondary'} onClick={() => setSelectedTab(TABS.COLLECTED)} sx={{ flexDirection: 'column', width: '90px' }}>
                <Favorite />
                {strings('COLLECTED')}
              </Button>
              <Button color={selectedTab === TABS.ACTIVITY ? 'primary' : 'secondary'} onClick={() => setSelectedTab(TABS.ACTIVITY)} sx={{ flexDirection: 'column', width: '90px' }}>
                <EventAvailable />
                {strings('ACTIVITY')}
              </Button>
            </Row>
            <Row sx={{ width: '216px', justifyContent: 'space-between' }}>
              <Button color={selectedTab === TABS.OFFERS ? 'primary' : 'secondary'} onClick={() => setSelectedTab(TABS.OFFERS)} sx={{ flexDirection: 'column', width: '90px' }}>
                <Gavel />
                {strings('OFFERS')}
              </Button>
              <Button color={selectedTab === TABS.LISTINGS ? 'primary' : 'secondary'} onClick={() => setSelectedTab(TABS.LISTINGS)} sx={{ flexDirection: 'column', width: '90px' }}>
                <LocalOfferOutlined />
                {strings('LISTINGS')}
              </Button>
            </Row>
          </Column>}
        <Column sx={{ flex: 1, }}>

          {/** Collected */}
          <Row sx={{ position: 'sticky', flex: 1, display: selectedTab === TABS.COLLECTED ? 'inherit' : 'none' }}>
            <Column sx={{ flex: '1', m: 2 }}>
              <InfiniteScroll
                dataLength={nfts.length}
                next={loadNext}
                hasMore={hasNext}
                loader={<h4>Loading...</h4>}>
                <Box sx={{ my: 5 }}>
                  {
                    !loading &&
                    nfts.length === 0 &&
                    <Alert severity='info'>{strings("COLLECTION_EMPTY")}</Alert>
                  }
                  <Grid container columnSpacing={mobile ? '20px' : '30px'} rowSpacing={'40px'}>
                    {
                      nfts.map((nft: INftToken) => (
                        <Grid item key={nft.id} xs={6} sm={6} md={4} lg={4} >
                          <NftCard nft={nft} />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </InfiniteScroll>
            </Column>
          </Row>
        </Column>
        {/** Created */}
        {selectedTab === TABS.CREATED && <TabCreated />}
        {/** Favorited **/}
        {selectedTab === TABS.FAVORITED && <TabFavorited />}
        {/** Hidden **/}
        {selectedTab === TABS.HIDDEN && <TabHidden />}
        {/** Activity **/}
        {selectedTab === TABS.ACTIVITY && <TabActivity />}
        {/** Offers **/}
        {selectedTab === TABS.OFFERS && <TabOffers />}
        {/** Listings **/}
        {
          selectedTab === TABS.LISTINGS &&
          <TabListings
            selectedTab={selectedTab}
            loading={loading}
            setLoading={setLoading}
            setError={setError}
            listing={listing}
            setListing={setListing}
            address={address} />
        }
      </Container>
    </PageBase >
  );
} 