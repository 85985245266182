import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { IContext, ICoin, IListOptions, INftCollection, INftToken, IPaged, Sorting, Summaries } from "../../../models";
import { documents } from "../../db-utils";
import { createReadOnly as createReadOnlyNftService} from "../../market/crypto/service-nft";
import { fillNftInfo } from "../service-info";

export const listCoins =
  (chainId: number): Promise<ICoin[]> =>
    documents(`/network-configs/${chainId}/currencies`);


export const listCollections =
  async (context: IContext, options: IListOptions):
    Promise<IPaged<INftCollection>> => {
    const args: any[] = [];

    const opt = {
      page: 0,
      perPage: 50,
      sort: Sorting.RECENTLY_LISTED,
      ...options
    }

    if (opt.owner)
      args.push(where('owner', '==', opt.owner));

    if (opt.chain)
      args.push(where('chain', '==', opt.chain));

    if (opt.tags)
      args.push(where('tags', 'array-contains-any', opt.tags));

    args.push(orderBy('timestamp', 'desc'));

    if (opt.page > 0) {
      const skipArgs = [...args];
      skipArgs.push(limit(opt.page * opt.perPage));
      const skipq = query<INftCollection>(collection(getFirestore(), 'collections'), ...skipArgs);
      const skipSnapshots = await getDocs(skipq);
      const skipRef = skipSnapshots.docs[skipSnapshots.docs.length - 1];
      args.push(startAfter(skipRef));
    }

    args.push(limit(opt.perPage));

    const items = (await documents('collections', { queryArgs: args })) as INftCollection[];

    await Promise.all(items.map(async col => {
      if(!col.chain || !col.address) return col;
      const nftc = await createReadOnlyNftService(col.chain, col.address);
      if (!col.summaries) col.summaries = {};
      col.summaries[Summaries.TOTAL_SUPPLY] = await nftc.totalSupply();
      return col;
    }));

    items.sort((a: any, b: any) => b.timestamp - a.timestamp);

    return {
      page: opt.page,
      perPage: opt.perPage,
      pages: 1, // not working
      total: items.length, // unknown
      hasNext: false, // @todo handle hasNext
      items
    }
  }


export const listNfts =
  async (context: IContext, options: IListOptions):
    Promise<IPaged<INftToken>> => {
    const args: any[] = [];
    const db = getFirestore();
    const colRef = collection(db, 'nfts');
    const { resaller } = context;
    let hasNext = true;
    const opt = {
      page: 0,
      perPage: 50,
      sort: options.sorting || Sorting.RECENTLY_CREATED,
      ...options
    }

    if (opt.owner)
      args.push(where('owners', 'array-contains', opt.owner));

    if (opt.chain)
      args.push(where('chain', '==', opt.chain));

    if (opt.contract)
      args.push(where('contract', '==', opt.contract));

    if (opt.tags)
      args.push(where('tags', 'array-contains-any', opt.tags));

    switch (opt.sort) {
      case Sorting.OLDEST:
        args.push(orderBy('timestamp', 'asc'));
        break;
      case Sorting.RECENTLY_CREATED:
      default:
        args.push(orderBy('timestamp', 'desc'));
        break;
    }
    args.push(orderBy('tokenId'));


    if (opt.page > 0) {
      const skipArgs = [...args];
      skipArgs.push(limit(opt.page * opt.perPage));
      const skipq = query<INftToken>(colRef, ...skipArgs);
      const skipSnapshots = await getDocs(skipq);
      const skipRef = skipSnapshots.docs[skipSnapshots.docs.length - 1];
      if (!skipRef)
        hasNext = false;
      else
        args.push(startAfter(skipRef));
    }

    args.push(limit(opt.perPage));

    const q = query<INftToken>(colRef, ...args);
    const querySnapshot = await getDocs(q);
    const items: INftToken[] = [];
    querySnapshot.forEach(doc => items.push({ ...doc.data(), id: doc.id }));
    if (querySnapshot.size < opt.perPage)
      hasNext = false;
    await Promise.all(
      items.map(
        async nft =>
          fillNftInfo(context, nft)
            .catch(console.error)));

    return {
      page: opt.page,
      perPage: opt.perPage,
      pages: querySnapshot.size / opt.perPage,
      total: querySnapshot.size,
      hasNext,
      items
    }
  }