import { ReactNode } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  DialogProps, DialogContentProps, DialogActionsProps,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface marketDialogProps extends DialogProps {
  title?: string,
  onClose?: () => void,
  children?: ReactNode,
}
interface marketDialogContentProps extends DialogContentProps {
  children?: ReactNode;
}
interface marketDialogActionsProps extends DialogActionsProps {
  children?: ReactNode
}

export const MarketDialogContent = (props: marketDialogContentProps) =>
  <DialogContent dividers {...props}>
    {props.children}
  </DialogContent>

export const MarketDialogActions = (props: marketDialogActionsProps) =>
  <DialogActions sx={{ justifyContent: 'center' }} {...props}>
    {props.children}
  </DialogActions>

export const MarketDialog = (props: marketDialogProps) =>
  <Dialog {...props} >
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {props.title}
      <IconButton onClick={props.onClose}>
        <Close fontSize="small" />
      </IconButton>
    </DialogTitle>
    {props.children}
  </Dialog >