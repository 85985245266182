import { Box } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UiContext } from "../../../../context/app";


export default ({ children }: { children: ReactNode }) => {
  const { mobile } = useContext(UiContext);
  const location = useLocation();
  const { contract } = useParams();
  
  let paddingTop = '88px'
  if (location.pathname === '/'
    || (location.pathname.includes('collection') && contract)
    || location.pathname.includes('profile'))
    paddingTop = '0';

  return (
    <Box style={{
      background: 'url(/assets/images/bb/bg_body.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center top',
      backgroundSize: '100vw',
      paddingTop: mobile ? '0' : paddingTop
    }}>
      {children}
    </Box>)

}