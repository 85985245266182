import { useEffect, useState, Fragment, useContext, useCallback } from "react";
import {
  Container, Grid, Box,
  Typography, Divider,
  Button, Link,
} from "@mui/material";
import { Swiper as SwiperList, SwiperSlide } from "swiper/react/swiper-react";
import Swiper from 'swiper/types/swiper-class';
import { Navigation, Grid as SwiperGrid } from "swiper";
import { useAppContext } from "../../../components/hooks";
import { PageBaseOfCampaignHHH as PageBaseOfCampaign } from "../../components/page-base-campaign";
import { SoylIcon } from "../../icons";
import { EMPTY_DATA, article } from '../../articles';
import { listNfts } from '../../service';
import { IListOptions, INftToken } from "../../../models";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import '../../../assets/swiper-button.css';
import { NftCard } from "../../../components/card-soyl-nft";
import { UiContext } from "../../../context/app";
import { useNavigate } from "react-router-dom";

const ContentItem = (props: any) => (
  <Box {...props}>

    <Typography variant="caption"  >
      {props.title}
    </Typography>

    <Typography variant="h1" sx={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
      {props.subtitle}
    </Typography>

  </Box>
);

const NavButton = (props: any) =>
  <Button fullWidth href={props.href} sx={{
    backgroundColor: 'inherit',
    fontSize: '22px',
    fontWeight: 'bold',
    p: 0,
    borderRadius: 0,
    ...props.sx
  }}
    startIcon={< Box width={'31px'} />} endIcon={< SoylIcon icon={props.icon} size={31} />}  >
    {props.children}
  </Button >

const StyledTitle = (props: any) =>
  <>
    <Box sx={{
      display: 'inline-block',
      width: '24px',
      height: '7px',
      marginBottom: '14px',
      marginRight: '20px',
      backgroundImage: 'linear-gradient(to right, #c8c8c8, #808080)',
    }} />
    {props.children}
    <Box component="span" sx={{
      display: 'inline-block',
      width: '24px',
      height: '7px',
      marginBottom: '14px',
      marginLeft: '20px',
    }} />
  </>;

export const PageHomeHHH = () => {
  const context = useAppContext();
  const { mobile, lang, strings } = useContext(UiContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(EMPTY_DATA);
  const [nfts, setNfts] = useState<INftToken[]>([]);
  const [listOptions, setListOptions] = useState<IListOptions>({});
  const [swiper, setSwiper] = useState<Swiper>();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const loadNext = () => {
    const curpage = listOptions?.page || 0;
    setListOptions({ ...listOptions, page: curpage + 1 });
  }

  const reload = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const rs = await listNfts(context, listOptions);
      setNfts([...nfts, ...rs.items]);
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [listOptions])

  useEffect(() => {
    reload();
    article(lang)
      .then(setData)
      .catch(console.error);
  }, [lang, listOptions]);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowSize(window.innerWidth))
    return () => window.removeEventListener('resize', () => setWindowSize(window.innerWidth));
  }, [])

  const HHH_link_caption = () => {
    return (lang === 'en-US') ? 'Collect 31 NFTs all at once to own the entire  #stayathome series by Hai-Hsing Huang' : 'mint 黃海欣NFT 一次擁有31張 #stayathome';
  }
  const HHH_link_subtitle = () => {
    return (lang === 'en-US') ? 'Sound Animation GIFs' : '[ GIF檔，含背景音效 ]';
  }

  return (
    <PageBaseOfCampaign >
      <Box sx={{ backgroundColor: '#242424' }}>
        <Container disableGutters>

          <Grid container p={0} m={0}>

            {/* Banner */}

            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff' }}>
              <img
                style={{ height: mobile ? '80vw' : '55vh', display: 'block' }}
                src="/assets/images/HHH/home-cover.jpg"
              />
            </Grid>


            {/* Artist Info */}

            <Grid item xs={12}
              px={mobile ? 2 : 5}
              py={2}
              sx={{
                display: 'flex',
                alignItems: mobile ? 'flex-start' : 'center',
                color: 'black',
                backgroundColor: 'white',
                flexDirection: mobile ? 'column' : 'row',
              }}>
              <Typography variant="body1" p={2} display={mobile ? 'none' : 'inherit'}>
                {data.intro}
              </Typography>
              <Divider flexItem sx={{ margin: '10px', display: mobile ? 'none' : 'inherit' }} orientation={mobile ? 'horizontal' : 'vertical'} />
              <ContentItem p={'10.5px'} title='Creator' subtitle={data.creator} />
              <Divider flexItem sx={{ margin: '10px' }} orientation={mobile ? 'horizontal' : 'vertical'} />
              <ContentItem p={'10.5px'} title='Collection' subtitle={data.collection} />
              <Divider flexItem sx={{ margin: '10px', display: mobile ? 'inherit' : 'none' }} orientation={'horizontal'} />
              <Typography variant="body1" p={2} display={mobile ? 'inherit' : 'none'}>
                {data.intro}
              </Typography>
            </Grid>
          </Grid>

        </Container>

        {/**
         * 此處因為使用 Grid spacing 會有邊界跑掉的問題，因此需要設定 overflow-x: hidden
         * 利用外層的退縮來修正 Grid 的偏移量。
         * 參考：https://stackoverflow.com/questions/61797942/reactjs-using-material-ui-grid-spacing
         */}
        <Container disableGutters sx={{
          overflowX: 'hidden',
          background: 'linear-gradient(to bottom, #3b3b3b, #575757 27%, #545353 59%, #111 104%)',
        }}>

          <Grid container p={5} spacing={5} px={mobile ? 5 : 19} sx={{
          }}>

            {/* Artist Spotlight */}
            <Grid item xs={12}>
              <Typography variant="h2" mb={4} align="center" sx={{ color: 'white' }}>
                <StyledTitle>
                  {data.creator}
                </StyledTitle>
              </Typography>
            </Grid>
            {!mobile ?
              <Grid item xs={12} display='flex'>
                <Box sx={{ flex: '1 1 372px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                  <Box sx={{ ml: '-29px', height: '2px', backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff 10%, #fff)' }} />
                  <img style={{ maxWidth: '372px', width: '100%' }} src="/assets/images/HHH/home-1.png" />
                </Box>
                <Box sx={{ width: '2px', my: '-20px', backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 5%, #fff 96%, rgba(255, 255, 255, 0))' }} />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ maxWidth: '528px', width: '100%', objectFit: 'cover' }} src="/assets/images/HHH/home-2.png" />
                </Box>
              </Grid>
              :
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }} >
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '65%', alignSelf: 'flex-start' }} >
                  <img style={{ width: 'calc(100% - 2px)', }} src="/assets/images/HHH/home-1.png" />
                  <Box sx={{
                    width: '2px',
                    mt: '-15px',
                    backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 10%, #fff)'
                  }} />
                </Box>
                <Box sx={{
                  height: '2px',
                  width: 'calc(100% + 34px)',
                  mx: '-17px',
                  backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff 5%, #fff 96%, rgba(255, 255, 255, 0))'
                }} />
                <img style={{ width: '100%', objectFit: 'cover' }} src="/assets/images/HHH/home-2.png" />

              </Grid>}
            <Grid item xs={12} md={12} pb={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {
                data.spotlightIntro.split('[BR]').map((p: string) =>
                  <Typography variant="body1" sx={{ color: 'white', textAlign: 'center' }}>
                    {p}
                  </Typography>
                )
              }
            </Grid>


          </Grid>
          <NavButton icon="navigate" href="/spotlight">Spotlight</NavButton>
        </Container>
        <Container disableGutters sx={{ backgroundColor: '#ffffff' }}>
          <a href="https://stayathome-web-6tapimffyq-de.a.run.app" target="_blank">
            <Box sx={{ display: mobile ? 'block' : 'flex' }}>
              <Box pl={mobile ? 3.25 : 11} pr={mobile ? 3.25 : 0} pt={mobile ? 5.5 : 14}>
                {mobile ?
                  <img src="/assets/images/HHH/home-3s.png" />
                  :
                  <img src="/assets/images/HHH/home-3.png" />
                }
              </Box>
              <Box>
                <Typography variant="h1" mt={mobile ? 0 : 20} ml={mobile ? 5 : 3} mr={mobile ? 5 : 15.5} sx={{ fontSize: '42px', color: "#000000" }}>{HHH_link_caption()}</Typography>
                <Typography variant="body1" mt={mobile ? 2.5 : 5.5} ml={mobile ? 5 : 3} mb={mobile ? 6 : 0} sx={{ color: "#000000" }}>{HHH_link_subtitle()}</Typography>
              </Box>
            </Box>
          </a>

          <NavButton icon="navigate" href="/market" sx={{ color: 'black' }}>COLLECTION</NavButton>
        </Container>

        <Container disableGutters>
          {/* Viewpoints */}
          <Grid item px={mobile ? 5 : 15} py={5} pb={15} xs={12} sx={{
            background: 'linear-gradient(to bottom, #c4c4c4, #ececec 11%, #ececec 90%, #c4c4c4)',
          }}>
            <Typography color="black" variant="h2" mb={10} align="center">
              <StyledTitle>
                VIEWPOINT
              </StyledTitle>
            </Typography>
            {data.viewpoints.map((dt: any, index: number) => (
              <Fragment key={dt.author}>
                <Typography color="black" variant="h3" mb={3} fontWeight='bold'>
                  <Box sx={{
                    width: '22px',
                    height: '5px',
                    margin: '0 0 5px 0',
                    backgroundColor: '#fff',
                  }} />
                  {dt.author}
                </Typography>
                <Typography color="black" variant="body1" mb={5}>
                  {dt.intro}
                  <Link color="#555555" href={`/HaiHsinHuang/viewpoint/${index}`}>{strings('MORE')}</Link>
                </Typography>
              </Fragment>
            ))}
          </Grid>
        </Container>
      </Box>
    </PageBaseOfCampaign >
  );
}