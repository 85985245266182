import { contractAt, ContractAtProps } from "./contractat";
import artifacts from '../../../../contracts/IERC165.json';

export enum InterfaceIds {
  IERC721 = '0x80ac58cd' ,
  IERC1155 = '0xd9b67a26',
  IRoality = '0xee6e3f1b',
}

export const supportsInterface = 
  (contractProps:ContractAtProps, interfaceId:string):
  Promise<boolean> =>
    contractAt({...contractProps, artifacts})
    .methods
    .supportsInterface(interfaceId)
    .call();