import { 
  IContext, IBidOptions, IOfferConfigs, 
  IOfferOptions, ISellConfigs, ISellOptions, IListOptions 
} from "../../../models";
import * as NftTrade from './nft-trade';
import * as NftMarket from './nft-market';

export interface ITradeProvider {
  bookForSell(context: IContext, options:ISellOptions):Promise<ISellConfigs>;
  cancelBook(context: IContext, target: string):Promise<void>;
  makeBid(context: IContext, options: IBidOptions):Promise<void>;
  purchase(context: IContext, options: { nftDocId:string, bookDocId: string }):Promise<void>;
  makeOffer(context: IContext, options: IOfferOptions):Promise<IOfferConfigs>;
  cancelOffer(context: IContext, target: string):Promise<void>;
  acceptOffer(context: IContext, target: string):Promise<any>;
  listSales(context: IContext, options: IListOptions):Promise<ISellConfigs[]>;
}

const TradeProvider:string = process?.env?.REACT_APP_TRADE_PROVIDER || 'NftMarket'; // NftTrade
const TradeServices:{[key:string]:ITradeProvider} = {
  "NftTrade": NftTrade as ITradeProvider,
  "NftMarket": NftMarket as ITradeProvider
}

export const bookForSell =  TradeServices[TradeProvider].bookForSell;
export const cancelBook =   TradeServices[TradeProvider].cancelBook;
export const makeBid =      TradeServices[TradeProvider].makeBid;
export const purchase =     TradeServices[TradeProvider].purchase;
export const makeOffer =    TradeServices[TradeProvider].makeOffer;
export const cancelOffer =  TradeServices[TradeProvider].cancelOffer;
export const acceptOffer =  TradeServices[TradeProvider].acceptOffer;
export const listSales =    TradeServices[TradeProvider].listSales;