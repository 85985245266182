import { useContext, useState, useCallback } from "react";
import {
  AppBar, Toolbar,
  Box, Drawer, List, Stack,
  IconButton, ListItemButton, Link,
  OutlinedInput,
  Typography,
  Avatar,
  Collapse, Grow,
  InputAdornment,
  Divider,
} from "@mui/material";
import { AccountBalanceWalletOutlined, DensityMedium, Search } from "@mui/icons-material";
import Cookies from "universal-cookie";
import { IUser } from "../../../../models";
import { Wallet } from "use-wallet/dist/cjs/types";
import { useAppContext } from "../../../../components/hooks";
import { UiContext, bbPaths } from "../../../../context/app";
import { makeStyles } from "@material-ui/styles";

import { BBIcon } from "../../../../assets/icons/bb";
import '../../../../index.css';
interface navProps {
  user: IUser | null,
  rightsideButtons?: any,
}
const DEFAULT_IMAGE_PATH = '/assets/images/bb/img_default';
const ICON_SIZE = 24;

export const ListDrawer = ({ user, open, close, }: { user?: IUser, open: boolean, close: (value: boolean) => void }) => {
  const { bbUserPaths, bbMainPaths, bbInfoPaths } = bbPaths;
  const { wallet } = useAppContext();
  const { strings, mobile } = useContext(UiContext);
  const classes = useStyles({ mobile });
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => close(false)}
      PaperProps={{
        className: classes.drawerPaper
      }}>
      <Box
        className={classes.bannerBox}
        sx={{ backgroundImage: `url(${user?.bannerImage}), url(${DEFAULT_IMAGE_PATH}/default_menu_cover.png)` }}>
        <Box className={classes.bannerShadow}>
          <Typography variant='h6'>{user?.name}</Typography>
        </Box>
        <Box
          className={classes.userLogo}
          sx={{ backgroundImage: user ? `url(${user?.image}), url(${DEFAULT_IMAGE_PATH}/default_avatar.png)` : '' }} />
      </Box>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => close(false)}>
          Close
        </Button>
      </Box> */}

      <Box className={classes.pathArea}>
        <List className={classes.lists}>
          {
            bbMainPaths.map((mainPath) =>
              <ListItemButton
                key={mainPath.title}
                component={Link}
                className={classes.drawerButton}
                href={mainPath.path}>
                <Box className={classes.drawerIconBox}>
                  <BBIcon icon={mainPath.icon} size={ICON_SIZE} />
                </Box>
                {strings(mainPath.title)}
              </ListItemButton>
            )
          }
        </List>
        <Divider className={classes.divider} />
        <List className={classes.lists}>
          {
            bbUserPaths.map((userPath) =>
              <ListItemButton
                key={userPath.title}
                component={Link}
                className={classes.drawerButton}
                href={userPath.path}>
                <Box className={classes.drawerIconBox}>
                  <BBIcon icon={userPath.icon} size={ICON_SIZE} />
                </Box>
                {strings(userPath.title)}
              </ListItemButton>
            )
          }

          <Box sx={{
            display: 'flex',
            gap: '16px',
            height: '40px',
            alignItems: 'center',
            paddingLeft: '32px',
            color: '#ADADAD',
          }}>
            <Box className={classes.drawerIconBox} />
            <Stack direction='row' gap={2} divider={<>/</>} >
              {
                bbInfoPaths.map((infoPath) =>
                  <Link
                    key={infoPath.title}
                    className={classes.drawerLink}
                    href={infoPath.path}>
                    {strings(infoPath.title)}
                  </Link>
                )
              }
            </Stack>
          </Box>
        </List>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px', marginTop: 'auto', marginBottom: '40px' }}>
        <ListItemButton
          className={classes.drawerButton}
          onClick={() => {
            new Cookies().remove('account', { path: '/' });
            wallet.reset();
            window.location.reload();
          }}>
          <Box className={classes.drawerIconBox}>
            <BBIcon icon={'ic_logout'} size={ICON_SIZE} />
          </Box>
          {strings('LOGOUT')}
        </ListItemButton>
      </Box>
    </Drawer >
  )
}

export const UserButton = ({ wallet, user }: { wallet: Wallet, user?: IUser }) => {
  const { mobile } = useContext(UiContext);
  return (
    wallet.isConnected() ?
      <IconButton href='/profile'>
        {
          user?.image ?
            <Avatar alt='You' src={user.image} sx={{ width: mobile ? 30 : 40, height: mobile ? 30 : 40, }} />
            :
            <BBIcon icon={'ic_person'} size={mobile ? 32 : 40} color='white' />
        }
      </IconButton >
      :
      <IconButton href={'/connect-wallet'}>
        <AccountBalanceWalletOutlined sx={{ fontSize: mobile ? 32 : 40 }} />
      </IconButton>)
}

export default ({ user, rightsideButtons }: navProps) => {
  const context = useAppContext();
  const { mobile } = useContext(UiContext);
  const { wallet } = context;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [search, setSearch] = useState('');
  const [isOpenSearchInput, setIsOpenSearchInput] = useState<boolean>(false);
  const classes = useStyles({ mobile });

  const inputRef = useCallback((node) => {
    if (isOpenSearchInput && node) {
      const timeout = setTimeout(() => {
        node.focus();
      }, 100);
      return () => { clearTimeout(timeout) };
    }
  }, [isOpenSearchInput]);

  const handleSearchPress: any = (e: KeyboardEvent) => {
    if (search.length <= 0) return;
    if (e.key === 'Enter')
      return window.location.replace(`/collection/?search=${search}`);
  }

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoBox}>
          <Link href='/' className={classes.logoLink}>
            <img src='/assets/images/bb/logo_bigamefi.svg' className={classes.logo} />
          </Link>
        </Box>
        <Box display='flex' sx={{ alignItems: 'center' }}>
          <IconButton onClick={() => setOpenDrawer(true)}>
            <DensityMedium fontSize='large' />
          </IconButton>
          <Collapse orientation='horizontal' in={!isOpenSearchInput} sx={{ marginLeft: '13px' }}>
            <IconButton
              onClick={() => setIsOpenSearchInput(!isOpenSearchInput)}
              className={classes.buttonSearch}>
              <Search fontSize='large' />
            </IconButton>
          </Collapse>
          <Grow in={isOpenSearchInput}>
            <OutlinedInput
              value={search}
              inputRef={inputRef}
              className={classes.searchInput}
              endAdornment={
                <InputAdornment position='end'>
                  <Search fontSize='large' sx={{ color: '#fff' }} />
                </InputAdornment>
              }
              onBlur={() => setIsOpenSearchInput(false)}
              onChange={e => setSearch(e.target.value)}
              onKeyPress={handleSearchPress} />
          </Grow>
        </Box>
        <Box className={classes.rightSideBox}>
          {rightsideButtons}
          <UserButton wallet={wallet} user={user || undefined} />
        </Box>
      </Toolbar>
      <ListDrawer user={user || undefined} open={openDrawer} close={setOpenDrawer} />
    </AppBar >
  );
};

const useStyles = makeStyles({

  appbar: {
    position: 'fixed',
    top: '24px',
    right: 'auto',
    width: '86%',
    maxWidth: '1240px',
    height: '80px',
    alignSelf: 'center',
    alignItem: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    background: 'rgba(0, 0, 0, 0.9)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 50,
  },
  drawerPaper: {
    padding: '0',
    width: ({ mobile }: any) => mobile ? '250px' : '17.3%',
    background: 'linear-gradient(87.69deg, #171717 2.29%, #252525 98.42%)',
  },
  bannerBox: {
    position: 'relative',
    flex: '0 0 120px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    boxShadow: '5px 0 4px rgba(0, 0, 0, 0.5)'
  },
  bannerShadow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(129.08deg, rgba(16, 16, 16, 0.6) 11.7%, rgba(40, 64, 64, 0.4) 100%)',
  },
  userLogo: {
    position: 'absolute',
    top: '100%',
    bottom: 0,
    right: '50%',
    left: '50%',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  pathArea: {
    paddingTop: '30px',
  },
  lists: {
    padding: '20px 0 20px 0px',
  },
  drawerIconBox: {
    width: ICON_SIZE + 2,
    height: ICON_SIZE + 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerButton: {
    height: '40px',
    paddingLeft: '32px',
    gap: '16px',
    color: '#ADADAD',
    '&:hover': {
      color: '#fff'
    }
  },
  drawerLink: {
    color: '#ADADAD',
    '&:hover': {
      color: '#fff'
    }
  },
  buttonSearch: {
    justifyContent: 'center',
  },
  searchInput: {
    maxHeight: '42px',
    backgroundColor: '#252525',
    '& fieldset': {
      border: '3px solid #5c5c5c',
      borderRadius: '8px',
    }
  },
  logoBox: {
    display: 'flex',
    position: 'absolute',
    width: '96.5%',
    justifyContent: 'center',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '100%',
    maxWidth: '250px',
    maxHeight: '45px',
    color: 'white'
  },
  rightSideBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  divider: {
    borderColor: '#3E3E3E'
  }
})
