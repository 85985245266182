import { ADDRESS_ZERO } from "../../../../models";
import { NftFuncBase, NftProtocol, Ownership, SearchEventProps, TransferEvent } from "./abstract";
import { contractAt } from "../utils/contractat";
import { send } from "../utils/send";
import { $num } from "../../func-pure";
import IERC721 from '../../../../contracts/IERC721.json';
import IERC721Metadata from '../../../../contracts/IERC721Metadata.json';
import ERC721Enumerable from '../../../../contracts/ERC721Enumerable.json';
import NFTWithErc1155UnmintedPersonal from '../../../../contracts/NFTWithErc1155UnmintedPersonal.json';


const REQUIRE_APPROVE_FOR_ALL = true; //process.env.REACT_APP_TRADE_PROVIDER === 'NftTrade';

export class NftFunc721 extends NftFuncBase {
  private c:any;
  private e:any;
  constructor(web3:any, chain: number, address:string){
    super(web3,chain,address);

    this.c = contractAt({
      chain, web3, address, 
      abi: [...IERC721.abi, ...IERC721Metadata.abi],
    });

    this.e = contractAt({
      chain, web3, address, 
      artifacts: ERC721Enumerable
    });

  }

  get protocol():NftProtocol {
    return NftProtocol.ERC721;
  }

  async ownershipsOf(options: SearchEventProps): Promise<{ [key: string]: Ownership; }> {
    const { tokenId } = options;
    // spec: when looking for single token, provide quicker way
    if(!!tokenId){
      const output:any = {};
      const owner = await this.c.methods.ownerOf(options.tokenId).call();
      if(owner !== ADDRESS_ZERO){
        output[owner] = {};
        output[owner][tokenId] = 1;
      }
      return output;
    }

    return super.ownershipsOf(options);
  }

  async ownersOf(options:SearchEventProps):Promise<string[]> {
    // note: Always retrn the latest ownership while tokenId exist.
    // spec: When looking for account of single token, there's a quicker way.
    if(!!options.tokenId)
      return [await this.c.methods.ownerOf(options.tokenId)];

    return super.ownersOf(options);
  }

  async ownersCountOf(options: SearchEventProps): Promise<number> {
    // note: Always retrn the latest ownership while tokenId exist.
    // spec: When looking for account of single token, there's a quicker way.
    if(!!options.tokenId)
      return await this.exists(options.tokenId) ? 1 : 0;
    
    return super.ownersCountOf(options);
  }

  async exists(tokenId: string): Promise<boolean> {
    try{
      const owner = await this.c.methods.ownerOf(tokenId).call();
      return owner !== ADDRESS_ZERO;
    }catch(error){
      // spec: in some case, access owner of non-existed token may cause error
      return false;
    }
  }

  async isOwner(tokenId: string, account: string): Promise<boolean> {
    return (await this.c.methods.ownerOf(tokenId).call()) === account;
  }

  async isApproved(tokenId: string, owner: string, operator: string): Promise<boolean> {
    if(REQUIRE_APPROVE_FOR_ALL)
      return (await this.c.methods.isApprovedForAll(owner, operator).call());
    else
      return (await this.c.methods.getApproved(tokenId).call()) === operator;
  }
  
  async isOfficial(nftTradeAddress: string): Promise<boolean> {
      return false;
  }
  
  async ownedQuantity(tokenId: string, owner: string): Promise<number> {
    return (await this.c.methods.ownerOf(tokenId).call()) === owner ? 1 : 0
  }

  async totalSupply(tokenId?: string): Promise<number> {
    let totalSupply = NaN;
    try{
      totalSupply = parseInt(await this.e.methods.totalSupply().call());
    } catch (err) {
      totalSupply = NaN;
    }
    return totalSupply;
  }
  
  async maxSupply(tokenId?: string | undefined): Promise<number> {
    let maxSupply = NaN;
    try{
      const supply = await contractAt({
        address: this.c.options.address,
        chain: this.c.options.chain,
        artifacts: NFTWithErc1155UnmintedPersonal
      });
      maxSupply = parseInt(await supply.methods.maxSupply(tokenId).call())
    }catch(err){
      maxSupply = NaN;
    }
    return maxSupply;
  }

  contractURI(): Promise<string> {
    // @todo: contractURI method is not existed
    // return this.c.methods.contractURI().call();
    return Promise.resolve("");
  }

  tokenURI(tokenId: string): Promise<string> {
    return this.c.methods.tokenURI(tokenId).call();
  }

  setApproveForAll(tokenId: string, operator: string, approved: boolean, options:any): Promise<void> {
    return send(
      REQUIRE_APPROVE_FOR_ALL ? 
        this.c.methods.setApprovalForAll(operator, approved):
        this.c.methods.approve(operator, tokenId), 
      options
    )
  }

  async transferEventsImp(props:SearchEventProps): Promise<TransferEvent[]> {
    // event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);
    const { from, to, tokenId, fromBlock = 'earliest', toBlock = 'latest', withStamp = false } = props;
    const singles = await this.c.getPastEvents('Transfer', {
      filter: { from, to, tokenId }, fromBlock, toBlock
    });

    const events = singles.map((evt:any) => ({
      event: evt.event,
      blockNumber: $num(evt.blockNumber),
      transactionIndex: $num(evt.transactionIndex),
      from: evt.returnValues.from,
      to: evt.returnValues.to,
      tokenId: evt.returnValues.tokenId,
      quantity: 1,
    }));
    
    if(!withStamp)
      return events;

    const web3 = this.config.web3;
    return Promise.all(events.map(async (evt:any) => ({
      ...evt,
      timestamp: (await web3.eth.getBlock(evt.blockNumber)).timestamp
    })));
  }
}