import { Box, Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useContext } from "react";
import { UiContext } from "../../../context/app"
import { DataGridHeader } from "../styled-components";

export default ({ listings }: { listings: [] }) => {
  const { strings } = useContext(UiContext);

  const listingColumns: GridColDef[] = [
    {
      field: 'unitPrice',
      headerName: strings('DATAGRID_UNIT_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
    },
    {
      field: 'USDUnitPrice',
      headerName: strings('DATAGRID_USD_UNIT_PRICE'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
    },
    {
      field: 'quantity',
      headerName: strings('DATAGRID_QUANTITY'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
    },
    {
      field: 'expiration',
      headerName: strings('DATAGRID_EXPIRATION'),
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: DataGridHeader,
    },
    {
      field: 'from',
      headerName: strings('DATAGRID_FROM'),
      editable: false,
      sortable: false,
      renderHeader: DataGridHeader,
    },
    {
      field: ' ',
    }

  ]
  return (
    <Box sx={{ flex: 1, flexWrap: 'nowrap', overflowX: 'scroll' }}>
      <DataGrid
        columns={listingColumns}
        rows={listings}
        disableColumnMenu
        hideFooter
        autoHeight
        selectionModel={'none'}
        sx={{ borderWidth: 0 }}
        components={{
          NoRowsOverlay: () =>
            <Alert severity="info">
              {strings('NO_OFFERS')}
            </Alert>,
        }}
      />
    </Box>
  )
}