import { PageBase } from "../../components/page-base";
import { createView } from "../../views";

const { default: PageHomeCard } = createView('page-home');

export const PageHome = () => {

  return (
    <PageBase>
      <PageHomeCard />
    </PageBase>
  )
}
