import { useEffect, useState } from "react";
import { useAppContext } from "../../components/hooks";
import { INftCollection } from "../../models";
import { createView } from "../../views";

const { default: CollectionSimpleView } = createView('collection-simple-view');

interface CollectionSimpleCardProps{
    collection?:INftCollection,
}

export const CollectionSimpleCard = ({ collection }: CollectionSimpleCardProps) => {
    const context = useAppContext();
    return (
        <CollectionSimpleView collection={collection} />
    )
}


