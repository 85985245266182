import { 
  Grid,
  Button, TextField, 
  Card, CardContent, CardActions, CardMedia, CardHeader,
} from '@mui/material';
import { DataGridPro, GridEvents, useGridApiRef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppContext, useNftBase } from '../../components/hooks';
import { PageBase } from '../../components/page-base';
import { INftToken, INftTokenMeta } from '../../models';
import { batchUpload, listNfts, nftId } from '../../services/market';

// collections/{hash}
// nfts/{hash}

// 1. set chain id
// 2. set contract
// 3. load totalsupply
// 4. load nft meta from firebase

const columns = [
  { field: 'id' },
  { field: 'tokenId'},
  { field: 'name', valueGetter: (p:any) => p.row.meta?.name },
  { field: 'description', valueGetter: (p:any) => p.row.meta?.description },
  { field: 'image', valueGetter: (p:any) => p.row.meta?.image },
  { field: 'animation_url', valueGetter: (p:any) => p.row.meta?.animation_url },
  { field: 'timestamp' },
];

export const PageTestBatch = () => {
  const context = useAppContext();
  const gridRef = useGridApiRef();
  const [targetSupply, setTargetSupply] = useState(204);
  const [nfts, setNfts] = useState<INftToken[]>([]);
  const [mappings, setMappings] = useState<any>({});
  const [cursor, setCursor] = useState(0);
  const [jsonStr, setJsonStr] = useState('');
  
  const connectContract = async () => {
    const chainId = '1'; //prompt("Chain Id");
    const contract = '0xf5002317F40285dCeDb97644a8a16251592A2B3a';//prompt("Input contract address");
    const description = 'Chang Ting-Tong’s project She & You & Her is a contemporary cautionary tale about our relationship with technology: do we control it or does it control us? The QK drop comes with a unique viewing experience with a special guest in the love hotel where She & You & Her is shown. After the viewing, the collector can take home a photo with the special guest as a souvenir.';
    const image = 'https://firebasestorage.googleapis.com/v0/b/soyl-market.appspot.com/o/images%2FQK_Version.gif?alt=media&token=1e3b4069-56cc-453a-ba87-4dbc31cecbd6';
    const namePattern = 'QK Version # [tokenId]';
    
    if(!chainId || !contract) return;

    const chain = parseInt(chainId);
    const { items: nfts} = await listNfts(context, {chain, contract});
    const amount = Math.max(nfts.length, targetSupply);

    nfts.forEach(n => {
      if(!n.timestamp)
        n.timestamp = moment().unix();
      delete n.saleOptions
    });

    let next = nfts;

    if(nfts.length < amount) {
      const less = amount - nfts.length;
      const newNfts:INftToken[] = [...Array(less)].map((val,index)=>({
        chain,
        contract,
        id: nftId(chain, contract, `${nfts.length + index + 1}`),
        tokenId: `${nfts.length + index + 1}`,
        meta: {
          name: namePattern.replace('[tokenId]', `${nfts.length + index + 1}`.padStart(3,'0')),
          description,
          image
        },
        timestamp: moment().unix()
      }));
      next = [...nfts, ...newNfts].sort((a:any,b:any) => (parseInt(a.tokenId) - parseInt(b.tokenId)));
    }
    const map:{[key:string]:number} = {};
    next.forEach((item,index)=>{
      if(!!item.id)
        map[item.id] = index;
    });
    setMappings(map);
    setNfts(next);
  }

  const setMeta = useCallback((changes:INftTokenMeta) => {
    if(cursor > nfts.length) return;
    const next = [...nfts];
    next[cursor].meta = {...nfts[cursor].meta, ...changes};
    setNfts(next);
  }, [cursor, nfts, setNfts]);

  const importJson = (value:string) => {
    const imports:INftToken[] = JSON.parse(value);
    const tokens:{[key:string]:INftToken} = {};
    
    let chain:number = 1;
    let contract:string = "0x8bd54bcB46E44150784dc47cE0Ac81Dc756bC460";

    nfts.forEach(n => {
      if(!!n.tokenId)
        tokens[n.tokenId] = n;
      if(!!n.chain) chain = n.chain;
      if(!!n.contract) contract = n.contract;
    });
    imports.forEach(n => {
      if(!!n.tokenId)
        tokens[n.tokenId] = {
          timestamp: moment().unix(),
          ...tokens[n.tokenId], 
          ...n,
          chain,
          contract,
          id: nftId(chain, contract, n.tokenId)
        };
    });

    const map:{[key:string]:number} = {};
    const next = Object.values(tokens);
    next.forEach(n => {
      if(!!n.id && !!n.tokenId)
        map[n.id] = parseInt(n.tokenId) - 1;
    });
    setMappings(map);
    setNfts(next);
  }

  const overwrite = async () => {
    if(window.confirm("This operation will override all existed data, continue?") == false)
      return;
    
    try{
      await batchUpload(context, nfts);
      alert('Upload Completed!');
    }catch(err:any){
      alert(err.message);
    }
  }

  useEffect(()=>{
    if(!gridRef.current) return;
    return gridRef.current.subscribeEvent(
      GridEvents.selectionChange, 
      (params:any, event:any, details:any) => {
        const [target] = params;
        const cur = mappings[target];
        setCursor(cur);
      }
    );
  },[gridRef, mappings]);

  const nft = nfts[cursor] || undefined;

  return (
    <PageBase>
      <Grid container m={2} spacing={2} sx={{height: '100vh'}}>

        <Grid item xs={12} md={3}>
          <Button 
            fullWidth
            onClick={connectContract}>
              Contract
            </Button>
          <Card>
            {
              !!nft?.meta?.animation_url &&
              <CardMedia 
                height={300}
                component="video"
                poster={nft?.meta?.image}
                src={nft?.meta?.animation_url}
                controls
                />
            }
            {
              !nft?.meta?.animation_url &&
              !!nft?.meta?.image &&
              <CardMedia 
                height={300}
                component="img"
                image={nft?.meta?.image}
                sx={{objectFit: 'contain'}}
                />
            }
            <CardContent>
              <TextField 
                fullWidth
                label="name" 
                onChange={e => setMeta({name: e.target.value})}
                value={nft?.meta?.name || ''} />
              <TextField 
                fullWidth
                onChange={e => setMeta({description: e.target.value})}
                label="description" 
                value={nft?.meta?.description || ''} />
            </CardContent>

            <CardActions>

              <Button component="label">
                IMG
                <input
                  accept="image/*"
                  id="image-upload"
                  type="file"
                  hidden
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    if (e.currentTarget?.files && e.currentTarget?.files.length > 0)
                      setMeta({image: URL.createObjectURL(e.currentTarget.files[0])});
                  }}
                />
              </Button>

              <Button component="label">
                VDO
                <input
                  accept="video/*"
                  id="video-upload"
                  type="file"
                  hidden
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    if (e.currentTarget?.files && e.currentTarget?.files.length > 0)
                      setMeta({animation_url: URL.createObjectURL(e.currentTarget.files[0])});
                  }}
                />
              </Button>

              <Button 
                color="warning"
                onClick={overwrite}
                >
                Overwrite
              </Button>

            </CardActions>
          </Card>

          <Card>
            <CardHeader title="Import" />
            <CardContent>
              <TextField 
                fullWidth
                multiline
                label="JSON" 
                rows={10} 
                value={jsonStr}
                onChange={e => setJsonStr(e.target.value)}
                />
            </CardContent>
            <CardActions>
              <Button onClick={() => importJson(jsonStr)}>UPDATE</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={9}>
          <DataGridPro 
            apiRef={gridRef}
            rows={nfts} 
            columns={columns}
            pageSize={20}
            />
        </Grid>

      </Grid>
    </PageBase>
  );

}