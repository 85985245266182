import { contractAt } from "../utils/contractat";
import { ADDRESS_ZERO } from "../../../../models";
import Ownerable from '../../../../contracts/Ownable.json';
import RoyaltyFeeManager from '../../../../contracts/RoyaltyFeeManager.json';
import RoyaltyFeeRegistry from '../../../../contracts/RoyaltyFeeRegistry.json';

enum ActivityTypes {
  UNKONWN = "UNKONWN",
  MINT = "UNKONWN",
  TRANSFER = "TRANSFER",
  BURN = "BURN",
}

export enum NftProtocol {
  UNKONWN = "UNKONWN",
  ERC721 = "ERC-721",
  ERC1155 = "ERC-1155",
}

export type TransferEvent = {
  event: string;
  blockNumber: number;
  transactionIndex: number;
  from: string;
  to: string;
  tokenId: string;
  quantity: number;
  timestamp: number;
}

export type SearchEventProps = {
  from?:string;
  to?:string;
  tokenId?: string;
  fromBlock?: string | number;
  toBlock?: string | number;
  withStamp?: boolean;
}

export type Ownership = {
  [key:string]:number
}

export interface IActivity {
  type: ActivityTypes;
  from: string;
  to: string;
  time: number;
  data: any;
}

export interface INftFunc {
  owner():Promise<string>;
  calculateRoyaltyFee(tokenId:string, amount:string):Promise<string>;
  royaltyInfo():Promise<{setter:string,receiver:string,fee:string}>;
  
  get protocol():NftProtocol;
  exists(tokenId: string): Promise<boolean>;
  isOwner(tokenId:string, account:string):Promise<boolean>;
  isApproved(tokenId:string, owner:string, operator:string):Promise<boolean>;
  isOfficial(nftTradeAddress:string):Promise<boolean>;
  ownershipsOf(options:SearchEventProps): Promise<{[key:string]:Ownership}>;
  ownersOf(options:SearchEventProps): Promise<string[]>;
  ownersCountOf(options:SearchEventProps): Promise<number>;
  ownedQuantity(tokenId:string, owner:string): Promise<number>;
  totalSupply(tokenId?:string):Promise<number>;
  maxSupply(tokenId?:string):Promise<number>;
  contractURI(): Promise<string>;
  tokenURI(tokenId:string):Promise<string>;
  setApproveForAll(tokenId:string, operator: string, approved: boolean, options:any):Promise<void>;
  transferEvents(props:SearchEventProps): Promise<TransferEvent[]>;

}

export abstract class NftFuncBase implements INftFunc {
  protected config:{web3:any, chain: number, address:string};

  constructor(web3:any, chain: number, address:string){
    this.config = {web3,chain,address};
  }

  owner():Promise<string> {
    const {web3, chain, address} = this.config;
    return contractAt({
      web3, chain, address, 
      artifacts: Ownerable
    }).methods.owner().call().catch(() => ADDRESS_ZERO);
  }

  calculateRoyaltyFee(tokenId:string, amount:string):Promise<string> {
    const {web3, chain, address} = this.config;
    return contractAt({
      web3, chain, address, 
      artifacts: RoyaltyFeeManager,
    }).methods.calculateRoyaltyFeeAndGetRecipient(address, tokenId, amount).call();
  }

  async royaltyInfo():Promise<{setter:string,receiver:string,fee:string}> {
    const {web3, chain, address} = this.config;
    const {setter, receiver, fee} = await contractAt({
      web3, chain, address, 
      artifacts: RoyaltyFeeRegistry,
    }).methods.royaltyFeeInfoCollection(address).call();
    return {setter, receiver, fee};
  }


  async ownershipsOf(options:SearchEventProps): Promise<{ [key: string]: Ownership; }> {
    const events = await this.transferEvents({...options, withStamp: false});
    const ownership:{[key:string]:Ownership} = {};
    events.forEach(evt => {
      let { tokenId, from, to } = evt;
      // sender
      if(!(from in ownership))
        ownership[from] = {};
      if(!(tokenId in ownership[from]))
        ownership[from][tokenId] = 0;
      ownership[from][tokenId] -= evt.quantity;

      // receiver
      if(!(to in ownership))
        ownership[to] = {};
      if(!(tokenId in ownership[to]))
        ownership[to][tokenId] = 0;
      ownership[to][tokenId] += evt.quantity;
    });

    // remove empty
    delete ownership[ADDRESS_ZERO];
    let emptyOwners = [];
    for(let owner in ownership) {
      let owns = ownership[owner];
      let ownedQty = 0;
      let emptyTokens = [];
      for(let tokenId in owns) {
        if(owns[tokenId] === 0)
          emptyTokens.push(tokenId);
        ownedQty += owns[tokenId];
      }
      for(let tokenId of emptyTokens)
        delete ownership[owner][tokenId];

      if(ownedQty === 0)
        emptyOwners.push(owner);
    }

    for(let owner of emptyOwners)
      delete ownership[owner];

    return ownership;
  }

  async ownersOf(options: SearchEventProps): Promise<string[]> {
    const ownersihps = await this.ownershipsOf(options);
    return Object.keys(ownersihps);
  }

  async ownersCountOf(options: SearchEventProps): Promise<number> {
    return (await this.ownersOf(options)).length;
  }
  
  abstract get protocol():NftProtocol;
  abstract exists(tokenId: string): Promise<boolean>;
  abstract isOwner(tokenId: string, account: string): Promise<boolean>;
  abstract isApproved(tokenId: string, owner: string, operator: string): Promise<boolean>;
  abstract isOfficial(nftTradeAddress:string):Promise<boolean>;
  abstract ownedQuantity(tokenId: string, owner: string): Promise<number>;
  abstract totalSupply(tokenId?: string): Promise<number>;
  abstract maxSupply(tokenId?:string):Promise<number>;
  abstract contractURI(): Promise<string>;
  abstract tokenURI(tokenId: string): Promise<string>;
  abstract setApproveForAll(tokenId: string, operator: string, approved: boolean, options: any): Promise<void>;
  abstract transferEventsImp(props:SearchEventProps): Promise<TransferEvent[]>;

  async transferEvents(props:SearchEventProps): Promise<TransferEvent[]> {
    const readEventsRange = async (start: number, end: number): Promise<TransferEvent[]> => {
      try{
        const evts = await this.transferEventsImp({...props, fromBlock:start, toBlock: end});
        return evts;
      } catch (err:any) {
        if(err.message === 'Returned error: query returned more than 10000 results') {
          const middle = Math.round((start + end) / 2);
          const evts1 = await readEventsRange(start,middle);
          const evts2 = await readEventsRange(middle+1, end);
          return [...evts1, ...evts2];
        } else {
          throw err;
        }
      }
    }

    const start = !props.fromBlock || props.fromBlock === 'earliest' ? 0 : props.fromBlock as number;
    const end = !props.toBlock || props.toBlock === 'latest' ? await this.config.web3.eth.getBlockNumber() : props.toBlock as number;
    const evts = await readEventsRange(start, end);

    return evts;
  }
}