import { useContext, useState } from "react";
import {
  Box, Stack,
  OutlinedInput, Button, Typography, Drawer, Paper
} from "@mui/material";
import { UiContext } from "../../../context/app";
import { IListOptions } from "../../../models";
import { AdvanceOption } from "../styled-components";
import { theme } from "../theme";

export default ({ onApplyFilter, listOptions, showFilterDrawer, onClose }: any) => {
  const { strings, mobile } = useContext(UiContext);
  const [fileterListOptions, setFileterListOptions] = useState<IListOptions>({ ...listOptions, range: [0, 9999] });

  const BaseAdvOpts = () => {
    return (
      <>
        <Box>
          <AdvanceOption
            title='Price'
            accordionProps={{ disableGutters: true, defaultExpanded: true, sx: { boxShadow: 0 } }}>
            <Stack direction='row' spacing='10px' sx={{ justifyContent: 'space-between' }}>
              <OutlinedInput
                placeholder='From'
                onChange={(e) =>
                  setFileterListOptions({
                    ...fileterListOptions,
                    range: [e.target.value, fileterListOptions.range && fileterListOptions?.range[1] || '9999']
                  })}
                value={fileterListOptions.range && fileterListOptions.range[0]}
              />
              <OutlinedInput
                placeholder='To'
                onChange={(e) => setFileterListOptions({
                  ...fileterListOptions,
                  range: [fileterListOptions.range && fileterListOptions.range[0] || '0', e.target.value]
                })}
                value={fileterListOptions.range && fileterListOptions.range[1]}
              />
            </Stack>
          </AdvanceOption>
          {/* @todo hide 尚無內容 */}
          {/* <AdvanceOption
          title='Tags'
          expandIcon={expandIcon}>
        </AdvanceOption> */}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: '20px',
        }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => {
              if (fileterListOptions.range && fileterListOptions.range[0] && fileterListOptions.range[1])
                onApplyFilter({
                  ...fileterListOptions,
                  page: 0,
                  range: [parseFloat(fileterListOptions.range[0]), parseFloat(fileterListOptions.range[1])]
                })
            }
            }
            sx={{ borderRadius: '8px' }}>
            {strings('APPLY')}
          </Button>
        </Box>
      </ >
    )
  }
  const MobileAdvDrawer = () => (
    <Drawer
      anchor="left"
      sx={{ zIndex: theme.zIndex.appBar - 1 }}
      open={showFilterDrawer}
      onClose={onClose}
      PaperProps={{
        sx: {
          mt: '55px',
          width: '100%',
        }
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: '20px 0 30px 0' }}>
        <Button
          sx={{ position: 'absolute', top: '16px', right: '8px' }}
          onClick={onClose}>
          Close
        </Button>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '22px',
            fontWeight: 'bold',
          }}>
          FILTER
        </Typography>
      </Box>
      <BaseAdvOpts />
    </Drawer>
  )
  const DesktopAdvOpts = () => {
    return (
      <Paper>
        <Typography
          variant='h6'
          paragraph
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: '16px',
            pt: '8px',
          }}>
          {strings('FILTER')}
        </Typography>
        <BaseAdvOpts />
      </Paper>
    )
  }
  return (
    mobile ? <MobileAdvDrawer /> : < DesktopAdvOpts />
  )
};