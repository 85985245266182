import { IContext, MAX_INT } from "../../models";
import { create } from "./crypto/service-nft";
import { contractAt } from "./crypto/utils/contractat";
import { send } from "./crypto/utils/send";


export const approveCoin = 
  async (context:IContext, currency:string, operator:string, amount:string) => {
    const { web3, wallet } = context;
    const artifacts = require('./artifacts/IERC20.json');
    const contract = contractAt({chain: wallet.chainId, web3, artifacts, address: currency});
    const allowance = await contract.methods.allowance(wallet.account, operator).call();

    if(web3.utils.toBN(allowance).lt(web3.utils.toBN(amount)))
      send(
        contract.methods.approve(operator, MAX_INT), 
        {from: wallet.account}
      );
  }

export const approveNft =
  async (context:IContext, contract:string, tokenId:string, operator:string) => {
    const { web3, wallet } = context;
    const nftc = await create(web3, wallet.chainId, contract);
    if(false == await nftc.isApproved(tokenId, wallet.account, operator))
      await nftc.setApproveForAll(tokenId, operator, true, {from: wallet.account});
  }