import { AppBar, Toolbar, Stack, Button, Box } from "@mui/material"
import { useContext } from "react"
import { useAppContext } from "../../../components/hooks"
import { saleButtonType } from "../../../components/utils"
import { UiContext } from "../../../context/app"
import { INftToken, UserPower } from "../../../models"
import { strings } from "../../../strings"
import { CancelBookButton } from "../button-cancel-book"

interface NftToolbarViewProps {
  nft: INftToken,
  finished?: () => void,
  onSaleTokens?: number,
  availableAmount?: number,
}

export default ({ nft, finished, onSaleTokens = 0, availableAmount = 0 }: NftToolbarViewProps) => {
  const { strings } = useContext(UiContext);
  const { wallet } = useAppContext();
  const SaleButtons = () => (
    <Stack direction={'row'} spacing={2}>
      {
        nft &&
        onSaleTokens > 0 &&
        <CancelBookButton
          nft={nft}
          finished={finished}>
          {strings('CANCEL_SELLING')}
        </CancelBookButton>
      }
      {
        nft &&
        availableAmount > 0 &&
        <Button
          variant='contained'
          href={`/nft/${nft.chain}/${nft.contract}/${nft.tokenId}/sell`}>
          {strings('SELL')}
        </Button>
      }
    </Stack>
  );
  return (
    <Box>
      <AppBar position='fixed' color={'inherit'} sx={{ zIndex: 2, marginTop: '58px' }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <SaleButtons />
        </Toolbar>
      </AppBar>
    </Box>
  )
}