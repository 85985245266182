import { useRef, useContext, useState, useEffect } from "react";
import {
  Box,
  Card, CardMedia, CardContent, CardActions,
  Typography,
  Divider,
  Button, Link
} from "@mui/material";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Countdowner } from "../../../components/countdowner";
import { useAppContext } from "../../../components/hooks";
import { NamedUser } from "../../../components/named-user";
import { Column, Row } from "../../../components/page-base";
import { $addr, $price } from "../../../components/utils";
import { UiContext } from "../../../context/app";
import { Summaries, SellMethods, SellStates, INftToken } from "../../../models";
import { stateOf } from "../../../services/market";
import moment from "moment";

const stringOf = (obj: any, key: string): string | undefined => {
  if (!obj) return undefined;
  if (key in obj && typeof obj[key] == 'string') return obj[key];
  return undefined;
}

const timingOf = (state: SellStates | undefined, begin: number, end: number) => {
  if (!state) return '';
  let timing = '';
  switch (state) {
    case SellStates.COMMING:
      timing = moment.unix(begin).fromNow();
      break;
    case SellStates.SELLING:
      timing = moment.unix(end).fromNow();
      break;
    case SellStates.ENDING:
      timing = 'ending';
      break;
    default:
      timing = '';
      break;
  }
  return timing;
}
interface NftCardProps {
  nft: INftToken,
  showCountdown?: boolean,
}
export default (props: NftCardProps) => {
  const cardRef = useRef<any>();
  const { wallet } = useAppContext();
  const { nft } = props;
  const { strings, mobile } = useContext(UiContext); // don't remove this

  const [cardMediaWidth, setCardMediaWidth] = useState();

  const state = stateOf(nft);

  let collectionName: string = '';
  let tokenName: string = '';
  let collectionOwner: string | undefined;
  let tokenStatusHint: any | undefined;
  let timing: string | undefined;
  let action: string | undefined = strings("VIEW");
  let href: string | undefined;
  let isMine: boolean = nft.owner == wallet.account;

  if (!!nft.contract && !!nft.tokenId) {
    href = `/nft/${nft.chain}/${nft.contract}/${nft.tokenId}`;
  }

  if (nft.summaries) {
    collectionName =
      stringOf(nft.summaries, Summaries.COLLECTION_NAME) ||
      $addr(nft.contract);
    collectionOwner =
      stringOf(nft.summaries, Summaries.COLLECTION_OWNER);
  }

  if (nft.meta) {
    tokenName = nft.meta?.name || '';
  }

  if (!!nft.saleOptions) {
    switch (nft.saleOptions?.method) {
      case SellMethods.FIXED_PRICE:
      case SellMethods.SELL_WITH_DECLINING_PRICE: {
        action = isMine ? strings('VIEW') : strings("BUY_NOW");
        tokenStatusHint = <>
          <FontAwesomeIcon icon={faEthereum} />
          &nbsp;
          {$price(nft.saleOptions.price || '0', 4)}
        </>;
        timing = timingOf(
          state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.SELL_TO_HIGHEST_BIDDER: {
        action = isMine ? strings('VIEW') : strings("PLACE_BID");
        tokenStatusHint = <>
          {strings('CARD_TOP_BID')}
          &nbsp;
          <FontAwesomeIcon icon={faEthereum} />
          &nbsp;
          {$price(nft.saleOptions.price || '0', 4)}
        </>;
        timing = timingOf(state,
          nft.saleOptions.duration.begin,
          nft.saleOptions.duration.end);
        break;
      }

      case SellMethods.NOT_FOR_SELL:
      default:
        action = strings("VIEW");
        tokenStatusHint = undefined;
        timing = undefined;
        break;
    }
  }

  const videoPlayer = (event: any, state?: string) => {
    let playPromise = event.target.play();
    if (playPromise !== undefined && state === 'play')
      playPromise.then(() => { }).catch((error: any) => { })
    if (playPromise && state === 'pause')
      playPromise.then(() => { event.target.pause() }).catch((error: any) => { })
  }

  useEffect(() => {
    setCardMediaWidth(cardRef?.current?.clientWidth)
    window.addEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth))
    return () => window.removeEventListener('resize', () => setCardMediaWidth(cardRef?.current?.clientWidth));
  }, [])

  return (
    <Link underline='none' href={href}>
      <Card ref={cardRef} sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        boxShadow: 0,
        borderRadius: 0,
      }}>
        <CardMedia
          component='video'
          sx={{ objectFit: 'cover', height: cardMediaWidth && cardMediaWidth * 1.27 }}
          src={props.nft.meta?.animation_url}
          poster={props.nft.meta?.image}
          onClick={(event: any) => videoPlayer(event, 'play')}
          onMouseEnter={(event: any) => videoPlayer(event, 'play')}
          onMouseLeave={(event: any) => videoPlayer(event, 'pause')}
          loop
        />
        <CardContent
          sx={{
            flex: 1,
            height: '100%',
            mt: 'auto',
            mb: 0,
            backgroundColor: 'red',
          }}>


          <Column sx={{ flex: 1 }}>

            <Typography variant='body1' sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }} >
              {`${collectionName} (${tokenName})`}
            </Typography>

            {!!collectionOwner &&
              <Typography>
                <NamedUser account={collectionOwner} />
              </Typography>
            }

            {(!!tokenStatusHint || !!timing) &&
              <Row sx={{ justifyContent: 'space-between', pt: '25.6px' }}>
                <Typography>{tokenStatusHint}</Typography>
                <Typography>{timing}</Typography>
              </Row>
            }
          </Column>

          {
            props.showCountdown &&
            (state === SellStates.COMMING || state === SellStates.SELLING) &&
            <>
              <Divider sx={{ my: mobile ? '4px' : 1 }} />
              <Box>
                <Typography variant="body1">
                  {
                    state === SellStates.COMMING ?
                      strings('SALE_START_AT')
                      : strings('SALE_ENDS')
                  }
                </Typography>
                <Countdowner
                  targetTime={
                    state === SellStates.COMMING ?
                      nft.saleOptions?.duration?.begin || 0
                      : nft.saleOptions?.duration?.end || 0
                  } />
              </Box>
            </>
          }
        </CardContent>

        {
          !!action && !!href &&
          <CardActions sx={{ px: 0 }}>
            <Button
              variant='outlined'
              sx={{
                borderRadius: '20px',
                color: '#707070',
                px: 4,
              }}
              color='secondary'
              href={href}
            >
              {action}
            </Button>
          </CardActions>
        }
      </Card>
    </Link>
  )

}
