import sha256 from "crypto-js/sha256";
import moment from "moment";
import { Chains, INftToken, SellStates } from "../../models";
import { web3ReadOnly } from "./crypto";

export const collectionId =
  (chain: Chains, contract: string): string =>
    sha256(`${chain}/${contract}`).toString();


export const nftId =
  (chain: Chains, contract: string, tokenId: string): string =>
    sha256(`${chain}/${contract}/${tokenId}`).toString();


// todo: support TradingMethod
export const stateOf = (token: INftToken) => {
  if (!token.saleOptions || !token.saleOptions.duration)
    return SellStates.NONE;

  const { begin, end } = token.saleOptions.duration;

  // todo: check other reference of state

  if (moment.unix(begin).isAfter(moment()))
    return SellStates.COMMING;
  if (moment().isBetween(moment.unix(begin), moment.unix(end)))
    return SellStates.SELLING;
  if (moment.unix(end).isBefore(moment()))
    return SellStates.ENDING;
}

export const $num = (value:any, defaultValue:number = 0) => {
  if (typeof value == 'number') return value;
  if (typeof value == 'string') return parseInt(value);
  return defaultValue;
}

export const expectedMaxSupply = (mintSig?:string, chainId?:number) => 
  !!(mintSig && chainId)?
  web3ReadOnly(chainId).utils.toBN(`0x${mintSig.substring(132)}`).toNumber(): 0;