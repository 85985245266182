import { 
  Container, Grid, Box,
  Typography, 
  useTheme, useMediaQuery, 
} from "@mui/material";
import { useContext, useMemo } from "react";
import { PageBaseOfCampaign } from "../../../campaign-20220316/components/page-base-campaign";
import { UiContext } from "../../../context/app";

export const PageServices = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { strings } = useContext(UiContext); // don't remove this

  
  return (
    <PageBaseOfCampaign sx={{
      background:'url(/assets/images/about-bg.jpg)',
      backgroundSize: 'cover',
      backgroundPosition:'center',
      }}>
        <Container disableGutters sx={{overflow: 'hidden'}}>

          <Grid container p={0} m={0}>

          <Grid item xs={12} 
              sx={{
                display: 'flex', 
                alignItems: 'center', 
                flexDirection: 'column',
                height: mobile ? 'calc(100vh - 180px)' : 'calc(100vh - 128px)', 
                justifyContent: 'center',
              }}>
          <Box mx={mobile ? 5 : 24}  sx={{
            backgroundColor:'rgba(255,255,255,0.9)',
          }}>
            <Typography variant="h1" mt={5.5} mx={mobile ? 5 : 26} align="center" mb={3.25} sx={{
              color:'#333333', 
              fontSize: '42px'
            }}>
              SERVICE
            </Typography>
            <Typography variant="body1" ml={mobile ? 4.25 : 14.5} mr={mobile ? 5 : 11.25} mb={7.5} sx={{
              color:'#000000',
              fontSize: '15px'
            }}>
              <span dangerouslySetInnerHTML={{__html:strings("SERVICES_DESC")}} />
            </Typography>
          </Box>


            </Grid>
          </Grid>
        </Container>
    </PageBaseOfCampaign>
  )
}