const appName = process.env.REACT_APP_NAME?.toLowerCase() || 'soyl';
const views: { [key: string]: any } = {};

views[appName] = {
  'page-home': require(`./${appName}/page-home`),

  'adv-view': require(`./${appName}/adv-view`),
  'collection-info-view': require(`./${appName}/collection-info-view`),
  'collection-simple-view': require(`./${appName}/collection-simple-view`),
  'nft-card-view': require(`./${appName}/nft-card-view`),
  'nft-toolbar-view': require(`./${appName}/nft-toolbar-view`),
  'nft-viewer-view': require(`./${appName}/nft-viewer-view`),
  'nft-title-view': require(`./${appName}/nft-title-view`),
  'nft-auction-view': require(`./${appName}/nft-auction-view`),
  'nft-detail-view': require(`./${appName}/nft-detail-view`),
  'nft-activity-view': require(`./${appName}/nft-activity-view`),
  'nft-offer-view': require(`./${appName}/nft-offer-view`),
  'nft-pricing-view': require(`./${appName}/nft-pricing-view`),
  'nft-listing-view': require(`./${appName}/nft-listing-view`),

  'page-base-background-view': require(`./${appName}/page-base/background-view`),
  'page-base-nav-view': require(`./${appName}/page-base/nav-view`),
  'page-base-mobile-nav-view': require(`./${appName}/page-base/nav-view/mobile`),
  'footer-view': require(`./${appName}/page-base/footer-view`),

  'CancelBookButton': require(`./${appName}/button-cancel-book`),
  'search-toolbar-view': require(`./${appName}/search-toolbar-view`),
  'advance-options-view': require(`./${appName}/advance-options-view`),
}
export const createView = (viewName: string): any => {
  return views[appName][viewName];
}