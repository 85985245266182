import { ContractProps, RoyaltyInfo } from ".";
import { IContext } from "../../../models";
import { supportsInterface, InterfaceIds } from "../crypto/utils/supportsInterface";
import { ADDRESS_ZERO } from '../../../models';
import { contractAt, send } from "../crypto";
import artifacts from '../../../contracts/NftMarketResaller.json';

export async function comission(context: IContext, chain: number):Promise<number>{
  return (
      await contractAt({ chain, artifacts })
        .methods
        .comission()
        .call()
    ).toNumber() * 0.001;
}

export async function setComission(context: IContext, chain: number, comission:number):Promise<void>{
  const { wallet, web3 } = context;
  const resaller = contractAt({ web3, chain, artifacts })
  const maxBookDuration = await resaller.methods.maxBookDuration().call();
  const minBookDuration = await resaller.methods.minBookDuration().call();

  return send(
    resaller
      .methods
      .alterFormula(
        Math.floor(comission * 1000).toString(),
        maxBookDuration,
        minBookDuration),
    { from: wallet.account }
  );
}

export async function royaltyInfo(context: IContext, contractProps:ContractProps, tokenId:string):Promise<RoyaltyInfo>{
  const { chain, address } = contractProps;

  if(!(await supportsInterface({ chain, address }, InterfaceIds.IRoality )))
    return { rate: 0, account: ADDRESS_ZERO};

  const nftc = contractAt({ chain, address, artifacts: require('../../../contracts/IRoality.json') });
  const rate = (await nftc.methods.roality().call()).toNumber() * 0.001;
  const account = await nftc.methods.roalityAccount().call();

  return { rate, account };
}

export async function setupRoyaltyInfo(context: IContext, contractProps:ContractProps, config: RoyaltyInfo):Promise<void>{
  const { wallet, web3 } = context;
  const { chain, address } = contractProps;

  if(!(await supportsInterface({ chain, address, web3 }, InterfaceIds.IRoality )))
    throw new Error("Given contract not support royalty change.");

  const nftc = contractAt({ chain, address, artifacts: require('../../../contracts/IRoality.json') });
  
  if(config.rate !== undefined || config.rate !== null)
    await send(nftc.methods.setRoality(`${Math.floor(config.rate * 1000)}`), {from:wallet.account});

  if(!!config.account)
    await send(nftc.methods.setRoalityAccount(config.account), {from: wallet.account});
  
}

export function maxFee(context: IContext, chain:number, nftContract:string, tokenId:string):Promise<string> {
  throw new Error('MaxFee calculation Not supported.');
}