import { useState, useContext, useEffect } from "react";
import { Box, Typography, Select, MenuItem, Alert, CircularProgress } from "@mui/material";
import { Axis, Grid, XYChart, BarSeries, LineSeries } from "@visx/xychart";
import moment from "moment";
import { $price } from "../../../components/utils";
import { INftToken, INftTokenPricingHistory } from "../../../models";
import { UiContext } from "../../../context/app";
import { listPricings } from "../../../services/market";
import { useAppContext } from "../../../components/hooks";

const PERIODS = [0, 7, 14, 30, 60, 90, 365];
const defaultMargin = { top: 30, right: 20, bottom: 30, left: 10 };
const hintMargin = '40px';

const loadScreen = (
  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', my: hintMargin }}>
    <CircularProgress />
  </Box>
)

const determinePeriodType = (days: number) => {
  if (days === 0) return "ALL";
  if (days >= 365) return "YEAR";
  return "DAYS";
}

const caculateAveragePrice = (records: INftTokenPricingHistory[]) => {
  if (records.length === 0) return 0;
  const priceList = records.map((record: any) => parseFloat($price(record.price)));
  return priceList.reduce((pre, current) => pre + current) / priceList.length;
}

// Todo: volume規則未定
const caculateVolume = (records: any[]) => records.length;

const PeriodPriceDetail = (props: any) => (
  < Box >
    <Typography>{props.title}</Typography>
    <Typography sx={{ fontWeight: "bold" }}>{`Ξ ${props.price}`}</Typography>
  </Box >
)

export default ({ nft }: { nft: INftToken }) => {
  const context = useAppContext();
  const { strings } = useContext(UiContext);

  const [loading, setLoading] = useState(false);
  const [historyPeriod, setHistoryPeriod] = useState(0);
  const [allPricing, setAllPricing] = useState<INftTokenPricingHistory[]>([]);

  const { chain, contract, tokenId } = nft;
  const options = { chain, contract, tokenId };

  const xAccessor = (d: INftTokenPricingHistory) => `${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('M')}/${moment(moment.unix(d.timestamp), 'YYYY/MM/DD').format('D')}`;
  const yAccessor = (d: INftTokenPricingHistory) => $price(d.price);
  const pricing = historyPeriod === 0 ?
    allPricing :
    allPricing.filter(history =>
      moment.unix(history.timestamp).isAfter(moment().add(-historyPeriod, 'days'))
    );
  const avgPrice = caculateAveragePrice(pricing);
  const volume = caculateVolume(pricing);

  useEffect(() => {
    if (!nft.id) return;
    setLoading(true);
    listPricings(context, options)
      .then(setAllPricing)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [nft]);

  if (loading) return loadScreen;

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Select
          value={historyPeriod}
          onChange={(e) => setHistoryPeriod(Number(e.target.value))}>
          {
            PERIODS.map(period => (
              <MenuItem
                key={period}
                value={period}>
                {strings(`PERIOD_${determinePeriodType(period)}`).replace('[day]', period.toString())}
              </MenuItem>))
          }
        </Select>

        <PeriodPriceDetail
          title={strings(`${determinePeriodType(historyPeriod)}_AVG_PRICE`).replace('[day]', historyPeriod.toString())}
          price={avgPrice} />

        {
          determinePeriodType(historyPeriod) !== "ALL" &&
          <PeriodPriceDetail
            title={strings(`${determinePeriodType(historyPeriod)}_VOLUME`).replace('[day]', historyPeriod.toString())}
            price={volume} />
        }
      </Box>

      {/* TODO: 1. dynamic width & height  */}
      {
        !pricing ||
          pricing.length === 0 ?
          <Alert severity="info" sx={{ my: hintMargin }}>There is no records</Alert>
          :
          <XYChart
            height={Math.max(300 - defaultMargin.bottom - defaultMargin.top, 250)}
            xScale={{ type: "band", padding: 0.8 }}
            yScale={{ type: "linear" }} >
            <Axis orientation='left' numTicks={5} />
            <Axis orientation='bottom' numTicks={Math.max(5, pricing.length)} />
            <Grid columns={false} />
            <BarSeries data={pricing} dataKey={"priceBar"} xAccessor={xAccessor} yAccessor={yAccessor} />
            <LineSeries data={pricing} dataKey={"priceLine"} xAccessor={xAccessor} yAccessor={yAccessor} />
          </XYChart>
      }
    </>
  );
}