import { contractAt } from "../utils/contractat";
import { NftFuncBase, NftProtocol, SearchEventProps, TransferEvent } from "./abstract";
import { send } from "../utils/send";
import { ADDRESS_ZERO } from "../../../../models";
import IERC1155 from '../../../../contracts/IERC1155.json';
import IERC1155MetadataURI from '../../../../contracts/IERC1155MetadataURI.json';
import ERC1155Supply from '../../../../contracts/ERC1155Supply.json';
import NFTWithErc1155UnmintedPersonal from '../../../../contracts/NFTWithErc1155UnmintedPersonal.json';
import { $num } from "../../func-pure";
import _ from "lodash";

export class NftFunc1155 extends NftFuncBase {
  private web3:any;
  private chain:number;
  private address:string;
  private c:any;

  constructor(web3:any, chain: number, address:string){
    super(web3,chain,address);
    
    this.web3 = web3;
    this.chain = chain;
    this.address = address;
    this.c = contractAt({
      chain, web3, address, 
      abi: [...IERC1155.abi, ...IERC1155MetadataURI.abi]
    });
  }

  get protocol():NftProtocol {
    return NftProtocol.ERC1155;
  }

  async exists(tokenId: string): Promise<boolean> {
    try{
      // spec: exists is not standard method of ERC1155
      const supply = await contractAt({
        web3: this.web3,
        chain: this.chain,
        address: this.address,
        artifacts: require('../../../../contracts/ERC1155Supply.json')
      });
      return await supply.methods.exists(tokenId).call();
    }catch(error) {
      console.warn(`Contract ${this.address} doesn't support exist function.`); 
    }

    // spec: if supply not working, count mint event instead.
    return (await this.transferEvents({from: ADDRESS_ZERO, tokenId})).length > 0;
  }

  async isOwner(tokenId: string, account: string): Promise<boolean> {
    return (await this.c.methods.balanceOf(account, tokenId).call()).gt('0');
  }

  isApproved(tokenId: string, owner: string, operator: string): Promise<boolean> {
    return this.c.methods.isApprovedForAll(owner, operator).call();
  }

  async isOfficial(nftTradeAddress: string): Promise<boolean> {
    const officialNftContract = await contractAt({
      address: this.address,
      chain: this.chain,
      artifacts: NFTWithErc1155UnmintedPersonal
    });
    try{
      const tradeAddressOfContract = await officialNftContract.methods.nftTradeAddress().call();
      return nftTradeAddress === tradeAddressOfContract;
    }catch(err){
      return false;
    }
  }

  async ownedQuantity(tokenId: string, owner: string): Promise<number> {
    const balance = await this.c.methods.balanceOf(owner, tokenId).call();
    return parseInt(balance);
  }

  async totalSupply(tokenId?: string): Promise<number> {
    let output:number;
    const { web3, chain, address } = this;
    const supply = contractAt({
      web3, chain, address, 
      artifacts: ERC1155Supply
    });
    try {
      const amount = await supply.methods.totalSupply(tokenId).call();
      output = parseInt(amount);
    } catch(err:any) {
      console.warn(`Contract ${this.address} doesn't support totalSupply function.`); 
      output = NaN;
    }

    // spec: if supply not working, count mint event instead.
    if(isNaN(output)) 
      output = (await this.transferEvents({from: ADDRESS_ZERO, tokenId})).length;

    return output;
  }

  async maxSupply(tokenId?: string | undefined): Promise<number> {
    let maxSupply = 0;
    try {
      const supply = await contractAt({
        address: this.address,
        chain: this.chain,
        artifacts: NFTWithErc1155UnmintedPersonal
      });
      maxSupply = parseInt(await supply.methods.maxSupply(tokenId).call())
    } catch (err) {
      console.error(err);  
    }
    return maxSupply;
  }

  contractURI(): Promise<string> {
    // @todo: contractURI method is not existed
    // return this.c.methods.contractURI().call();
    return Promise.resolve("");
  }

  async tokenURI(tokenId: string): Promise<string> {
    return (await this.c.methods.uri(tokenId).call()).replace('{id}', tokenId);
  }

  setApproveForAll(tokenId:string, operator: string, approved: boolean, options:any): Promise<void> {
    return send(this.c.methods.setApprovalForAll(operator, approved), options);
  }

  async transferEventsImp(props:SearchEventProps): Promise<TransferEvent[]> {
    // event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value);
    // event TransferBatch(
    //     address indexed operator,
    //     address indexed from,
    //     address indexed to,
    //     uint256[] ids,
    //     uint256[] values
    // );
    const { from, to, tokenId, fromBlock = 'earliest', toBlock = 'latest', withStamp = false } = props;
    const query = { filter: {from, to}, fromBlock, toBlock };
    const singles = await this.c.getPastEvents('TransferSingle', query);
    const multiples = await this.c.getPastEvents('TransferBatch', query);
    const web3 = this.config.web3;

    let events = [];
    
    events.push(...singles.map((evt:any) => ({
      event: evt.event,
      blockNumber: $num(evt.blockNumber),
      transactionIndex: $num(evt.transactionIndex),
      from: evt.returnValues.from,
      to: evt.returnValues.to,
      tokenId: evt.returnValues.id,
      quantity: $num(evt.returnValues.value),
    })));

    events.push(...multiples.reduce((r:any[],c:any) => {
      const { event, blockNumber, transactionIndex } = c;
      const { from, to, ids, values } = c.returnValues;
      const ary = [];
      for(let i = 0; i < ids.length; i++) {
        ary.push({
          event,
          blockNumber: $num(blockNumber),
          transactionIndex: $num(transactionIndex),
          from,
          to,
          tokenId: ids[i],
          quantity: $num(values[i])
        });
      }
      r.push(...ary);
      return r;
    }, []));

    events.sort((a,b) => {
      if(a.blockNumber == b.blockNumber)
        return a.transactionIndex < b.transactionIndex ? -1 : 
          (a.transactionIndex > b.transactionIndex) ? 1 : 0;
      else
        return (a.blockNumber < b.blockNumber) ? -1 : 1;
    });

    if(!!tokenId)
      events = events.filter(e => e.tokenId === tokenId);

    if(!withStamp) 
      return events;

    return Promise.all(events.map(async evt => ({
      ...evt,
      timestamp: (await web3.eth.getBlock(evt.blockNumber)).timestamp
    })));
  }
}