import {
  Box, Button, IconButton
} from '@mui/material'
import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const DEFAULT_IMG_ACCEPT_TYPE = "image/*";
const DEFAULT_VIDEO_ACCEPT_TYPE = "video/*";


const ImagePreviewer = ({ src, alt, imgStyle, onClear }: any) => <>
  <img
    src={typeof src === 'string' ? src : URL.createObjectURL(src)}
    style={{ height: '100%', objectFit: "cover", ...imgStyle }} alt={alt} />
  {
    onClear &&
    <IconButton
      sx={{ position: 'absolute', right: 1, top: 1, color: 'gray' }}
      onClick={(e) => {
        e.stopPropagation();
        onClear()
      }}>
      <CloseIcon />
    </IconButton>
  }
</>

// TODO: audio display
export const ImageDropzone = ({ sx, imgStyle, iconStyle, acceptType, src, alt, insertIcon, onDrop, onClear }: any) => {
  return <Dropzone
    accept={acceptType || DEFAULT_IMG_ACCEPT_TYPE}
    onDrop={onDrop}>
    {
      ({ getRootProps, getInputProps }) => (
        <Box
          {...getRootProps()}
          sx={{
            position: 'relative',
            '&:hover': {
              cursor: "pointer",
              filter: 'brightness(90%)',
            },
            ...sx,
          }} >
          {
            src ? <ImagePreviewer src={src} alt={alt} imgStyle={imgStyle} onClear={onClear} /> :
              <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#00000012',
                },
                ...imgStyle
              }}>
                {insertIcon || <InsertPhotoIcon sx={{ fontSize: 40, ...iconStyle }} />}
              </Box>
          }
          <input {...getInputProps()} />
        </Box>)
    }
  </Dropzone>
}

export const VideoDropzone = ({ sx, style, accept, src, onDrop, onClear }: any) => {
  return <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      p: 0.5,
      ...sx,
    }}>

    <video
      key={src}
      style={{ width: '100%', height: 'calc(100% - 50px)', flex: 1, ...style }}
      controls loop>
      <source src={src} />
    </video>

    {
      src &&
      <IconButton
        sx={{ position: 'absolute', right: 1, top: 1, color: 'gray' }}
        onClick={(e) => {
          e.stopPropagation();
          onClear()
        }}>
        <CloseIcon />
      </IconButton>
    }

    <Dropzone
      accept={accept || DEFAULT_VIDEO_ACCEPT_TYPE}
      onDrop={onDrop}
    >
      {
        ({ getRootProps, getInputProps }) => (
          <Button sx={{
            '&:hover': {
              backgroundColor: '#00000032',
            },
          }}
            fullWidth
            {...getRootProps()}>
            Change
            <input {...getInputProps()} />
          </Button>
        )
      }
    </Dropzone>
  </Box>
}
