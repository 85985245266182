import { useEffect, useState } from "react";
import { useAppContext } from "../../components/hooks";
import { INftToken, INftTokenActivities, IPaged } from "../../models";
import { listActivities } from "../../services/market";
import { createView } from "../../views"

const { default: NftActivityView } = createView('nft-activity-view');

export const NftActivityCard = ({ nft }: { nft: INftToken }) => {
  const context = useAppContext();
  const [loading, setLoading] = useState(false);
  const [activies, setActivies] = useState<IPaged<INftTokenActivities>>()
  const { chain, contract, tokenId } = nft;
  const reload = async (types?: string[]) => {
    if(!chain || !contract || !tokenId) return;
    try {
      setLoading(true);
      const nftActivies = await listActivities(
        context, { chain, contract, tokenId, types });
      setActivies(nftActivies);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    reload();
  }, [chain, contract, tokenId]);

  return (
    <NftActivityView
      nft={nft}
      activies={activies}
      reloadActivities={reload}
      loading={loading} />
  )
}