import { useContext, useState } from "react";
import { Box, Typography, Button, InputAdornment, MenuItem } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { BlendTitleTypography, SelectBB } from "../styled-components";
import { UiContext } from "../../../context/app";

const timeSpan = [
  { days: '1', text: '1 day' },
  { days: '7', text: '1 week' },
  { days: '14', text: '2 weeks' },
  { days: '30', text: '1 month' },
  { days: '180', text: '6 months' },
  { days: '365', text: '1 year' }];
const currencies = ['ETH', 'USDT']; // @todo temp

interface TitleBarProps {
  title?: string,
  children?: any
  loadMoreHref?: string
  onClickAll: () => void,
  setPeriod: (value: string) => void,
  setCurrency: (value: string) => void,
}

export const TitleBar = (props: TitleBarProps) => {
  const { title, children, loadMoreHref } = props;
  const [times, setTimes] = useState('1');
  const [currency, setCurrency] = useState('ETH');
  const { mobile, strings } = useContext(UiContext);
  const classes = useStyle({ mobile });
  return (
    <Box>
      <Box className={classes.topBox}>
        <Box className={classes.titleBox}>
          <BlendTitleTypography title={title} fontSize={mobile ? '24px' : '28px'} />
        </Box>
        <Box className={classes.selectBox}>
          {/* <Button
            variant='outlined'
            className={classes.allButton}
            onClick={props.onClickAll}>
            ALL
          </Button> */}
          <SelectBB
            className={classes.select}
            value={times}
            startAdornment={(
              <InputAdornment position='start'>
                <Typography className={classes.adornmentText}>&nbsp;In</Typography>
              </InputAdornment>
            )}
            onChange={(e: any) => {
              props.setPeriod(e.target.value)
              setTimes(e.target.value)
            }}>
            {
              timeSpan.map((time) => <MenuItem key={time.text} value={time.days} >{time.text}</MenuItem>)
            }
          </SelectBB>
          {/* <SelectBB
            className={classes.select}
            defaultValue={currency}
            value={currency}
            onChange={(e: any) => {
              props.setCurrency(e.target.value)
              setCurrency(e.target.value)
            }}>
            {
              currencies.map((currency) => <MenuItem key={currency} value={currency}>{currency}</MenuItem>)
            }
          </SelectBB> */}
        </Box>
      </Box >
      <Box className={classes.children}>
        {children}

        {
          loadMoreHref &&
          <Box className={classes.footer}>
            <Button variant='outlined' href={loadMoreHref} className={classes.loadButton}>{strings('LOAD_MORE')}</Button>
          </Box>
        }
      </Box>
    </Box >
  )
};

const useStyle = makeStyles({
  topBox: {
    position: 'relative',
    display: ({ mobile }: any) => mobile ? 'inline' : 'flex',
    alignItems: 'center',
    padding: ({ mobile }: any) => mobile ? '' : '83px 0 60px',

  },
  titleBox: {
    display: 'flex',
    flex: 1,
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: ({ mobile }: any) => mobile ? '60px 0 33px 0' : '',
  },
  selectBox: {
    display: 'flex',
    position: ({ mobile }: any) => mobile ? 'static' : 'absolute',
    right: 0,
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    height: ({ mobile }: any) => mobile ? '40px' : 'none',
    background: ({ mobile }: any) => mobile ? '#252525' : 'none',
    borderRadius: ({ mobile }: any) => mobile ? '90px' : 0,
    padding: ({ mobile }: any) => mobile ? '5px 10px 5px 10px' : '',
  },
  select: {
    display: 'flex',
    maxHeight: ({ mobile }: any) => mobile ? '30px' : '40px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'rgba(0, 255, 240, 0.9)',
    '& fieldset': {
      borderRadius: 50,
      borderColor: '#74FBEF',
      borderWidth: '3px',
    },
  },
  allButton: {
    maxHeight: '30px'
  },
  adornmentText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  children: {
  },
  footer: {
    alignItem: 'center',
    textAlign: 'center',
    marginTop: ({ mobile }) => mobile ? '17px' : '60px',
  },
  loadButton: {
    textTransform: 'none',
    fontSize: ({ mobile }) => mobile ? '20px' : '24px',
    padding: ({ mobile }) => mobile ? '12px 34px 12px 34px' : '18px 32px 18px 32px',
    height: ({ mobile }) => mobile ? '45px' : '60px',
    width: '200px'
  }
})